import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import Select from 'react-select'

import * as Routes from '../../routes'
import { fetchResultData } from '../AnalyticsDashboard/utils'
import { showLoading } from '../entries/utils'
import ChartSection from './components/ChartSection'

const fetchEmailData = async (dealershipSlug: string, template?: string) => {
  if (!dealershipSlug) return null

  const fetchUrl = Routes.analytics_dealership_emails_path(dealershipSlug, {
    template: template,
  })
  return fetchResultData(fetchUrl)
}

const EmailAnalytics: React.FC = () => {
  const { dealershipSlug } = useParams()
  const [template, setTemplate] = useState<string | undefined>()

  const {
    data: emailData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['emailAnalytics', dealershipSlug, template],
    queryFn: () => fetchEmailData(dealershipSlug as string, template),
    enabled: !!dealershipSlug, // Ensure query runs only when dealershipSlug is available
    staleTime: 5 * 60 * 1000, // Cache data for 5 minutes
    refetchOnWindowFocus: false, // Prevent automatic refetching on window focus
  })

  const emailStatusToClass = (status: string) => {
    switch (status) {
      case 'click':
      case 'open':
        return 'text-success'
      case 'bounce':
      case 'blocked':
        return 'text-warning'
      default:
        return ''
    }
  }

  const subText = (
    <ul className="mt-3">
      <li>
        {emailData?.emails_sent?.total} total emails in the {emailData?.emails_sent?.timeframe_name}
      </li>
    </ul>
  )

  const analytics = () => (
    <>
      <div className="row">
        {emailData?.statuses?.map((data: any, index: number) => (
          <div className="col-md-3" key={`email-status-${index}`}>
            <div className="box p-2 text-center rounded-corners">
              <h4 className={emailStatusToClass(data.status.toLowerCase())}>{data.percentage}%</h4>
              <p className="mb-0 text-secondary">{data.status}</p>
              <small className="text-secondary">
                {data.count} {data.status}
              </small>
            </div>
          </div>
        ))}
      </div>
      <div className="row">
        <div className="col-md-6">
          <ChartSection
            title="Emails Sent"
            subtitle={emailData?.emails_sent?.timeframe_name || 'Last 30 days'}
            chartData={emailData?.emails_sent?.daily_counts}
            chartType="Bar"
            subText={subText}
          />
        </div>
        <div className="col-md-6">
          <ChartSection
            title="Email Status"
            subtitle={emailData?.email_status?.timeframe_name || 'Last 30 days'}
            chartData={emailData?.email_status?.status_distribution}
            chartType="Pie"
          />
        </div>
      </div>
    </>
  )

  return (
    <div className="p-4 w-100">
      <div className="d-flex align-items-center mb-3">
        <h4 className="mb-0">Email analytics</h4>
        <div className="ml-auto">
          <Select
            placeholder="Template..."
            options={emailData?.email_templates}
            isClearable
            onChange={(option: { value: string } | null) => setTemplate(option?.value)}
            value={emailData?.email_templates.find((option: any) => option.value === template)}
          />
        </div>
      </div>
      {isLoading ? (
        showLoading()
      ) : error ? (
        <p className="text-danger">Error loading data</p>
      ) : (
        analytics()
      )}
    </div>
  )
}

export default EmailAnalytics
