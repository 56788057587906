import { ReactNode } from 'react'

import { Sidebar } from 'primereact/sidebar'

interface SlideInProps {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  children: ReactNode
  header?: ReactNode
}

const sidebarStyle = (): React.CSSProperties => ({
  height: '100%',
  width: '100%',
  maxHeight: '100vh',
  maxWidth: '1000px',
  borderRadius: 0,
  backgroundColor: '#fff',
})

const SlideIn: React.FC<SlideInProps> = ({ isOpen, children, setIsOpen, header }) => {
  return (
    <Sidebar
      visible={isOpen}
      onHide={() => setIsOpen(false)}
      blockScroll={true}
      dismissable={true}
      position="right"
      style={sidebarStyle()}
      header={header}
    >
      {children}
    </Sidebar>
  )
}

export default SlideIn
