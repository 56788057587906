import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Link } from 'react-router-dom'
import Select from 'react-select'

import * as Routes from '../../routes'
import { standardHeaders } from '../entries/utils'

const fetchCarsNeedingStockImages = async (manufacturerId) => {
  let url = Routes.cars_needing_stock_images_stock_image_sets_path()
  if (manufacturerId) {
    url += `?manufacturer_id=${manufacturerId}`
  }

  const response = await fetch(url, { headers: standardHeaders })
  if (!response.ok) throw new Error('Failed to fetch data')
  return response.json()
}

const useCarsNeedingStockImages = (selectedManufacturer) => {
  return useQuery({
    queryKey: ['carsNeedingStockImages', selectedManufacturer],
    queryFn: () => fetchCarsNeedingStockImages(selectedManufacturer),
    staleTime: 5 * 60 * 1000, // Cache data for 5 minutes
    keepPreviousData: true, // Prevent UI flicker while loading new data
  })
}

const Button = (rowData) => (
  <>
    <Link
      className="btn btn-outline-primary btn-sm mr-2"
      to={Routes.new_stock_image_set_path()}
      state={{
        manufacturer_id: rowData.manufacturer?.id,
        family_id: rowData.variant?.family_id,
        year: rowData.year,
        badge: rowData.badge,
        simple_colour: rowData.simple_colour,
        bodyconfiguration: rowData.variant?.bodyconfiguration,
      }}
    >
      Add Stock Image
    </Link>
    <Link
      className="btn btn-outline-primary btn-sm"
      to={Routes.dealership_car_path(rowData.dealership.slug, rowData.slug)}
    >
      View Car
    </Link>
  </>
)

const Model = (rowData) => (
  <>
    <div>{rowData.model}</div>
    <div className="small text-secondary">{rowData.badge || '(No Badge)'}</div>
    <div className="small text-secondary">{rowData.variant?.bodyconfiguration}</div>
  </>
)

const ToDoCars = () => {
  const [selectedManufacturer, setSelectedManufacturer] = useState(null)

  const { data, isLoading } = useCarsNeedingStockImages(selectedManufacturer)

  // Extract manufacturer options
  const manufacturerOptions = data?.manufacturers?.map((manufacturer) => ({
    value: manufacturer.id,
    label: manufacturer.name,
  }))

  const selectedManufacturerOption = manufacturerOptions?.find(
    (manufacturer) => manufacturer.value === selectedManufacturer
  )

  return (
    <div className="box mb-3">
      <div className="p-3">
        <div className="d-flex">
          <div>
            <h5>Cars which need stock images</h5>
            <div className="text-secondary">
              {data?.total} {selectedManufacturerOption?.label} cars currently need stock images
              added
            </div>
          </div>
          <div className="ml-auto">
            <Select
              options={manufacturerOptions}
              onChange={(e) => setSelectedManufacturer(e?.value)}
              placeholder="Filter by Manufacturer"
              value={selectedManufacturerOption}
            />
          </div>
        </div>
      </div>
      <DataTable
        value={data?.cars}
        loading={isLoading}
        paginator
        rows={5}
        rowsPerPageOptions={[5, 10, 25, 50]}
      >
        <Column field="year" header="Year" />
        <Column field="manufacturer.name" header="Make" />
        <Column field="model" header="Model" body={Model} />
        <Column field="simple_colour" header="Colour" />
        <Column field={Button} header="Buttons" />
      </DataTable>
    </div>
  )
}

export default ToDoCars
