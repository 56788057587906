import { useRef } from 'react'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Toast } from 'primereact/toast'
import { useParams } from 'react-router-dom'

import * as Routes from '../../../routes'
import { standardHeaders } from '../..//entries/utils'
import { Form, FormInput, FormSelect } from '../../formUtils'

export interface WebsiteStockSettingsFormData {
  include_sold_cars_days: number
  default_search_path?: string
}

export interface WebsiteStockSettings {
  dealership_websites: DealershipWebsite[]
  website: {
    id: number
  } & WebsiteStockSettingsFormData
}

interface DealershipWebsite {
  id: number
  allow_link_from_dealership_websites_to_website: boolean
  cars_count: number
  dealership: Dealership
}

interface Dealership {
  id: number
  name: string
}

const selectOptions = Array.from({ length: 11 }, (_, i) => i.toString())

/**
 * Form for controlling the number of days to include sold cars in the stock search on a website,
 * as well as the default search path on a website
 */
export const StockSettingsForm = ({
  defaultValues,
}: {
  defaultValues: WebsiteStockSettingsFormData
}) => {
  const { websiteSlug } = useParams()
  const queryClient = useQueryClient()
  const notification = useRef<Toast>(null)

  const { mutate, isPending } = useMutation({
    mutationFn: (data: WebsiteStockSettingsFormData) =>
      fetch(Routes.website_path(websiteSlug), {
        method: 'PATCH',
        headers: standardHeaders,
        body: JSON.stringify({
          website: {
            include_sold_cars_days: parseInt(`${data.include_sold_cars_days}`),
            default_search_path: data.default_search_path,
          },
        }),
      }).then((res) => {
        if (!res.ok) throw new Error('Failed to update stock settings')
        return res
      }),
    onSuccess: () => {
      notification.current?.show({
        severity: 'success',
        summary: 'Stock Settings Saved',
        detail: 'Settings have been updated successfully',
        life: 5000,
      })
      queryClient.invalidateQueries({ queryKey: ['stock', websiteSlug] })
    },
    onError: (error) => {
      notification.current?.show({
        severity: 'error',
        summary: 'Error Saving Settings',
        detail: error instanceof Error ? error.message : 'An unexpected error occurred',
        life: 5000,
      })
    },
  })

  return (
    <div className="box p-3">
      <Toast ref={notification} />
      <Form onSubmit={async (data) => mutate(data)} defaultValues={defaultValues}>
        <FormSelect
          name="include_sold_cars_days"
          label="Include sold cars for how many days?"
          required
          options={selectOptions}
        />
        <FormInput
          name="default_search_path"
          label="Default search path (the search path that has no user filters applied)"
          placeholder="e.g. new-used-and-demo-cars"
        />

        <button className="btn btn-outline-primary" type="submit">
          {isPending ? 'Loading...' : 'Update'}
        </button>
      </Form>
    </div>
  )
}
