import { Tooltip } from 'react-tooltip'

import { EditingPreviewTotal } from '../../../contexts/PreviewDataContext'
import { ModuleComponentConfig, ModuleItem } from '../../../types'
import { getColor } from '../../../utils'

const PreviousTimePeriod: React.FC<{
  previousPeriod: number
  lastYear: number
  previousPeriodDuration: string[]
  lastYearDuration: string[]
  moduleConfig: ModuleComponentConfig
  item: ModuleItem
  editingPreviewTotal: EditingPreviewTotal | null
}> = ({
  previousPeriod,
  lastYear,
  previousPeriodDuration,
  lastYearDuration,
  moduleConfig,
  item,
  editingPreviewTotal,
}) => {
  const shouldReverse =
    moduleConfig.title === 'Cost Per Click' || moduleConfig.title === 'Cost Per Lead'

  const lastYearToShow = editingPreviewTotal?.[item?.module]?.lastYear || lastYear
  const previousPeriodToShow = editingPreviewTotal?.[item?.module]?.previousPeriod || previousPeriod
  const lastYearDurationToShow =
    editingPreviewTotal?.[item?.module]?.lastYearDuration || lastYearDuration
  const previousPeriodDurationToShow =
    editingPreviewTotal?.[item?.module]?.previousPeriodDuration || previousPeriodDuration

  return (
    <div className="row">
      <div className="col-6">
        <small className="text-secondary">Previous year</small>
        <p className="mb-0">
          <b
            className={getColor(lastYearToShow, shouldReverse)}
            id={`lastYear-${moduleConfig.title}-${lastYearToShow || 0}`}
          >
            {lastYearToShow ? lastYearToShow.toLocaleString() + '%' : 'N/A'}
          </b>
        </p>
        <Tooltip
          anchorId={`lastYear-${moduleConfig.title}-${lastYearToShow || 0}`}
          place="bottom"
          style={{ zIndex: 10000 }}
        >
          {lastYearDurationToShow
            ? `from ${lastYearDurationToShow?.[0]} to ${lastYearDurationToShow?.[1]}`
            : ''}
        </Tooltip>
      </div>
      <div className="col-6 d-flex justify-content-end flex-column align-items-end">
        <small className="text-secondary">Previous period</small>
        <p
          className="mb-0"
          id={`previousPeriod-${moduleConfig.title}-${previousPeriodToShow || 0}`}
        >
          <b className={getColor(previousPeriodToShow, shouldReverse)}>
            {previousPeriodToShow ? previousPeriodToShow.toLocaleString() + '%' : 'N/A'}
          </b>
        </p>
        <Tooltip
          anchorId={`previousPeriod-${moduleConfig.title}-${previousPeriodToShow || 0}`}
          place="bottom"
          style={{ zIndex: 10000 }}
        >
          {previousPeriodDurationToShow
            ? `from ${previousPeriodDurationToShow?.[0]} to ${previousPeriodDurationToShow?.[1]}`
            : ''}
        </Tooltip>
      </div>
    </div>
  )
}

export default PreviousTimePeriod
