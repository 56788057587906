import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Link, useParams } from 'react-router-dom'
import Select from 'react-select'

import * as Routes from '../../routes'
import Loading from '../Loading'
import { humanize } from '../editor/common/commonUtils'
import { standardHeaders } from '../entries/utils'
import { Toggle } from '../settings/leadSettings'
import CarScore from './CarScore'
import { Subtitle } from './CarSearchItem'

const Car = (rowData) => {
  let { dealershipSlug } = useParams()
  let car = rowData.car
  return (
    <div className="d-flex">
      <img
        src={car.primary_image_url}
        alt={car.name}
        height={50}
        className="rounded mr-3"
        style={{ objectFit: 'cover' }}
      />
      <div>
        <div>
          <Link to={Routes.dealership_car_path(dealershipSlug, car.slug)}>{car.name}</Link>
        </div>
        <small className="text-secondary">
          <Subtitle car={car} />
        </small>
        <div className="small text-secondary">
          Leads: {car.lead_clusters_count} | Views: {car.views}
        </div>
      </div>
    </div>
  )
}

export const CarGrade = (rowData) => {
  let car = rowData.car

  if (!car.car_grade) {
    return <small className="text-secondary">Not Graded</small>
  }

  return <CarScore hit={car} />
}

const TimeData = ({ dateTime }) => {
  const now = moment()
  const endTime = moment(dateTime)

  const duration = moment.duration(endTime.diff(now))
  const days = duration.days()
  const hours = duration.hours()

  return (
    <>
      {moment(dateTime).format('DD/MM/YY HH:mm')}
      <div className="small text-secondary">
        {days}d and {hours}h
      </div>
    </>
  )
}

const StartedAt = (rowData) => {
  return <TimeData dateTime={rowData.created_at} />
}

const Reasons = (rowData) => {
  return (
    <ul>
      {rowData.reasons.map((reason, i) => (
        <li key={i}>{reason}</li>
      ))}
    </ul>
  )
}

const EndsAt = (rowData) => {
  if (!rowData.end_time) {
    return <span>Nil end time</span>
  }

  return <TimeData dateTime={rowData.end_time} />
}

const Ignore = (rowData) => {
  let { dealershipSlug } = useParams()

  return (
    <Toggle
      objectType={'stock_restriction'}
      attributeName={'ignored'}
      rowData={rowData}
      url={`/dealerships/${dealershipSlug}/stock_restrictions/${rowData.id}`}
    />
  )
}

let options = ['used', 'new', 'demo'].map((o) => ({ value: o, label: humanize(o) }))
let statusOptions = ['in_stock', 'sold', 'loaner'].map((o) => ({ value: o, label: humanize(o) }))
let stockRestrictionStatusOptions = ['current', 'expired'].map((o) => ({
  value: o,
  label: humanize(o),
}))

const fetchStockRestrictions = async ({ queryKey }) => {
  const [, dealershipSlug, carType, status, stockRestrictionStatus] = queryKey
  const response = await fetch(
    Routes.stock_restrictions_dealership_cars_path(dealershipSlug, {
      car_type: carType,
      status: status,
      stock_restriction_status: stockRestrictionStatus,
    }),
    {
      headers: standardHeaders,
    }
  )
  if (!response.ok) {
    throw new Error('Failed to fetch stock restrictions')
  }
  return response.json()
}

const SmartPublisher = () => {
  const { dealershipSlug } = useParams()

  const [carType, setCarType] = useState(options[0])
  const [status, setStatus] = useState(statusOptions[0])
  const [stockRestrictionStatus, setStockRestrictionStatus] = useState(
    stockRestrictionStatusOptions[0]
  )

  // TanStack Query hook
  const { data, isLoading, error } = useQuery({
    queryKey: [
      'stockRestrictions',
      dealershipSlug,
      carType.value,
      status.value,
      stockRestrictionStatus.value,
    ],
    queryFn: fetchStockRestrictions,
    staleTime: 5 * 60 * 1000, // Cache data for 5 minutes
    refetchOnWindowFocus: false, // Avoid refetching when switching tabs
  })

  if (error) return <div>Error loading stock restrictions.</div>

  return (
    <div className="p-3 w-100">
      <div className="d-flex mb-2">
        <h4>Smart Publisher</h4>
        <div className="ml-auto d-flex">
          <Link
            to={`/dealerships/${dealershipSlug}/cars/smart_publisher_settings`}
            className="btn btn-outline-primary mr-2"
          >
            Settings
          </Link>
          <Select
            options={statusOptions}
            defaultValue={status}
            onChange={setStatus}
            className="mr-2"
          />
          <Select
            options={stockRestrictionStatusOptions}
            defaultValue={stockRestrictionStatus}
            onChange={setStockRestrictionStatus}
            className="mr-2"
          />
          <Select options={options} defaultValue={carType} onChange={setCarType} />
        </div>
      </div>

      <div className="box">
        <div className="p-3">
          <h5>{carType.label} Cars</h5>
          {data?.stats && (
            <div>
              <ul>
                <li>
                  Total {carType.label} cars: {data.stats.total_cars}
                </li>
                {stockRestrictionStatus.value === 'current' && (
                  <>
                    <li>Current Stock Restrictions: {data.stats.stock_restrictions_count}</li>
                    <li>Percentage Restricted: {data.stats.percentage_restricted}%</li>
                  </>
                )}
              </ul>
            </div>
          )}
          {isLoading && <Loading />}
        </div>

        <DataTable value={data?.stock_restrictions || []} loading={isLoading}>
          <Column body={Car} header="Car" />
          <Column field="reasons" header="Reason" body={Reasons} />
          <Column sortable body={CarGrade} field="car_grade" header="Car Grade" />
          <Column field="created_at" body={StartedAt} header="Started" />
          <Column field="end_time" body={EndsAt} header="Ends" />
          <Column field="ignored" body={Ignore} header="Ignore?" />
        </DataTable>
      </div>
    </div>
  )
}

export default SmartPublisher
