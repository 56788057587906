import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'

import * as Routes from '../../routes'
import Loading from '../Loading'
import { standardHeaders } from '../entries/utils'
import { SectionHeading } from '../entries/utils'
import { CopyableText } from '../shared/CopyableText'

const fetchFtpAccounts = async () => {
  const res = await fetch(Routes.ftp_accounts_path(), { headers: standardHeaders })
  return res.json()
}

const Created = (rowData) => <>{moment(rowData.created_at).fromNow()}</>

const Active = (rowData) => {
  if (rowData.active) {
    return <i className="fa fa-check text-success"></i>
  }

  return <i className="fa fa-times text-danger"></i>
}

const FtpAccounts = () => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['ftp_accounts'],
    queryFn: () => fetchFtpAccounts(),
    keepPreviousData: true,
  })

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <SectionHeading title="FTP Accounts">
        <a className="btn btn-outline-secondary" href={Routes.new_ftp_account_path()}>
          Add New
        </a>
      </SectionHeading>
      <div className="box">
        <DataTable value={data}>
          <Column field="created_at" header="Created" body={Created} />
          <Column field="name" header="Name" sortable />
          <Column
            field="username"
            header="Username"
            body={(r) => <CopyableText text={r.username} />}
          />
          <Column
            field="password"
            header="Password"
            body={(r) => <CopyableText text={r.password} />}
          />
          <Column
            field="server_address"
            header="Address"
            body={(r) => <CopyableText text={r.server_address} />}
          />
          <Column field="schema" header="Schema" />
          <Column field="active" header="Active" body={Active} sortable />
          <Column body={(rowData) => <a href={Routes.ftp_account_path(rowData.id)}>Show</a>} />
          <Column body={(rowData) => <a href={Routes.edit_ftp_account_path(rowData.id)}>Edit</a>} />
          <Column
            body={(rowData) => (
              <a href={Routes.ftp_account_path(rowData.id)} data-method="delete">
                Destroy
              </a>
            )}
          />
        </DataTable>
      </div>
    </>
  )
}

const Wrapper = () => (
  <div className="container py-4">
    <FtpAccounts />
  </div>
)

export default Wrapper
