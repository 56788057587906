import moment from 'moment-timezone'
import { Panel } from 'primereact/panel'

const LeadClusterStatusChanges = ({ statusChanges }) => {
  return (
    <Panel header="Timeline" toggleable>
      <div className="lead-cluster-status-timeline">
        {statusChanges.map((statusChange, index) => (
          <div key={index} className="timeline-event">
            <div className="timeline-dot"></div>
            <div className="timeline-content">
              {(() => {
                let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
                let formattedTime = moment
                  .utc(statusChange.created_at)
                  .tz(timezone)
                  .format('DD/MM/YYYY, h:mm:ss a')
                return (
                  <>
                    <div className="timeline-date">{formattedTime}</div>
                    <h5 className="timeline-state">
                      <span className={`badge ${statusChange.badge_colour}`}>
                        {statusChange.new_status}
                      </span>
                    </h5>
                    <p className="timeline-user">{statusChange.user.name}</p>
                  </>
                )
              })()}
            </div>
          </div>
        ))}
      </div>
    </Panel>
  )
}

export default LeadClusterStatusChanges
