import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'

import { Select } from '../entries/FormElements'
import { standardHeaders } from '../entries/utils'
import { SectionHeading } from '../entries/utils'

const fetchExternalIdentifiers = async (dealershipId) => {
  const data = await fetch(Routes.external_identifiers_path({ dealership_id: dealershipId }), {
    headers: standardHeaders,
  })
  return data.json()
}

const Table = ({ data }) => {
  return (
    <DataTable value={data.external_identifiers} paginator rows={10}>
      <Column field="dealership.name" header="Dealership" sortable />
      <Column field="location.name" header="Location" sortable />
      <Column field="identifier_name" header="Identifier Name" sortable />
      <Column field="primary_code" header="Primary Code" sortable />
      <Column field="secondary_code" header="Secondary Code" sortable />
      <Column field="manufacturer.name" header="Manufacturer" sortable />
      <Column
        body={(rowData) => <a href={Routes.external_identifier_path(rowData.id)}>Show</a>}
        header=""
      />
      <Column
        body={(rowData) => <a href={Routes.edit_external_identifier_path(rowData.id)}>Edit</a>}
        header=""
      />
      <Column
        body={(rowData) => (
          <Button
            label="Destroy"
            icon="pi pi-trash"
            className="p-button-danger"
            onClick={() => confirm(`Are you sure you want to delete this?`)}
          />
        )}
        header=""
      />
    </DataTable>
  )
}

export default function ExternalIdentifiersTable() {
  let [dealershipId, setDealershipId] = useState(null)

  const { data, isLoading, error } = useQuery({
    queryKey: ['external_identifiers', dealershipId],
    queryFn: () => fetchExternalIdentifiers(dealershipId),
  })

  if (error) return <p>Error fetching data</p>

  let dealershipOptions = data?.dealerships.map((dealership) => ({
    label: dealership.name,
    value: dealership.id,
  }))

  return (
    <div className="container py-3">
      <SectionHeading title="Listing External Identifiers">
        <a href={Routes.new_external_identifier_path()} className="btn btn-outline-primary">
          New External Identifier
        </a>
      </SectionHeading>
      <div className="row">
        <div className="col-md-4">
          <Select
            options={dealershipOptions}
            label="Dealership"
            onChange={(e) => setDealershipId(e?.value)}
          />
        </div>
      </div>
      <div className="box">{isLoading ? <p>Loading...</p> : <Table data={data} />}</div>
    </div>
  )
}
