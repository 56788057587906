import { useState } from 'react'

import { useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { standardHeaders } from '../entries/utils'

const ContactUnsubscribe = () => {
  const [email, setEmail] = useState('')
  const [unsubscribed, setUnsubscribed] = useState(false)
  let { dealershipSlug } = useParams()

  const handleSubmit = (e) => {
    e.preventDefault()

    fetch(Routes.unsubscribe_dealership_contacts_path(dealershipSlug), {
      method: 'POST',
      headers: standardHeaders,
      body: JSON.stringify({ email }),
    }).then((response) => {
      if (response.ok) {
        setUnsubscribed(true)
      } else {
        response.json().then((data) => {
          alert(data.error)
        })
      }
    })
  }

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card p-4 shadow">
            <h2 className="text-center">Unsubscribe from Emails</h2>
            {unsubscribed ? (
              <div className="alert alert-success text-center">
                You have been unsubscribed successfully.
              </div>
            ) : (
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Enter your email address:
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <button type="submit" className="btn btn-danger w-100">
                  Unsubscribe
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUnsubscribe
