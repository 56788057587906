import { useEffect, useState } from 'react'

import { Badge } from 'primereact/badge'
import { Card } from 'primereact/card'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Paginator } from 'primereact/paginator'
import ReactDOM from 'react-dom'

const Autograb = () => {
  const [dealershipData, setDealershipData] = useState([])
  const [stats, setStats] = useState({
    totalLookups: 0,
    last7DaysCount: 0,
    failedThisMonthCount: 0,
  })
  const [loading, setLoading] = useState(true)
  const [expandedRows, setExpandedRows] = useState(null)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await fetch('/admin/autograb.json')
      const data = await response.json()

      setDealershipData(data.dealerships)
      setStats({
        totalLookups: data.total_lookups,
        last7DaysCount: data.last_7_days_count,
        failedThisMonthCount: data.failed_this_month_count,
      })
    } catch (error) {
      console.error('Error fetching autograb data:', error)
    } finally {
      setLoading(false)
    }
  }

  const autograbCountTemplate = (count, failedCount) => {
    return (
      <div>
        <span>{count}</span>
        {failedCount > 0 && (
          <Badge
            value={failedCount}
            severity="danger"
            className="ml-2"
            tooltip="Failed lookups"
            tooltipOptions={{ position: 'top' }}
          />
        )}
      </div>
    )
  }

  const lastMonthTemplate = (rowData) => {
    return autograbCountTemplate(rowData.last_month_count, rowData.last_month_failed_count)
  }

  const thisMonthTemplate = (rowData) => {
    return autograbCountTemplate(rowData.this_month_count, rowData.this_month_failed_count)
  }

  const header = (
    <div className="flex flex-wrap justify-content-between align-items-center">
      <h3 className="m-0">Autograb Statistics</h3>
    </div>
  )

  const websiteRowExpansionTemplate = (data) => {
    const lastMonth = new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleString(
      'default',
      { month: 'long' }
    )
    const thisMonth = new Date().toLocaleString('default', { month: 'long' })

    return (
      <div className="p-3">
        <h5>Websites for {data.dealership_name}</h5>
        <DataTable value={data.websites} className="p-datatable-sm">
          <Column field="website_name" header="Website" />
          <Column
            header={lastMonth}
            body={(rowData) => (
              <div>
                <div className="mb-3">
                  <strong>Total:</strong>{' '}
                  {autograbCountTemplate(rowData.last_month_count, rowData.last_month_failed_count)}
                </div>
                <div className="mb-2">
                  <strong>Lookups:</strong> {rowData.last_month_lookups_count}
                </div>
                <div>
                  <strong>Valuations:</strong> {rowData.last_month_valuations_count}
                </div>
              </div>
            )}
          />
          <Column
            header={thisMonth}
            body={(rowData) => (
              <div>
                <div className="mb-2">
                  <strong>Autograbs:</strong>{' '}
                  {autograbCountTemplate(rowData.this_month_count, rowData.this_month_failed_count)}
                </div>
                <div>
                  <strong>Lookups:</strong> {rowData.this_month_lookups_count}
                </div>
                <div>
                  <strong>Valuations:</strong> {rowData.this_month_valuations_count}
                </div>
              </div>
            )}
          />
        </DataTable>
      </div>
    )
  }

  return (
    <div className="container py-4">
      <Card className="mb-4">
        <ul className="list-none p-0 m-0">
          <li className="mb-2">
            <strong>{stats.totalLookups}</strong> total Autograb lookups so far this month
          </li>
          <li className="mb-2">
            <strong>{stats.last7DaysCount}</strong> Autograb lookups in the last 7 days
          </li>
          <li className="mb-2 text-danger">
            <strong>{stats.failedThisMonthCount}</strong> failed Autograb lookups this month
          </li>
        </ul>
      </Card>

      <DataTable
        value={dealershipData}
        header={header}
        loading={loading}
        emptyMessage="No autograb data found"
        className="p-datatable-sm"
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={websiteRowExpansionTemplate}
      >
        <Column expander style={{ width: '3em' }} />
        <Column field="dealership_name" header="Dealership" sortable />
        <Column
          header={`${new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleString('default', { month: 'long' })}`}
          body={lastMonthTemplate}
          sortable
          sortField="last_month_count"
        />
        <Column
          header={new Date().toLocaleString('default', { month: 'long' })}
          body={thisMonthTemplate}
          sortable
          sortField="this_month_count"
        />
      </DataTable>
    </div>
  )
}

export default Autograb
