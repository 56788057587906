import LoadingBoxes from '../../../../entries/LoadingBoxes'
import {
  useAnalyticsDashboard,
  usePreviewData,
  useTimeRangeLocation,
} from '../../../contexts/hooks'
import { useTabs } from '../../../contexts/hooks'
import ChartComponent from '../../../features/Charts/components/ChartComponent'
import { useViewportData } from '../../../hooks'
import type { ModuleProps } from '../../../types'
import { ga4TestingData, getModuleComponentFromName } from '../../../utils'
import PreviousTimePeriod from '../../Charts/components/PreviousTimePeriod'
import { TotalForTimePeriod } from '../../Charts/components/TotalForTimePeriod'
import { usePreviousTimePeriod } from '../../Charts/hooks'
import ModuleFooters from './ModuleFooters'
import { useModuleData } from './hooks'

const allowedModulesForTotal = [
  'Google Adwords Ads',
  'Google Display Ads',
  'Google VLA Ads',
  'Google Ads',
  'Facebook Ads',
  'Google Analytics',
]

const GenericModuleComponent: React.FC<ModuleProps> = ({
  timeRange,
  item,
  selectedLocation,
  isOverlay = false,
}) => {
  const { data, loading, error, viewportRef } = useViewportData(
    item?.module,
    timeRange,
    selectedLocation,
    item.filters
  )
  const moduleConfig = getModuleComponentFromName(item?.module)
  const { editModeEnabled, dashboardLevel } = useAnalyticsDashboard()
  const { editingPreviewData, editingPreviewTotal } = usePreviewData()
  const { selectedGaWebsitePerTab } = useTimeRangeLocation()
  const { selectedTab } = useTabs()
  const {
    totalForTimePeriod,
    previousPeriod,
    lastYear,
    previousPeriodDuration,
    lastYearDuration,
    loading: previousPeriodLoading,
  } = usePreviousTimePeriod(item?.module)
  let dataToShow = useModuleData(data, item, editingPreviewData, editModeEnabled)

  // Uncomment to use ga4 testing data
  // dataToShow = item?.module === 'google_analytics' ? ga4TestingData : dataToShow

  //  If it's an allowed module, we take previous loading into account, so that everything gets loaded together. If it's not relevant i.e. that module doesnt have a previous period, we don't care about it
  const isAllowedModule = allowedModulesForTotal.includes(moduleConfig?.group)
  const globalLoading = loading || (isAllowedModule && previousPeriodLoading)

  // do not get this total if the module is Google Analytics for Dealership level or Pie/Bar chart types as they are not eligible for total
  const notEligibleForTotal =
    moduleConfig?.group === 'Google Analytics' &&
    (dashboardLevel === 'Dealership' || ['Pie', 'Bar'].includes(moduleConfig?.defaultChartType))

  const isEligibleForTotal =
    allowedModulesForTotal.includes(moduleConfig?.group) && !notEligibleForTotal

  const canShowData = error || (!globalLoading && dataToShow)

  // Filter GA modules by selected website per tab
  if (moduleConfig?.group === 'Google Analytics' && selectedGaWebsitePerTab[selectedTab]) {
    dataToShow = dataToShow?.[selectedGaWebsitePerTab[selectedTab]] || dataToShow
  }

  return (
    <div className="h-100" style={{ minHeight: 'inherit' }} ref={viewportRef}>
      {canShowData ? (
        <>
          {isEligibleForTotal ? (
            <TotalForTimePeriod
              item={item}
              moduleConfig={moduleConfig}
              totalForTimePeriod={totalForTimePeriod}
              editingPreviewTotal={editingPreviewTotal}
            />
          ) : null}
          <ChartComponent
            chartData={dataToShow || []}
            isOverlay={isOverlay}
            module={item?.module}
          />
          {isEligibleForTotal ? (
            <PreviousTimePeriod
              previousPeriod={previousPeriod}
              lastYear={lastYear}
              previousPeriodDuration={previousPeriodDuration}
              lastYearDuration={lastYearDuration}
              moduleConfig={moduleConfig}
              item={item}
              editingPreviewTotal={editingPreviewTotal}
            />
          ) : null}
        </>
      ) : (
        <LoadingBoxes />
      )}
      <ModuleFooters item={item} data={data} timeRange={timeRange} />
    </div>
  )
}

export default GenericModuleComponent
