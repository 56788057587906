import React, { useMemo, useState } from 'react'

import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { MultiSelect } from 'primereact/multiselect'

import LoadingBoxes from '../../../../entries/LoadingBoxes'
import SlideIn from '../../../../shared/SlideIn'
import { useViewportData } from '../../../hooks'
import { CampaignPerformance, ModuleProps } from '../../../types'
import TotalRow from './TotalRow'

const CampaignColumn: React.FC<{
  field: string
  data: CampaignPerformance
  reverseColor?: boolean
}> = ({ field, data, reverseColor = false }) => {
  function getColor(value: string, reverse: boolean = false): string {
    if (value?.toString()?.includes('-')) {
      return reverse ? 'text-success' : 'text-danger'
    } else {
      return reverse ? 'text-danger' : 'text-success'
    }
  }

  const differenceField = `${field}_difference`
  const percentageField = `${field}_diff_percentage`

  if (field === 'campaign_name') {
    // Check if the campaign_name is a URL
    const isURL = (str: string) => /^(https?:\/\/)/.test(str)
    const campaignName = data[field]

    // If it's a valid URL, show the image
    if (isURL(campaignName)) {
      return <img src={campaignName} alt="Campaign" style={{ maxWidth: '100px' }} />
    }

    // If it's not a URL, show the name as text
    return <span>{campaignName || 'No Image Available'}</span>
  }

  const displayValue = roundLeadsValues(field, data[field])

  return (
    <>
      <span>{displayValue}</span>
      <br />
      <small
        className={getColor(data[differenceField], reverseColor)}
        title={data[percentageField]}
        onMouseEnter={(e) => (e.currentTarget.innerText = data[percentageField])}
        onMouseLeave={(e) => (e.currentTarget.innerText = data[differenceField])}
      >
        {data[differenceField]}
      </small>
    </>
  )
}

const PerformanceByCampaign: React.FC<ModuleProps> = ({ item, timeRange, selectedLocation }) => {
  const { data, loading, viewportRef } = useViewportData(item?.module, timeRange, selectedLocation)
  const [visible, setVisible] = useState<boolean>(false)
  const [selectedCampaigns, setSelectedCampaigns] = useState<CampaignPerformance[]>([]) // Store multiple selected campaigns
  const campaigns = data?.[item?.module]
  const totalRow = campaigns?.[campaigns.length - 1] // Assume the last row is the total row
  const campaignsWithoutTotal = campaigns?.slice(0, campaigns.length - 1) // Exclude the last row for sorting
  const timeframe = (timeRange?.timeframe || 'Last 30 Days').toLowerCase()
  // Define columns dynamically
  const displayAdsExcludedColumns = ['phone_call_leads', 'form_leads']
  const isDisplayAds = item?.module?.includes('display_ads')
  const columns = [
    {
      field: 'campaign_name',
      header: 'Campaign Name',
      body: (rowData: CampaignPerformance) => (
        <CampaignColumn field="campaign_name" data={rowData} />
      ),
    },
    {
      field: 'clicks',
      header: 'Clicks',
      body: (rowData: CampaignPerformance) => <CampaignColumn field="clicks" data={rowData} />,
    },
    {
      field: 'impressions',
      header: 'Impressions',
      body: (rowData: CampaignPerformance) => <CampaignColumn field="impressions" data={rowData} />,
    },
    {
      field: 'phone_call_leads',
      header: 'Phone Call Leads',
      body: (rowData: CampaignPerformance) => (
        <CampaignColumn field="phone_call_leads" data={rowData} />
      ),
    },
    {
      field: 'form_leads',
      header: 'Form Leads',
      body: (rowData: CampaignPerformance) => <CampaignColumn field="form_leads" data={rowData} />,
    },
    {
      field: 'leads',
      header: 'Leads',
      body: (rowData: CampaignPerformance) => <CampaignColumn field="leads" data={rowData} />,
    },
    {
      field: 'cost_per_lead',
      header: 'Cost Per Lead',
      body: (rowData: CampaignPerformance) => (
        <CampaignColumn field="cost_per_lead" data={rowData} reverseColor />
      ),
    },
    {
      field: 'cost',
      header: 'Amount Spent',
      body: (rowData: CampaignPerformance) => (
        <CampaignColumn field="cost" data={rowData} reverseColor />
      ),
    },
  ]

  const columnsToShow = isDisplayAds
    ? columns.filter((col) => !displayAdsExcludedColumns.includes(col.field))
    : columns
  const optionLabel = isDisplayAds ? 'campaign_id' : 'campaign_name'
  const allConversionsData = useMemo(() => {
    return campaigns?.reduce((acc: any[], campaign: any) => {
      if (campaign?.conversions_data) {
        return [...acc, ...campaign.conversions_data]
      }
      return acc
    }, [])
  }, [campaigns])

  const filteredConversionsData = useMemo(() => {
    if (selectedCampaigns.length > 0 && allConversionsData) {
      return allConversionsData.filter((data: any) => {
        return selectedCampaigns.some((campaign) => campaign.campaign_name === data.campaign_name)
      })
    }
    return allConversionsData
  }, [selectedCampaigns, allConversionsData])

  const handleRowClick = (campaign: any) => {
    if (!isDisplayAds) {
      setSelectedCampaigns([campaign])
      setVisible(true)
    }
  }

  return (
    <div ref={viewportRef}>
      {!loading && data && campaigns ? (
        <div>
          <DataTable
            value={campaignsWithoutTotal}
            className="table table-sm table-striped mt-3"
            rows={10}
            scrollable
            scrollHeight="400px" // Adjust this value based on your design
            sortMode="multiple"
            onRowClick={(e) => handleRowClick(e.data)}
          >
            {columnsToShow.map((col, idx) => (
              <Column key={idx} field={col.field} header={col.header} body={col.body} sortable />
            ))}
          </DataTable>

          {/* Render the total row using the TotalRow component */}
          {totalRow && <TotalRow totalRow={totalRow} columns={columnsToShow} />}
        </div>
      ) : (
        <LoadingBoxes />
      )}

      <SlideIn header="Conversion Breakdown Data" isOpen={visible} setIsOpen={setVisible}>
        <div className="box position-relative mb-3 p-3 mt-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="pt-3">
              Filter By Campaigns:
              <MultiSelect
                value={selectedCampaigns}
                options={campaigns}
                onChange={(e) => setSelectedCampaigns(e.value)}
                optionLabel={optionLabel}
                display="chip"
                style={{ minWidth: '20rem' }}
                placeholder="Select Campaigns"
                filter
              />
            </div>
            {timeframe && <small className="text-secondary ml-auto">{timeframe}</small>}
          </div>
          <DataTable value={filteredConversionsData} className="p-datatable-striped mt-3">
            <Column sortable field="campaign_name" header="Campaign Name" />
            <Column sortable field="conversion_action" header="Conversion Action" />
            <Column sortable field="conversions" header="Conversions" />
          </DataTable>
        </div>
      </SlideIn>
    </div>
  )
}

export const roundLeadsValues = (field: string, value: string | number) => {
  const shouldRound = ['phone_call_leads', 'form_leads', 'leads'].includes(field)
  return shouldRound ? Math.round(Number(value)) : value
}

export default PerformanceByCampaign
