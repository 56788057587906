import { useContext, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { CurrentUserContext } from '../contexts'
import { useFetchDealership } from '../dataHooks'
import { SectionHeading, showLoading, standardHeaders } from '../entries/utils'
import { CopyableText } from '../shared/CopyableText'
import SlideIn from '../shared/SlideIn'

const Actions = (rowData) => {
  return (
    <div>
      <a
        href={Routes.edit_apikey_path(rowData.id)}
        className="btn btn-sm btn-outline-secondary mr-1"
      >
        Edit
      </a>
      <a
        href={Routes.apikey_path(rowData.id)}
        data-method="delete"
        className="btn btn-sm btn-outline-secondary"
      >
        Destroy
      </a>
    </div>
  )
}

const Dealerships = (rowData) => {
  return (
    <div className="small">
      {rowData.dealerships.map((dealership) => (
        <div key={dealership.id}>{dealership.name}</div>
      ))}
    </div>
  )
}

const Name = (rowData) => {
  let [visible, setVisible] = useState(false)
  let { dealershipSlug } = useParams()
  let fetchUrl = dealershipSlug
    ? Routes.dealership_apikey_path(dealershipSlug, rowData.id)
    : Routes.apikey_path(rowData.id)

  let { data } = useQuery({
    queryKey: [rowData.id, 'apikey'],
    queryFn: async () => {
      let res = await fetch(fetchUrl, { headers: standardHeaders })
      return res.json()
    },
    enabled: visible,
  })

  return (
    <>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault()
          setVisible(true)
        }}
      >
        {rowData.name}
      </a>
      <SlideIn header={rowData.name} isOpen={visible} setIsOpen={setVisible}>
        <div className="py-3">
          <div>
            <b>Token:</b> <CopyableText text={data?.token} />
          </div>
        </div>
      </SlideIn>
    </>
  )
}

const Apikeys = () => {
  const { dealershipSlug } = useParams()
  const { dealership } = useFetchDealership()
  const currentUser = useContext(CurrentUserContext)

  const getFetchUrl = () => {
    if (
      dealershipSlug &&
      (currentUser?.admin || currentUser?.dealerships?.[dealership?.id]?.role === 'manager')
    ) {
      return Routes.dealership_apikeys_path(dealershipSlug)
    }
    if (currentUser?.admin && !dealershipSlug) {
      return Routes.apikeys_path()
    }
    return null
  }

  const fetchApikeys = async () => {
    const fetchUrl = getFetchUrl()
    if (!fetchUrl) return null

    const res = await fetch(fetchUrl, { headers: standardHeaders })

    return res.json()
  }

  let { data, isLoading } = useQuery({
    queryKey: ['apikeys'],
    queryFn: fetchApikeys,
    enabled: !!getFetchUrl(),
  })

  if (isLoading) {
    return showLoading()
  }

  return (
    <div className="container py-3">
      <SectionHeading title="API keys">
        {currentUser?.admin && !dealershipSlug ? (
          <a href={Routes.new_apikey_path()} className="btn btn-outline-primary">
            <i className="fa fa-plus mr-1"></i>
            API Key
          </a>
        ) : (
          <a href={Routes.support_tickets_path()} className="btn btn-outline-primary">
            <i className="fa fa-plus mr-1"></i>
            Request Apikey
          </a>
        )}
      </SectionHeading>
      <div className="box overflow-hidden">
        <DataTable value={data}>
          <Column field="id" header="ID" />
          <Column field="name" header="Name" body={Name} />
          <Column field="dealerships" header="Dealerships" body={Dealerships} />
          <Column
            field="permissions"
            header="Permissions"
            body={(rowData) => <>{rowData.permissions.join(', ')}</>}
          />
          {currentUser?.admin && !dealershipSlug && (
            <Column
              field="whitelisted_ips"
              header="Whitelisted IPs"
              body={(rowData) => <>{rowData.whitelisted_ips.join(', ')}</>}
            />
          )}
          <Column
            field="mask_token"
            header="Token"
            body={(rowData) => <CopyableText text={rowData.mask_token} />}
          />
          {currentUser?.admin && !dealershipSlug && <Column header="Actions" body={Actions} />}
        </DataTable>
      </div>
    </div>
  )
}

export default Apikeys
