import { useQuery } from '@tanstack/react-query'

import * as Routes from '../../routes'
import { standardHeaders } from '../entries/utils'

const fetchWebsites = async ({ queryKey }) => {
  const [, params, ownerId, ownerClass] = queryKey

  const queryParams = params
    ? Object.keys(params)
        .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&')
    : ''

  let websitesRoute = ''
  if (ownerClass === 'Manufacturer') {
    websitesRoute = Routes.manufacturer_websites_path(ownerId)
  } else if (ownerClass === 'Dealership') {
    websitesRoute = Routes.dealership_websites_path(ownerId)
  } else {
    websitesRoute = Routes.websites_path()
  }

  const response = await fetch(`${websitesRoute}.json?${queryParams}`, {
    method: 'GET',
    headers: standardHeaders,
    credentials: 'same-origin',
  })

  if (!response.ok) {
    throw new Error('Failed to fetch websites')
  }

  return response.json()
}

export const useWebsitesQuery = (params, ownerId, ownerClass) => {
  return useQuery({
    queryKey: ['websites', params, ownerId, ownerClass],
    queryFn: fetchWebsites,
    staleTime: 1000 * 60 * 5, // Cache for 5 minutes
    retry: 2, // Retry failed requests twice
  })
}
