import { useViewportData } from '../../../hooks'
import type { ModuleProps } from '../../../types'

interface Website {
  id: number
  rank: number
  name: string
  leads_count: number
  current: boolean
}

const WebsiteRanking: React.FC<ModuleProps> = ({ item, timeRange, selectedLocation }) => {
  const { data, loading, viewportRef } = useViewportData(item?.module, timeRange, selectedLocation)
  const chartData = data?.[item?.module] || []

  return (
    <div ref={viewportRef}>
      {chartData?.length === 0 && <p className="text-center text-secondary">No Data available</p>}
      {chartData?.length > 0 && !loading && (
        <div className="position-relative" style={{ overflowY: 'auto' }}>
          <table className="table table-sm">
            <thead>
              <tr>
                <th>Rank</th>
                <th>Website</th>
                <th>Leads (last 30 days)</th>
              </tr>
            </thead>
            <tbody>
              {chartData?.map((website: Website) => (
                <tr key={website.id} className={website.current ? 'table-secondary' : ''}>
                  <td>{website.rank}</td>
                  <td>{website.name}</td>
                  <td>{website.leads_count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

export default WebsiteRanking
