import { useState } from 'react'

import { classNames } from 'primereact/utils'

import { Switch } from '../../entries/FormElements'

/**
 * Displays a SERP preview of the page's data.
 * @param title - The page's metaTitle.
 * @param description - The page's metaDescription.
 * @param url - The page's url.
 */
export const GoogleSearchPreview = ({
  title,
  description,
  url,
  type = 'mobile',
}: {
  title: string
  description: string
  url: string
  type?: 'mobile' | 'desktop'
}) => {
  const [truncate, setTruncate] = useState(true)

  const truncateTitle = (title: string) => {
    if (truncate) {
      return title.length > 60 ? title.substring(0, 57) + '...' : title
    }
    return title
  }

  const truncateDescription = (description: string) => {
    if (truncate) {
      return description.length > 160 ? description.substring(0, 157) + '...' : description
    }
    return description
  }

  const canBeTruncated = title.length > 60 || description.length > 160 // Only show the switch if the text can be truncated

  return (
    <div>
      <div className="d-flex flex-column w-100 overflow-hidden">
        <div
          id="serp_preview_url"
          className={classNames('text-success small', type === 'mobile' ? 'order-1' : 'order-2')}
        >
          {url}
        </div>
        <div
          id="serp_preview_title"
          className={classNames('text-primary', type === 'mobile' ? 'order-2' : 'order-1')}
          style={{ fontSize: '1.5rem' }}
        >
          {truncateTitle(title)}
        </div>
        <div id="serp_preview_description" className="text-muted small order-3">
          {truncateDescription(description)}
        </div>
      </div>
      {canBeTruncated && (
        <div className="d-flex justify-content-end mt-3">
          <Switch
            label={'View full text'}
            onChange={() => setTruncate(!truncate)}
            value={!truncate}
            id="view-full-text"
          />
        </div>
      )}
    </div>
  )
}
