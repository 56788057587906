import { createContext, useEffect, useState } from 'react'

import { Sidebar } from 'primereact/sidebar'
import { useParams } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

import * as Routes from '../../routes'
import { standardHeaders } from '../entries/utils'
import Form from '../shared/Form'
import HookFormField from '../shared/hookFormField'

let attributes = {
  name: { type: 'text', label: 'Name', required: true },
  url: {
    type: 'text',
    label: 'URL',
    hint: 'ALWAYS USE HTTPS://WWW FOR WEBSITES UNLESS USING A SUBDOMAIN',
    required: true,
  },
  manufacturer_id: { type: 'select', label: 'Manufacturer', options: 'manufacturers' },
  vercel_account_id: { type: 'select', label: 'Vercel Account', options: 'vercel_accounts' },
  primary_developer_id: { type: 'select', label: 'Primary Developer', options: 'admin_users' },
  launch_date: { type: 'date', label: 'Launch Date' },
  dealerstudio: {
    type: 'switch',
    label: 'Dealer Studio',
  },
  published: {
    type: 'switch',
    label: 'Published',
  },
  use_pages_path: {
    type: 'switch',
    label: 'Use Pages Path',
    hint: 'Determines whether pages are loaded under /pages/[slug] or /[slug]',
  },
  build_model_pages: {
    type: 'switch',
    label: 'Build Model Pages',
    hint: 'Build website model pages at /models/[slug]',
  },
  offers_subscription: {
    type: 'switch',
    label: 'Offers Subscription',
    hint: 'Is the website a subscription website? e.g., Loopit website or primarily is for car subscriptions',
  },
  enable_accessory_addons: {
    type: 'switch',
    label: 'Enable Accessories',
    hint: 'Enable accessory addons which can be used on the checkout enquiry forms',
  },
  vercel_repo_name: {
    type: 'vercelSelect',
    label: 'Select Vercel Project',
  },
}

const DetailsTab = () => {
  return (
    <>
      <HookFormField attribute="name" />
      <HookFormField attribute="url" />
      <HookFormField attribute="manufacturer_id" />
      <HookFormField attribute="primary_developer_id" />
      <HookFormField attribute="launch_date" />
      <HookFormField attribute="published" />
      <HookFormField
        attribute="dealerstudio"
        hint={() => (
          <>
            Is website hosted by Dealer Studio?{' '}
            <b>If this is turned OFF the stock will NOT be updated</b>
          </>
        )}
      />
      <HookFormField attribute="use_pages_path" />
      <HookFormField attribute="build_model_pages" />
      <HookFormField attribute="offers_subscription" />
      <HookFormField attribute="enable_accessory_addons" />
    </>
  )
}

const VercelTab = ({ website }) => {
  return (
    <>
      <HookFormField attribute="vercel_account_id" />
      <HookFormField attribute="vercel_repo_name" website={website} />
    </>
  )
}

let WebsiteForm = ({ newWebsite = false, website, formData, loadLazyData, setVisible }) => {
  let { dealershipSlug } = useParams()

  let defaultValues = {
    name: website?.name || '',
    url: website?.url || 'https://www.',
    dealerstudio: website?.dealerstudio ?? true,
    published: website?.published ?? true,
    manufacturer_id: website?.manufacturer_id || '',
    primary_developer_id: website?.primary_developer_id || '',
    use_pages_path: website?.use_pages_path ?? true,
    build_model_pages: website?.build_model_pages ?? false,
    offers_subscription: website?.offers_subscription ?? false,
    enable_accessory_addons: website?.enable_accessory_addons ?? false,
    launch_date: website?.launch_date ?? false,
    vercel_account_id: website?.vercel_account_id || '',
    vercel_repo_name: website?.vercel_repo_name || '',
  }

  let submitUrl = newWebsite
    ? Routes.dealership_websites_path(dealershipSlug)
    : Routes.website_path(website?.id)

  return (
    <Form
      defaultValues={defaultValues}
      attributes={attributes}
      formData={formData}
      submitUrl={submitUrl}
      submitMethod={newWebsite ? 'POST' : 'PATCH'}
      afterSubmit={() => {
        loadLazyData && loadLazyData()
        setVisible(false)
      }}
    >
      <div className="py-2 lead-event-form">
        <Tabs>
          {website?.id && (
            <div className="p-2 border mb-2 bg-white rounded">
              <TabList>
                <Tab>
                  <i className="fa fa-info mr-2"></i>
                  Details
                </Tab>
                <Tab>
                  <i className="fa fa-info mr-2"></i>
                  Vercel
                </Tab>
              </TabList>
            </div>
          )}
          <TabPanel>
            <div className="p-2 border rounded mb-2 bg-white">
              <DetailsTab />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="p-2 border rounded mb-2 bg-white">
              <VercelTab website={website} />
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </Form>
  )
}

const Wrapper = ({
  visible,
  newWebsite,
  setVisible,
  title = 'Edit Website',
  website,
  loadLazyData,
}) => {
  let [formData, setFormData] = useState({})
  let [loading, setLoading] = useState(true)
  let { dealershipSlug } = useParams()

  if (!dealershipSlug) {
    return null
  }

  useEffect(() => {
    if (visible) {
      fetch(Routes.new_dealership_website_path(dealershipSlug), { headers: standardHeaders })
        .then((res) => res.json())
        .then((res) => {
          setFormData(res)
          setLoading(false)
        })
    }
  }, [visible])

  return (
    <Sidebar
      header={title}
      visible={visible}
      style={{ width: '50vw' }}
      onHide={() => setVisible(false)}
      position="right"
    >
      {loading ? (
        <div className="text-center">
          <i className="pi pi-spin pi-spinner" style={{ fontSize: '2em' }}></i>
        </div>
      ) : (
        <WebsiteForm
          newWebsite={newWebsite}
          formData={formData}
          website={website}
          loadLazyData={loadLazyData}
          setVisible={setVisible}
        />
      )}
    </Sidebar>
  )
}

export default Wrapper
