import { useQuery } from '@tanstack/react-query'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Link, useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { SectionHeading } from '../entries/utils'
import { standardHeaders } from '../entries/utils'

const fetchGAProfiles = async (dealershipSlug: string) => {
  const response = await fetch(Routes.dealership_ga_profiles_path(dealershipSlug), {
    headers: standardHeaders,
  })
  if (!response.ok) throw new Error('Failed to fetch GA profiles')
  return response.json()
}

export default function GoogleAnalyticsProfiles() {
  const { dealershipSlug } = useParams()
  const { data, isLoading, isError } = useQuery({
    queryKey: ['gaProfiles', dealershipSlug],
    queryFn: () => fetchGAProfiles(dealershipSlug!),
    enabled: !!dealershipSlug,
  })

  if (isLoading) return <div className="p-4">Loading...</div>
  if (isError) return <div className="p-4 text-danger">Error loading profiles</div>

  return (
    <div className="settings-layout">
      <SectionHeading title="Google Analytics Profiles">
        <a
          href={Routes.fetch_access_token_dealership_ga_profiles_path(dealershipSlug)}
          className="btn btn-outline-success"
        >
          Link GA4 Profile
        </a>
      </SectionHeading>
      <div className="box">
        <DataTable value={data} className="table">
          <Column field="name" header="Name" sortable />
          <Column field="selected_property" header="Property ID" sortable />
          <Column
            header="Website Name"
            sortable
            body={(rowData) => (
              <Link to={Routes.dealership_website_path(dealershipSlug, rowData.website?.id)}>
                {rowData.website.name}
              </Link>
            )}
          />
          <Column
            header="Actions"
            body={(rowData) => (
              <>
                <a
                  href={Routes.edit_dealership_ga_profile_path(dealershipSlug, rowData.id)}
                  className="btn btn-sm btn-outline-secondary mr-1"
                >
                  Edit
                </a>
                <a
                  href={Routes.dealership_ga_profile_path(dealershipSlug, rowData.id)}
                  data-method="delete"
                  className="btn btn-sm btn-outline-secondary"
                  data-confirm="Are you sure?"
                  rel="nofollow"
                >
                  Destroy
                </a>
              </>
            )}
          />
        </DataTable>
      </div>
    </div>
  )
}
