import { useRef, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Link, useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import Loading from '../Loading'
import { DownloadCsvHeader } from '../entries/DataTableSearch'
import { standardHeaders } from '../entries/utils'

const fetchWebhookRecipient = async (webhookRecipientId) => {
  const response = await fetch(Routes.webhook_recipient_path(webhookRecipientId), {
    headers: standardHeaders,
  })
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  return response.json()
}

const Active = (rowData) => {
  if (rowData.active) {
    return (
      <>
        <i className="text-success fa fa-check"></i>
      </>
    )
  }

  return (
    <>
      <i className="text-danger fa fa-times"></i>
    </>
  )
}

const Actions = (rowData) => {
  let [loading, setLoading] = useState(false)

  const exportFeeds = () => {
    setLoading(true)
    fetch(Routes.export_webhook_feed_dealership_feed_path(rowData.dealership_id, rowData.id), {
      headers: standardHeaders,
      method: 'POST',
    }).then((res) => {
      if (res.ok) {
        setLoading(false)
      } else {
        setLoading(false)
        alert('Error exporting feeds')
      }
    })
  }

  return (
    <>
      <div onClick={exportFeeds} className="btn btn-outline-secondary btn-sm">
        {loading ? 'Exporting...' : 'Export'}
      </div>
    </>
  )
}

const WebhookRecipientsTable = () => {
  let { webhookRecipientId } = useParams()
  const dt = useRef(null)

  const { data, isLoading, error } = useQuery({
    queryKey: ['webhookRecipients', webhookRecipientId],
    queryFn: () => fetchWebhookRecipient(webhookRecipientId),
    staleTime: 600000, // Cache data for 10 minutes
  })

  if (isLoading) return <Loading />
  if (error) return <p>Error loading data: {error.message}</p>

  const header = DownloadCsvHeader({ dt: dt })

  return (
    <>
      <div className="details">
        <p>
          <b>Name:</b> {data.name}
        </p>
        <p>
          <b>Endpoint:</b> {data.endpoint}
        </p>
        {data.basic_username && (
          <p>
            <b>Basic username:</b> {data.basic_username}
          </p>
        )}
        {data.basic_password && (
          <p>
            <b>Basic password:</b> {data.basic_password}
          </p>
        )}
        {data.bearer_token && (
          <p>
            <b>Bearer Token:</b> {data.bearer_token}
          </p>
        )}
        {data.api_key && (
          <p>
            <b>API Key:</b> {data.api_key}
          </p>
        )}
      </div>

      <div className="actions mb-2">
        <div
          className="btn btn-outline-secondary"
          onClick={() => (window.location.href = Routes.edit_webhook_recipient_path(data.id))}
        >
          Edit
        </div>
      </div>
      <div className="box">
        <DataTable value={data.feeds} paginator rows={50} ref={dt} header={header}>
          <Column
            field="name"
            header="Name"
            body={(feed) => (
              <>
                <Link to={Routes.dealership_feed_path(feed.dealership.slug, feed.id)}>
                  {feed.name}
                </Link>
              </>
            )}
          />
          <Column
            field="dealership.name"
            header="Dealership"
            body={(feed) => (
              <>
                <Link to={Routes.dealership_path(feed.dealership.slug)}>
                  {feed.dealership.name}
                </Link>
              </>
            )}
          />
          <Column field="cars_count" header="Cars Count" />
          <Column field="active" header="Active" body={Active} sortable />
          <Column header="Actions" body={Actions} />
        </DataTable>
      </div>
    </>
  )
}

const Wrapper = () => {
  return (
    <div className="container py-2">
      <WebhookRecipientsTable />
    </div>
  )
}

export default Wrapper
