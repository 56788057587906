import { useContext, useState } from 'react'

import { useDraggable, useDroppable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import { useParams } from 'react-router-dom'

import { KanbanContext, LeadClusterContext } from '../../contexts'
import { formatSimpleDuration } from '../../entries/timeAgo'
import { humanize } from '../../entries/utils'
import { updateLeadClusterAPI } from '../../leads/utils'
import LeadSourceBadge from '../LeadSourceBadge'
import SlideIn from '../SlideIn'

const RequiresApproval = ({ leadCluster }) => {
  let [loading, setLoading] = useState(false)
  let { refreshData } = useContext(KanbanContext)
  let { dealershipSlug } = useParams()

  const toggle = async (approved) => {
    setLoading(true)
    let data = {
      lead_cluster: {
        approved: approved,
      },
    }

    await updateLeadClusterAPI(leadCluster.id, data, dealershipSlug)
      .then((res) => res)
      .then(() => {
        refreshData()
      })
    setLoading(false)
  }

  const Loading = () => (
    <div>
      <i className="fa fa-spinner fa-spin"></i>
    </div>
  )

  return (
    <div className="p-2 d-flex align-items-center border-bottom">
      <div className="text-center font-weight-bold mr-auto">APPROVE:</div>
      <div className="btn-group">
        <button className="btn btn-outline-success btn-sm" onClick={() => toggle(true)}>
          {loading ? <Loading /> : <span>Approve</span>}
        </button>
      </div>
    </div>
  )
}

const SlideInComponent = ({ cluster, buttonComponent }) => {
  let [leadCluster, setLeadCluster] = useState(cluster)

  return (
    <LeadClusterContext.Provider value={{ leadCluster, setLeadCluster }}>
      <SlideIn leadCluster={leadCluster} buttonComponent={buttonComponent} />
    </LeadClusterContext.Provider>
  )
}

const KanbanItem = ({ leadCluster, index, parent }) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: leadCluster.id,
    data: {
      id: leadCluster.id,
      title: leadCluster.title,
      index: index,
      parent: parent,
    },
  })

  const style = {
    transform: CSS.Translate.toString(transform),
    zIndex: 100000,
    opacity: leadCluster.loading ? 0.5 : 1,
  }

  const renderButton = ({ setVisible }) => (
    <div className="btn btn-outline-primary btn-sm" onClick={() => setVisible(true)}>
      View
    </div>
  )

  return (
    <div
      className={
        'border mb-2 rounded ' + (leadCluster.requires_approval ? 'bg-light-red' : 'bg-white')
      }
      style={style}
      ref={setNodeRef}
    >
      <div className="border-bottom p-2" {...listeners} {...attributes}>
        <div className="d-flex">
          <h6 dangerouslySetInnerHTML={{ __html: leadCluster.title }} />
          <div className="text-secondary small ml-auto">
            {formatSimpleDuration(new Date(leadCluster.created_at), new Date())} ago
          </div>
        </div>
      </div>
      {leadCluster.car && leadCluster.car.make && (
        <div className="p-2 border-bottom d-flex align-items-center">
          <img
            src={leadCluster.car.primary_image_url}
            height="40"
            className="rounded"
            loading="lazy"
          />
          <div className="ml-2 text-secondary">
            <small>
              {leadCluster.car.year} {leadCluster.car.make} {leadCluster.car.model}
            </small>
            <div className="small">{leadCluster.car.stocknum}</div>
            <div className="small">${leadCluster.car.price.toLocaleString()}</div>
          </div>
        </div>
      )}
      {leadCluster.contact?.email && (
        <div className="p-2 d-flex align-items-center border-bottom">
          <div className="text-secondary small font-weight-bold">Email:</div>
          <div className="text-secondary small ml-auto">
            <a href={`mailto:${leadCluster.contact.email}`}>{leadCluster.contact.email}</a>
          </div>
        </div>
      )}
      {leadCluster.contact?.phone_number && (
        <div className="p-2 d-flex align-items-center border-bottom">
          <div className="text-secondary small font-weight-bold">Phone number:</div>
          <div className="text-secondary small ml-auto">{leadCluster.contact?.phone_number}</div>
        </div>
      )}
      {leadCluster.user && (
        <div className="p-2 d-flex align-items-center border-bottom">
          <div className="badge badge-info">{leadCluster.user?.name}</div>
        </div>
      )}
      {leadCluster.requires_approval && <RequiresApproval leadCluster={leadCluster} />}
      <div className="p-2 d-flex align-items-center">
        <div>
          <div className="badge badge-sm badge-secondary">{leadCluster.category}</div>
          <div>
            <LeadSourceBadge lead={leadCluster} />
          </div>
        </div>
        <div className="ml-auto">
          <SlideInComponent cluster={leadCluster} buttonComponent={renderButton} />
        </div>
      </div>
    </div>
  )
}

export default function KanbanLane({ lead_status_option_id, title, items, colour_class_name }) {
  const { setNodeRef } = useDroppable({
    id: title,
    data: {
      leadStatusOptionId: lead_status_option_id,
    },
  })

  return (
    <div style={{ width: 300, minWidth: 300 }}>
      <div className="border rounded my-3 bg-white mr-2 mb-3">
        <div ref={setNodeRef}>
          <div className="heading border-bottom p-2">
            <div className={`badge badge-${colour_class_name}`}>{humanize(title)}</div>
          </div>
          <div className="p-2">
            {items.map((leadCluster, index) => (
              <KanbanItem
                leadCluster={leadCluster}
                key={leadCluster?.id}
                index={index}
                parent={title}
              />
            ))}
          </div>
          <div className="border-top p-2">
            <div className="text-center small text-secondary">
              {items.length} {items.length === 1 ? 'lead' : 'leads'} total
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
