import { useEffect, useState } from 'react'

import { Accordion, AccordionTab } from 'primereact/accordion'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Tooltip } from 'primereact/tooltip'

import LoadingBoxes from '../../../../entries/LoadingBoxes'
import { truncateString } from '../../../../entries/utils'
import { useTabs, useTimeRangeLocation } from '../../../contexts/hooks'
import { useViewportData } from '../../../hooks'
import type { ModuleProps } from '../../../types'

type TotalPageViewsData = (string | number)[][]

const TopPageViews: React.FC<ModuleProps> = ({ timeRange, item, selectedLocation }) => {
  const { data, loading, error, viewportRef } = useViewportData(
    item?.module,
    timeRange,
    selectedLocation
  )

  const { selectedTab } = useTabs()
  const { selectedGaWebsitePerTab } = useTimeRangeLocation()

  const [totalPageViewsToShow, setTotalPageViewsToShow] = useState<{
    top_page_views: { [website: string]: any[] }
  }>(data)

  // Add effect to handle selected website per tab
  useEffect(() => {
    if (selectedGaWebsitePerTab[selectedTab]) {
      setTotalPageViewsToShow({
        top_page_views: {
          [selectedGaWebsitePerTab[selectedTab]]:
            data?.top_page_views[selectedGaWebsitePerTab[selectedTab]],
        },
      })
    } else {
      setTotalPageViewsToShow(data)
    }
  }, [selectedGaWebsitePerTab, data?.top_page_views, selectedTab])

  // Path Column data layout
  const pathTemplate = (rowData: any) => {
    const truncatedPath = truncateString(rowData.path, 31)
    const isTruncated = truncatedPath !== rowData.path

    if (isTruncated) {
      return (
        <>
          <Tooltip
            target={`.path-tooltip-${rowData.path.replace(/[^a-zA-Z0-9]/g, '-')}`}
            position="top"
          >
            {rowData.path}
          </Tooltip>
          <span className={`path-tooltip-${rowData.path.replace(/[^a-zA-Z0-9]/g, '-')}`}>
            {truncatedPath}
          </span>
        </>
      )
    }

    return <span>{rowData.path}</span>
  }

  // Difference Column data layout
  const differenceTemplate = (rowData: any) => {
    const difference = rowData.count - rowData.previousCount
    const percentage = rowData.percentage

    return (
      <div className="d-flex justify-content-end align-items-center">
        {difference > 0 ? (
          <div className="text-success d-flex" id={`total-page-views-${difference}`}>
            +{difference} <span className="mx-1 text-dark">|</span>
            <div className="text-center">
              {percentage !== null ? `+${Math.abs(percentage)}%` : 'N/A'}
            </div>
          </div>
        ) : (
          <div className="text-danger d-flex" id={`total-page-views-${difference}`}>
            {difference} <span className="mx-1 text-dark">|</span>
            <div className="text-center">
              {percentage !== null ? `-${Math.abs(percentage)}%` : 'N/A'}
            </div>
          </div>
        )}
      </div>
    )
  }

  // Formats the data ready for PrimeReact DataTable
  const formatTableData = (data: TotalPageViewsData) => {
    return data?.map(([path, count, previousCount, percentage]) => ({
      path,
      count: count as number,
      previousCount: previousCount as number,
      percentage,
      difference: (count as number) - (previousCount as number),
    }))
  }

  const centerContentStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px', // Adjust this height as needed
    width: '100%',
    textAlign: 'center' as const,
    color: '#666',
    fontSize: '14px',
  }

  const tableHeaderStyle = {
    fontSize: '13px',
    background: '#f4f4f4',
  }

  const dataTablePt = {
    root: {
      style: {
        fontSize: '13px',
        background: 'rgb(222 226 230)',
        borderRadius: '8px',
      },
    },
    wrapper: {
      style: {
        borderRadius: '8px 8px 0 0',
        background: 'rgb(222 226 230)',
      },
    },
    table: {
      style: {
        background: 'white',
      },
    },
  }

  // Table Column settings and styles
  const tableColumns = [
    {
      field: 'path',
      header: 'Page Path',
      body: pathTemplate,
      headerStyle: { ...tableHeaderStyle, minWidth: '200px' },
    },
    {
      field: 'count',
      header: (
        <div>
          Page Views
          <div className="text-secondary" style={{ fontSize: '10px' }}>
            ({timeRange?.timeframe || 'Last 30 Days'})
          </div>
        </div>
      ),
      sortable: true,
      headerStyle: { ...tableHeaderStyle, minWidth: '120px' },
      align: 'right' as const,
    },
    {
      field: 'difference',
      header: (
        <div>
          <span>Difference</span>
          <div className="text-secondary" style={{ fontSize: '10px' }}>
            (vs. previous period)
          </div>
        </div>
      ),
      body: differenceTemplate,
      sortable: true,
      headerStyle: { ...tableHeaderStyle, minWidth: '160px' },
      align: 'right' as const,
    },
  ]

  return (
    <div ref={viewportRef}>
      {/* Loading State */}
      {loading ? (
        <LoadingBoxes />
      ) : error ? (
        // Error State
        <div style={centerContentStyle}>
          There was an error fetching the data for this module,
          <br />
          please try refresh or check again later.
        </div>
      ) : !totalPageViewsToShow ||
        !totalPageViewsToShow.top_page_views ||
        Object.keys(totalPageViewsToShow.top_page_views).length === 0 ? (
        // No Data State
        <div style={centerContentStyle}>No data available for this module</div>
      ) : (
        // Data State
        <div className="mt-3">
          {Object.entries(totalPageViewsToShow.top_page_views).length === 1 ? (
            <>
              <h6>{Object.keys(totalPageViewsToShow.top_page_views)[0]}</h6>
              <DataTable
                value={formatTableData(Object.values(totalPageViewsToShow.top_page_views)[0])}
                scrollable
                showGridlines
                size="small"
                emptyMessage="No Data available"
                loading={loading}
                stripedRows
                pt={dataTablePt}
              >
                {tableColumns.map((col, idx) => (
                  <Column key={idx} {...col} />
                ))}
              </DataTable>
            </>
          ) : (
            // Multiple websites - render accordion
            <Accordion multiple activeIndex={0}>
              {Object.entries(totalPageViewsToShow.top_page_views || {})?.map(
                ([website, pageData]) => (
                  <AccordionTab
                    key={website}
                    header={<span style={{ fontSize: '15px', fontWeight: 'bold' }}>{website}</span>}
                    pt={{
                      content: {
                        style: { border: 'none', padding: '0' },
                      },
                      headerAction: {
                        style: { color: 'black', border: 'none', paddingLeft: '0' },
                      },
                    }}
                  >
                    <DataTable
                      value={formatTableData(pageData)}
                      showGridlines
                      scrollable
                      size="small"
                      emptyMessage="No Data available"
                      loading={loading}
                      stripedRows
                      pt={dataTablePt}
                    >
                      {tableColumns.map((col, idx) => (
                        <Column key={idx} {...col} />
                      ))}
                    </DataTable>
                  </AccordionTab>
                )
              )}
            </Accordion>
          )}
        </div>
      )}
    </div>
  )
}

export default TopPageViews
