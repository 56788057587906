import { useContext, useEffect, useState } from 'react'

import moment from 'moment-timezone'
import { FilterMatchMode, PrimeReactProvider } from 'primereact/api'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { Paginator } from 'primereact/paginator'
import { SplitButton } from 'primereact/splitbutton'
import { useParams } from 'react-router-dom'

import { CurrentUserContext } from '../contexts'
import DataTableSearch from '../entries/DataTableSearch'
import { humanize } from '../entries/utils'
import { formatPhone } from '../entries/utils'
import { CopyableText } from '../shared/CopyableText'
import { PhoneCallsService } from './phoneCallsService'

const ItemCategory = (phoneCall) => {
  const category = phoneCall.category_id ? phoneCall.category_id !== '' : false
  return (
    <div
      className={category ? 'badge badge-success' : 'badge badge-warning'}
      id={`status-${phoneCall.id}`}
      style={{ whiteSpace: 'normal' }}
    >
      {humanize(phoneCall.category)}
    </div>
  )
}

const Audio = (phoneCall) => {
  const [showControls, setShowControls] = useState(false)

  const handleClick = (event) => {
    event.preventDefault()
    setShowControls(true)
  }

  return (
    <div>
      <a onClick={handleClick} href="">
        <i className="fa fa-headphones" aria-hidden="true"></i> Listen
      </a>
      {showControls && (
        <audio controls>
          <source src={phoneCall.recording_url} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      )}
      <br />
      {phoneCall.lead_url && (
        <a href={phoneCall.lead_url} target="_blank" rel="noreferrer">
          <i className="fa fa-bolt" aria-hidden="true"></i> Lead
        </a>
      )}
    </div>
  )
}

const startTime = (phoneCall) => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const formattedTime = moment(phoneCall.start_time)
    .tz(userTimezone)
    .format('DD/MM/YYYY, h:mm:ss a')
  const relativeTime = moment(phoneCall.start_time).tz(userTimezone).fromNow()
  return (
    <div>
      <p>
        {formattedTime}
        <br />
        <span className="text-secondary"> {relativeTime}</span>
      </p>
    </div>
  )
}

const Action = (phoneCall) => {
  const items = [
    {
      label: 'Delete',
      icon: 'fa fa-trash',
      command: () => {
        const userConfirmation = window.confirm('Are you sure?')

        if (!userConfirmation) {
          return
        }
        fetch(phoneCall.delete_url, {
          method: 'DELETE',
          headers: {
            'X-CSRF-Token': document
              .querySelector('meta[name="csrf-token"]')
              .getAttribute('content'),
          },
        }).then(() => {
          window.location.reload()
        })
      },
    },
  ]

  return (
    <div style={{ position: 'relative' }}>
      <SplitButton
        label="Show"
        buttonProps={{ id: 'show-button' }}
        onClick={() => {
          window.location.href = phoneCall.show_url
        }}
        model={items}
        menuButtonProps={{ id: 'action-button' }}
        outlined
        rounded
      />
    </div>
  )
}

const CallerNumber = (rowData) => {
  return (
    <>
      <CopyableText text={formatPhone(rowData.caller_number)} />
    </>
  )
}

const App = () => {
  let [loading, setLoading] = useState(false)
  const [totalRecords, setTotalRecords] = useState(0)
  const [phoneCalls, setPhoneCalls] = useState(null)
  const [categories, setCategories] = useState([])
  const [websites, setWebsites] = useState([])
  const [locations, setLocations] = useState([])
  const { dealershipSlug } = useParams()

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    category: { value: null, matchMode: FilterMatchMode.EQUALS },
    website: { value: null, matchMode: FilterMatchMode.EQUALS },
    location: { value: null, matchMode: FilterMatchMode.EQUALS },
  })

  const optionsMap = {
    Category: categories.map((category) => ({ label: humanize(category), value: category })),
    Website: websites.map((website) => ({ label: website, value: website })),
    Location: locations.map((location) => ({ label: location, value: location })),
  }

  const categoryRowFilterTemplate = (options) => rowFilterTemplate(options, 'Category')
  const websiteRowFilterTemplate = (options) => rowFilterTemplate(options, 'Website')
  const locationRowFilterTemplate = (options) => rowFilterTemplate(options, 'Location')

  const rowFilterTemplate = (options, name, width = '10rem') => {
    const selectedOptions = optionsMap[name]
    return (
      <Dropdown
        value={options.value}
        options={selectedOptions}
        onChange={(e) => {
          const updatedFilters = { ...lazyState.filters }
          updatedFilters[name.toLowerCase()].value = e.value
          setLazyState({
            ...lazyState,
            filters: updatedFilters,
            page: lazyState.page || 1,
            first: 0,
          })
        }}
        placeholder={`Select ${name}`}
        className="p-column-filter"
        style={{ maxWidth: width }}
      />
    )
  }

  const [lazyState, setLazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      global: { value: '', matchMode: 'contains' }, // FilterMatchMode.CONTAINS is not used because lazyState was not updating
      category: { value: '', matchMode: 'equals' },
      website: { value: '', matchMode: 'equals' },
      location: { value: '', matchMode: 'equals' },
      caller_number: { value: '', matchMode: 'contains' },
    },
  })

  useEffect(() => {
    loadLazyData()
  }, [lazyState])

  const loadLazyData = () => {
    setLoading(true)
    let page_number = 0
    if (lazyState.page === 0 || lazyState.first === 0) {
      page_number = 1
    } else {
      page_number = lazyState.page + 1
    }

    let params = {
      page: page_number,
      rows: lazyState.rows,
      query: lazyState.filters.global.value,
      category: lazyState.filters.category.value,
      website: lazyState.filters.website.value,
      location: lazyState.filters.location.value,
      caller_number: lazyState.filters.caller_number.value,
    }

    // Filter out empty parameters
    Object.keys(params).forEach((key) => {
      if (params[key] === '' || params[key] === null || params[key] === 'null') {
        delete params[key]
      }
    })

    let csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content')
    let dealershipId = dealershipSlug || null
    try {
      PhoneCallsService.getPhoneCalls(params, csrf, dealershipId).then((data) => {
        setTotalRecords(data.data.total_records)
        setCategories(data.data.categories)
        setWebsites(data.data.websites)
        setLocations(data.data.locations)
        let sortedPhoneCalls = data.data.phone_calls.sort(
          (a, b) => new Date(b.start_time) - new Date(a.start_time)
        )
        setPhoneCalls(sortedPhoneCalls)
        setLoading(false)
      })
    } catch (error) {
      console.error(error)
    }
  }

  const onFilter = (event) => {
    event['first'] = 0
    event['page'] = 1
    setLazyState(event)
  }

  const onPageChange = (event) => {
    event['filters'] = lazyState.filters
    setLazyState(event)
  }

  const header = DataTableSearch({
    filters: lazyState.filters,
    setFilters: (newFilters) => {
      setFilters(newFilters)

      setLazyState((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          global: newFilters.global,
        },
        first: 0, // Reset to first page when searching
        page: 1,
      }))
    },
  })

  const currentUser = useContext(CurrentUserContext)

  return (
    <div className="p-3">
      <PrimeReactProvider>
        <div className="p-grid">
          <div className="p-col-12">
            <div className="card">
              <DataTable
                value={phoneCalls}
                tableStyle={{ minWidth: '50rem' }}
                scrollable
                globalFilterFields={['website', 'location', 'caller_number', 'category']}
                style={{ overflow: 'visible' }}
                wrapper={{ style: { overflow: 'visible' } }}
                filterDisplay="row"
                header={header}
                rows={lazyState.rows}
                first={lazyState.first}
                totalRecords={totalRecords}
                filters={lazyState.filters}
                onFilter={onFilter}
                loading={loading}
              >
                {currentUser?.admin && <Column field="service" header="Service" sortable />}
                <Column
                  field="website"
                  header="Website"
                  sortable
                  filter
                  showFilterMenu={false}
                  style={{ maxWidth: '8rem' }}
                  filterElement={websiteRowFilterTemplate}
                />
                <Column
                  field="location"
                  header="Location"
                  sortable
                  filter
                  showFilterMenu={false}
                  style={{ maxWidth: '8rem' }}
                  filterElement={locationRowFilterTemplate}
                />
                <Column
                  field="caller_number"
                  header="Caller Number"
                  sortable
                  filter
                  style={{ maxWidth: '10rem' }}
                  filterPlaceholder="Search by Caller Number"
                  body={CallerNumber}
                />
                <Column field="talktime" header="Talk time" sortable />
                <Column body={startTime} field="start_time" header="Call date" sortable />
                <Column
                  field="category"
                  header="Category"
                  sortable
                  filter
                  showFilterMenu={false}
                  style={{ maxWidth: '8rem' }}
                  filterElement={categoryRowFilterTemplate}
                  body={ItemCategory}
                />
                <Column field="recording_url" header="Audio" body={Audio} sortable />
                <Column field="actions" header="Actions" body={Action} />
              </DataTable>
              <Paginator
                first={lazyState.first}
                rows={lazyState.rows}
                totalRecords={totalRecords}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        </div>
      </PrimeReactProvider>
    </div>
  )
}

export default App
