import { useContext, useEffect, useState } from 'react'

import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { FormContext } from '../contexts'
import {
  CreatableSelect,
  DateInput,
  Input,
  NumberInput,
  PhoneInput,
  Select,
  Switch,
} from '../entries/FormElements'
import { standardHeaders } from '../entries/utils'
import VercelSelect from '../websites/VercelSelect'

const ManufacturerSelect = ({ ...props }) => {
  return <Select {...props} id="make-select" />
}

const ModelSelect = ({ ...props }) => {
  const { control } = useFormContext()
  const manufacturerId = useWatch({ control, name: 'manufacturer_id' })
  let [familyOptions, setFamilyOptions] = useState([])

  useEffect(() => {
    if (!manufacturerId) {
      return
    }

    fetch(Routes.manufacturer_families_path(manufacturerId), { headers: standardHeaders })
      .then((res) => res.json())
      .then((res) => {
        setFamilyOptions(
          res.map((m) => {
            return { value: m.name, label: m.name }
          })
        )
      })
  }, [manufacturerId])

  return (
    <Select
      {...props}
      options={familyOptions}
      isDisabled={familyOptions?.length === 0}
      id="model-select"
    />
  )
}

const Field = ({ attribute, hint, ...props }) => {
  let { control, errors, formData, attributes } = useContext(FormContext)

  if (!attributes || !attributes[attribute]) {
    return <p className="text-danger">Missing attribute data</p>
  }

  let type = attributes[attribute].type

  const renderInput = (field) => {
    switch (type) {
      case 'number':
        return (
          <NumberInput
            className={`form-control ${errors && errors[attribute] ? 'is-invalid' : ''}`}
            {...field}
            {...attributes[attribute]}
            onChange={(value) => {
              field.onChange(value.target.rawValue)
            }}
          />
        )
      case 'phone':
        return (
          <PhoneInput
            className={`form-control ${errors && errors[attribute] ? 'is-invalid' : ''}`}
            {...field}
            {...attributes[attribute]}
            onChange={(value) => {
              field.onChange(value.target.rawValue)
            }}
          />
        )
      case 'date':
        return (
          <DateInput
            label={attributes[attribute].label}
            className={`${errors && errors[attribute] ? 'is-invalid' : ''}`}
            {...field}
            onChange={(value) => {
              field.onChange(value)
            }}
          />
        )
      case 'vercelSelect':
        return (
          <VercelSelect
            label={attributes[attribute].label}
            onChange={(e) => field.onChange(e?.value)}
            {...props}
          />
        )
      case 'text':
        return (
          <Input
            className={`form-control ${errors && errors[attribute] ? 'is-invalid' : ''}`}
            {...attributes[attribute]}
            {...field}
          />
        )
      case 'email':
        return (
          <Input
            type="email"
            className={`form-control ${errors && errors[attribute] ? 'is-invalid' : ''}`}
            {...attributes[attribute]}
            {...field}
          />
        )
      case 'switch':
        return (
          <div className="mb-3">
            <Switch
              {...field}
              label={attributes[attribute].label}
              id={attribute}
              value={field.value}
              onChange={(e) => field.onChange(!field.value)}
            />
            {hint && <small className="form-text text-muted">{hint()}</small>}
            {attributes[attribute].hint && (
              <small className="form-text text-muted">{attributes[attribute].hint}</small>
            )}
          </div>
        )
      case 'select':
        let options = formData[attributes[attribute].options]

        return (
          <Select
            label={attributes[attribute].label}
            className={`${errors && errors[attribute] ? 'is-invalid' : ''}`}
            options={options || []}
            required={attributes[attribute].required}
            value={options?.filter((o) => o.value === field.value)}
            onChange={(e) => field.onChange(e.value)}
            id={attributes[attribute].id || `${attribute}_select`}
            hint={attributes[attribute].hint}
          />
        )
      case 'select_multiple':
        let multi_options = formData[attributes[attribute].options]

        return (
          <Select
            label={attributes[attribute].label}
            className={`${errors && errors[attribute] ? 'is-invalid' : ''}`}
            options={multi_options || []}
            required={attributes[attribute].required}
            onChange={(options) => field.onChange(options.map((o) => o.value))}
            id={attributes[attribute].id || `${attribute}_select`}
            value={multi_options?.filter((o) => field.value.includes(o.value))}
            closeMenuOnSelect={false}
            hint={attributes[attribute].hint}
            isMulti
          />
        )
      case 'manufacturer_select':
        return (
          <ManufacturerSelect
            label={attributes[attribute].label}
            className={`${errors && errors[attribute] ? 'is-invalid' : ''}`}
            options={formData[attributes[attribute].options] || []}
            value={formData[attributes[attribute].options]?.find((o) => o.value === field.value)}
            onChange={(e) => field.onChange(e.value)}
          />
        )
      case 'model_select':
        return (
          <ModelSelect
            label={attributes[attribute].label}
            className={`${errors && errors[attribute] ? 'is-invalid' : ''}`}
            options={formData[attributes[attribute].options] || []}
            value={formData[attributes[attribute].options]?.find((o) => o.value === field.value)}
            onChange={(e) => field.onChange(e.value)}
          />
        )
      case 'creatable_select':
        return (
          <CreatableSelect
            label={attributes[attribute].label}
            className={`${errors && errors[attribute] ? 'is-invalid' : ''}`}
            onChange={(e) => field.onChange(e.map((i) => i.value))}
            value={field.value.map((o) => ({ label: o, value: o }))}
          />
        )
      default:
        return <p className="text-danger">Invalid field type</p>
    }
  }

  return (
    <>
      <Controller name={attribute} control={control} render={({ field }) => renderInput(field)} />
      {errors && errors[attribute] && (
        <div className="invalid-feedback">{errors[attribute].message}</div>
      )}
    </>
  )
}

export default Field
