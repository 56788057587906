import { useEffect, useState } from 'react'

import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Link } from 'react-router-dom'

import * as Routes from '../../../../../routes'
import { Select } from '../../../../entries/FormElements'
import LoadingBoxes from '../../../../entries/LoadingBoxes'
import SlideIn from '../../../../shared/SlideIn'
import { fetchModuleData } from '../../../api'
import { useAnalyticsDashboard } from '../../../contexts/hooks'
import { useViewportData } from '../../../hooks'
import type { ModuleProps, TimeRange } from '../../../types'

type LeadAnalyticsData = {
  today_count: number
  raw_statistics: string
  count_by_category: CountByCategory[]
  count_by_source: CountBySource[]
  count_by_dealerships: CountByDealership[]
}

type CountByCategory = {
  category: string
  current: number
  raw: string
}

type LocationOptions = {
  label: string
  value: string
}

type CountBySource = {
  source: string
  current: number
  raw: string
}

type CountByDealership = {
  slug: string
  name: string
  raw: string
}

type Subcategories = {
  leads_by_subcategory: [string, number][] | null
  leads_by_lead_status: [string, number][] | null
  leads_by_source: [string, number][] | null
  locations: { label: string; value: string }[]
}

const CategoryCount: React.FC<{
  categoryCount: CountByCategory
  timeRange: TimeRange
}> = ({ categoryCount, timeRange }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [location, setLocation] = useState<LocationOptions | null>(null)
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const [subcategories, setSubcategories] = useState<Subcategories>(null)
  const {
    leads_by_subcategory: leadsBySubcategory,
    leads_by_lead_status: leadsByLeadStatus,
    leads_by_source: leadsBySource,
    locations,
  } = subcategories || {}
  const { dashboardLevel, dashboardLevelLoaded, dealership, dealershipGroup, website } =
    useAnalyticsDashboard()

  useEffect(() => {
    if (visible && dashboardLevelLoaded) {
      let selectedLocation = null
      if (location?.value) {
        selectedLocation = { id: Number(location?.value), name: location?.label }
      }

      setLoading(true)
      fetchModuleData({
        module: 'leads_by_subcategory',
        category: categoryCount.category,
        timeRange: timeRange,
        selectedLocation: selectedLocation,
        dashboardLevel,
        dashboardLevelLoaded,
        dealership,
        dealershipGroup,
        website,
      }).then((data) => {
        setSubcategories(data)
        setLoading(false)
      })
    }
  }, [visible, dealership, website, location])

  return (
    <>
      <div
        className={'row' + (isHovered ? ' bg-light' : '')}
        key={`category_count_${categoryCount.category}_${categoryCount.current}`}
        onClick={() => setVisible(true)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{ cursor: 'pointer' }}
      >
        <div className="col-6 py-1">{categoryCount.category}</div>
        <div className="col-6 py-1">
          {categoryCount.current} &nbsp;
          <span
            dangerouslySetInnerHTML={{
              __html: categoryCount.raw,
            }}
          />
        </div>
      </div>
      <SlideIn
        header={`Breakdown: ${categoryCount.category}`}
        isOpen={visible}
        setIsOpen={setVisible}
      >
        <div className="py-3">
          <Select
            label="Locations Filter"
            options={locations}
            onChange={(o: any) => setLocation(o)}
            value={location}
            hint={null}
            required={false}
          />
          {loading ? (
            <LoadingBoxes />
          ) : (
            <>
              <h6>{categoryCount.category} by Leads Subcategory</h6>
              <div className="box mb-3">
                <DataTable value={leadsBySubcategory}>
                  <Column field="subcategory" header="Subcategory" style={{ width: '50%' }} />
                  <Column field="count" header="Leads" style={{ width: '50%' }} />
                </DataTable>
              </div>
              <h6>{categoryCount.category} by Source</h6>
              <div className="box mb-3">
                <DataTable value={leadsBySource}>
                  <Column field="source" header="Source" style={{ width: '50%' }} />
                  <Column field="count" header="Leads" style={{ width: '50%' }} />
                </DataTable>
              </div>
              <h6>{categoryCount.category} by Leads Status</h6>
              <div className="box">
                <DataTable value={leadsByLeadStatus}>
                  <Column field="lead_status" header="Status" style={{ width: '50%' }} />
                  <Column field="count" header="Leads" style={{ width: '50%' }} />
                </DataTable>
              </div>
            </>
          )}
        </div>
        <DataTable value={leadsByLeadStatus}>
          <Column field="lead_status" header="Status" style={{ width: '50%' }} />
          <Column field="count" header="Leads" style={{ width: '50%' }} />
        </DataTable>
      </SlideIn>
    </>
  )
}

const LeadAnalytics: React.FC<ModuleProps> = ({ timeRange, item, selectedLocation }) => {
  const { data, loading, viewportRef } = useViewportData(item?.module, timeRange, selectedLocation)
  const { dashboardLevel } = useAnalyticsDashboard()

  const {
    today_count: todayCount,
    raw_statistics: rawStatistics,
    count_by_category: countByCategory,
    count_by_source: countBySource,
    count_by_dealerships: countByDealership,
  }: LeadAnalyticsData = data || {}

  return (
    <div ref={viewportRef}>
      {!loading && data ? (
        <div>
          <ul>
            <li>{todayCount || 0} Leads today</li>
            {rawStatistics && (
              <li>
                <div
                  dangerouslySetInnerHTML={{
                    __html: rawStatistics,
                  }}
                />
              </li>
            )}
          </ul>
          <div>
            <div className="row font-weight-bold ">
              <div
                style={{
                  backgroundColor: '#dee2e6',
                  height: '1px',
                  width: '97%',
                  margin: '0 auto',
                }}
              />
              <div className="col-6 py-2">Category</div>
              <div className="col-6 py-2">
                Leads &nbsp;
                <span className="small text-secondary">
                  ({timeRange?.timeframe || 'Last 30 Days'})
                </span>
              </div>
              <div
                style={{
                  backgroundColor: '#dee2e6',
                  height: '2px',
                  width: '97%',
                  margin: '0 auto',
                }}
              />
            </div>
            <div className="mt-2">
              {countByCategory?.map((categoryCount, index) => (
                <CategoryCount
                  key={`category_${index}`}
                  categoryCount={categoryCount}
                  timeRange={timeRange}
                />
              ))}
            </div>
          </div>
          {item?.module !== 'autobot_leads' ? (
            <>
              <div className="row font-weight-bold mt-2">
                <div
                  style={{
                    backgroundColor: '#dee2e6',
                    height: '1px',
                    width: '97%',
                    margin: '0 auto',
                  }}
                />
                <div className="col-6 py-2">Source</div>
                <div className="col-6 py-2">
                  Leads &nbsp;
                  <span className="small text-secondary">
                    ({timeRange?.timeframe || 'Last 30 Days'})
                  </span>
                </div>
                <div
                  style={{
                    backgroundColor: '#dee2e6',
                    height: '2px',
                    width: '97%',
                    margin: '0 auto',
                  }}
                />
              </div>
              <div className="mt-2">
                {countBySource?.map((sourceCount, index) => (
                  <div
                    className="row"
                    key={`source_count_${sourceCount.source}_${sourceCount.current}`}
                  >
                    <div className="col-6 py-1"> {sourceCount.source}</div>
                    <div className="col-6 py-1">
                      {sourceCount.current} &nbsp;
                      <span
                        dangerouslySetInnerHTML={{
                          __html: sourceCount.raw,
                        }}
                      />
                    </div>
                    {index < countBySource.length - 1 && (
                      <div
                        style={{
                          backgroundColor: '#dee2e6',
                          height: '1px',
                          width: '97%',
                          margin: '0 auto',
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
            </>
          ) : null}
          {dashboardLevel == 'Website' && countByDealership?.length > 1 && (
            <>
              <div className="row font-weight-bold mt-2">
                <div
                  style={{
                    backgroundColor: '#dee2e6',
                    height: '1px',
                    width: '97%',
                    margin: '0 auto',
                  }}
                />
                <div className="col-6 py-2">Dealership</div>
                <div className="col-6 py-2">
                  Leads &nbsp;
                  <span className="small text-secondary">
                    ({timeRange?.timeframe || 'Last 30 Days'})
                  </span>
                </div>
                <div
                  style={{
                    backgroundColor: '#dee2e6',
                    height: '2px',
                    width: '97%',
                    margin: '0 auto',
                  }}
                />
              </div>
              <div className="mt-2">
                {countByDealership?.map((dealershipCount, index) => (
                  <div className="row" key={`source_count_${dealershipCount.name}`}>
                    <div className="col-6 py-1">
                      <Link to={Routes.dealership_path(dealershipCount.slug)}>
                        {dealershipCount.name}
                      </Link>
                    </div>
                    <div className="col-6 py-1">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: dealershipCount.raw,
                        }}
                      />
                    </div>
                    {index < countByDealership.length - 1 && (
                      <div
                        style={{
                          backgroundColor: '#dee2e6',
                          height: '1px',
                          width: '97%',
                          margin: '0 auto',
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      ) : (
        <LoadingBoxes />
      )}
      <small className="text-secondary">*Excludes leads marked as "spam"</small>
    </div>
  )
}

export default LeadAnalytics
