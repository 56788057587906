import { Tooltip } from 'primereact/tooltip'

export function formatDuration(seconds) {
  if (seconds < 60) {
    return `${seconds} sec${seconds !== 1 ? 's' : ''}`
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    if (remainingSeconds === 0) {
      return `${minutes} min${minutes !== 1 ? 's' : ''}`
    }
    return `${minutes} min${minutes !== 1 ? 's' : ''} ${remainingSeconds} sec${remainingSeconds !== 1 ? 's' : ''}`
  } else {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    if (minutes === 0) {
      return `${hours} hr${hours !== 1 ? 's' : ''}`
    }
    return `${hours} hr${hours !== 1 ? 's' : ''} ${minutes} min${minutes !== 1 ? 's' : ''}`
  }
}

const getColorClass = (seconds) => {
  const hours = seconds / 3600
  if (hours < 4) return 'text-success'
  if (hours < 8) return 'text-warning'
  return 'text-danger'
}

const ActionedWithin = ({ duration, title, business = false }) => {
  if (duration == null) {
    return
  }

  const colorClass = getColorClass(duration)
  const id = `actioned-within-${business ? 'bus' : 'tot'}`

  return (
    <>
      <div className="pt-1 px-3 small">
        <div className="d-flex align-items-center">
          <div className="mr-auto">{title}</div>
          <div className={`text-right ${colorClass}`}>
            <div>
              {formatDuration(duration)}
              <i className="fa fa-question-circle ml-1" id={id}></i>
            </div>
          </div>
          <Tooltip target={`#${id}`} position="left">
            <div>Counts the time it takes for the lead to move out of status "new".</div>
            {business ? (
              <div>Only counts business hours from 9am to 5pm. Monday - Saturday.</div>
            ) : (
              <div>Counts all hours</div>
            )}
          </Tooltip>
        </div>
      </div>
    </>
  )
}

const Wrapper = ({ leadCluster }) => {
  if (leadCluster.first_actioned_within == null) {
    return
  }

  return (
    <>
      <hr />
      <div className="px-3">
        <div className="mr-auto">
          <b>Actioned Within:</b>
        </div>
      </div>
      <ActionedWithin
        duration={leadCluster.first_actioned_within}
        title="Business hours:"
        business={true}
      />
      <ActionedWithin duration={leadCluster.total_first_actioned_within} title="Total:" />
    </>
  )
}

export default Wrapper
