import { useContext, useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import Loading from '../Loading'
import { LeadClusterContext } from '../contexts'
import { standardHeaders } from '../entries/utils'
import Apicall from '../leads/Apicall'
import DealerEmail from '../leads/DealerEmail'
import Email from '../leads/Email'
import { canManageLeads } from '../leads/utils'
import SlideIn from '../shared/SlideIn'

const ResendToThirdPartiesButton = ({ lead_id }) => {
  let { leadCluster } = useContext(LeadClusterContext)
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)

  const resendToThirdParties = () => {
    setLoading(true)
    const fetchUrl = lead_id
      ? Routes.resend_to_third_parties_dealership_lead_path(leadCluster.dealership_id, lead_id)
      : Routes.resend_to_third_parties_dealership_lead_cluster_path(
          leadCluster.dealership_id,
          leadCluster.id
        )
    fetch(fetchUrl, {
      method: 'POST',
      headers: standardHeaders,
    })
      .then((res) => res.json())
      .then(() => {
        setLoading(false)
        setSent(true)
      })
  }

  return (
    <div
      onClick={resendToThirdParties}
      className={'btn btn-outline-secondary btn-block mb-3' + (sent ? ' disabled' : '')}
      disabled={sent || loading}
    >
      {sent ? (
        <div className="text-success">
          <i className="fa fa-check mr-2"></i>
          Resent to third party
        </div>
      ) : (
        <>
          <i className="fa fa-recycle mr-2"></i>
          {loading ? 'Loading...' : 'Resend to third parties'}
        </>
      )}
    </div>
  )
}

const ResendToCustomerButton = ({ lead_id }) => {
  let { leadCluster } = useContext(LeadClusterContext)
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)

  const resendToCustomer = () => {
    setLoading(true)
    fetch(Routes.resend_customer_email_dealership_lead_path(leadCluster.dealership_id, lead_id), {
      method: 'POST',
      headers: standardHeaders,
    })
      .then((res) => res.json())
      .then(() => {
        setLoading(false)
        setSent(true)
      })
  }

  return (
    <div
      onClick={resendToCustomer}
      className={'btn btn-outline-secondary btn-block mb-3' + (sent ? ' disabled' : '')}
      disabled={sent || loading}
    >
      {sent ? (
        <div className="text-success">
          <i className="fa fa-check mr-2"></i>
          Resent to Customer
        </div>
      ) : (
        <>
          <i className="fa fa-recycle mr-2"></i>
          {loading ? 'Loading...' : 'Resend to Customer'}
        </>
      )}
    </div>
  )
}

const SendToUnitsButton = ({ lead_id }) => {
  let { leadCluster } = useContext(LeadClusterContext)
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)

  if (!lead_id) {
    return null
  }

  const sendToUnits = () => {
    if (disabled) return

    setLoading(true)
    fetch(
      Routes.resend_to_third_parties_dealership_lead_path(leadCluster.dealership_id, lead_id) +
        '?medium=units&manual_send=true',
      {
        method: 'POST',
        headers: standardHeaders,
      }
    )
      .then((res) => res.json())
      .then(() => {
        setLoading(false)
        setSent(true)
      })
  }

  let disabled = loading || sent

  return (
    <div
      onClick={sendToUnits}
      className={'btn btn-outline-secondary btn-block' + (disabled ? ' disabled' : '')}
      disabled={disabled}
    >
      {sent ? (
        <div className="text-success">
          <i className="fa fa-check mr-2"></i>
          Sent to Units
        </div>
      ) : (
        <>
          <i className="fa fa-recycle mr-2"></i>
          {loading ? 'Loading...' : 'Send to Units'}
        </>
      )}
    </div>
  )
}

const Emails = ({ leadId, count, hasUnitsNotification }) => {
  const [visible, setVisible] = useState(false)
  const { leadCluster } = useContext(LeadClusterContext)
  const { dealershipSlug } = useParams()
  const title = leadId ? 'Emails/API calls' : 'API calls'
  const buttonClass = `${leadId ? 'btn-sm ml-2' : 'btn-block'}`

  const fetchEmailsAndApicalls = async () => {
    const res = await fetch(
      Routes.emails_and_apicalls_dealership_lead_path(dealershipSlug, leadId),
      {
        headers: standardHeaders,
      }
    )
    return res.json()
  }

  const { data, isLoading, isFetching, refetch, isFetched } = useQuery({
    queryKey: ['emailsAndApicalls', dealershipSlug, leadId],
    queryFn: fetchEmailsAndApicalls,
    enabled: !!visible && !!leadId, // only fetch when visible and leadId is present
  })

  const [totalCount, setTotalCount] = useState(0)

  useEffect(() => {
    if (leadId) {
      setTotalCount(count)
    } else {
      setTotalCount(leadCluster.apicalls?.length || 0)
    }
  }, [leadId, count, leadCluster])

  // Set data if no leadId (fallback to context)
  const apicalls = leadId ? data?.apicalls : leadCluster.apicalls
  const emails = leadId ? data?.emails : []
  const dealerEmails = leadId ? data?.dealer_emails : []

  return (
    <>
      <div
        className={`btn btn-outline-secondary ${buttonClass}`}
        onClick={() => {
          setVisible(true)
          if (leadId) refetch()
        }}
      >
        <i className="fa fa-envelope"></i>
        <span className="ml-2">{leadId ? totalCount : `${title} (${totalCount || 0})`}</span>
      </div>
      <SlideIn header={title} isOpen={visible} setIsOpen={setVisible} style={{ width: '50vw' }}>
        <div className="py-3">
          <div className="d-flex px-3 mb-2">
            <div className="ml-auto">
              <div className="btn btn-outline-secondary btn-sm" onClick={refetch}>
                <i className="fa fa-rotate mr-1" />
                {isLoading || isFetching ? 'Loading...' : 'Refresh'}
              </div>
            </div>
          </div>
          {isLoading || isFetching ? <Loading /> : null}

          {emails?.map((email) => (
            <Email email={email} key={email.id} />
          ))}

          {apicalls?.map((apicall) => (
            <Apicall apicall={apicall} key={apicall.id} />
          ))}

          {dealerEmails?.map((dealerEmail) => (
            <DealerEmail dealerEmail={dealerEmail} key={dealerEmail.id} />
          ))}

          <div className="px-3 mt-2">
            {canManageLeads() && <ResendToThirdPartiesButton lead_id={leadId} />}
            {canManageLeads() && leadId && <ResendToCustomerButton lead_id={leadId} />}
            {canManageLeads() && hasUnitsNotification && <SendToUnitsButton lead_id={leadId} />}
          </div>
        </div>
      </SlideIn>
    </>
  )
}

export default Emails
