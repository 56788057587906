import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Link, useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { fromNow } from '../entries/utils'
import SlideIn from '../shared/SlideIn'

const Logs = ({ feedRun }) => {
  let { dealershipSlug } = useParams()
  let [visible, setVisible] = useState(false)

  const { data, isLoading } = useQuery({
    queryKey: ['logs', feedRun.id],
    queryFn: async () => {
      const response = await fetch(Routes.logs_dealership_feed_run_path(dealershipSlug, feedRun.id))
      return response.json()
    },
    enabled: visible,
  })

  return (
    <>
      <SlideIn isOpen={visible} setIsOpen={setVisible} header="Feed Run Logs">
        {isLoading && <div>Loading...</div>}
        <p className="mt-3 text-secondary">Created {fromNow(feedRun.created_at)}</p>
        <div className="box p-3 log-container my-3" id="log-container">
          <div
            className="text-secondary"
            dangerouslySetInnerHTML={{ __html: data?.feed_run?.log }}
          />
        </div>
        {data?.car_exports && data?.car_exports?.length > 0 && (
          <>
            <h5>Photo Exports</h5>
            {data.car_exports?.map((carExport) => (
              <div className="box p-2 my-3">
                <div>
                  <Link to={Routes.dealership_car_path(dealershipSlug, carExport.car.slug)}>
                    {carExport.car.name} ({carExport.car.stocknum})
                  </Link>
                  <div>
                    <span className="text-secondary">{fromNow(carExport.updated_at)} ago</span>
                  </div>
                </div>
                <div
                  className="text-secondary"
                  dangerouslySetInnerHTML={{ __html: carExport.log }}
                />
              </div>
            ))}
          </>
        )}
      </SlideIn>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault()
          setVisible(true)
        }}
      >
        View Logs
      </a>
    </>
  )
}

export default Logs
