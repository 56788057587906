import { Dialog } from 'primereact/dialog'
import { useNavigate, useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import Form from '../shared/Form'
import HookFormField from '../shared/hookFormField'

let attributes = {
  first_name: { type: 'text', label: 'First Name', required: true, placeholder: 'John' },
  last_name: { type: 'text', label: 'Last Name', required: true, placeholder: 'Doe' },
  email: { type: 'text', label: 'Email Address', required: true, placeholder: 'john@example.com' },
  business_name: { type: 'text', label: 'Business Name', required: false },
  phone_number: {
    type: 'phone',
    label: 'Phone Number',
    required: true,
    placeholder: '0412 123 123',
  },
  opt_in_to_marketing: { type: 'switch', label: 'Opt in to marketing' },
}

let formData = {}

const ContactForm = ({ contact, visible, setVisible }) => {
  let navigate = useNavigate()
  let { dealershipSlug } = useParams()

  let defaultValues = {
    first_name: contact?.first_name || '',
    last_name: contact?.last_name || '',
    email: contact?.email || '',
    phone_number: contact?.phone_number || '',
    business_name: contact?.business_name || '',
  }

  return (
    <Dialog
      header="Add Contact"
      visible={visible}
      style={{ width: '50vw' }}
      onHide={() => setVisible(false)}
      dismissableMask={true}
    >
      <Form
        submitUrl={Routes.dealership_contacts_path(dealershipSlug)}
        defaultValues={defaultValues}
        attributes={attributes}
        formData={formData}
        afterSubmit={(res) => {
          if (res && res.id) {
            navigate(Routes.dealership_contact_path(dealershipSlug, res.id))
          }
        }}
      >
        <div className="row">
          <div className="col-md-6">
            <HookFormField attribute="first_name" />
          </div>
          <div className="col-md-6">
            <HookFormField attribute="last_name" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <HookFormField attribute="email" />
          </div>
          <div className="col-md-6">
            <HookFormField attribute="business_name" />
          </div>
        </div>
        <HookFormField attribute="phone_number" />
        <HookFormField attribute="opt_in_to_marketing" />
      </Form>
    </Dialog>
  )
}

export default ContactForm
