import { useCallback, useEffect, useRef, useState } from 'react'

import flatpickr from 'flatpickr'
import moment from 'moment'

// Utility function to generate the yearly time frame (last 364 days)
export const getYearlyTimeRange = (timeRange) => {
  if (!timeRange) {
    return undefined
  }

  const isTimeRangeEmpty = timeRange.start === 0 && timeRange.end === 0

  // Set end date based on input timeRange or default to "yesterday"
  const endDate = isTimeRangeEmpty
    ? moment().subtract(1, 'days').endOf('day') // End date is yesterday for empty range
    : moment.unix(timeRange.end).subtract(1, 'days').endOf('day') // End date is from input

  // Calculate start date: 364 days before the end date
  const startDate = endDate.clone().subtract(364, 'days').startOf('day')

  // Format timeframe string
  const timeframe = `from ${startDate.format('DD/MM/YY')} to ${endDate.format('DD/MM/YY')}`

  return {
    start: startDate.unix(),
    end: endDate.unix(),
    timeframe,
    type: 'yearly',
  }
}

const dropdownOptions = [
  {
    label: 'Last 7 days',
    start: moment().subtract(7, 'd').format('DD-MM-YY'),
    end: moment().subtract(1, 'd').format('DD-MM-YY'),
    type: 'weekly',
  },
  {
    label: 'Last 30 days',
    start: moment().subtract(30, 'd').format('DD-MM-YY'),
    end: moment().subtract(1, 'd').format('DD-MM-YY'),
    type: 'monthly',
  },
  {
    label: 'Month to Date',
    start: moment().startOf('month').format('DD-MM-YY'),
    end: moment().format('DD-MM-YY'),
    type: 'monthly',
  },
  {
    label: moment().subtract(1, 'months').format('MMMM'),
    start: moment().subtract(1, 'months').startOf('month').format('DD-MM-YY'),
    end: moment().subtract(1, 'months').endOf('month').format('DD-MM-YY'),
    type: 'monthly',
  },
  {
    label: moment().subtract(2, 'months').format('MMMM'),
    start: moment().subtract(2, 'months').startOf('month').format('DD-MM-YY'),
    end: moment().subtract(2, 'months').endOf('month').format('DD-MM-YY'),
    type: 'monthly',
  },
  {
    label: moment().subtract(3, 'months').format('MMMM'),
    start: moment().subtract(3, 'months').startOf('month').format('DD-MM-YY'),
    end: moment().subtract(3, 'months').endOf('month').format('DD-MM-YY'),
    type: 'monthly',
  },
  {
    label: 'Last 364 days',
    start: moment().subtract(1, 'years').format('DD-MM-YY'),
    end: moment().subtract(1, 'd').format('DD-MM-YY'),
    type: 'yearly',
  },
]

const TimeRangePickerComponent = ({ handleUpdateTimeRange }) => {
  const [minDate, setMinDate] = useState(null)
  const [maxDate, setMaxDate] = useState(null)
  const [dateStr, setDateStr] = useState(null)
  const [timeRangeType, setTimeRangeType] = useState(null)
  const [selectedTimeRange, setSelectedTimeRange] = useState('Last 30 days')
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const dropdownRef = useRef(null)

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const handleDropdownClick = (item) => {
    const pickr = document.getElementById('flatpickr-date-range')._flatpickr
    pickr.setDate([item.start, item.end], true)
    setTimeRangeType(item.type)
    setSelectedTimeRange(item.label)
  }

  const handleDatePickerChange = useCallback((selectedDates, dateStr) => {
    let start = Date.parse(selectedDates[0]) / 1000
    let end = Date.parse(selectedDates[1]) / 1000 + 60 * 60 * 24 + 30000
    setSelectedTimeRange(null)
    if (start && end) {
      setMinDate(start)
      setMaxDate(end)
      setDateStr(`from ${dateStr}`)
    } else {
      handleUpdateTimeRange({
        start: minDate,
        end: maxDate,
        timeframe: dateStr,
        type: timeRangeType,
      })
    }
  }, [])

  useEffect(() => {
    if (minDate && maxDate) {
      handleUpdateTimeRange({
        start: minDate,
        end: maxDate,
        timeframe: dateStr,
        type: timeRangeType,
      })
    }
  }, [minDate, maxDate])

  useEffect(() => {
    flatpickr('#flatpickr-date-range', {
      allowInput: true,
      dateFormat: 'd/m/y',
      maxDate: moment().format('DD/MM/YY'),
      mode: 'range',
      onChange: (selectedDates, dateStr, instance) => {
        handleDatePickerChange(selectedDates, dateStr)
      },
    })
  }, [])

  // handle clicks outside of the dropdown menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div ref={dropdownRef} className="TimeRangePicker analytics-header-dropdown">
      <div className="input-group">
        <input
          type="text"
          className="form-control rounded py-2"
          id="flatpickr-date-range"
          placeholder="Time Range"
          style={{ height: '42px' }}
        />
        <div className="input-group-append">
          <i
            className={`fa-solid fa-chevron-${isDropdownOpen ? 'up' : 'down'} position-absolute`}
            style={{
              right: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              cursor: 'pointer',
              zIndex: 10,
              padding: '0.8rem 0.75rem',
              color: '#495057',
            }}
            onClick={toggleDropdown}
          ></i>
          <div className={`dropdown-menu dropdown-menu-right${isDropdownOpen ? ' show' : ''}`}>
            {dropdownOptions.map((item) => (
              <a
                key={item.label}
                className={'dropdown-item' + (selectedTimeRange === item.label ? ' active' : '')}
                onClick={() => {
                  handleDropdownClick(item)
                  setIsDropdownOpen(false)
                }}
              >
                {item.label}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const TimeRangePicker = connectRange(TimeRangePickerComponent)
export default TimeRangePicker

// TODO (Codemod generated): ensure your usage correctly maps the props from the connector to the hook
function connectRange(Component) {
  const Range = (props) => {
    return <Component {...props} />
  }

  return Range
}
