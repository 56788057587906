import { saveAs } from 'file-saver'
import JSZip from 'jszip'

const DownloadPhotos = ({ car_id, photoUrls, setLoading, loading }) => {
  const downloadAsZip = async () => {
    setLoading(true)

    const zip = new JSZip()
    const folderName = `Photos_${car_id}`
    const folder = zip.folder(folderName)
    try {
      const allPromises = photoUrls.map(async (photoUrl) => {
        const res = await fetch(photoUrl)
        const blob = await res.blob()
        folder.file(photoUrl.split('/').pop(), blob, { binary: true })
      })

      await Promise.all(allPromises)

      const zipBlob = await zip.generateAsync({ type: 'blob' })
      saveAs(zipBlob, `${folderName}.zip`)
    } catch (error) {
      console.error('Error downloading photos:', error)
    } finally {
      setLoading(false)
    }
  }

  if (photoUrls.length < 2) {
    return null
  }

  return (
    <button
      className="btn btn-outline-primary btn-sm mb-2"
      onClick={downloadAsZip}
      disabled={loading}
    >
      {loading ? (
        <>
          <i className="fa fa-spinner fa-spin mr-2"></i>Downloading...
        </>
      ) : (
        <>
          <i className="fa fa-download mr-2"></i>Download
        </>
      )}
    </button>
  )
}

export default DownloadPhotos
