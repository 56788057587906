import { useContext, useEffect, useState } from 'react'

import { formatDistanceToNow } from 'date-fns'
import { Link, useParams } from 'react-router-dom'

import { DealershipContext, LeadClusterContext } from '../contexts'
import EditableItem from '../leads/EditableItem'
import OwnerSelect from '../leads/OwnerSelect'
import RequiresApproval from '../leads/RequiresApproval'
import StatusSelect from '../leads/StatusSelect'
import {
  canChangeLeadClusterLocation,
  canManageLeads,
  canReassignLeads,
  updateLeadClusterLocation,
  updateLeadClusterUserId,
  updateLeadStatusOptionId,
} from '../leads/utils'
import ActionedWithin from './ActionedWithin'
import Emails from './Emails'
import InsuranceSidebarItem from './Insurance/SidebarItem'
import LocationSelect from './SelectLocation'
import SendToRevolution from './SendToRevolution'
import Tags from './Tags'

const timeAgo = (date) => {
  if (!date) return 'invalid date'

  return formatDistanceToNow(new Date(date), { addSuffix: true })
}

const UserAssignment = () => {
  let { dealershipSlug } = useParams()
  const { leadCluster } = useContext(LeadClusterContext) || {}
  const [assignedUserId, setAssignedUserId] = useState(leadCluster.user_id)

  const onOwnerChange = async (leadClusterId, userId) => {
    try {
      setAssignedUserId(userId)

      const res = await updateLeadClusterUserId(leadClusterId, userId, dealershipSlug)

      if (res.error) {
        throw new Error(res.message || 'Failed to update lead cluster owner.')
      }
    } catch (error) {
      console.error(`Error updating owner for lead cluster ${leadClusterId}:`, error)
      throw error // Ensure the calling function (e.g., onChange) catches this
    }
  }

  return (
    <div className="px-3">
      <div className="small mb-1">
        <b>Assigned to:</b>
      </div>
      {canManageLeads() && canReassignLeads() ? (
        <OwnerSelect
          hit={leadCluster}
          onOwnerChange={onOwnerChange}
          selectedValue={assignedUserId}
        />
      ) : (
        <div className="text-secondary">{leadCluster?.user?.name || 'Unassigned'}</div>
      )}
    </div>
  )
}

function LeadDetails({ status }) {
  const { leadCluster, setLeadCluster } = useContext(LeadClusterContext)
  let { dealershipSlug } = useParams()
  const { dealership } = useContext(DealershipContext)
  const locations = dealership?.locations
  const locationOptions = locations?.map((location) => ({
    value: location.id,
    label: location.name,
  }))

  const onLocationChange = async (leadId, locationId) => {
    try {
      const res = await updateLeadClusterLocation(leadId, locationId, dealershipSlug)

      if (res.error) {
        throw new Error(res.message || 'Failed to update lead cluster location.')
      }

      setLeadCluster(res)
    } catch (error) {
      console.error(`Failed to update lead cluster location for ${leadId}:`, error)
      throw error // Ensure the error propagates to `onChange`
    }
  }

  let [contact, setContact] = useState(leadCluster?.contact)

  useEffect(() => {
    setContact(leadCluster?.contact)
  }, [leadCluster])

  const onStatusChange = async (leadClusterId, newStatusOptionId) => {
    try {
      const res = await updateLeadStatusOptionId(leadClusterId, newStatusOptionId, dealershipSlug)

      if (res.error) {
        throw new Error(res.message || 'Failed to update lead cluster status.')
      }

      setLeadCluster(res)
    } catch (error) {
      console.error(`Failed to update lead cluster ${leadClusterId}:`, error)
      throw error // Ensure the error propagates to `onChange`
    }
  }

  const NonEditableItem = ({ field, label, value, setContact, placeholder }) => {
    return (
      <div className="d-flex pt-1 px-3 align-items-center">
        <div className="mr-auto">
          <b>{label}:</b>
        </div>
        <div className="text-secondary text-right">{contact[field]}</div>
      </div>
    )
  }

  const InfoComponent = canManageLeads() ? EditableItem : NonEditableItem

  return (
    <div className="col-lg-3">
      <div className="bg-white border-left h-100">
        <div className="pt-3 px-3">
          <div className="mr-auto small mb-1 d-flex">
            <b>Lead Cluster Status:</b>
            {leadCluster?.pipeline && (
              <span className="small text-secondary ml-auto">
                Pipeline: {leadCluster.pipeline?.name}
              </span>
            )}
          </div>
          {canManageLeads() && leadCluster && leadCluster?.lead_status_option_id ? (
            <StatusSelect
              hit={leadCluster}
              attribute="lead_status_option_id"
              onStatusChange={onStatusChange}
              selectedValue={leadCluster.lead_status_option_id}
              leadStatusOptions={leadCluster.lead_status_options}
              leadCluster={leadCluster}
              setLeadCluster={setLeadCluster}
            />
          ) : (
            leadCluster &&
            leadCluster?.lead_status && (
              <div className="text-secondary">{leadCluster?.lead_status}</div>
            )
          )}

          {leadCluster && leadCluster.requires_approval === true && <RequiresApproval />}
          {leadCluster?.approver && (
            <div className="small text-secondary mt-1">
              <div className="d-flex align-items-center">
                <div className="mr-auto">Approved by:</div>
                <div>{leadCluster.approver.name}</div>
              </div>
            </div>
          )}
        </div>
        <hr />
        {leadCluster && status === 'completed' && <UserAssignment />}
        <hr />
        <div className="pt-1 px-3">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <b>Created:</b>
            </div>
            {leadCluster && (
              <div className="text-secondary text-right">
                {timeAgo(leadCluster.first_lead_created_at)}
                <div className="small">
                  {' '}
                  (
                  {new Date(leadCluster.first_lead_created_at).toLocaleString('en-AU', {
                    timeZone: 'Australia/Brisbane',
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                  )
                </div>
              </div>
            )}
          </div>
        </div>
        <ActionedWithin leadCluster={leadCluster} />
        <hr />
        <div className="pt-1 px-3">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <b>Location:</b>
            </div>
            {canChangeLeadClusterLocation() && leadCluster ? (
              <LocationSelect
                hit={leadCluster}
                attribute="location_id"
                onLocationChange={onLocationChange}
                selectedValue={leadCluster.location_id}
                locationOptions={locationOptions}
              />
            ) : (
              <div className="text-secondary text-right">{leadCluster?.location?.name}</div>
            )}
          </div>
        </div>
        <hr />
        <div className="pt-1 px-3">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <b className="mr-2">Tags:</b>
              {leadCluster?.tags?.length > 0 &&
                leadCluster.tags.map((tag) => (
                  <div className="badge badge-secondary badge-sm mr-1" key={tag}>
                    {tag}
                  </div>
                ))}
            </div>
            {leadCluster?.tags && (
              <Tags
                leadCluster={leadCluster}
                setLeadCluster={setLeadCluster}
                renderButton={(setVisible) =>
                  canManageLeads() && (
                    <div
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => setVisible(true)}
                    >
                      <i className="fa fa-plus" />
                    </div>
                  )
                }
              />
            )}
          </div>
        </div>
        <hr />
        <div>
          <InfoComponent
            field="first_name"
            label="First Name"
            value={contact?.first_name}
            setContact={setContact}
          />
          <InfoComponent
            field="last_name"
            label="Last Name"
            value={contact?.last_name}
            setContact={setContact}
          />
          <InfoComponent
            field="phone_number"
            label="Phone"
            value={contact?.phone_number}
            setContact={setContact}
          />
          <InfoComponent
            field="email"
            label="Email"
            value={contact?.email}
            setContact={setContact}
          />
          <InfoComponent
            field="postcode"
            label="Postcode"
            value={contact?.postcode}
            setContact={setContact}
          />
          <InfoComponent
            field="business_name"
            label="Business Name"
            value={contact?.business_name}
            setContact={setContact}
          />
          <InfoComponent
            field="date_of_birth"
            placeholder="dd/mm/yyyy"
            label="DOB"
            value={contact?.date_of_birth}
            setContact={setContact}
          />
        </div>
        {leadCluster?.cars?.length > 0 && leadCluster?.dealership?.allianz_integration && (
          <InsuranceSidebarItem />
        )}
        <hr />
        {leadCluster && (
          <>
            <div className="px-3 pt-1">
              <Link
                to={`/dealerships/${dealershipSlug}/contacts/${leadCluster.contact_id}`}
                className="btn btn-outline-secondary btn-block"
              >
                <i className="fa fa-user mr-2"></i>
                View Contact
              </Link>
            </div>
            <div className="px-3 pt-1">
              <Emails />
            </div>
            <SendToRevolution leadCluster={leadCluster} />
          </>
        )}
      </div>
    </div>
  )
}

export default LeadDetails
