import { useContext, useRef, useState } from 'react'

import moment from 'moment'
import { Toast } from 'primereact/toast'
import { Link, useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { CarContext } from '../contexts'
import { standardHeaders } from '../entries/utils'
import { CopyableText } from '../shared/CopyableText'
import CarPrice from './CarPrice'
import { MarkAsSold } from './CarSearchItem'

const Stat = ({ heading, value, attribute, isEditable = false, body }) => {
  let { car, setCar } = useContext(CarContext)

  const [onHover, setOnHover] = useState(false)
  let [editing, setEditing] = useState(false)
  let [loading, setLoading] = useState(false)
  let [editableValue, setEditableValue] = useState(value)
  const toast = useRef(null)

  const onSubmit = () => {
    setLoading(true)
    fetch(`/cars/${car.id}`, {
      method: 'PATCH',
      headers: standardHeaders,
      body: JSON.stringify({ car: { [attribute]: editableValue } }),
    })
      .then((res) => res.json())
      .then((data) => {
        setCar(data)
        setEditing(false)
        setLoading(false)
      })
  }

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        toast.current.show({
          severity: 'success',
          summary: 'Copied',
          detail: 'Text copied to clipboard!',
        })
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err)
      })
  }

  return (
    <>
      <th>
        <Toast ref={toast} />
        {heading}
      </th>
      <td
        onMouseEnter={() => {
          isEditable && setOnHover(true)
        }}
        onMouseLeave={() => {
          isEditable && setOnHover(false)
        }}
        id={`editable-item-${attribute}`}
      >
        {editing ? (
          <>
            <input
              className="form-control mb-2"
              placeholder={heading}
              value={editableValue}
              onChange={(e) => setEditableValue(e.target.value)}
              id={`editable-item-${attribute}-input`}
            />
            <div
              className={'btn btn-outline-secondary btn-sm mr-2' + (loading ? ' disabled' : '')}
              onClick={onSubmit}
              id={`editable-item-${attribute}-save`}
              disabled={loading}
            >
              {loading ? <i className="fa fa-spinner fa-spin"></i> : 'Save'}
            </div>
            <div
              className="btn btn-outline-secondary btn-sm"
              onClick={() => setEditing(false)}
              disabled={loading}
            >
              Cancel
            </div>
          </>
        ) : (
          <div className="d-flex">
            {(body && body()) || value || <span className="text-secondary small">nil</span>}
            <div
              className={onHover ? 'visible ml-auto' : 'invisible'}
              id={`editable-item-${attribute}-button`}
            >
              <div className="btn btn-sm btn-outline-secondary" onClick={() => setEditing(true)}>
                <i className="fa fa-edit"></i>
              </div>
              {value !== undefined && value !== null && (
                <div className="btn btn-sm btn-outline-secondary ml-1" onClick={copyToClipboard}>
                  <i className="fa fa-copy"></i>
                </div>
              )}
            </div>
          </div>
        )}
      </td>
    </>
  )
}

const CarWebsiteButtonGroup = ({ car }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className="btn-group mr-2">
      <a href={car.url} target="_blank" className="btn btn-outline-primary btn-sm">
        <i className="fa fa-external-link-alt mr-1"></i>
        View on Website
      </a>
      <button
        type="button"
        className="btn btn-outline-primary btn-sm dropdown-toggle dropdown-toggle-split"
        id="dropdownMenuButton"
        aria-expanded={isOpen}
        onClick={toggleDropdown}
      >
        <span className="sr-only">Toggle Dropdown</span>
      </button>
      <ul className={`dropdown-menu ${isOpen ? 'show' : ''}`} aria-labelledby="dropdownMenuButton">
        {car.website_urls &&
          car.website_urls.map((website) => (
            <li key={website.id}>
              <a
                className="dropdown-item"
                href={website.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-external-link-alt mr-1"></i>
                {website.name}
              </a>
            </li>
          ))}
      </ul>
    </div>
  )
}

const CarSpecs = ({ capitalise }) => {
  let { dealershipSlug } = useParams()
  let { car, setCar } = useContext(CarContext)

  const address = car.location && car.location.address

  return (
    <div className="box mb-3">
      <div className="pt-3 px-3 d-flex">
        <h4 className="mb-0">
          <i className="fa fa-list-check mr-1"></i>Specs
        </h4>
        <div className="ml-auto align-items-center d-flex">
          {car.has_redbook ? (
            <div className="badge badge-success mr-2">
              <i className="fa fa-check mr-1"></i>Redbook Code
            </div>
          ) : (
            <div className="badge badge-danger mr-2">
              <i className="fa fa-exclamation-triangle mr-1"></i>No Redbook Code
            </div>
          )}
          {car.has_nvic ? (
            <div className="badge badge-success">
              <i className="fa fa-check mr-1"></i>NVIC Code
            </div>
          ) : (
            <div className="badge badge-danger">
              <i className="fa fa-exclamation-triangle mr-1"></i>No NVIC Code
            </div>
          )}
        </div>
      </div>
      <div className="table-responsive car-specs__table">
        <table className="table mt-4" style={{ tableLayout: 'fixed' }}>
          <tbody>
            <tr>
              <th>Price:</th>
              <td>
                <CarPrice hit={car} setCar={setCar} showGrade={false} />
              </td>
              <th>Status:</th>
              <td>
                <div className="d-flex">
                  <div>
                    <span
                      className={
                        ['sold', 'withdrawn', 'incomplete'].includes(car.status)
                          ? 'text-danger'
                          : 'text-success'
                      }
                    >
                      {capitalise(car.status?.replace(/_/g, ' '))}
                    </span>
                    &nbsp; &nbsp;
                    {car.status === 'sold' && (
                      <span className="small text-secondary">{car.sold_date}</span>
                    )}
                  </div>
                  <div className="ml-auto">
                    <MarkAsSold car={car} setCar={setCar} />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>Stock number:</th>
              <td>
                <CopyableText text={car.stocknum} />
              </td>
              <Stat
                heading="Registration:"
                value={car.regplate}
                isEditable={true}
                attribute="regplate"
                body={() => (
                  <>
                    {car.regplate ? (
                      car.regplate.toUpperCase()
                    ) : (
                      <span className="text-secondary mb-0">No Rego</span>
                    )}{' '}
                    &nbsp;
                    {car.rego_expiry && (
                      <span className="small text-secondary">
                        Expires {moment(car.rego_expiry).format('DD/MM/YY')}
                      </span>
                    )}
                  </>
                )}
              />
            </tr>
            {car.car_type === 'used' &&
              car.purchase_cost &&
              car?.buyer?.(
                <tr>
                  <th>Purchase Cost:</th>
                  <td>
                    <div>
                      {car.purchase_cost?.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </div>
                  </td>
                  <th>Buyer:</th>
                  <td>
                    <div>{car.buyer?.name}</div>
                  </td>
                </tr>
              )}
            <tr>
              <Stat
                heading="Body:"
                body={() => (
                  <div>
                    <div>{car.body}</div>
                    <div className="text-secondary small">{car.bodyconfiguration}</div>
                  </div>
                )}
              />
              <Stat heading="Drive:" value={car.drive} />
            </tr>
            <tr>
              <Stat heading="Vin:" value={car.vin} isEditable={true} attribute="vin" />
              <Stat
                heading="Colour:"
                body={() => (
                  <div className="d-flex flex-column">
                    {car.simple_colour}
                    {car.colour && car.colour !== car.simple_colour && (
                      <span className="small text-secondary">Paint code = {car.colour}</span>
                    )}
                    {car.interior_colour && (
                      <span className="small text-secondary">
                        Interior Colour = {car.interior_colour}
                      </span>
                    )}
                  </div>
                )}
              />
            </tr>
            <tr>
              <Stat
                heading="Odometer:"
                value={car.km}
                isEditable={true}
                attribute="km"
                body={() => <>{car.km?.toLocaleString() + ' kms'}</>}
              />
              <th>Transmission:</th>
              <td>
                {car.simple_transmission}
                <small className="text-secondary ml-1">{car.fuel}</small>
              </td>
            </tr>
            <tr>
              <th>Days Old:</th>
              <td>{car.days_old} days</td>
              <th>URL:</th>
              <td>
                {car.show_on_dealer_website && <CarWebsiteButtonGroup car={car} />}
                {car.carsales_url && (
                  <a
                    href={car.carsales_url}
                    target="_blank"
                    className="btn btn-outline-primary btn-sm"
                  >
                    <i className="fa fa-external-link-alt mr-1"></i>
                    View on Carsales
                  </a>
                )}
              </td>
            </tr>
            <tr>
              <th>Views:</th>
              <td>{car.views}</td>
              <th>Source:</th>
              <td>
                {capitalise(car.source || '')}
                {car.feed && (
                  <Link
                    to={
                      car.feed
                        ? Routes.dealership_feed_path(dealershipSlug || car.dealership_id, car.feed)
                        : '#'
                    }
                  >
                    View Feed
                  </Link>
                )}
              </td>
            </tr>
            <tr>
              <th>Location:</th>
              <td>
                {car.location && `${car.location.name}`}
                <small className="text-secondary ml-1">{car.location_code}</small>
              </td>
              <th>Address:</th>
              <td>
                {address &&
                  `${address.street}, ${address.city}, ${address.state}, ${address.postcode}`}
              </td>
            </tr>
            <tr>
              <Stat
                heading="Build Date:"
                value={car.build_date ? moment(car.build_date).format('DD/MM/YYYY') : null}
              />
              <Stat
                heading="Compliance Date:"
                value={
                  car.compliance_date ? moment(car.compliance_date).format('DD/MM/YYYY') : null
                }
              />
            </tr>
            {car.category && (
              <tr>
                <Stat heading="Category:" value={car.category} />
              </tr>
            )}
            {car.appraiser && (
              <tr>
                <Stat heading="Appraiser:" value={car.appraiser.name} />
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default CarSpecs
