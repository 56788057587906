import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { standardHeaders } from '../entries/utils'
import Form from '../shared/Form'
import showToast from '../shared/ShowToast'
import SlideIn from '../shared/SlideIn'
import HookFormField from '../shared/hookFormField'

let attributes = {
  dealership_user_ids: {
    type: 'select_multiple',
    label: 'Users',
    hint: 'Select the users to send the notification to.',
    options: 'dealership_users',
  },
  aggregator_website_notification: {
    type: 'switch',
    label: 'Aggregator Website Notification',
    hint: 'ADMIN ONLY: If turned on, will target all dealerships under the website.',
  },
  is_disabled: {
    type: 'switch',
    label: 'Disable',
    hint: 'If disabled, will NOT be sent.',
  },
  category: {
    type: 'select_multiple',
    label: 'Lead Categories',
    hint: 'Optional. Leave blank for all categories.',
    options: 'categories',
  },
  websites: {
    type: 'select_multiple',
    label: 'Websites',
    hint: 'Optional. Leave blank for all websites.',
    options: 'websites',
  },
  locations: {
    type: 'select_multiple',
    label: 'Locations',
    hint: 'Optional. Leave blank for all locations.',
    options: 'locations',
  },
  sources: {
    type: 'select_multiple',
    label: 'Lead sources',
    hint: 'Optional. Leave blank for all sources.',
    options: 'sources',
  },
}

const CategoriesButtons = ({ data }) => {
  const { control } = useFormContext()

  const { append } = useFieldArray({
    control,
    name: 'category',
  })

  let groups = [
    { name: 'Sales', categories: data.sales_categories },
    { name: 'Trade', categories: data.trade_categories },
    { name: 'Service', categories: data.service_categories },
    { name: 'General', categories: data.general_categories },
  ]

  return (
    <>
      {groups.map((group, index) => (
        <div
          className="btn btn-outline-secondary btn-sm mr-2"
          key={index}
          onClick={() => {
            append(group.categories)
          }}
        >
          <i className="fas fa-plus mr-1"></i>
          {group.name}
        </div>
      ))}
    </>
  )
}

const NotificationForm = ({ data, setVisible, refetch, notificationRef }) => {
  let { dealershipSlug } = useParams()
  let formData = { ...data }
  let defaultValues = {
    name: 'Lead',
    dealership_user_ids: [],
    category: [],
    websites: [],
    locations: [],
    sources: [],
    aggregator_website_notification: false,
    is_disabled: false,
  }

  return (
    <div className="py-3">
      <Form
        table_name="notification"
        submitUrl={Routes.dealership_notifications_path(dealershipSlug)}
        defaultValues={defaultValues}
        attributes={attributes}
        formData={formData}
        afterSubmit={() => {
          showToast(notificationRef, 'success', 'Notification created')
          setVisible(false)
          refetch()
        }}
      >
        <HookFormField attribute="dealership_user_ids" />
        <HookFormField attribute="category" />
        <CategoriesButtons data={data} />
        <HookFormField attribute="websites" />
        <HookFormField attribute="locations" />
        <HookFormField attribute="sources" />
        <HookFormField attribute="is_disabled" />
        <HookFormField attribute="aggregator_website_notification" />
      </Form>
    </div>
  )
}

const NewNotification = ({ medium, refetch, notification, notificationRef }) => {
  let [visible, setVisible] = useState(false)
  let { dealershipSlug } = useParams()

  const { data, isLoading } = useQuery({
    queryKey: ['new_notification', dealershipSlug],
    queryFn: async () => {
      let res = await fetch(Routes.new_dealership_notification_path(dealershipSlug), {
        headers: standardHeaders,
      })
      return res.json()
    },
    enabled: visible,
  })

  return (
    <>
      <SlideIn isOpen={visible} setIsOpen={setVisible} header="Create Notification">
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <NotificationForm
            data={data}
            setVisible={setVisible}
            notification={notification}
            refetch={refetch}
            notificationRef={notificationRef}
          />
        )}
      </SlideIn>
      <a
        href="#"
        className="dropdown-item"
        id={`add-${medium}`}
        onClick={(e) => {
          e.preventDefault()
          setVisible(true)
        }}
      >
        Email
      </a>
    </>
  )
}

export default NewNotification
