import { useEffect, useRef, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { PrimeReactProvider } from 'primereact/api'
import { FilterMatchMode, FilterService } from 'primereact/api'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { SplitButton } from 'primereact/splitbutton'
import { Toast } from 'primereact/toast'
import { useParams } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'

import * as Routes from '../../routes'
import Loading from '../Loading'
import DataTableSearch from '../entries/DataTableSearch'
import { humanize } from '../entries/utils'
import { standardHeaders } from '../entries/utils'
import AuditTrailSlideIn from '../shared/AuditTrailSlideIn'
import showToast from '../shared/ShowToast'
import Form from './Form'
import { NotificationService } from './notificationService'

let addNotificationPath = (medium) => {
  let { dealershipSlug } = useParams()

  const name = medium === 'cluster_webhook' ? 'LeadCluster' : 'Lead'
  return Routes.new_dealership_notification_path(dealershipSlug, { medium: medium, name: name })
}

const customFilter = (value, filters) => {
  if (value === null || value === '' || (value !== '' && filters === '')) {
    return true
  }
  return value.includes(filters) // filters is an array
}

FilterService.register('custom_website', customFilter)
FilterService.register('custom_category', customFilter)
FilterService.register('custom_source', customFilter)
FilterService.register('custom_location', customFilter)

export const Action = ({ notification, refetch, notificationRef }) => {
  let { dealershipSlug } = useParams()
  const [showSlideIn, setShowSlideIn] = useState(false)
  const handleHideSlideIn = () => {
    setShowSlideIn(false)
  }
  const items = [
    {
      label: 'View',
      icon: 'fa fa-eye',
      command: () => {
        window.location.href = Routes.dealership_notification_path(
          notification.dealership_id,
          notification.id
        )
      },
    },
    {
      label: 'Duplicate',
      icon: 'fas fa-copy',
      command: () => {
        const userConfirmation = window.confirm('Are you sure?')

        if (!userConfirmation) {
          return
        }
        fetch(
          `${Routes.create_duplicate_dealership_notification_path(
            notification.dealership_id,
            notification.id
          )}.json`,
          {
            method: 'POST',
            headers: standardHeaders,
            credentials: 'same-origin',
          }
        )
          .then((response) => response.json())
          .then((data) => {
            window.location.href = data.redirect_url
          })
      },
    },
    {
      label: 'Destroy',
      icon: 'fa fa-trash',
      command: () => {
        const userConfirmation = window.confirm('Are you sure?')

        if (!userConfirmation) {
          return
        }

        fetch(`${Routes.dealership_notification_path(dealershipSlug, notification.id)}.json`, {
          method: 'DELETE',
          headers: standardHeaders,
          credentials: 'same-origin',
        }).then(() => {
          showToast(notificationRef, 'success', 'Notification destroyed')
          refetch()
        })
      },
    },
    {
      label: 'Audit Trail',
      icon: 'fa fa-history',
      command: () => {
        setShowSlideIn(true)
      },
    },
  ]

  return (
    <div style={{ position: 'relative' }}>
      <SplitButton
        label="Edit"
        buttonProps={{ id: 'edit-button' }}
        onClick={() => {
          window.open(
            Routes.edit_dealership_notification_path(notification.dealership_id, notification.id),
            '_blank'
          )
        }}
        model={items}
        menuButtonProps={{ id: 'action-button' }}
        outlined
        rounded
      />
      {showSlideIn && (
        <AuditTrailSlideIn
          AuditTrailObject={notification}
          AuditTrailObjectName="Notification"
          Url={Routes.audit_trail_dealership_notification_path(
            notification.dealership_id,
            notification.id
          )}
          ShowSlideIn={showSlideIn}
          onHide={handleHideSlideIn}
        />
      )}
    </div>
  )
}

const User = (notification) => {
  return (
    <>
      {notification.notification_users?.map((user) => (
        <div key={user.id}>
          <div
            className="badge badge-sm badge-secondary mb-1 mr-1"
            id={`hit-${notification.id}-user-${user.id}`}
          >
            {user.name}
          </div>
          <Tooltip
            anchorId={`hit-${notification.id}-user-${user.id}`}
            place="bottom"
            style={{ zIndex: 10000 }}
          >
            {user.email}
          </Tooltip>
        </div>
      ))}
      {notification.emails?.map((email) => (
        <div key={email}>
          <div className="badge badge-sm badge-secondary mb-1 mr-1">{email}</div>
        </div>
      ))}
    </>
  )
}

const Medium = (notification) => {
  return (
    <>
      <div>{humanize(notification.medium) || 'All'}</div>
      {notification.is_disabled && <div className="badge badge-sm badge-danger ml-1">Disabled</div>}
    </>
  )
}

const Consumer = (notification) => {
  return humanize(notification.webhook_consumer_name) || ''
}

const Category = (notification) => {
  return humanize(notification.notification_category) || 'All'
}

const Subcategory = (notification) => {
  return humanize(notification.notification_subcategory) || 'All'
}

const Website = (notification) => {
  return notification.joined_websites || 'All'
}

const Source = (notification) => {
  return notification.notification_source || 'All'
}

const Location = (notification) => {
  return notification.notification_locations || 'All'
}

const App = ({ mediums = [], categories = [], websites = [], sources = [], locations = [] }) => {
  let { dealershipSlug } = useParams()
  let notificationRef = useRef(null)
  const [filters, setFilters] = useState({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
    medium: { value: '', matchMode: FilterMatchMode.CONTAINS },
    notification_category: { value: '', matchMode: FilterMatchMode.CUSTOM },
    joined_websites: { value: '', matchMode: FilterMatchMode.CUSTOM },
    notification_source: { value: '', matchMode: FilterMatchMode.CUSTOM },
    notification_locations: { value: '', matchMode: FilterMatchMode.CUSTOM },
    notification_subcategory: { value: '', matchMode: FilterMatchMode.CUSTOM },
  })

  const optionsMap = {
    Medium: mediums.map((medium) => ({ label: humanize(medium), value: medium })),
    Category: categories.map((category) => ({ label: humanize(category), value: category })),
    Website: websites.map((website) => ({ label: website, value: website })),
    Source: sources.map((source) => ({ label: source, value: source })),
    Location: locations.map((location) => ({ label: location, value: location })),
  }

  const mediumRowFilterTemplate = (options) => rowFilterTemplate(options, 'Medium')
  const categoryRowFilterTemplate = (options) => rowFilterTemplate(options, 'Category')
  const websiteRowFilterTemplate = (options) => rowFilterTemplate(options, 'Website')
  const sourceRowFilterTemplate = (options) => rowFilterTemplate(options, 'Source')
  const locationRowFilterTemplate = (options) => rowFilterTemplate(options, 'Location')

  const rowFilterTemplate = (options, name, width = '10rem') => {
    const selectedOptions = optionsMap[name]
    return (
      <Dropdown
        value={options.value}
        options={selectedOptions}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder={`Select ${name}`}
        className="p-column-filter"
        style={{ maxWidth: width }}
      />
    )
  }

  const [loading, setLoading] = useState(false)
  const [totalRecords, setTotalRecords] = useState(0)
  const [notifications, setNotifications] = useState(null)

  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 25,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      global: { value: '', matchMode: 'contains' }, // FilterMatchMode.CONTAINS is not used because lazyState was not updating
      notification_users: { value: '', matchMode: 'contains' },
      medium: { value: '', matchMode: 'contains' },
      joined_websites: { value: '', matchMode: 'custom_website' },
      notification_source: { value: '', matchMode: 'custom_source' },
      notification_locations: { value: '', matchMode: 'custom_location' },
      notification_category: { value: '', matchMode: 'custom_category' },
      notification_subcategory: { value: '', matchMode: 'contains' },
    },
  })

  useEffect(() => {
    loadLazyData()
  }, [lazyState])

  const loadLazyData = () => {
    setLoading(true)
    if (lazyState.page === undefined) {
      return
    }
    let page_number = 0
    if (lazyState.page === 0 || lazyState.first === 0) {
      page_number = 1
    } else {
      page_number = lazyState.page + 1
    }
    let params = { page: page_number, rows: lazyState.rows }
    try {
      NotificationService.getNotifications(params, dealershipSlug).then((data) => {
        setTotalRecords(data.data.total_records)
        setNotifications(data.data.notifications)
        setLoading(false)
      })
    } catch (error) {
      console.error(error)
    }
  }

  const header = DataTableSearch({ filters: lazyState.filters, setFilters: setFilters })
  return (
    <PrimeReactProvider>
      <Toast ref={notificationRef} />
      <div className="clearfix mb-2">
        <div className="float-right">
          <button
            className="btn btn-outline-success dropdown-toggle btn-block"
            data-toggle="dropdown"
            id="add-notification-dropdown"
          >
            Add Notification
          </button>
          <div className="dropdown-menu dropdown-menu-right">
            {mediums &&
              mediums.map((medium) => {
                if (medium === 'email') {
                  return (
                    <Form
                      medium={medium}
                      refetch={loadLazyData}
                      notificationRef={notificationRef}
                    />
                  )
                }

                return (
                  <a href={addNotificationPath(medium)} className="dropdown-item" key={medium}>
                    {humanize(medium)}
                  </a>
                )
              })}
          </div>
        </div>
      </div>
      <div className="box">
        <DataTable
          value={notifications}
          tableStyle={{ minWidth: '50rem' }}
          paginator
          rows={lazyState.rows}
          scrollable
          globalFilterFields={[
            'joined_user_names',
            'joined_user_emails',
            'medium',
            'joined_websites',
            'notification_category',
            'notification_subcategory',
          ]}
          style={{ overflow: 'visible' }}
          wrapper={{ style: { overflow: 'visible' } }}
          filterDisplay="row"
          header={header}
          first={lazyState.first}
          totalRecords={totalRecords}
          filters={lazyState.filters}
          loading={loading}
        >
          <Column body={User} sortable field="notification_users" header="Users"></Column>
          <Column
            body={Medium}
            sortable
            field="medium"
            header="Medium"
            style={{ maxWidth: '10rem' }}
            showFilterMenu={false}
            filterMenuStyle={{ width: '14rem' }}
            filter
            filterElement={mediumRowFilterTemplate}
          ></Column>
          <Column body={Consumer} sortable field="webhook_consumer_name" header="Consumer"></Column>
          <Column
            body={Category}
            sortable
            field="notification_category"
            header="Category"
            showFilterMenu={false}
            filterMenuStyle={{ width: '14rem' }}
            filter
            filterElement={categoryRowFilterTemplate}
          ></Column>
          <Column
            body={Subcategory}
            sortable
            field="notification_subcategory"
            style={{ maxWidth: '10rem' }}
            header="Subcategories"
          ></Column>
          <Column
            body={Website}
            sortable
            field="joined_websites"
            header="Website(s)"
            showFilterMenu={false}
            filterMenuStyle={{ width: '14rem' }}
            filter
            filterElement={websiteRowFilterTemplate}
          ></Column>
          <Column
            body={Source}
            sortable
            field="notification_source"
            header="Source(s)"
            showFilterMenu={false}
            filterMenuStyle={{ width: '14rem' }}
            filter
            filterElement={sourceRowFilterTemplate}
          ></Column>
          <Column
            body={Location}
            sortable
            field="notification_locations"
            header="Location(s)"
            showFilterMenu={false}
            filterMenuStyle={{ width: '14rem' }}
            filter
            filterElement={locationRowFilterTemplate}
          ></Column>
          <Column
            body={(rowData) => (
              <Action
                notification={rowData}
                refetch={loadLazyData}
                notificationRef={notificationRef}
              />
            )}
            header="Actions"
          ></Column>
        </DataTable>
      </div>
    </PrimeReactProvider>
  )
}

const fetchData = async (dealershipSlug) => {
  let res = await fetch(Routes.react_index_params_dealership_notifications_path(dealershipSlug), {
    headers: standardHeaders,
  })
  return res.json()
}

const Wrapper = () => {
  let { dealershipSlug } = useParams()

  const { data, isLoading } = useQuery({
    queryKey: ['notifications_index_params', dealershipSlug],
    queryFn: () => fetchData(dealershipSlug),
    staleTime: 5 * 60 * 1000, // Cache data for 5 minutes
  })

  if (isLoading) {
    return <Loading />
  }

  return <App {...data} />
}

export default Wrapper
