import { useEffect, useState } from 'react'

import { Select } from '../entries/FormElements'
import { standardHeaders } from '../entries/utils'

const FormCarSelect = ({
  leadFormData,
  setCarId,
  locationId,
  setVehicleMake,
  setVehicleModel,
  vehicleModel,
  vehicleMake,
  setCarType,
  setCategory,
}) => {
  let [genericCar, setGenericCar] = useState(false)
  let [carOptions, setCarOptions] = useState([])
  let [manufacturerId, setManufacturerId] = useState(null)
  let [manufacturers, setManufacturers] = useState([])
  let [families, setFamilies] = useState([])

  let families_url = `/manufacturers/${manufacturerId}/families.json`

  useEffect(() => {
    if (!genericCar) return

    fetch(`/manufacturers.json`, {
      headers: standardHeaders,
    })
      .then((response) => response.json())
      .then((data) => {
        setManufacturers(data)
      })
  }, [genericCar])

  useEffect(() => {
    if (!vehicleMake) return

    fetch(families_url, {
      headers: standardHeaders,
    })
      .then((response) => response.json())
      .then((data) => {
        setFamilies(data)
      })
  }, [vehicleMake])

  let manufacturerOptions = manufacturers?.map((manufacturer) => ({
    value: manufacturer.id,
    label: manufacturer.name,
  }))

  let familyOptions = families?.map((family) => ({
    value: family.id,
    label: family.name,
  }))

  useEffect(() => {
    let options = []
    if (locationId) {
      options = leadFormData?.cars
        ?.filter((car) => car.location_id === locationId)
        .map((car) => ({
          value: car.id,
          label: car.name,
        }))
    } else {
      options = leadFormData?.cars?.map((car) => ({
        value: car.id,
        label: car.name,
      }))
    }

    setCarOptions(options)
  }, [locationId])

  return (
    <>
      <div className="btn-group mb-2">
        <button
          type="button"
          className={'btn btn-sm btn-outline-secondary' + (!genericCar ? ' active' : '')}
          onClick={() => {
            setGenericCar(false)
            setVehicleMake(null)
            setVehicleModel(null)
          }}
        >
          Existing Car
        </button>
        <button
          type="button"
          className={'btn btn-sm btn-outline-secondary' + (genericCar ? ' active' : '')}
          onClick={() => {
            setCarId(null)
            setGenericCar(!genericCar)
            setCategory(null)
          }}
        >
          Build New Car
        </button>
      </div>
      {genericCar ? (
        <div className="row">
          <div className="col-6">
            <Select
              label="Make"
              options={manufacturerOptions}
              onChange={(option) => {
                setManufacturerId(option?.value)
                setVehicleMake(option?.label)
                setVehicleModel(null)
              }}
            />
          </div>
          <div className="col-6">
            <Select
              label="Model"
              isDisabled={!vehicleMake}
              options={familyOptions}
              value={familyOptions?.find((family) => family.label === vehicleModel) || null}
              onChange={(option) => {
                setVehicleModel(option?.label)
              }}
            />
          </div>
        </div>
      ) : (
        <Select
          label="Cars"
          options={carOptions}
          onChange={(e) => {
            setCarId(e ? e.value : null)
            let carType = leadFormData?.cars?.find((car) => car.id === e.value)?.car_type
            setCarType(carType)
          }}
          hint={locationId ? `Only showing cars from this location ${locationId}` : null}
        />
      )}
    </>
  )
}

export default FormCarSelect
