import { useEffect, useState } from 'react'

import moment from 'moment'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { Errors, Input, Select } from '../entries/FormElements'
import { standardHeaders } from '../entries/utils'
import { humanize } from '../entries/utils'
import DateRangeSelector from '../shared/DateRangeSelector'

const NewReport = ({
  visible,
  setVisible,
  onHide,
  customReport,
  customReportsData,
  fetchCustomReports,
  fetchReports,
}) => {
  let [customReportDateRanges, setCustomReportDateRanges] = useState([])
  let [dateRange, setDateRange] = useState()
  const [useCustomDateRange, setUseCustomDateRange] = useState(false)
  const [customStartDate, setCustomStartDate] = useState(null)
  const [customEndDate, setCustomEndDate] = useState(null)
  let [leadStateOptions, setLeadStateOptions] = useState([])
  let [leadStates, setLeadStates] = useState([])
  let [name, setName] = useState('')
  let [locations, setLocations] = useState([])
  let [categories, setCategories] = useState([])
  let [marketingSources, setMarketingSources] = useState([])
  let [loading, setLoading] = useState(false)
  let [errors, setErrors] = useState(false)
  let [fields, setFields] = useState(customReport?.fields || customReportsData.default_fields)
  let { dealershipSlug } = useParams()

  useEffect(() => {
    fetchCustomReportDateRanges()
    fetchLeadClusterLeadStates()
  }, [])

  const onDateRangeChange = (ranges) => {
    if (ranges.selection.startDate && ranges.selection.endDate) {
      setCustomStartDate(ranges.selection.startDate)
      setCustomEndDate(ranges.selection.endDate)
    }
  }

  const fetchCustomReportDateRanges = () => {
    fetch(Routes.date_ranges_dealership_custom_reports_path(dealershipSlug))
      .then((res) => res.json())
      .then((data) => {
        setCustomReportDateRanges(data['date_ranges'])
      })
  }

  const fetchLeadClusterLeadStates = () => {
    fetch(Routes.lead_states_dealership_lead_clusters_path(dealershipSlug))
      .then((res) => res.json())
      .then((data) => {
        let lso = data.map((leadState) => ({
          label: leadState,
          value: leadState,
        }))
        setLeadStateOptions(lso)
      })
  }

  const handleDateRangeChange = (value) => {
    setUseCustomDateRange(value === 'custom_range')
    setDateRange(value)
  }

  const createCustomReport = () => {
    let url = Routes.dealership_custom_reports_path(dealershipSlug)
    let method = 'POST'
    if (customReport?.id) {
      url = Routes.dealership_custom_report_path(dealershipSlug, customReport.id)
      method = 'PUT'
    }

    setLoading(true)
    fetch(url, {
      method: method,
      headers: standardHeaders,
      body: JSON.stringify({
        custom_report: {
          date_range: dateRange,
          date_range_starts_at: customStartDate,
          date_range_ends_at: customEndDate,
          name: name,
          fields: fields,
          categories: categories,
          lead_states: leadStates,
          location_ids: locations,
          marketing_sources: marketingSources,
          reportable_type: 'LeadCluster',
        },
      }),
    })
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        if (data.errors) {
          setLoading(false)
          setErrors(data.errors)
        } else {
          setLoading(false)
          setVisible(false)
          fetchCustomReports()
          fetchReports()
          setDateRange(undefined)
        }
      })
      .catch((error) => {
        setLoading(false)
        console.error('Error:', error)
      })
  }

  let locationOptions = customReportsData.locations.map((location) => ({
    label: `${location.name}`,
    value: location.id,
  }))

  let categoryOptions = customReportsData.categories.map((category) => ({
    label: category,
    value: category,
  }))

  let marketingSourceOptions = customReportsData.marketing_sources.map((marketingSource) => ({
    label: marketingSource,
    value: marketingSource,
  }))

  return (
    <Dialog
      header="Create Report"
      visible={visible}
      onHide={onHide}
      dismissableMask
      style={{ width: '50vw' }}
    >
      <div className="mb-3">
        <Input
          label="Name"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required={true}
        />
        <Select
          label="Fields"
          placeholder="Select Fields"
          options={customReportsData.all_fields.map((field) => ({ label: field, value: field }))}
          onChange={(selectedOption) => setFields(selectedOption.map((o) => o.value))}
          isMulti={true}
          defaultValue={fields.map((field) => ({ label: field, value: field }))}
          menuPortalTarget={document.body}
        />
        <Select
          label="Date Range"
          placeholder="Select Date Range..."
          options={customReportDateRanges.map((field) => ({
            label: humanize(field),
            value: field,
          }))}
          onChange={(selectedOption) => handleDateRangeChange(selectedOption.value)}
        />
        {useCustomDateRange && (
          <DateRangeSelector
            startDate={customStartDate}
            endDate={customEndDate}
            onChange={onDateRangeChange}
          />
        )}
        <Select
          label="Location"
          placeholder="Select Location..."
          options={locationOptions}
          onChange={(selectedOption) => setLocations(selectedOption.map((o) => o.value))}
          defaultValue={locationOptions.filter((l) => locations.includes(l.value.toString()))}
          isMulti={true}
          menuPortalTarget={document.body}
        />
        <Select
          label="Categories"
          placeholder="Select Category..."
          options={categoryOptions}
          onChange={(selectedOption) => setCategories(selectedOption.map((o) => o.value))}
          defaultValue={categoryOptions.filter((l) => categories.includes(l.value.toString()))}
          isMulti={true}
          menuPortalTarget={document.body}
        />
        <Select
          label="Lead States"
          placeholder="Select Lead States..."
          options={leadStateOptions}
          defaultValue={leadStateOptions.filter((l) => leadStates.includes(l.value.toString()))}
          onChange={(selectedOption) => setLeadStates(selectedOption.map((o) => o.value))}
          isMulti={true}
          menuPortalTarget={document.body}
        />
        <Select
          label="Marketing Source"
          placeholder="Select Marketing Source..."
          options={marketingSourceOptions}
          onChange={(selectedOption) => setMarketingSources(selectedOption.map((o) => o.value))}
          defaultValue={marketingSourceOptions.filter((l) =>
            marketingSources.includes(l.value.toString())
          )}
          isMulti={true}
          menuPortalTarget={document.body}
        />
      </div>
      <div
        className={'btn btn-outline-success btn-block mb-3' + (loading ? ' disabled' : '')}
        onClick={createCustomReport}
        disabled={loading}
      >
        {loading ? (
          <span>
            <i className="fa fa-spinner fa-spin mr-1"></i>
            Loading...
          </span>
        ) : (
          <>{customReport?.id ? 'Update' : 'Add'} Report</>
        )}
      </div>
      {errors && <Errors errors={errors} />}
    </Dialog>
  )
}

const Locations = (customReport) => {
  if (customReport.location_names.length === 0) {
    return <div className="badge badge-secondary">All</div>
  }

  return (
    <div>
      {customReport?.location_names.map((location) => (
        <div className="badge badge-secondary mr-1" key={location}>
          {location}
        </div>
      ))}
    </div>
  )
}

const Categories = (customReport) => {
  if (customReport.categories.length === 0) {
    return <div className="badge badge-secondary">All</div>
  }

  return (
    <div>
      {customReport?.categories.map((category) => (
        <div className="badge badge-secondary mr-1" key={category}>
          {category}
        </div>
      ))}
    </div>
  )
}

const Actions = ({ customReport, fetchCustomReports }) => {
  let { dealershipSlug } = useParams()
  let [deleting, setDeleting] = useState(false)
  let [loading, setLoading] = useState(false)

  const destroy = () => {
    setDeleting(true)
    fetch(Routes.dealership_custom_report_path(dealershipSlug, customReport.id), {
      method: 'DELETE',
      headers: standardHeaders,
    })
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        fetchCustomReports()
        setDeleting(false)
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  const generate = () => {
    setLoading(true)
    fetch(Routes.generate_dealership_custom_report_path(dealershipSlug, customReport.id), {
      method: 'POST',
      headers: standardHeaders,
    })
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        fetchCustomReports()
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  return (
    <div className="btn-group">
      <button
        type="button"
        className="btn btn-sm btn-outline-secondary dropdown-toggle"
        data-toggle="dropdown"
        aria-expanded="false"
      >
        Actions
      </button>
      <ul className="dropdown-menu dropdown-menu-right">
        <li>
          <a
            onClick={(e) => {
              e.preventDefault()
              generate()
            }}
            href="#"
            className="dropdown-item"
          >
            {deleting ? (
              <span>
                <i className="fa fa-spinner fa-spin mr-1"></i>
                Loading...
              </span>
            ) : (
              <>Generate Report</>
            )}
          </a>
        </li>
        <li>
          <a
            onClick={(e) => {
              e.preventDefault()
              destroy()
            }}
            href="#"
            disabled={deleting}
            className="dropdown-item"
          >
            {deleting ? (
              <span>
                <i className="fa fa-spinner fa-spin mr-1"></i>
                Loading...
              </span>
            ) : (
              <>
                <i className="fa fa-trash" /> Destroy
              </>
            )}
          </a>
        </li>
      </ul>
    </div>
  )
}

const CreatedAt = (customReport) => {
  return <>{moment(customReport.created_at).fromNow()}</>
}

const FileUrl = (customReport) => {
  if (!customReport.file_url) {
    return null
  }

  return (
    <a className="btn btn-outline-secondary btn-sm" href={customReport.file_url}>
      <i className="fa fa-download mr-1" />
      Download
    </a>
  )
}

const Status = (customReport) => {
  let className = 'badge-success'

  if (customReport.status === 'pending') {
    className = 'badge-warning'
  }

  if (customReport.status === 'failed') {
    className = 'badge-danger'
  }

  return <div className={`badge ${className}`}>{humanize(customReport.status)}</div>
}

const Reports = ({ reports, loading, setLoading, fetchReports }) => {
  return (
    <div className="box">
      <div className="p-3">
        <div className="d-flex">
          <div>
            <h5 className="mb-0">Reports Generated</h5>
            <div className="small text-secondary">Reports are kept for 7 days before deletion</div>
          </div>
          <div className="ml-auto">
            <button
              className="btn btn-outline-primary"
              onClick={(e) => {
                e.preventDefault()
                fetchReports()
              }}
            >
              <i className="fa fa-refresh mr-1"></i>
              {loading ? 'Loading...' : 'Refresh'}
            </button>
          </div>
        </div>
      </div>
      <DataTable value={reports} loading={loading}>
        <Column field="created_at" header="Created" body={CreatedAt} />
        <Column field="custom_report.name" header="Report Name" />
        <Column field="status" header="Status" body={Status} />
        <Column field="file_url" header="File URL" body={FileUrl} />
      </DataTable>
    </div>
  )
}

const CustomReportName = (customReport) => {
  return (
    <>
      <div>{customReport.name}</div>
      {customReport.user_name && (
        <small className="text-secondary">
          Created by: {customReport.user_name} {moment(customReport.created_at).fromNow()}
        </small>
      )}
    </>
  )
}

const DateRange = (customReport) => {
  if (customReport.date_range) {
    return (
      <>
        <div>{humanize(customReport.date_range)}</div>
      </>
    )
  }
}

const LeadReports = () => {
  let [customReportsData, setCustomReportsData] = useState()
  let [showNewReport, setShowNewReport] = useState(false)
  let [loading, setLoading] = useState(true)
  let { dealershipSlug } = useParams()
  let [reports, setReports] = useState([])
  let [loadingReports, setLoadingReports] = useState(true)

  useEffect(() => {
    fetchCustomReports()
  }, [])

  useEffect(() => {
    fetchReports()
  }, [])

  const fetchCustomReports = () => {
    fetch(Routes.dealership_custom_reports_path(dealershipSlug, { reportable_type: 'LeadCluster' }))
      .then((res) => res.json())
      .then((data) => {
        setCustomReportsData(data)
        setLoading(false)
      })
  }

  const fetchReports = () => {
    setLoadingReports(true)
    fetch(Routes.reports_dealership_lead_clusters_path(dealershipSlug), {
      headers: standardHeaders,
    })
      .then((res) => res.json())
      .then((data) => {
        setReports(data.reports)
        setLoadingReports(false)
      })
  }

  return (
    <div className="p-3">
      <div className="box mb-3">
        <div className="d-flex align-items-center p-3">
          <h5 className="mb-0">Lead Reports</h5>
          <div className="ml-auto">
            <a
              href="#"
              className="btn btn-outline-primary"
              onClick={(e) => {
                e.preventDefault()
                setShowNewReport(true)
              }}
            >
              <i className="fa fa-plus mr-1"></i>
              Report
            </a>
            {customReportsData && (
              <NewReport
                visible={showNewReport}
                onHide={() => setShowNewReport(false)}
                setVisible={setShowNewReport}
                customReportsData={customReportsData}
                fetchCustomReports={fetchCustomReports}
                fetchReports={fetchReports}
              />
            )}
          </div>
        </div>
        <DataTable value={customReportsData?.custom_reports} loading={loading}>
          <Column field="name" header="Name" body={CustomReportName} />
          <Column header="Date Range" body={DateRange} />
          <Column header="Locations" body={Locations} />
          <Column header="Categories" body={Categories} />
          <Column
            header="Actions"
            body={(rowData) => (
              <Actions customReport={rowData} fetchCustomReports={fetchCustomReports} />
            )}
          />
        </DataTable>
      </div>
      <Reports
        reports={reports}
        loading={loadingReports}
        setLoading={setLoadingReports}
        fetchReports={fetchReports}
      />
    </div>
  )
}

export default LeadReports
