import { useContext } from 'react'

import * as Routes from '../../routes'
import { CurrentUserContext, DealershipContext, LeadClusterContext, LeadContext } from '../contexts'
import { standardHeaders } from '../entries/utils'

export const updateLeadClusterAPI = async (leadClusterId, data, dealershipId) => {
  const url = Routes.dealership_lead_cluster_path(dealershipId, leadClusterId)

  try {
    const response = await fetch(url, {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: standardHeaders,
    })

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({})) // Handle non-JSON errors
      throw new Error(`Error ${response.status}: ${errorData.message || response.statusText}`)
    }

    return response.json() // Ensure only valid JSON is returned
  } catch (error) {
    console.error(`Failed to update lead cluster ${leadClusterId}:`, error)
    throw error // Ensure errors propagate properly
  }
}

export const updateLeadClusterUserId = async (leadClusterId, userId, dealershipSlug) => {
  const data = {
    lead_cluster: {
      user_id: userId,
    },
  }

  return await updateLeadClusterAPI(leadClusterId, data, dealershipSlug)
}

export const updateLeadStatusOptionId = async (
  leadClusterId,
  leadStatusOptionid,
  dealershipSlug
) => {
  let data = {
    lead_cluster: {
      lead_status_option_id: leadStatusOptionid,
    },
  }
  return await updateLeadClusterAPI(leadClusterId, data, dealershipSlug)
}

export const updateLeadClusterLocation = async (leadClusterId, locationId, dealershipSlug) => {
  let data = {
    lead_cluster: {
      location_id: locationId,
    },
  }
  return await updateLeadClusterAPI(leadClusterId, data, dealershipSlug)
}

export const deleteLeadClusterAPI = (dealershipSlug, leadClusterId) => {
  return fetch(Routes.dealership_lead_cluster_path(dealershipSlug, leadClusterId), {
    method: 'DELETE',
    headers: standardHeaders,
  })
}

export const updateContactAPI = (contactId, data, dealershipSlug) => {
  return fetch(Routes.dealership_contact_path(dealershipSlug, contactId), {
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: standardHeaders,
  })
}

export const ownerContext = () => {
  const { lead, setLead } = useContext(LeadContext)
  const { leadCluster, setLeadCluster } = useContext(LeadClusterContext)

  // Check if lead is present, otherwise use leadCluster
  return {
    owner: lead ?? leadCluster,
    setOwner: lead ? setLead : setLeadCluster,
  }
}

export const canManageLeads = () => {
  const currentUser = useContext(CurrentUserContext)
  const { dealership } = useContext(DealershipContext)
  return currentUser?.dealerships?.[dealership?.id]?.can_manage_leads || currentUser?.admin
}

export const canReassignLeads = () => {
  const currentUser = useContext(CurrentUserContext)
  const { dealership } = useContext(DealershipContext)
  return currentUser?.dealerships?.[dealership?.id]?.can_reassign_leads || currentUser?.admin
}

export const canChangeLeadClusterLocation = () => {
  const currentUser = useContext(CurrentUserContext)
  const { dealership } = useContext(DealershipContext)
  const dealershipUser = currentUser?.dealerships?.[dealership?.id]
  const userCanManageLeads = dealershipUser?.can_manage_leads && dealershipUser?.role === 'manager'
  return userCanManageLeads || currentUser?.admin
}
