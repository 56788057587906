import { useEffect, useRef, useState } from 'react'

import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Link, useNavigate } from 'react-router-dom'

import * as Routes from '../../routes'
import { DownloadCsvHeader } from '../entries/DataTableSearch'
import { standardHeaders } from '../entries/utils'
import { Toggle } from '../settings/leadSettings'
import Form from '../shared/Form'
import SlideIn from '../shared/SlideIn'
import HookFormField from '../shared/hookFormField'

const Name = (dealership) => {
  return <Link to={Routes.dealership_path(dealership.slug)}>{dealership.name}</Link>
}

const TwoFactor = (dealership) => {
  return (
    <Toggle
      attributeName="enforce_two_factor"
      objectType="dealership"
      rowData={dealership}
      url={`/dealerships/${dealership.id}`}
    />
  )
}

let attributes = {
  name: { type: 'text', label: 'Dealership Name', required: true },
}

const AddDealership = () => {
  let navigate = useNavigate()
  let [visible, setVisible] = useState(false)

  let defaultValues = {
    name: '',
  }

  return (
    <>
      <SlideIn isOpen={visible} setIsOpen={setVisible} header="Add Dealership">
        <Form
          submitUrl={Routes.dealerships_path()}
          defaultValues={defaultValues}
          attributes={attributes}
          formData={{}}
          afterSubmit={(res) => {
            if (res && res.id) {
              navigate(Routes.dealership_path(res.id))
            }
          }}
        >
          <div className="py-3">
            <HookFormField attribute="name" />
          </div>
        </Form>
      </SlideIn>
      <a
        href="#"
        className="btn btn-outline-primary"
        onClick={(e) => {
          e.preventDefault()
          setVisible(true)
        }}
      >
        <i className="fa fa-plus mr-2"></i>
        Dealership
      </a>
    </>
  )
}

const Dealerships = () => {
  const dt = useRef(null)
  const [dealerships, setDealerships] = useState([])
  let [loading, setLoading] = useState(true)
  let [page, setPage] = useState(1)
  let [totalCount, setTotalCount] = useState(0)

  let rows = 50

  const header = DownloadCsvHeader({ dt: dt })

  useEffect(() => {
    setLoading(true)
    fetch(Routes.dealerships_path({ page: page, per_page: rows }), {
      headers: standardHeaders,
    })
      .then((response) => response.json())
      .then((data) => {
        setDealerships(data.dealerships)
        setTotalCount(data.total_count)
        setLoading(false)
      })
  }, [page])

  const onPageChange = (event) => {
    setPage(event.page + 1) // PrimeReact pages are 0-based; Kaminari pages are 1-based
  }

  return (
    <div className="container py-4">
      <div className="d-flex mb-2">
        <h4>Dealerships</h4>
        <div className="ml-auto">
          <AddDealership />
        </div>
      </div>
      <div className="box">
        <DataTable
          value={dealerships}
          paginator
          lazy
          rows={rows}
          first={(page - 1) * rows}
          totalRecords={totalCount}
          onPage={onPageChange}
          loading={loading}
          ref={dt}
          header={header}
        >
          <Column field="name" header="Name" body={Name} />
          <Column field="two_factor" header="Enforce Two Factor" body={TwoFactor} />
          <Column field="timezone" header="State" />
          <Column field="dealership_group.name" header="Group Name" />
        </DataTable>
      </div>
    </div>
  )
}

export default Dealerships
