import { useMemo, useRef, useState } from 'react'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import { Tooltip } from 'react-tooltip'

import { useFetchWebsite } from '../../dataHooks'
import { GoogleSearchPreview } from '../../editor/toolbar/GoogleSearchPreview'
import { standardHeaders } from '../../entries/utils'
import { handleResponse } from '../../formUtils'
import SearchPagesForm from './SearchPagesForm'
import { type SearchPageTemplateShortcode } from './searchPageTemplateShortcodes'

export interface SearchPageTemplate {
  id: number
  category: string
  enabled: boolean
  title_template: string
  description_template: string
  heading_template: string
  use_custom_heading: boolean
  is_custom: boolean
}

// Function to preview template with sample values

export const SearchPagesTable = ({
  data,
  isLoading,
}: {
  data: { templates: SearchPageTemplate[] }
  isLoading: boolean
}) => {
  const queryClient = useQueryClient()
  const { website } = useFetchWebsite()
  const [currentAction, setCurrentAction] = useState<'default' | 'edit' | 'seo-preview'>('default')
  const [selectedTemplate, setSelectedTemplate] = useState<SearchPageTemplate | null>(null)
  const notification = useRef<Toast>(null)

  const { mutate: deleteTemplate } = useMutation({
    mutationFn: async (template: SearchPageTemplate) => {
      const response = await fetch(
        `/websites/${website.slug}/search_page_templates/${template.id}`,
        {
          method: 'DELETE',
          headers: standardHeaders,
        }
      )
      await handleResponse(response)
    },
    onSuccess: () => {
      notification.current?.show({
        severity: 'success',
        summary: 'Template has been reset to default',
      })
      queryClient.invalidateQueries({ queryKey: ['searchPageTemplates', website.slug] })
    },
    onError: () => {
      notification.current?.show({
        severity: 'error',
        summary: 'Failed to reset template to default',
      })
    },
  })

  const sampleAliases = useMemo(() => {
    // Future task, we could use meilisearch to get the most popular values for each shortcode for the current website
    // However, the hardcoded samples should be fine for now
    const defaultAliases: Record<SearchPageTemplateShortcode, string> = {
      // Basic variables
      '[count]': Math.floor(Math.random() * 1000).toString(),
      '[website_name]': website?.name,
      '[default_vehicle]': 'Car',

      // Vehicle type variables
      '[car_type]': 'Used',
      '[car_types]': 'New, Used, and Demo',
      '[body]': 'Sedan',
      '[bodies]': 'Sedans, SUVs, and Hatchbacks',
      '[drive]': '4WD',
      '[fuel]': 'Diesel',
      '[category]': 'Prestige and Luxury',

      // Brand/model variables
      '[make]': 'Toyota',
      '[makes]': 'Toyota, Honda, and Mazda',
      '[make_model]': 'Toyota Camry',

      // Location variables
      '[default_city]': website?.override_city ?? website?.address?.city ?? 'Brisbane',
      '[default_state]': website?.address?.state ?? 'QLD',
      '[state]': website?.address?.state ?? 'QLD',
      '[city]': website?.address?.city ?? 'Brisbane',
      '[dealership_name]': website?.dealerships?.[0]?.name ?? 'My Used Cars',
      '[location_name]': website?.locations?.[0]?.name ?? 'Brisbane',
    }
    return defaultAliases
  }, [website])

  const transformedText = (template: string): string => {
    return template.replace(/\[(.*?)\]/g, (match) => sampleAliases[match] || match)
  }

  const categoryTemplate = (rowData: SearchPageTemplate) => {
    return <strong>{rowData.category}</strong>
  }

  const enabledBodyTemplate = (rowData: SearchPageTemplate) => {
    if (rowData.enabled) {
      return (
        <div
          className="btn btn-sm btn-success"
          style={{ borderRadius: '999px', fontSize: '0.75rem' }}
        >
          Enabled
        </div>
      )
    }
    return (
      <button
        className="btn btn-sm btn-outline-danger"
        style={{ borderRadius: '999px', fontSize: '0.75rem' }}
        disabled
      >
        Hidden
      </button>
    )
  }

  const titleTemplate = (rowData: SearchPageTemplate) => {
    return (
      <div className="truncate bg-light" style={{ maxWidth: '260px' }}>
        {rowData.title_template}
      </div>
    )
  }

  const descriptionTemplate = (rowData: SearchPageTemplate) => {
    return (
      <div className="truncate text-muted" style={{ maxWidth: '460px' }}>
        {rowData.description_template}
      </div>
    )
  }

  const actionBodyTemplate = (rowData: SearchPageTemplate) => {
    return (
      <div>
        <Tooltip id={`edit-${rowData.category}`}>Edit Template</Tooltip>
        <Tooltip id={`preview-${rowData.category}`}>SEO Preview</Tooltip>
        {rowData.is_custom && <Tooltip id={`reset-${rowData.category}`}>Reset To Default</Tooltip>}
        <button
          className="btn btn-sm btn-outline-primary mr-2"
          onClick={() => {
            setSelectedTemplate(rowData)
            setCurrentAction('edit')
          }}
          data-tooltip-id={`edit-${rowData.category}`}
          data-testid={`edit-${rowData.category}`}
        >
          <i className="fas fa-pencil-alt"></i>
        </button>
        <button
          className="btn btn-sm btn-outline-info mr-2"
          onClick={() => {
            setSelectedTemplate(rowData)
            setCurrentAction('seo-preview')
          }}
          data-tooltip-id={`preview-${rowData.category}`}
          data-testid={`preview-${rowData.category}`}
        >
          <i className="fas fa-eye"></i>
        </button>
        <button
          className="btn btn-sm btn-outline-danger"
          onClick={() => {
            deleteTemplate(rowData)
          }}
          data-tooltip-id={`reset-${rowData.category}`}
          disabled={!rowData.is_custom}
          data-testid={`reset-${rowData.category}`}
        >
          <i className="fas fa-undo"></i>
        </button>
      </div>
    )
  }

  const hidePreviewDialog = () => {
    setCurrentAction('default')
    setSelectedTemplate(null)
  }

  return (
    <>
      <DataTable
        value={data?.templates}
        tableStyle={{ minWidth: '50rem' }}
        emptyMessage="No templates found"
        loading={isLoading}
      >
        <Column field="category" header="Category" body={categoryTemplate} />
        <Column field="enabled" header="Status" body={enabledBodyTemplate} />
        <Column field="title_template" header="Title Template" body={titleTemplate} />
        <Column
          field="description_template"
          header="Description Template"
          body={descriptionTemplate}
        />
        <Column body={actionBodyTemplate} header="Actions" />
      </DataTable>

      <Dialog
        header="Google Search Preview"
        visible={currentAction === 'seo-preview'}
        style={{ width: '50rem' }}
        onHide={hidePreviewDialog}
      >
        {selectedTemplate && (
          <GoogleSearchPreview
            title={`${transformedText(selectedTemplate.title_template)} | ${website.name}`}
            description={transformedText(selectedTemplate.description_template)}
            url={`${website.url}/search/${selectedTemplate.category}`}
            type="desktop"
          />
        )}
      </Dialog>

      <Dialog
        header={`Edit Search Page Template${selectedTemplate ? ` for ${selectedTemplate.category}` : ''}`}
        visible={currentAction === 'edit'}
        style={{ width: '50rem' }}
        onHide={hidePreviewDialog}
      >
        {selectedTemplate && (
          <div className="p-3">
            <SearchPagesForm
              template={selectedTemplate}
              website={website}
              onClose={hidePreviewDialog}
              notification={notification}
            />
          </div>
        )}
      </Dialog>
      <Toast ref={notification} />
    </>
  )
}
