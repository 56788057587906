import { useRef, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { SplitButton } from 'primereact/splitbutton'
import { Toast } from 'primereact/toast'
import { useNavigate, useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import Loading from '../Loading'
import { CarsalesAccountsTable } from '../admin/CarsalesAccounts'
import { humanize } from '../entries/utils'
import { standardHeaders } from '../entries/utils'
import { Toggle } from '../settings/leadSettings'
import { CopyableText } from '../shared/CopyableText'
import showToast from '../shared/ShowToast'
import Show from './show'

const feedTypes = [
  'import',
  'email',
  'export',
  'url-only',
  'webhook',
  'dealer_solutions_api',
  'loopit',
  'titan_api',
  'database_feed',
  'orbee',
  'autotrader',
]

const Name = (rowData) => {
  let [isOpen, setIsOpen] = useState(false)

  return (
    <div>
      <Show feedId={rowData.id} isOpen={isOpen} setIsOpen={setIsOpen} />
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault()
          setIsOpen(true)
        }}
      >
        {rowData.name || 'View'}
      </a>
      {rowData.csv_filename && (
        <div className="small text-secondary">
          <CopyableText text={rowData.csv_filename} />
        </div>
      )}
      <div className="small text-secondary">Created {moment(rowData.created_at).fromNow()}</div>
      <div className="small text-secondary">
        Last edited by {rowData.last_edited_by} {moment(rowData.updated_at).fromNow()}
      </div>
      {rowData.secondary_feed && (
        <div>
          <div className="badge badge-secondary badge-sm">Secondary feed</div>
        </div>
      )}
      {rowData.frequency && (
        <div className="small text-secondary">Frequency: {rowData.frequency}</div>
      )}
      {rowData.schema === 'Autogate' && !rowData.export_photos && (
        <div className="badge badge-danger mt-2">
          <i className="fa fa-exclamation-triangle mr-1"></i>
          Photos not exported
        </div>
      )}
    </div>
  )
}

const Email = (rowData) => {
  return (
    <div className="small text-secondary">
      <CopyableText text={rowData.email} />
    </div>
  )
}

const StockType = (rowData) => {
  let { dealershipSlug } = useParams()

  return (
    <>
      <div>{rowData.stock_type || rowData.stock_types.join(', ')}</div>
      {rowData.stock_filters_count > 0 && (
        <a href={`/dealerships/${dealershipSlug}/feeds/${rowData.id}/stock_filters`}>
          <div className="small text-secondary">{rowData.stock_filters_count} stock filters</div>
        </a>
      )}
    </>
  )
}

const ActionsDropdown = ({ rowData, notification }) => {
  let { dealershipSlug } = useParams()
  const navigate = useNavigate()

  const items = [
    {
      label: 'View',
      command: () => navigate(Routes.dealership_feed_path(dealershipSlug, rowData.id)),
    },
    {
      label: 'Edit',
      command: () =>
        (window.location.href = `/dealerships/${dealershipSlug}/feeds/${rowData.id}/edit`),
    },
    {
      label: 'Destroy',
      command: () => {
        fetch(`/dealerships/${dealershipSlug}/feeds/${rowData.id}`, {
          method: 'DELETE',
          headers: standardHeaders,
        })
          .then((response) => {
            if (response.ok) {
              showToast(notification, 'success', 'Feed scheduled for deletion')
            } else {
              showToast(notification, 'error', 'An error has occured')
            }
          })
          .catch((error) => {
            console.error('Error:', error)
          })
      },
    },
  ]

  if (['export', 'url-only', 'webhook'].includes(rowData.feed_type)) {
    items.push({
      label: 'Download CSV Sample',
      command: () =>
        (window.location.href = `/dealerships/${dealershipSlug}/feeds/${rowData.id}.csv`),
    })
  }

  if (['email', 'import', 'titan_api', 'export', 'webhook'].includes(rowData.feed_type)) {
    items.push({
      label: 'Stock Filters',
      command: () =>
        (window.location.href = `/dealerships/${dealershipSlug}/feeds/${rowData.id}/stock_filters`),
    })
  }

  if (rowData.feed_type === 'export') {
    items.push({
      label: 'Export',
      command: () => (window.location.href = `/dealerships/${dealershipSlug}/feeds/${rowData.id}`),
    })
  }

  return (
    <SplitButton
      label="View"
      onClick={() => navigate(Routes.dealership_feed_path(dealershipSlug, rowData.id))}
      model={items}
      className="p-button-secondary"
      size="small"
      outlined
    />
  )
}

const ActiveToggle = (rowData) => {
  let { dealershipSlug } = useParams()

  return (
    <Toggle
      rowData={rowData}
      url={`/dealerships/${dealershipSlug}/feeds/${rowData.id}`}
      attributeName="active"
      objectType="feed"
    />
  )
}

const FeedType = ({ feedType, feeds, notification }) => {
  let feedsOfType = feeds.filter((feed) => feed.feed_type === feedType)

  if (feedsOfType.length === 0) {
    return null
  }

  return (
    <>
      <h5 className="text-secondary">{humanize(feedType)}</h5>
      <div key={feedType} className="box mb-3">
        <DataTable value={feedsOfType}>
          <Column field="name" header="Name" body={Name} sortable />
          {feedType === 'email' && <Column field="email" header="Email" body={Email} />}
          <Column field="schema" header="Provider" sortable />
          <Column field="stock_type" header="Stock Type(s)" body={StockType} />
          <Column field="active" header="Active" body={ActiveToggle} sortable />
          <Column
            header="Actions"
            body={(rowData) => <ActionsDropdown rowData={rowData} notification={notification} />}
          />
        </DataTable>
      </div>
    </>
  )
}

const Heading = () => {
  let { dealershipSlug } = useParams()

  return (
    <>
      <div className="clearfix">
        <div className="float-right">
          <div className="dropdown">
            <button
              className="btn btn-success dropdown-toggle"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa fa-plus mr-1"></i> Feed
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <div className="dropdown-header">Import</div>
              <a
                href={`/dealerships/${dealershipSlug}/feeds/select_ftp_account?feed_type=import`}
                className="dropdown-item"
                data-remote="true"
              >
                FTP Import
              </a>
              <a
                href={`/dealerships/${dealershipSlug}/feeds/new?feed_type=email`}
                className="dropdown-item"
                data-remote="true"
              >
                Email Import
              </a>
              <a
                href={`/dealerships/${dealershipSlug}/feeds/new?feed_type=dealer_solutions_api`}
                className="dropdown-item"
                data-remote="true"
              >
                Cox API
              </a>
              <a
                href={`/dealerships/${dealershipSlug}/feeds/new?feed_type=titan_api`}
                className="dropdown-item"
                data-remote="true"
              >
                Titan API
              </a>
              <a
                href={`/dealerships/${dealershipSlug}/feeds/new?feed_type=database_feed`}
                className="dropdown-item"
                data-remote="true"
              >
                Database Feed
              </a>
              <div className="dropdown-divider"></div>
              <div className="dropdown-header">Export</div>
              <a
                href={`/dealerships/${dealershipSlug}/feeds/select_ftp_account?feed_type=export`}
                className="dropdown-item"
                data-remote="true"
              >
                FTP Export
              </a>
              <a
                href={`/dealerships/${dealershipSlug}/feeds/select_webhook_recipient?feed_type=webhook`}
                className="dropdown-item"
                data-remote="true"
              >
                Webhook/API export
              </a>
              <a
                href={`/dealerships/${dealershipSlug}/feeds/new?feed_type=url-only`}
                className="dropdown-item"
                data-remote="true"
              >
                URL Only Feeds
              </a>
              <a
                href={`/dealerships/${dealershipSlug}/feeds/new?feed_type=loopit`}
                className="dropdown-item"
                data-remote="true"
              >
                Loopit
              </a>
              <a
                href={`/dealerships/${dealershipSlug}/feeds/new?feed_type=orbee`}
                className="dropdown-item"
                data-remote="true"
              >
                Orbee
              </a>
              <a
                href={`/dealerships/${dealershipSlug}/feeds/new?feed_type=autotrader`}
                className="dropdown-item"
                data-remote="true"
              >
                Autotrader
              </a>
            </div>
          </div>
        </div>
        <h1 className="h2 mb-3">Inventory Feeds</h1>
      </div>
    </>
  )
}

const fetchFeeds = async (dealershipSlug) => {
  const response = await fetch(Routes.dealership_feeds_path(dealershipSlug), {
    headers: standardHeaders,
  })
  if (!response.ok) {
    throw new Error('Failed to fetch feeds')
  }
  return response.json()
}

const FeedsIndex = () => {
  const { dealershipSlug } = useParams()
  const notification = useRef(null)
  let [page, setPage] = useState(1)
  const rows = 25

  const { data, isLoading, error } = useQuery({
    queryKey: ['feeds', dealershipSlug],
    queryFn: () => fetchFeeds(dealershipSlug),
    staleTime: 1000 * 60 * 5, // Cache data for 5 minutes
  })

  if (error) {
    return <div className="text-danger">Error loading feeds: {error.message}</div>
  }

  return (
    <div className="w-100">
      <Toast ref={notification} />
      <Heading />
      {isLoading && <Loading />}

      {feedTypes.map((feedType) => (
        <FeedType
          key={feedType}
          feedType={feedType}
          feeds={data?.feeds || []}
          notification={notification}
        />
      ))}

      {data?.carsales_accounts?.length > 0 && (
        <>
          <h5 className="text-secondary mb-0">Carsales Accounts</h5>
          <small className="text-secondary">
            Used to import leads/cars from Carsales into Dealer Studio
          </small>
          <CarsalesAccountsTable
            carsalesAccounts={data.carsales_accounts}
            totalCount={data.carsales_accounts.length}
            loading={isLoading}
            page={page}
            setPage={setPage}
            rows={rows}
          />
        </>
      )}
    </div>
  )
}

export default FeedsIndex
