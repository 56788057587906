import { useRef } from 'react'

import { Toast } from 'primereact/toast'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { useFetchDealership, useFetchWebsite } from '../dataHooks'
import { standardHeaders } from '../entries/utils'
import showToast from '../shared/ShowToast'

const Breadcrumb = () => {
  let { websiteSlug } = useParams()
  let navigate = useNavigate()
  const notification = useRef(null)
  const { pathname } = useLocation()

  let { dealership } = useFetchDealership()
  let { website } = useFetchWebsite(websiteSlug)

  if (!websiteSlug) {
    return null
  }

  const handleWebsiteChange = (e, website) => {
    e.preventDefault()
    const currentPath = pathname.replace(websiteSlug, website.slug)
    navigate(`${currentPath}`)
  }

  const rebuildWebsite = (e) => {
    e.preventDefault()
    fetch(Routes.rebuild_dealership_website_path(dealership.slug, website.id), {
      method: 'POST',
      headers: standardHeaders,
    })
      .then((response) => response.json())
      .then((res) => {
        showToast(notification, 'success', res.message)
        navigate(Routes.deployments_website_path(website.slug))
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  return (
    <div className="d-flex align-items-center" style={{ background: '#e9ecef' }}>
      <Toast ref={notification} />
      <nav className="w-100">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item active d-flex align-items-center">
            Dealership:
            {dealership && (
              <Link to={Routes.dealership_path(dealership.slug)} className="ml-1">
                {dealership.name}
              </Link>
            )}
          </li>
          <li className="breadcrumb-item active d-flex align-items-center">
            <button
              className="btn btn-outline-secondary btn-sm dropdown-toggle"
              type="button"
              data-toggle="dropdown"
            >
              {website?.name}
            </button>
            <div className="dropdown-menu">
              {dealership &&
                dealership.websites
                  .sort((a, b) => a.name?.toLowerCase().localeCompare(b.name?.toLowerCase() ?? ''))
                  .map((site) => {
                    return (
                      <a
                        key={site.id}
                        href="#"
                        onClick={(e) => handleWebsiteChange(e, site)}
                        className="dropdown-item"
                      >
                        {site.name}
                      </a>
                    )
                  })}
            </div>
          </li>
        </ol>
      </nav>
      <div className="ml-auto pr-2 align-items-center d-md-flex d-none">
        <div className="dropdown">
          <button
            className="btn btn-outline-primary dropdown-toggle btn-sm"
            type="button"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            Action
          </button>
          {website && (
            <ul className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
              <li>
                <a className="dropdown-item" href={website.url} target="_blank">
                  <i className="fa fa-link mr-2" />
                  Open Website
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href={`https://vercel.com/totaldealer/${website.vercel_repo_name}`}
                  target="_blank"
                >
                  <i className="fa fa-external-link mr-2" />
                  Open in Vercel
                </a>
              </li>
              {website.dealerstudio && website.vercel_project_id && (
                <li>
                  <a className="dropdown-item" href="#" onClick={rebuildWebsite}>
                    <i className="fa fa-recycle mr-2" />
                    Rebuild
                  </a>
                </li>
              )}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

export default Breadcrumb
