import { useRef } from 'react'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { TabPanel, TabView } from 'primereact/tabview'
import { Toast } from 'primereact/toast'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import * as Routes from '../../../routes'
import { cloudindaryImages } from '../../constants'
import { standardHeaders } from '../../entries/utils'
import { formInputFromTabs, formInputsFromAllTabs } from '../../formTabs'
import { Form, FormInput, FormSwitch } from '../../formUtils'
import {
  type GarageSettings,
  type GarageSettingsData,
  garageSettingsFormTabs as formTabs,
} from './garageSettingsFormInputs'

const GarageSettingsFields = () => {
  const { watch } = useFormContext()

  const [garageEnabled, invertGarageLogo] = watch(['garageEnabled', 'invertGarageLogo'])
  const tabs = Object.keys(formTabs)

  return (
    <>
      {garageEnabled ? (
        <TabView>
          {tabs.map((tab) => (
            <TabPanel key={tab} header={formTabs[tab].label}>
              <div className="row">
                {formInputFromTabs(tab, formTabs).map((input) => (
                  <div
                    key={input.label}
                    className={`col-12 mb-2 ${tab !== 'featuresEnabled' ? 'col-lg-6' : ''}`}
                  >
                    {input.type === 'boolean' ? (
                      <FormSwitch name={input.key} label={input.label} />
                    ) : (
                      <FormInput name={input.key} label={input.label} />
                    )}
                  </div>
                ))}
              </div>
              {tab === 'featuresEnabled' && (
                <div
                  id="mygarage-logo-container"
                  className={`mt-1 p-2 ${invertGarageLogo ? 'bg-light' : 'bg-dark'}`}
                  style={{ width: '110px', borderRadius: '2px' }}
                >
                  <img
                    src={cloudindaryImages.garageLogo}
                    alt="Garage"
                    width={105}
                    height={20}
                    className=""
                    style={{
                      width: 'auto',
                      height: '18px',
                      filter: invertGarageLogo ? 'invert(1)' : 'none',
                    }}
                  />
                </div>
              )}
            </TabPanel>
          ))}
        </TabView>
      ) : (
        <FormSwitch
          name="garageEnabled"
          label={
            formTabs?.featuresEnabled?.formInputs?.garageEnabled?.label ?? 'Garage/Portal Enabled'
          }
        />
      )}
    </>
  )
}

export const GarageSettingsForm = ({ data }: { data: GarageSettingsData }) => {
  const { websiteSlug } = useParams()
  const queryClient = useQueryClient()
  const notification = useRef<Toast>(null)

  const { mutate, isPending } = useMutation({
    mutationFn: async (formData: GarageSettings) => {
      const req = await fetch(Routes.website_path(websiteSlug), {
        method: 'PUT',
        headers: standardHeaders,
        body: JSON.stringify({
          website: {
            garage_enabled: formData.garageEnabled,
            garage_settings: formData,
          },
        }),
      })
      if (!req.ok) {
        throw new Error('Failed to update garage settings')
      }
      return req.json()
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['garageSettings', websiteSlug] })
      notification.current?.show({
        severity: 'success',
        summary: 'Garage Settings Saved',
        detail: 'Please note the website may require a rebuild for these settings to be live',
        life: 5000,
      })
    },
    onError: (error) => {
      notification.current?.show({
        severity: 'error',
        summary: 'Error Saving Settings',
        detail: error instanceof Error ? error.message : 'An unexpected error occurred',
        life: 5000,
      })
    },
  })

  const defaultSettings = Object.fromEntries(
    formInputsFromAllTabs(formTabs).map((input) => [input.key, input.defaultValue])
  )

  const defaultValues = {
    ...defaultSettings,
    ...data?.garage_settings,
    garageEnabled: data?.garage_enabled || data?.garage_settings?.garageEnabled || false,
  }

  return (
    <>
      <Toast ref={notification} />
      <Form onSubmit={async (data) => mutate(data)} defaultValues={defaultValues}>
        <GarageSettingsFields />

        <button
          type="submit"
          className={'ml-3 mt-3 btn btn-outline-primary' + (isPending ? ' disabled' : '')}
        >
          {isPending ? 'Saving...' : 'Save Settings'}
        </button>
      </Form>
    </>
  )
}
