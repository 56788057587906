import { CSSProperties } from 'react'

import { useConfig } from '../../../contexts/hooks'
import { useWindowWidth } from '../../../hooks'
import { ModuleItem } from '../../../types'
import ModulePicker from '../../EditMode/components/ModulePicker'

const BlankModule: React.FC<{ editModeEnabled: boolean; items: ModuleItem[] }> = ({
  editModeEnabled,
  items,
}) => {
  const { config } = useConfig()
  const windowWidth = useWindowWidth()

  const styles: CSSProperties = {
    display: editModeEnabled && windowWidth >= 1200 ? 'flex' : 'none',
    width: '100%',
    borderRadius: '10px',
    backgroundColor: '#fff',
    minHeight:
      config?.layoutSize === 'small' ? '13vw' : config?.layoutSize === 'medium' ? '17vw' : '20vw',
    height: editModeEnabled
      ? config?.layoutSize === 'small'
        ? '17vw'
        : config?.layoutSize === 'medium'
          ? '20vw'
          : '25vw'
      : 'auto',
    border: '3px solid #cdd4da',
    borderStyle: 'dashed',
    justifyContent: 'center',
    alignItems: 'center',
  }
  return (
    <div style={styles}>
      <ModulePicker items={items} hideSelect isBlankModule />
    </div>
  )
}

export default BlankModule
