import { useContext, useState } from 'react'

import { instantMeiliSearch } from '@meilisearch/instant-meilisearch'
import moment from 'moment'
import { Dialog } from 'primereact/dialog'
import { Panel } from 'primereact/panel'
import { Configure, InstantSearch, SearchBox, useHits } from 'react-instantsearch'
import { Link, useParams } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { Tooltip } from 'react-tooltip'

import * as Routes from '../../routes'
import { CarStatus } from '../cars/CarSearchItem'
import { indexName } from '../cars/config'
import { LeadClusterContext } from '../contexts'
import MenuSelect from '../entries/MenuSelect'
import { humanize } from '../entries/utils'
import { getMeilisearchConfig } from '../entries/utils'
import { canManageLeads, updateLeadClusterAPI } from '../leads/utils'
import AddCarForm from './AddCarForm'

const Hit = ({ hit }) => {
  const { leadCluster, setLeadCluster } = useContext(LeadClusterContext)
  const [loading, setLoading] = useState(false)
  let currentIds = leadCluster.cars.filter((clc) => clc.car).map((c) => c.car.id)
  let disabled = loading || currentIds.map((h) => h.toString()).includes(hit.id)
  let { dealershipSlug } = useParams()

  const addCar = ({ id }) => {
    if (disabled) {
      return
    }

    let params = {
      lead_cluster: {
        car_ids: [...currentIds, id],
      },
    }
    setLoading(true)
    updateLeadClusterAPI(leadCluster.id, params, dealershipSlug).then((data) => {
      setLoading(false)
      setLeadCluster(data)
    })
  }

  return (
    <div className="d-flex py-3 border-bottom">
      <div>
        <img
          src={hit.primary_image_url}
          height="50"
          className="mr-3 img-fluid"
          alt={hit.name}
          style={{ maxWidth: 75 }}
        />
      </div>
      <div className="mr-auto">
        {hit.name}
        <div className="small text-secondary">
          <span className="mr-1">{hit.stocknum}</span>
          <CarStatus car={hit} />
        </div>
      </div>
      <div>
        <div
          className={
            'btn btn-sm' + (disabled ? ' disabled btn-outline-success' : ' btn-outline-primary')
          }
          onClick={() => addCar({ id: hit.id })}
          disabled={disabled}
        >
          {loading ? (
            <i className="fa fa-spinner fa-spin"></i>
          ) : (
            <>
              <i className="fa fa-plus mr-2"></i>
              Car
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const CustomHits = () => {
  let { hits } = useHits()

  return <>{hits && hits.map((hit) => <Hit hit={hit} key={hit.id} />)}</>
}

const Car = ({ car_lead_cluster }) => {
  let { car } = car_lead_cluster
  let { dealershipSlug } = useParams()
  const carStatusClass = car.status === 'sold' ? 'text-danger' : 'text-success'
  const carKm = car.km ? `${Number(car.km).toLocaleString()} kms` : '0 kms'
  const carPrice = car.price
    ? car.price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    : '$0.00'

  return (
    <div className="d-flex align-items-center lead-car-container">
      {car.primary_image_url && (
        <img
          src={car.primary_image_url}
          height="50"
          className="mr-3 img-fluid rounded border"
          alt={car.name}
          style={{ maxWidth: 100, minWidth: 100 }}
        />
      )}
      <div className="mr-auto">
        <Link to={Routes.dealership_car_path(dealershipSlug, car.slug)}>
          <h6 className="mb-0">{car.name}</h6>
        </Link>
        <div>
          <span className={carStatusClass}>{car.status ? humanize(car.status) : ''}</span>
          {car.reserved && (
            <span className="text-danger">
              {' '}
              - <i className="fa fa-lock mr-1" />
              Reserved
            </span>
          )}
        </div>
        <div className="text-secondary small">
          Stock number: {car.stocknum}
          {car.regplate && (
            <>
              <span> - </span>
              Rego: {car.regplate}
            </>
          )}
          <span> - </span>
          {carKm}
          <span> - </span>
          {carPrice}
        </div>
        <div className="text-secondary small">
          Added {moment(car_lead_cluster.created_at).fromNow()}
        </div>
      </div>
      {canManageLeads() && <RemoveCarLeadCluster carLeadClusterId={car_lead_cluster.id} />}
    </div>
  )
}

const RemoveCarLeadCluster = ({ carLeadClusterId }) => {
  const { leadCluster, setLeadCluster } = useContext(LeadClusterContext) || {}
  let [loading, setLoading] = useState(false)
  let { dealershipSlug } = useParams()

  const removeCarFromCluster = () => {
    let params = {
      lead_cluster: {
        car_lead_clusters_attributes: [
          {
            id: carLeadClusterId,
            _destroy: true,
          },
        ],
      },
    }
    setLoading(true)
    updateLeadClusterAPI(leadCluster.id, params, dealershipSlug).then((data) => {
      setLoading(false)
      setLeadCluster(data)
    })
  }

  return (
    <div>
      <div
        onClick={removeCarFromCluster}
        className={'btn btn-outline-danger btn-sm' + (loading ? ' disabled' : '')}
        id={`car-remove-${carLeadClusterId}`}
        disabled={loading}
      >
        {loading ? <i className="fa fa-spinner fa-spin"></i> : <i className="fa fa-times"></i>}
      </div>
      <Tooltip
        anchorSelect={`#car-remove-${carLeadClusterId}`}
        place="bottom"
        style={{ zIndex: 10000 }}
      >
        Remove car from lead cluster
      </Tooltip>
    </div>
  )
}

const GenericCar = ({ car_lead_cluster }) => {
  return (
    <div className="d-flex align-items-center lead-car-container">
      <div className="mr-auto">
        <div>
          {car_lead_cluster.year} {car_lead_cluster.manufacturer?.name}{' '}
          {car_lead_cluster.family?.name} {car_lead_cluster.badge}
        </div>
        {car_lead_cluster.colour && (
          <div className="text-secondary small">Colour: {car_lead_cluster.colour}</div>
        )}
        <div className="text-secondary small">Generic Car</div>
        <div className="text-secondary small">
          Added {moment(car_lead_cluster.created_at).fromNow()}
        </div>
      </div>
      <RemoveCarLeadCluster carLeadClusterId={car_lead_cluster.id} />
    </div>
  )
}

const Cars = () => {
  const { leadCluster } = useContext(LeadClusterContext) || {}
  const { cars } = leadCluster
  let [visible, setVisible] = useState(false)
  const { meilisearchHost, meilisearchApiKey } = getMeilisearchConfig()
  const { searchClient } = instantMeiliSearch(meilisearchHost, meilisearchApiKey)
  const index = `${indexName}:custom_rank:desc`
  const filters = leadCluster.car_search_filters

  return (
    <div className="py-2">
      <Dialog
        header={`Add car of interest to lead cluster`}
        visible={visible}
        style={{ width: '50vw' }}
        breakpoints={{ '960px': '75vw', '641px': '100vw' }}
        onHide={() => setVisible(false)}
        dismissableMask={true}
      >
        <Tabs>
          <div className="mb-2">
            <div className="rounded border overflow-hidden lead-tabs">
              <TabList>
                <div className="d-flex">
                  <Tab>Inventory</Tab>
                  <Tab>
                    <div id="add-car-tab">Add Car</div>
                  </Tab>
                </div>
              </TabList>
            </div>
          </div>

          <TabPanel>
            <InstantSearch indexName={index} searchClient={searchClient}>
              <Configure filters={filters} />
              <div className="row no-gutters">
                <div className="col-6">
                  <SearchBox placeholder="Search by stocknum, make, model etc" className="mr-2" />
                </div>
                <div className="col-6">
                  <MenuSelect attribute="status" />
                </div>
              </div>
              <CustomHits />
            </InstantSearch>
          </TabPanel>
          <TabPanel>
            <AddCarForm setVisible={setVisible} />
          </TabPanel>
        </Tabs>
      </Dialog>
      <Panel header={`Cars of interest (${cars.length})`} toggleable>
        {cars && cars.length > 0 && (
          <div className="border-bottom pb-3">
            {cars
              .filter((clc) => clc.car)
              .map((car_lead_cluster) => (
                <Car car_lead_cluster={car_lead_cluster} key={car_lead_cluster.id} />
              ))}
            {cars
              .filter((clc) => !clc.car)
              .map((car_lead_cluster) => (
                <GenericCar car_lead_cluster={car_lead_cluster} key={car_lead_cluster.id} />
              ))}
          </div>
        )}
        {cars.length === 0 && (
          <div className="text-secondary small">No cars of interest have been added</div>
        )}
        {canManageLeads() && (
          <div className="d-flex mt-3">
            <div
              className="btn btn-outline-primary ml-auto btn-sm"
              id="add-car-btn"
              onClick={() => setVisible(true)}
            >
              <i className="fa fa-plus mr-2"></i>
              Car
            </div>
          </div>
        )}
      </Panel>
    </div>
  )
}

export default Cars
