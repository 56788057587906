import { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import Loading from '../Loading'
import { useFetchWebsite } from '../dataHooks'
import { Select } from '../entries/FormElements'
import { standardHeaders } from '../entries/utils'

const WebsiteLocationsForm = ({ website, locations }) => {
  let { dealershipSlug, websiteSlug } = useParams()
  let [loading, setLoading] = useState(false)

  let [locationIds, setLocationIds] = useState(website?.locations.map((l) => l.id))

  let currentSelection = website.locations.map((l) => ({ label: l.name, value: l.id }))

  let locationOptions = locations.map((l) => ({ label: l.name, value: l.id }))

  const updateLocations = () => {
    setLoading(true)
    fetch(Routes.dealership_website_path(dealershipSlug, websiteSlug), {
      headers: standardHeaders,
      method: 'PUT',
      body: JSON.stringify({
        website: {
          location_ids: locationIds,
        },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        // i was not able to get tanstack refetch to work for some reason
        window.location.reload()
        setLoading(false)
      })
  }

  return (
    <div className="box mt-3 p-3">
      <Select
        label="Locations"
        options={locationOptions}
        defaultValue={currentSelection}
        isMulti
        onChange={(options) => setLocationIds(options.map((o) => o.value))}
      />
      <button
        className={'btn btn-secondary' + (loading ? ' disabled' : '')}
        onClick={updateLocations}
      >
        {loading ? 'Loading...' : 'Update Locations'}
      </button>
    </div>
  )
}

const WebsiteLocationsFormWrapper = () => {
  let { websiteSlug } = useParams()
  let { website } = useFetchWebsite()
  let [dealershipWebsiteLocations, setDealershipWebsiteLocations] = useState([])

  useEffect(() => {
    let url = Routes.location_options_website_path(websiteSlug)
    fetch(url, { headers: standardHeaders })
      .then((res) => res.json())
      .then((res) => {
        setDealershipWebsiteLocations(res)
      })
  }, [])

  if (!website) return <Loading />

  return <WebsiteLocationsForm website={website} locations={dealershipWebsiteLocations} />
}

export default WebsiteLocationsFormWrapper
