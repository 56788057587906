import { Tooltip } from 'primereact/tooltip'

const CarDestinations = ({ car }) => {
  let destinationsWithPricing = car.export_destinations?.filter(
    (destination) => destination?.config?.dapprice || destination?.config?.egcprice
  )

  if (!destinationsWithPricing || destinationsWithPricing?.length < 1) {
    return
  }

  return (
    <>
      <i
        className="fa fa-flag text-danger"
        aria-hidden="true"
        id={`third-party-pricing-${car.id}`}
      ></i>
      <Tooltip target={`#third-party-pricing-${car.id}`} position="bottom">
        <div>
          <small className="mb-4">Special third party pricing is in place:</small>
          {destinationsWithPricing.map((destination) => (
            <div key={destination.id} className="small mb-2">
              <strong>{destination.destination_name}</strong>
              {destination.config?.dapprice && <div>DAP Price: {destination.config.dapprice}</div>}
              {destination.config?.egcprice && <div>EGC Price: {destination.config.egcprice}</div>}
            </div>
          ))}
        </div>
      </Tooltip>
    </>
  )
}

export default CarDestinations
