import { Dispatch, RefObject, SetStateAction } from 'react'

import _ from 'lodash'
import { SelectButton } from 'primereact/selectbutton'
import { Toast } from 'primereact/toast'
import { Tooltip } from 'primereact/tooltip'

import { copyToClipboard, togglePageVisibility, undoDraftChanges } from './ToolbarUtils'

export const PageUrlSection = ({ pageUrl }: { pageUrl: string }) => (
  <div className="d-flex align-items-center">
    <span className="font-weight-bold">URL:</span>
    <a href={pageUrl} target="_blank" className="editorjs-url text-primary ml-1">
      {pageUrl}
    </a>
    <Tooltip target="#copy-url-button" content="Copy the page url" position="top" />
    <button
      id="copy-url-button"
      className="btn btn-sm p-0 ml-1"
      onClick={() => copyToClipboard(pageUrl)}
    >
      <i className="fa-solid fa-clone" />
    </button>
  </div>
)

export const UndoButton = ({
  blocksJson,
  setDraftBlocksJson,
  setShouldSaveDraft,
}: {
  blocksJson: string
  setDraftBlocksJson: Dispatch<SetStateAction<string>>
  setShouldSaveDraft: Dispatch<SetStateAction<boolean>>
}) => (
  <>
    <Tooltip target=".undo-button" content="Undo your saved draft changes" position="top" />
    <button
      id="undo-changes-button"
      className="btn btn-outline-secondary mt-2 mt-lg-0 mr-2 undo-button"
      onClick={() => undoDraftChanges(blocksJson, setDraftBlocksJson, setShouldSaveDraft)}
    >
      <i className="fa fa-undo" />
    </button>
  </>
)

export const PreviewButton = ({ previewUrl }: { previewUrl: string }) => (
  <>
    <Tooltip
      target="#preview-draft-button"
      content="Preview your draft changes in a new tab"
      position="top"
    />
    <a
      href={previewUrl}
      target="_blank"
      id="preview-draft-button"
      className="btn btn-info mt-2 mt-lg-0 mr-2"
    >
      <i className="fa-solid fa-print-magnifying-glass"></i>
    </a>
  </>
)

export const SaveDraftButton = ({
  loading,
  submitForm,
}: {
  loading: boolean
  submitForm: (param: string) => void
}) => (
  <>
    <Tooltip
      target="#header-save-draft-button"
      content="Save your changes as a draft"
      position="top"
    />
    <button
      id="header-save-draft-button"
      disabled={loading}
      className="btn btn-primary mt-2 mt-lg-0 mr-2"
      onClick={() => submitForm('save_draft')}
    >
      {loading && <span>Loading...</span>}
      {!loading && <i className="fa-solid fa-floppy-disk"></i>}
    </button>
  </>
)

export const PageVisibilityButton = ({
  type = 'page',
  togglePageVisibilityUrl,
  csrf,
  visible,
  setVisible,
  notification,
}: {
  type?: 'page' | 'blog'
  togglePageVisibilityUrl: string
  csrf: string
  visible: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
  notification: RefObject<Toast>
}) => (
  <SelectButton
    id={`${type}_visibility_select_button`}
    className="d-flex mt-2 mt-lg-0 mr-2"
    value={visible}
    onChange={(e) =>
      togglePageVisibility(
        type,
        e,
        togglePageVisibilityUrl,
        csrf,
        visible,
        setVisible,
        notification
      )
    }
    options={[
      { label: <i className="fa fa-eye-slash" />, value: false },
      { label: <i className="fa fa-eye" />, value: true },
    ]}
    pt={{
      button: ({ context }) => ({
        className: `${context.selected ? 'bg-success' : undefined}`,
        style: {
          height: 38,
          border: 'none',
          boxShadow: 'none',
        },
      }),
    }}
    tooltip={`Change ${type} visibility`}
    tooltipOptions={{ position: 'top' }}
  />
)

export const SettingsButton = ({ setShow }: { setShow: Dispatch<SetStateAction<boolean>> }) => (
  <button
    id="settings-button"
    className="btn btn-outline-dark mt-2 mt-lg-0"
    onClick={() => {
      setShow(true)
    }}
  >
    <i className="fa-solid fa-gear" />
  </button>
)
