/** The full list of available shortcodes that can be used in templates */
export const searchPageTemplateShortcodes = [
  '[count]',
  '[website_name]',
  '[car_type]',
  '[car_types]',
  '[body]',
  '[bodies]',
  '[drive]',
  '[fuel]',
  '[category]',
  '[make]',
  '[makes]',
  '[make_model]',
  '[default_city]',
  '[default_state]',
  '[default_vehicle]',
  '[state]',
  '[city]',
  '[dealership_name]',
  '[location_name]',
] as const

export type SearchPageTemplateShortcode = (typeof searchPageTemplateShortcodes)[number]

export const shortcodeDescriptions: Record<SearchPageTemplateShortcode, string> = {
  '[website_name]': 'Website name',
  '[default_city]': 'Default city of the website',
  '[default_state]': 'Default state of the website',
  '[default_vehicle]': 'Default vehicle sold on the website (Car, Motorcycle, etc.)',
  '[count]': 'Total number of vehicles on the current search page',
  '[car_type]': 'Vehicle type of the current page (new, used, or demo)',
  '[make]': 'Vehicle make of the current page',
  '[make_model]': 'Make and model of the current page',
  '[body]': 'Body type of the current page',
  '[drive]': 'Drive type of the current page',
  '[fuel]': 'Fuel type of the current page',
  '[category]': 'Category of the current page (Prestige and Luxury, Sports Cars, etc.)',
  '[state]': 'State of the current page',
  '[city]': 'City of the current page',
  '[dealership_name]': 'Dealership name of the current page',
  '[location_name]': 'Location name of the current page',
  '[car_types]': 'All available car types on the current page (New, Used, and Demo)',
  '[bodies]': 'All available body types on the current page (Sedan, SUV, Truck, etc.)',
  '[makes]': 'All available vehicle makes on the current page (Toyota, Honda, Mazda, etc.)',
}
