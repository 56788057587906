import { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useHits, useInstantSearch } from 'react-instantsearch'
import { useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import CarSearchItem from './CarSearchItem'

const NoCarsFound = () => {
  const { uiState } = useInstantSearch()
  const query = uiState[Object.keys(uiState)[0]]?.query ?? ''
  let [cars, setCars] = useState([])
  let [loading, setLoading] = useState(false)
  let { dealershipSlug } = useParams()

  const search = () => {
    setLoading(true)
    fetch(Routes.archived_cars_dealership_cars_path(dealershipSlug, { query: query }))
      .then((res) => res.json())
      .then((data) => {
        setCars(data)
        setLoading(false)
      })
  }

  if (!query) {
    return null
  }

  return (
    <div className="box p-3 my-3">
      <div>No cars found for {query}.</div>
      <div className="mb-2 small text-secondary">
        Please note we only keep cars in our search system for 1 month (to keep our system fast).
        However, you can still searched for the remaining cars below:
      </div>
      <div
        className={'btn btn-outline-secondary btn-sm' + (loading ? ' disabled' : '')}
        onClick={search}
      >
        {loading ? 'Searching...' : 'Search for archived cars'}
      </div>
      {cars && cars.length > 0 && (
        <div className="mt-2">
          <div className="small text-secondary">Archived cars</div>
          {cars.map((car) => (
            <CarSearchItem hit={car} key={car.id} />
          ))}
        </div>
      )}
    </div>
  )
}

const Hits = ({
  setAutograb,
  hasAutograb,
  beta,
  recentlyUpdatedCars = [],
  addRecentlyUpdatedCar,
  ...props
}) => {
  let [carIds, setCarIds] = useState([])
  let [views, setViews] = useState([])

  let { hits } = useHits()

  const { status } = useInstantSearch()

  useEffect(() => {
    if (status === 'idle') {
      setCarIds(hits.map((hit) => hit.id))
    }
  }, [status])

  const { data } = useQuery({
    queryKey: ['carViews', carIds],
    queryFn: async () => {
      if (carIds.length === 0) return []
      const response = await fetch(Routes.api_v1_cars_path({ ids: carIds.join(',') }))
      return response.json()
    },
    enabled: carIds.length > 0,
    staleTime: 10 * 60 * 1000, // Cache data for 10 minutes
    cacheTime: 10 * 60 * 1000, // Keep cache for 10 minutes
  })

  useEffect(() => {
    if (data) {
      setViews(data)
    }
  }, [data])

  if (hits.length === 0) {
    return <NoCarsFound />
  }

  return (
    <div className="my-2">
      {hits.map((hit) => {
        let car = hit
        let recentlyUpdatedData = recentlyUpdatedCars.filter((c) => c.id == hit.id)[0]
        if (recentlyUpdatedData) {
          car = {
            ...hit,
            ...recentlyUpdatedData,
          }
        }
        if (views.length > 0) {
          // the views data in meilisearch is inaccurate as this is not updated regularly
          // therefore we need to fetch the views data from the backend

          let postgresData = views.filter((v) => v.id == hit.id)[0]

          car = {
            ...car,
            views: postgresData?.views,
            lead_clusters_count: postgresData?.lead_clusters_count,
          }
        }
        return (
          <CarSearchItem
            hit={car}
            key={hit.id}
            setAutograb={setAutograb}
            beta={beta}
            hasAutograb={hasAutograb}
            addRecentlyUpdatedCar={addRecentlyUpdatedCar}
            {...props}
          />
        )
      })}
    </div>
  )
}

export default Hits
