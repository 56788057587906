import { useState } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { Input } from '../entries/FormElements'
import { standardHeaders } from '../entries/utils'

const Form = ({ feed, refetch }) => {
  let [loading, setLoading] = useState(false)
  let { dealershipSlug } = useParams()

  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: feed?.name || '', // Default value for the 'name' field
    },
  })

  const onSubmit = (data) => {
    setLoading(true)

    fetch(`/dealerships/${dealershipSlug}/feeds/${feed.id}`, {
      method: 'PATCH',
      headers: standardHeaders,
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(() => {
        refetch()
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error:', error)
        setLoading(false)
      })
  }

  return (
    <div className="box p-3 mt-2">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => <Input {...field} label="Name" required={true} />}
        />

        <button
          type="submit"
          className={'btn btn-outline-primary' + (loading ? ' disabled' : '')}
          disabled={loading}
        >
          {loading ? 'Saving...' : 'Save'}
        </button>
      </form>
    </div>
  )
}

export default Form
