import { Bin } from '../../../components/Icons'
import { useItems } from '../../../contexts/hooks'
import type { ModuleItem } from '../../../types'

const ModuleCloser: React.FC<{ item: ModuleItem }> = ({ item }) => {
  const { handleRemoveItem } = useItems()
  const handleCloseModule = () => {
    if (item) {
      handleRemoveItem(item)
    }
  }
  return (
    <div
      className="DeleteModuleButton"
      onClick={handleCloseModule}
      style={{ cursor: 'pointer', display: 'flex', userSelect: 'none' }}
    >
      <Bin />
      <span className="ml-2" style={{ color: '#5f6269' }}>
        Delete
      </span>
    </div>
  )
}

export default ModuleCloser
