import { useQuery } from '@tanstack/react-query'
import { Link, useLocation, useParams } from 'react-router-dom'

import * as Routes from '../../routes'

const Anchor = ({ to, children, ...props }) => (
  <a href={to} {...props}>
    {children}
  </a>
)

const fetchSettingsLinks = async (dealershipSlug) => {
  const response = await fetch(Routes.settings_sidemenu_links_dealership_path(dealershipSlug))
  if (!response.ok) throw new Error('Failed to fetch settings menu links')
  return response.json()
}

const SettingsMenu = ({ children }) => {
  let { dealershipSlug } = useParams()
  const { pathname } = useLocation()

  // Use TanStack Query to fetch menu links
  const { data, error, isLoading } = useQuery({
    queryKey: ['settings-menu', dealershipSlug],
    queryFn: () => fetchSettingsLinks(dealershipSlug),
    staleTime: 1000 * 60 * 5, // Cache for 5 minutes
  })

  if (isLoading) return <div className="p-2">Loading menu...</div>
  if (error) return <div>Error loading menu</div>

  return (
    <div className="pr-3">
      <div className="row">
        <div className="col-md-2">
          <ul className="list-group settings-menu">
            {data.links.map((link, index) => {
              let LinkComponent = link.reactRouter ? Link : Anchor
              let active = pathname === link.path

              return (
                <LinkComponent
                  to={link.path}
                  className={'list-group-item list-group-item-action' + (active ? ' active' : '')}
                  key={index}
                >
                  <i className={`fa ${link.iconClass} mr-1`}></i>
                  {link.text}
                  {link.badge && <span className="badge badge-danger ml-2">{link.badge}</span>}
                </LinkComponent>
              )
            })}
          </ul>
        </div>
        <div className="col-md-10 py-3">{children}</div>
      </div>
    </div>
  )
}

export default SettingsMenu
