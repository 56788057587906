import { useCallback, useEffect, useRef, useState } from 'react'

import { useQuery } from '@tanstack/react-query'

import { getYearlyTimeRange } from '../entries/TimeRange'
import { fetchModuleData, saveConfig, setModuleLayoutsCall } from './api'
import { moduleComponents } from './config'
import { facebookAdsModules, ga4Modules, overrideExcludedModules } from './constants'
import { useAnalyticsDashboard, useConfig, useItems, useTabs } from './contexts/hooks'
import {
  AnalyticsBlockConfig,
  Location,
  ModuleItem,
  TabFilters,
  TimeRange,
  TimeRangeOverrideGroup,
} from './types'
import { fetchModuleLayoutByTab, getModuleComponentFromName } from './utils'

export const useViewportData = (
  module: string,
  timeRange: TimeRange,
  selectedLocation: Location,
  additionalParams?: { [key: string]: any }
): {
  data: any
  loading: boolean
  error?: Error | null
  viewportRef: React.RefObject<HTMLDivElement>
} => {
  const viewportRef = useRef<HTMLDivElement>(null)
  const isInViewport = useOnScreen(viewportRef)
  const { config }: { config: { defaultTimeRangeOverride?: string[] | TimeRangeOverrideGroup[] } } =
    useConfig()
  const {
    dashboardLevel,
    dashboardLevelLoaded,
    dealership,
    dealershipGroup,
    manufacturer,
    website,
  } = useAnalyticsDashboard()
  const { selectedTab } = useTabs()
  const { items, moduleErrors, setModuleErrors } = useItems()
  const moduleConfig = getModuleComponentFromName(module)

  function shouldOverride(group: TimeRangeOverrideGroup): boolean {
    if (overrideExcludedModules.includes(module)) return false
    return moduleConfig?.group === group && config?.defaultTimeRangeOverride?.includes(group)
  }

  let overrideToYearlyTimeRange = false
  switch (true) {
    case shouldOverride('Google Adwords Ads'):
    case shouldOverride('Google Display Ads'):
    case shouldOverride('Google VLA Ads'):
    case shouldOverride('Facebook Ads'):
    case shouldOverride('Google Ads'):
    case shouldOverride('Google Analytics'):
      overrideToYearlyTimeRange = true
      break
    default:
      break
  }

  const yearlyTimeRange: TimeRange | undefined = overrideToYearlyTimeRange
    ? getYearlyTimeRange(timeRange)
    : undefined

  const moduleItem = items?.filter((item) => item.module === module)
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [
      'moduleData',
      module,
      JSON.stringify(timeRange),
      yearlyTimeRange ? JSON.stringify(yearlyTimeRange) : null,
      selectedLocation?.name ? `location-${selectedLocation.name}` : 'location-all',
      `level-${dashboardLevel}`,
      `tab-${selectedTab}`,
      moduleItem[0]?.filters ? `moduleFilters-${moduleItem[0]?.filters}` : null,
      config?.defaultTimeRangeOverride
        ? `timeRangeOverride-${config?.defaultTimeRangeOverride}`
        : null,
      dealership?.id ? `dealership-${dealership.id}` : null,
      dealershipGroup?.id ? `dealershipGroup-${dealershipGroup.id}` : null,
      manufacturer?.id ? `manufacturer-${manufacturer.id}` : null,
      website?.id ? `website-${website.id}` : null,
      JSON.stringify(additionalParams ?? {}),
    ],
    queryFn: async () => {
      const result = await fetchModuleData({
        module,
        timeRange,
        yearlyTimeRange,
        selectedLocation,
        dashboardLevel,
        dashboardLevelLoaded,
        selectedTab,
        dealership,
        dealershipGroup,
        manufacturer,
        website,
        additionalParams,
      })
      return result
    },
    enabled: isInViewport && dashboardLevelLoaded,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    gcTime: 1000 * 60 * 60, // 1 hour
    retry: 2,
  })

  if (error && !moduleErrors[selectedTab]?.[module]) {
    setModuleErrors((prev) => ({
      ...prev,
      [selectedTab]: {
        ...(prev[selectedTab] || {}),
        [module]: refetch,
      },
    }))
  } else if (!error && moduleErrors[selectedTab]?.[module]) {
    setModuleErrors((prev) => ({
      ...prev,
      [selectedTab]: {
        ...(prev[selectedTab] || {}),
        [module]: undefined,
      },
    }))
  }

  return { data, loading: isLoading, error, viewportRef }
}

export function useOnScreen(ref: React.RefObject<HTMLDivElement>): boolean {
  const [isIntersecting, setIntersecting] = useState<boolean>(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting))

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
      observer.disconnect()
    }
  }, [ref])

  return isIntersecting
}

export const useSaveConfig: () => () => Promise<void> = () => {
  const { config } = useConfig()
  const {
    dashboardLevel,
    dashboardLevelLoaded,
    dealership,
    dealershipGroup,
    manufacturer,
    website,
    analyticsBlock,
  } = useAnalyticsDashboard()

  return async () => {
    await saveConfig(
      config,
      dashboardLevel,
      dashboardLevelLoaded,
      analyticsBlock,
      dealership,
      dealershipGroup,
      manufacturer,
      website
    )
  }
}

export const useSetModuleLayouts: () => {
  setModuleLayouts: (
    layouts?: {
      [key: number]: ModuleItem[]
    },
    previousConfig?: AnalyticsBlockConfig,
    previousTabFilters?: { [key: number]: TabFilters }
  ) => Promise<void>
  loading: boolean
} = () => {
  const { config } = useConfig()
  const {
    dashboardLevel,
    dashboardLevelLoaded,
    dealership,
    manufacturer,
    website,
    dealershipGroup,
    analyticsBlock,
  } = useAnalyticsDashboard()
  const { tabLayouts, tabTitles, tabFilters } = useTabs()
  const [loading, setLoading] = useState<boolean>(false)

  const setModuleLayouts = async (
    layouts: { [key: number]: ModuleItem[] },
    previousConfig: AnalyticsBlockConfig,
    previousTabFilters: { [key: number]: TabFilters }
  ) => {
    const layoutsToSave = layouts || tabLayouts
    const configToSave = previousConfig || config
    const tabFiltersToSave = previousTabFilters || tabFilters

    try {
      setLoading(true)
      await setModuleLayoutsCall(
        layoutsToSave,
        tabTitles,
        tabFiltersToSave,
        configToSave,
        dashboardLevel,
        dashboardLevelLoaded,
        analyticsBlock,
        dealership,
        dealershipGroup,
        manufacturer,
        website
      )
    } catch (error) {
      console.error('Error saving module layouts:', error)
    } finally {
      setLoading(false)
    }
  }

  return { setModuleLayouts, loading }
}

export const useNameBasedOnLevel = (): string | undefined => {
  const { dashboardLevel, dashboardLevelLoaded, dealership, website, dealershipGroup } =
    useAnalyticsDashboard()
  if (!dashboardLevelLoaded) {
    return undefined
  }
  if (dashboardLevel === 'Dealership') {
    return dealership?.name
  } else if (dashboardLevel === 'Website') {
    return website?.name
  } else if (dashboardLevel === 'Dealership Group') {
    return dealershipGroup?.name
  } else {
    return undefined
  }
}

export const useWindowWidth = (): number => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowWidth
}

export const useFetchModuleLayoutByTab: () => (tabIndex: number) => ModuleItem[] = () => {
  const { analyticsBlock } = useAnalyticsDashboard()

  return useCallback(
    (tabIndex: number) => {
      const layouts = fetchModuleLayoutByTab(tabIndex, analyticsBlock)
      return layouts
    },
    [analyticsBlock]
  )
}
// Creates an array of ModuleItem's, if the visibleLevels preoperty includes the dashboardLevel and the module is not excluded
export const useModuleItemsBasedOnLevel = (): ModuleItem[] => {
  const { dashboardLevel } = useAnalyticsDashboard()
  const { hasFacebookAds, hasGoogleAds, hasGA4 } = useModuleConditionals()
  const excludedGoogleKeys = ['google_analytics', 'google_reviews_count']

  // Map exclusions based on conditions
  const exclusions = {
    facebook: !hasFacebookAds ? facebookAdsModules : [],
    google: !hasGoogleAds
      ? Object.keys(moduleComponents)?.filter(
          (key) => key.includes('google') && !excludedGoogleKeys.includes(key)
        )
      : [],
    ga4: !hasGA4 ? ga4Modules : [],
  }

  // Flatten and gather excluded modules
  const excludedModules: string[] = [
    ...exclusions.facebook,
    ...exclusions.google,
    ...exclusions.ga4,
  ]

  // Return filtered modules based on visibility and exclusions
  return Object.keys(moduleComponents)
    .filter((key) => !excludedModules.includes(key))
    .filter((key) => moduleComponents[key].visibleLevels.includes(dashboardLevel))
    .map((key) => ({ module: key }))
}

export const useModuleConditionals = (): {
  hasFacebookAds: boolean
  hasGoogleAds: boolean
  hasGA4: boolean
} => {
  const { dashboardLevel, dealership, website } = useAnalyticsDashboard()

  const hasFacebookAds =
    (dashboardLevel === 'Website' && website?.facebook_ad_campaigns) ||
    (dashboardLevel === 'Dealership' && dealership?.has_facebook_ad_accounts)

  const hasGoogleAds =
    (dashboardLevel === 'Website' && website?.google_ad_campaigns) ||
    (dashboardLevel === 'Dealership' && dealership?.has_google_ad_accounts)

  const hasGA4 =
    (dashboardLevel === 'Website' && website?.ga_profile) ||
    (dashboardLevel === 'Dealership' && dealership?.has_ga_profiles)

  return { hasFacebookAds, hasGoogleAds, hasGA4 }
}
