import { useEffect, useRef, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Toast } from 'primereact/toast'
import { Link, useSearchParams } from 'react-router-dom'
import Select from 'react-select'

import * as Routes from '../../routes'
import { standardHeaders } from '../entries/utils'
import showToast from '../shared/ShowToast'
import EditForm from './EditForm'
import Show from './Show'
import ToDoCars from './ToDoCars'

// Hook for fetching and caching stock image sets
const useStockImageSets = (params) => {
  return useQuery({
    queryKey: ['stockImageSets', params],
    queryFn: async () => {
      const queryParams = new URLSearchParams(params).toString()
      const url = `${Routes.stock_image_sets_path()}${queryParams ? '?' + queryParams : ''}`

      const response = await fetch(url, { headers: standardHeaders })
      if (!response.ok) throw new Error('Failed to fetch data')
      return response.json()
    },
    staleTime: 5 * 60 * 1000, // Cache data for 5 minutes
    keepPreviousData: true, // Keep the previous data while refetching
  })
}

const StockImageSets = () => {
  const [selectedManufacturer, setSelectedManufacturer] = useState()
  const [selectedFamily, setSelectedFamily] = useState()
  const [selectedYear, setSelectedYear] = useState()
  const [selectedBadge, setSelectedBadge] = useState()
  const notification = useRef(null)
  const [searchParams] = useSearchParams()
  const [page, setPage] = useState(1)
  const rows = 25

  // Construct query parameters
  const queryParams = Object.fromEntries(
    Object.entries({
      manufacturer_id: selectedManufacturer || searchParams.get('manufacturer_id'),
      family_id: selectedFamily?.value,
      year: selectedYear?.value,
      badge: selectedBadge?.value,
      page: page,
      per_page: rows,
    }).filter(([_, value]) => value !== undefined)
  )

  // Fetch data using TanStack Query
  const { data, isLoading, refetch } = useStockImageSets(queryParams)

  // Set default selected manufacturer when data loads
  useEffect(() => {
    if (data && !selectedManufacturer) {
      setSelectedManufacturer(data.selected_manufacturer?.id || data.manufacturers[0]?.id)
    }
  }, [data])

  const handleDelete = async (rowData) => {
    if (confirm('Are you sure you want to delete this stock image set?')) {
      const response = await fetch(`/stock_image_sets/${rowData.id}`, {
        method: 'DELETE',
        headers: standardHeaders,
      })
      if (response.ok) {
        showToast(notification, 'success', 'Stock Image Set deleted successfully')
        refetch() // Refresh cached data
      }
    }
  }

  const onPageChange = (event) => {
    setPage(event.page + 1) // PrimeReact pages are 0-based; Kaminari pages are 1-based
  }

  return (
    <div className="container py-3">
      <ToDoCars />
      <Toast ref={notification} />
      <div className="box">
        <div className="p-3">
          <h5 className="mb-3">Stock Image Sets</h5>
          <div className="row">
            {selectedManufacturer && (
              <>
                <div className="col-md-4 mb-2">
                  <Select
                    placeholder="Select Make"
                    isSearchable
                    defaultValue={{
                      value: selectedManufacturer,
                      label: data?.manufacturers?.find((m) => m.id === selectedManufacturer)?.name,
                    }}
                    onChange={(option) => setSelectedManufacturer(option.value)}
                    options={data?.manufacturers?.map((m) => ({ value: m.id, label: m.name }))}
                  />
                </div>
                <div className="col-md-4 mb-2">
                  <Select
                    placeholder="Select Model"
                    isClearable
                    isSearchable
                    value={selectedFamily}
                    onChange={setSelectedFamily}
                    options={data?.families?.map((f) => ({ value: f.id, label: f.name }))}
                  />
                </div>
                <div className="col-md-4 mb-2">
                  <Select
                    placeholder="Select Year"
                    isClearable
                    isSearchable
                    value={selectedYear}
                    onChange={setSelectedYear}
                    options={data?.years?.map((year) => ({ value: year, label: year }))}
                  />
                </div>
                <div className="col-md-4 mb-2">
                  <Select
                    placeholder="Select Badge"
                    isClearable
                    isSearchable
                    value={selectedBadge}
                    onChange={setSelectedBadge}
                    options={data?.badges?.map((b) => ({
                      value: b || 'null',
                      label: b || '(No Badge)',
                    }))}
                  />
                </div>
              </>
            )}
            <div className="col-md-4">
              <Link
                to={Routes.new_stock_image_set_path()}
                className="btn btn-outline-primary btn-block"
              >
                <i className="fa fa-plus mr-2"></i>
                Stock Image Set
              </Link>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="text-center p-3">
            <i className="fa fa-spinner fa-spin mr-2"></i>
            Loading Stock Image Sets...
          </div>
        ) : (
          <DataTable
            value={data?.stock_image_sets}
            loading={isLoading}
            lazy
            paginator
            rows={rows}
            first={(page - 1) * rows}
            totalRecords={data?.total_count || 0}
            onPage={onPageChange}
          >
            <Column
              sortable
              body={(rowData) => (
                <img
                  src={rowData.primary_photo_url}
                  style={{ width: '100px' }}
                  className="rounded border"
                  loading="lazy"
                />
              )}
              header="Photo"
            />
            <Column sortable field="year" header="Year" />
            <Column sortable field="manufacturer.name" header="Make" />
            <Column
              sortable
              body={(rowData) => (
                <div>
                  {rowData.family.name} {rowData.body}{' '}
                  <small className="text-secondary">{rowData.badge || '(No Badge)'}</small>
                  <div className="small text-secondary">{rowData.bodyconfiguration}</div>
                </div>
              )}
              field="family.name"
              header="Model"
            />
            <Column sortable field="bodystyle" header="Bodystyle" />
            <Column sortable field="modelyear" header="Modelyear" />
            <Column sortable field="colour" header="Colour" />
            <Column sortable field="photos_count" header="Photo count" />
            <Column
              body={(rowData) => <div>{moment(rowData.created_at).fromNow()}</div>}
              field="created_at"
              header="Created"
            />
            <Column
              header="Actions"
              body={(rowData) => (
                <div className="btn-group">
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-edit mr-2"></i>
                    Edit
                  </button>
                  <div className="dropdown-menu">
                    <Show
                      stockImageSet={rowData}
                      setStockImageSets={() => refetch()}
                      stockImageSets={data}
                    />
                    <EditForm stockImageSet={rowData} />
                    <a
                      href={`/stock_image_sets/${rowData.id}/photos/new`}
                      className="dropdown-item"
                    >
                      <i className="fa fa-plus mr-2"></i> Photos
                    </a>
                    <a href={`/stock_image_sets/${rowData.id}/duplicate`} className="dropdown-item">
                      <i className="fa fa-copy mr-2"></i> Duplicate
                    </a>
                    <button
                      onClick={() => handleDelete(rowData)}
                      className="dropdown-item text-danger"
                    >
                      <i className="fa fa-trash mr-2"></i> Delete
                    </button>
                  </div>
                </div>
              )}
            />
          </DataTable>
        )}
      </div>
    </div>
  )
}

export default StockImageSets
