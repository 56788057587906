import { useEffect, useRef, useState } from 'react'

import { PrimeReactProvider } from 'primereact/api'
import { Column } from 'primereact/column'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import { useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { SectionHeading, csrfToken } from '../entries/utils'
import { standardHeaders } from '../entries/utils'
import showToast from '../shared/ShowToast'
import OrderForm from './OrderForm'
import { OrderService } from './OrderService'

const Orders = () => {
  const { websiteSlug } = useParams()
  const [orders, setOrders] = useState([])
  const notification = useRef(null)
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [orderStatusOptions, setOrderStatusOptions] = useState([])
  const [contacts, setContacts] = useState([])
  const [parts, setParts] = useState([])
  const [isButtonVisible, setIsButtonVisible] = useState(false) // TODO: Remove this later

  const openModal = (order = null) => {
    setSelectedOrder(order)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setSelectedOrder(null)
    setIsModalOpen(false)
  }

  const handleSave = async (order) => {
    if (order.id) {
      const url = Routes.website_order_path(websiteSlug, order.id)
      const response = await fetch(url, {
        method: 'PUT',
        headers: standardHeaders,
        body: JSON.stringify({ order: order }),
      })
      if (response.ok) {
        showToast(notification, 'success', 'Order Updated')
        closeModal()
        fetchOrders()
      } else {
        showToast(notification, 'error', 'Error updating Order Status Option')
      }
    } else {
      const url = Routes.website_orders_path(websiteSlug)
      const response = await fetch(url, {
        method: 'POST',
        headers: standardHeaders,
        body: JSON.stringify({ order: order }),
      })
      if (!response.ok) {
        showToast(notification, 'success', 'Order Created')
        closeModal()
        fetchOrders()
      } else {
        showToast(notification, 'error', 'Error creating Order')
      }
    }
  }

  const fetchOrders = () => {
    let params = { website_id: websiteSlug }
    try {
      OrderService.getOrders(params, csrfToken, websiteSlug).then((data) => {
        setOrders(data.data.orders)
        setOrderStatusOptions(data.data.order_status_options)
        setContacts(data.data.contacts)
        setParts(data.data.parts)
      })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchOrders()
  }, [])

  // Custom body templates
  const customerDetails = (order) => {
    const { first_name, last_name, email, phone_number } = order.contact
    return (
      <div>
        <strong>{`${first_name} ${last_name}`}</strong>
        <br />
        {email}
        <br />
        {phone_number}
      </div>
    )
  }

  const orderItems = (order) => {
    return (
      <ul className="list-unstyled">
        {order.order_items.map((item, index) => (
          <li key={index}>
            {item.quantity} x {item.part.name}
          </li>
        ))}
      </ul>
    )
  }

  const orderStatus = (order) => {
    return (
      <span className={`badge badge-${order.order_current_status_colour}`}>
        {order.order_current_status}
      </span>
    )
  }

  const orderShippingCost = (order) => {
    const shippingCost = parseFloat(order.shipping_cost)
    return <span>${shippingCost.toFixed(2)}</span>
  }

  const orderTotal = (order) => {
    const totalPrice = parseFloat(order.total_price)
    return <span>${totalPrice.toFixed(2)}</span>
  }

  //console.log(data) // TODO: Replace sample data with this later

  return (
    <div className="p-2">
      <PrimeReactProvider>
        <Toast ref={notification} />
        <ConfirmDialog />

        <div className="px-3 py-1">
          <SectionHeading title="Online Store Orders">
            {isButtonVisible && (
              <button
                className="btn btn-outline-primary"
                id="new-order-button"
                onClick={() => openModal()}
              >
                <i className="fa fa-plus"></i>
                &nbsp;Create New Order
              </button>
            )}
          </SectionHeading>
          <div className="p-grid">
            <div className="p-col-12">
              <div className="box overflow-hidden">
                <DataTable value={orders} tableStyle={{ minWidth: '50rem' }}>
                  <Column field="order_number" header="Order Number" />
                  <Column
                    field="order_date"
                    header="Order Date"
                    body={(order) => {
                      const date = order.order_date
                        ? new Date(order.order_date)
                        : new Date(order.created_at)
                      return date.toLocaleDateString()
                    }}
                  />
                  <Column header="Customer Details" body={customerDetails} />
                  <Column header="Order Items" body={orderItems} />
                  <Column header="Order Status" body={orderStatus} />
                  <Column header="Shipping Cost" body={orderShippingCost} />
                  <Column header="Order Total" body={orderTotal} />
                  <Column
                    header="Actions"
                    body={(rowData) => (
                      <div>
                        <button
                          className="btn btn-outline-primary ml-auto btn-sm mb-2 mr-2"
                          onClick={() => openModal(rowData)}
                        >
                          {' '}
                          <i className="fas fa-edit"></i>
                          &nbsp;Edit
                        </button>
                        <button
                          className="btn btn-outline-danger ml-auto btn-sm mb-2"
                          onClick={() =>
                            confirmDialog({
                              message: 'Are you sure you want to delete this Order?',
                              header: 'Delete Order',
                              icon: 'pi pi-exclamation-triangle',
                              accept: async () => {
                                const url = Routes.website_order_path(websiteSlug, rowData.id)
                                const response = await fetch(url, {
                                  method: 'DELETE',
                                  headers: standardHeaders,
                                })
                                if (response) {
                                  showToast(notification, 'success', 'Order Deleted')
                                  fetchOrders()
                                } else {
                                  showToast(notification, 'error', 'Error deleting Order')
                                }
                              },
                            })
                          }
                        >
                          <i className="fas fa-trash"></i>
                          &nbsp;Delete
                        </button>
                      </div>
                    )}
                  ></Column>
                </DataTable>
              </div>
              {isModalOpen && (
                <Dialog
                  header={selectedOrder ? 'Edit Order' : 'New Order'}
                  visible={isModalOpen}
                  style={{ minWidth: '800px' }}
                  onHide={closeModal}
                  dismissableMask={true}
                >
                  <OrderForm
                    order={selectedOrder}
                    onSave={handleSave}
                    orderStatusOptions={orderStatusOptions}
                    contacts={contacts}
                    parts={parts}
                  />
                </Dialog>
              )}
            </div>
          </div>
        </div>
      </PrimeReactProvider>
    </div>
  )
}

export default Orders
