import { useContext, useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { history } from 'instantsearch.js/es/lib/routers'
import {
  Configure,
  InstantSearch,
  Pagination,
  SearchBox,
  SortBy,
  useClearRefinements,
} from 'react-instantsearch'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'

import * as Routes from '../../routes'
import Loading from '../Loading'
import { CurrentUserContext } from '../contexts'
import { useFetchDealership } from '../dataHooks'
import RefinementListSelect from '../editor/common/RefinementListSelect'
import { meilisearchClient, standardHeaders } from '../entries/utils'
import { humanize } from '../entries/utils'
import DateRangePicker from '../leads/DateRangePicker'
import Form from '../leads/Form'
import { canManageLeads } from '../leads/utils'
import CustomHits from './CustomHits'
import KanbanBoard from './KanbanBoard'
import TasksView from './TasksView'

const Col = ({ children }) => <div className="col-md-6 col-lg-4 col-xl-3 mb-2">{children}</div>

const fetchLeads = async ({ queryKey }) => {
  const [, slug, websiteSlug, dealershipSlug, pipelineId] = queryKey
  if (!slug) return null

  let url
  if (websiteSlug) {
    url = Routes.index_react_website_lead_clusters_path(websiteSlug)
  } else if (dealershipSlug) {
    url = Routes.index_react_dealership_lead_clusters_path(slug)
  }

  if (pipelineId) {
    url += `?pipeline_id=${pipelineId}`
  }

  const response = await fetch(url, {
    headers: standardHeaders,
  })
  return response.json()
}

const Wrapper = ({ defaultLayout = 'list' }) => {
  const [layout, setLayout] = useState(defaultLayout)
  const { dealership } = useFetchDealership()
  const { dealershipSlug, websiteSlug, pipelineId } = useParams()
  const navigate = useNavigate()

  const slug = window.dealership?.slug || dealershipSlug

  const { data: searchParams, isLoading } = useQuery({
    queryKey: ['leads', slug, websiteSlug, dealershipSlug, pipelineId],
    queryFn: fetchLeads,
    enabled: !!slug, // Only run query if slug is available
    staleTime: 10 * 60 * 1000, // Cache data for 10 minutes
    cacheTime: 10 * 60 * 1000,
  })

  const changeLayout = (layout) => {
    setLayout(layout)
    let url
    if (layout === 'kanban') {
      url = Routes.kanban_dealership_lead_clusters_path(slug)
    } else if (layout === 'tasks') {
      url = Routes.tasks_dealership_lead_clusters_path(slug)
    } else {
      url = Routes.dealership_lead_clusters_path(slug)
    }

    if (pipelineId !== undefined && pipelineId !== null) {
      url += `/pipelines/${pipelineId}`
    }

    navigate(url)
  }

  const pipelineCategoriesCount = searchParams?.pipeline?.categories?.length || 0

  if (searchParams?.preventAccess) {
    return (
      <div className="pt-3 container-fluid">
        <div className="box text-danger p-3">
          <i className="fa fa-exclamation-triangle mr-2" />
          Please turn on two factor authentication to access leads
        </div>
      </div>
    )
  }

  return (
    <div className="pt-3 container-fluid">
      {searchParams?.pipeline && searchParams.hasPipelines && (
        <div className="mb-3 w-100 border rounded py-2 px-3 bg-primary text-white d-flex align-items-center">
          <div>
            <i className="fa fa-info-circle mr-2" />
            You are viewing the <b>{searchParams.pipeline.name}</b> Pipeline
            {pipelineCategoriesCount > 0 && (
              <>
                {' '}
                ({pipelineCategoriesCount} lead categor{pipelineCategoriesCount === 1 ? 'y' : 'ies'}
                ){' '}
              </>
            )}
          </div>
          <div className="ml-auto">
            <Link
              to={Routes.lead_settings_dealership_path(dealershipSlug)}
              className="btn btn-outline-light btn-sm"
            >
              View Settings
            </Link>
          </div>
        </div>
      )}
      <div className="mb-2">
        <div className="btn-group btn-block">
          <button
            type="button"
            className={`btn ${layout === 'list' ? 'btn-secondary' : 'btn-outline-secondary'}`}
            onClick={() => changeLayout('list')}
          >
            <i className="fa fa-table mr-1" /> Classic
          </button>
          {dealership?.tasks_view && (
            <button
              type="button"
              className={`btn ${layout === 'tasks' ? 'btn-secondary' : 'btn-outline-secondary'}`}
              onClick={() => changeLayout('tasks')}
            >
              <i className="fa fa-check mr-1" /> Tasks View
            </button>
          )}
          <button
            type="button"
            className={`btn ${layout === 'kanban' ? 'btn-secondary' : 'btn-outline-secondary'}`}
            onClick={() => changeLayout('kanban')}
            id="kanban-board-button"
          >
            <i className="fa fa-bars mr-1" /> Kanban Board
          </button>
        </div>
      </div>
      <div className={layout === 'list' ? 'd-block' : 'd-none'}>
        {isLoading && <Loading />}
        {searchParams?.searchFilters && <SearchLeadClusters {...searchParams} />}
      </div>
      {layout === 'tasks' && <TasksView />}
      {layout === 'kanban' && <KanbanBoard />}
    </div>
  )
}

function ClearRefinements({ translations }) {
  const { refine } = useClearRefinements({})
  let { dealershipSlug } = useParams()

  const handleClick = () => {
    localStorage.removeItem(`refinementList_leadCluster_${dealershipSlug}`)
    refine()
  }

  return (
    <div className="ais-ClearRefinements">
      <button className="ais-ClearRefinements-button" onClick={handleClick}>
        {translations.resetButtonText}
      </button>
    </div>
  )
}

function renameTransformItems(items) {
  return items.map((item) => {
    if (item.label === 'Adwords') {
      item.label = 'Google'
    }
    return item
  })
}

function transformLeadStatusNames(items) {
  return items.map((item) => {
    item.label = humanize(item.label)
    return item
  })
}

function storeTagItems(items) {
  // Store the items value in the local storage
  localStorage.setItem('uniqueTags', JSON.stringify(items.map((item) => item.value)))
  return items
}

const SearchLeadClusters = ({
  searchFilters,
  users,
  leadStatusOptions,
  aggregatorUser,
  activeFilters,
}) => {
  let [activeLeadClustersOnly, setActiveLeadClustersOnly] = useState(true)
  let [recentlyUpdated, setRecentlyUpdated] = useState([])
  let { dealershipSlug, pipelineId } = useParams()
  let currentUser = useContext(CurrentUserContext)

  let defaultIndex = 'last_lead_created_at'
  if (['cars4us', 'mct-motorsport-brisbane', 'orange-motor-group'].includes(dealershipSlug)) {
    defaultIndex = 'last_lead_created_at_timestamp'
  }

  const searchClient = meilisearchClient(dealershipSlug || dealership.id)
  let indexBase = `LeadCluster_${process.env.RAILS_ENV}`
  let index = `${indexBase}:${defaultIndex}:desc`

  const sortingOptions = [
    { value: `${indexBase}:last_lead_created_at:desc`, label: 'Last lead created at' },
    {
      value: `${indexBase}:last_lead_created_at_timestamp:desc`,
      label: 'Last lead created at timestamp',
    },
    {
      value: `${indexBase}:user_name:asc`,
      label: 'Assigned User',
    },
  ]

  useEffect(() => {
    if (dealershipSlug && recentlyUpdated?.length === 0) {
      let url = Routes.recently_updated_dealership_lead_clusters_path(dealershipSlug)
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          if (data.length > 0) setRecentlyUpdated(data)
        })
    }
  }, [dealershipSlug, pipelineId])

  const savedSortBy = `LeadCluster_${process.env.RAILS_ENV}:${defaultIndex}:desc`
  const savedRefinements =
    JSON.parse(localStorage.getItem(`refinementList_leadCluster_${dealershipSlug}`)) || {}
  const routing = {
    router: history(),
    stateMapping: {
      stateToRoute(uiState) {
        const indexUiState = uiState[savedSortBy]
        const refinementList = indexUiState?.refinementList || {}
        const refinementListString = JSON.stringify(refinementList)
        localStorage.setItem(`refinementList_leadCluster_${dealershipSlug}`, refinementListString)
        const sortBy = indexUiState.sortBy || savedSortBy
        localStorage.setItem(`sortBy_leadCluster_${dealershipSlug}`, sortBy)

        return {
          q: indexUiState.query,
          page: indexUiState.page,
          refinements: indexUiState?.refinementList || savedRefinements,
        }
      },
      routeToState(routeState) {
        return {
          [savedSortBy]: {
            query: routeState.q,
            page: routeState.page,
            refinementList: {
              ...routeState.refinements,
              ...(Object.keys(savedRefinements).length !== 0 ? savedRefinements : {}),
            },
          },
        }
      },
    },
  }

  let filters = searchFilters
  if (activeLeadClustersOnly) {
    filters += activeFilters
  }

  return (
    <InstantSearch
      indexName={index}
      searchClient={searchClient}
      routing={routing}
      future={{
        preserveSharedStateOnUnmount: true,
      }}
    >
      <Configure filters={filters} hitsPerPage={40} />
      <div className="box p-3">
        <div className="row">
          <Col>
            <SearchBox className="mb-0" placeholder="Search Leads" />
          </Col>
          <Col>
            <RefinementListSelect
              attribute="lead_status"
              limit={20}
              transformItems={transformLeadStatusNames}
            />
          </Col>
          <Col>
            <RefinementListSelect attribute="user_name" limit={30} />
          </Col>
          <Col>
            <RefinementListSelect attribute="source" />
          </Col>
          <Col>
            <RefinementListSelect
              attribute="marketing_source"
              transformItems={renameTransformItems}
            />
          </Col>
          <Col>
            <RefinementListSelect attribute="category" limit={20} />
          </Col>
          <Col>
            <RefinementListSelect attribute="websites.name" buttonText="Website" limit={30} />
          </Col>
          <Col>
            <RefinementListSelect attribute="location_name" limit={30} />
          </Col>
          <Col>
            <DateRangePicker attribute="created_at_timestamp" />
          </Col>
          <Col>
            <ClearRefinements translations={{ resetButtonText: 'Clear Filters' }} />
          </Col>
          <Col>
            <RefinementListSelect attribute="requires_approval" />
          </Col>
          <Col>
            {canManageLeads() ? (
              <div className="btn-group btn-block">
                <Form />
                <button
                  id="leads-search-dropdown"
                  type="button"
                  className="btn btn-outline-primary dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                ></button>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop1">
                  <Link
                    to={Routes.reports_dealership_lead_clusters_path(dealershipSlug)}
                    className="dropdown-item"
                  >
                    Download Data
                  </Link>
                </div>
              </div>
            ) : (
              <Link
                to={Routes.reports_dealership_lead_clusters_path(dealershipSlug)}
                className="btn w-100 btn-outline-primary"
              >
                Download Data
              </Link>
            )}
          </Col>
          <Col>
            <RefinementListSelect attribute="tags" transformItems={storeTagItems} />
          </Col>
          <Col>
            <div
              id="active-leads-btn"
              className={
                'btn btn-outline-success btn-block ' + (activeLeadClustersOnly ? 'active' : '')
              }
              onClick={() => setActiveLeadClustersOnly(!activeLeadClustersOnly)}
            >
              {activeLeadClustersOnly && <i className="fa fa-check mr-1" />}
              Active Leads Only
            </div>
            <Tooltip anchorSelect="#active-leads-btn" place="bottom">
              <div>If turned ON, this will filter to show ONLY active lead clusters</div>
              <div>Lost & converted lead clusters will be hidden</div>
            </Tooltip>
          </Col>
          {currentUser?.admin && (
            <Col>
              <SortBy items={sortingOptions} />
            </Col>
          )}
        </div>
      </div>
      <CustomHits
        leadStatusOptions={leadStatusOptions}
        users={users}
        recentlyUpdated={recentlyUpdated}
        aggregatorUser={aggregatorUser}
      />
      <div className="py-3">
        <Pagination />
      </div>
    </InstantSearch>
  )
}

export default Wrapper
