import { useState } from 'react'

import { FormProvider, useForm } from 'react-hook-form'

import { FormContext } from '../contexts'
import { Errors } from '../entries/FormElements'
import { standardHeaders } from '../entries/utils'

const Form = ({
  table_name,
  defaultValues,
  children,
  attributes,
  formData,
  submitUrl,
  afterSubmit,
  submitMethod = 'POST',
}) => {
  let [loading, setLoading] = useState(false)
  let [errors, setErrors] = useState({})

  const methods = useForm({
    defaultValues: defaultValues,
  })

  const onSubmit = (data) => {
    let body = JSON.stringify(data)
    if (table_name) {
      body = JSON.stringify({ [table_name]: data })
    }

    setLoading(true)
    fetch(submitUrl, {
      method: submitMethod,
      headers: standardHeaders,
      body: body,
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 422) {
            return response.json().then((res) => {
              setErrors(res) // Handle validation errors
              setLoading(false)
              throw new Error('Validation error') // Ensures the next .then() is skipped
            })
          }
          throw new Error('Network response was not ok')
        }
        return response.json()
      })
      .then((res) => {
        setLoading(false)
        afterSubmit(res)
      })
      .catch((error) => {
        console.error('Error:', error)
        setLoading(false)
      })
  }

  const { handleSubmit, control } = methods

  return (
    <FormContext.Provider value={{ control, errors, attributes, formData }}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {children}
          <Errors errors={errors} />
          <button
            type="submit"
            className={'btn btn-primary btn-block' + (loading ? ' disabled' : '')}
          >
            {loading ? 'Saving...' : 'Save'}
          </button>
        </form>
      </FormProvider>
    </FormContext.Provider>
  )
}

export default Form
