import { ModuleItem, TimeRange } from '../../../types'
import { getModuleComponentFromName } from '../../../utils'

const ModuleFooters: React.FC<{ item: ModuleItem; data: any; timeRange: TimeRange }> = ({
  item,
  data,
  timeRange,
}) => {
  const moduleConfig = getModuleComponentFromName(item?.module)

  const footerComponents = [
    {
      name: 'PaymentsFooter',
      component: PaymentsFooter,
      shouldRender: moduleConfig?.title === 'Payments',
    },
    {
      name: 'TestDrivesFooter',
      component: TestDrivesFooter,
      shouldRender: moduleConfig?.title === 'Test Drives',
    },
    {
      name: 'SoldCarsFooter',
      component: SoldCarsFooter,
      shouldRender: moduleConfig?.title === 'Cars Sold',
    },
    {
      name: 'CarWatchlistFooter',
      component: CarWatchlistFooter,
      shouldRender: moduleConfig?.title === 'Car Watchlist',
    },
    {
      name: 'PortalLoginsFooter',
      component: PortalLoginsFooter,
      shouldRender: moduleConfig?.title === 'Portal Logins',
    },
  ]

  return (
    <>
      {footerComponents.map(
        (FooterComponent) =>
          FooterComponent.shouldRender && (
            <FooterComponent.component
              key={FooterComponent.name}
              data={data}
              timeRange={timeRange}
            />
          )
      )}
    </>
  )
}

const PaymentsFooter: React.FC<{ data: any; timeRange: TimeRange }> = ({ data, timeRange }) => {
  const timeframe = (timeRange?.timeframe || 'Last 30 Days').toLowerCase()
  return (
    <>
      {data?.number_of_payments > 0 ? (
        <ul className="mt-4">
          <li>
            {data.number_of_payments} payments {timeframe}
          </li>
          <li>
            {data.total_amount} total in payments {timeframe}
          </li>
        </ul>
      ) : null}
    </>
  )
}

const TestDrivesFooter: React.FC<{ data: any; timeRange: TimeRange }> = ({ data, timeRange }) => {
  const timeframe = (timeRange?.timeframe || 'Last 30 Days').toLowerCase()
  return (
    <>
      {data?.total_test_drives > 0 ? (
        <ul className="mt-4">
          <li>
            {data.total_test_drives} {data.total_test_drives === 1 ? 'test drive' : 'test drives'}{' '}
            {timeframe}
          </li>
        </ul>
      ) : null}
    </>
  )
}

const SoldCarsFooter: React.FC<{ data: any; timeRange: TimeRange }> = ({ data, timeRange }) => {
  const timeframe = (timeRange?.timeframe || 'Last 30 Days').toLowerCase()
  return (
    <>
      {data?.total_sold_count > -1 ? (
        <ul className="mt-4">
          <li>
            {data.total_sold_count} total cars withdrawn {timeframe}
          </li>
        </ul>
      ) : null}
    </>
  )
}

const CarWatchlistFooter: React.FC<{ data: any; timeRange: TimeRange }> = ({ data, timeRange }) => {
  const timeframe = (timeRange?.timeframe || 'Last 30 Days').toLowerCase()

  return (
    <>
      {data?.total_watchlist > -1 ? (
        <span>
          <ul className="mt-4">
            <li>
              {data.total_watchlist} total car watches {timeframe}
            </li>
          </ul>
        </span>
      ) : null}
    </>
  )
}

const PortalLoginsFooter: React.FC<{ data: any; timeRange: TimeRange }> = ({ data, timeRange }) => {
  const timeframe = (timeRange?.timeframe || 'Last 30 Days').toLowerCase()

  return (
    <>
      {data?.total_portal_logins > -1 ? (
        <span>
          <ul className="mt-4">
            <li>
              {data.total_portal_logins} total portal logins {timeframe}
            </li>
          </ul>
        </span>
      ) : null}
    </>
  )
}

export default ModuleFooters
