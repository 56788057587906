import { useEffect, useState } from 'react'

import { Dialog } from 'primereact/dialog'
import Select from 'react-select'

import { CreatableSelect } from '../entries/FormElements'
import { standardHeaders } from '../entries/utils'

const customFeatureOptions = [
  'Full Service History',
  'One Owner',
  'Non-Smoker',
  'Canopy',
  'Towbar',
  'Roof Racks',
  'Tinted Windows',
  'Leather Seats',
  'Winch',
  'Snorkel',
  'Bullbar',
  'Side Steps',
  'Spot Lights',
  'Lift kit',
  'Winch',
  'Nudgebar',
  'Elderly Driver',
  'Female Driver',
  'Sportsbar',
  'Roller shutter',
  'Tub liner',
  'Premium sound system',
  'Tool Box',
  'Upgraded Suspension',
]

let certificationType = {
  bmw: [
    'BMW Demonstrator Vehicle',
    'BMW Demo Plus Full Circle',
    'BMW Full Circle',
    'BMW Premium Certified',
    'BMW Premium Plus Full Circle',
  ],
}

let selectStyle = {
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
}

const CarSettings = ({ car, setCar, visible, setVisible, children }) => {
  let [loading, setLoading] = useState(false)
  let [tag, setTag] = useState(car.tag || null)
  let [availableTags, setAvailableTags] = useState(false)
  let [customFeatures, setCustomFeatures] = useState(car.custom_features || [])
  let [error, setError] = useState(false)
  let [certified, setCertified] = useState(car.certified)
  let [reservable, setReservable] = useState(car.reservable || true)
  let [certifiedType, setCertifiedType] = useState()
  let [canShowFinance, setCanShowFinance] = useState(car.can_show_finance || true)

  useEffect(() => {
    if (visible) {
      setLoading(true)
      // Fetch the available car tags
      fetch(`/cars/${car.id}/tags`)
        .then((res) => res.json())
        .then((res) => setAvailableTags(res.tags))

      fetch(`/cars/${car.id}.json`)
        .then((res) => res.json())
        .then((res) => {
          setCertifiedType(res.certified_type)
          setReservable(res.reservable)
          setCanShowFinance(res.can_show_finance)
          setLoading(false)
        })
    }
  }, [visible])

  let Submit = () => {
    setLoading(true)
    fetch(`/cars/${car.id}`, {
      method: 'PATCH',
      headers: standardHeaders,
      body: JSON.stringify({
        car: {
          tag: tag,
          certified: certified,
          custom_features: customFeatures,
          certified_type: certifiedType,
          reservable: reservable,
          can_show_finance: canShowFinance,
        },
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json()
        }
        throw new Error('Something went wrong')
      })
      .then((res) => {
        setLoading(false)
        // Update the car data only overwriting the tag
        setCar({
          ...car,
          tag: res.tag,
          certified: res.certified,
          custom_features: res.custom_features,
        })
        // close dialog
        setVisible(false)
      })
      .catch((error) => {
        console.log(error)
        setError('An error has occured')
      })
  }

  const tagStyle = (tag) => {
    switch (tag) {
      case 'Test Drive Only':
        return 'warning'
      case 'New':
        return 'success'
      case 'Pre-order':
        return 'info'
      default:
        return 'primary'
    }
  }

  return (
    <>
      {children ? (
        children
      ) : (
        <div>
          <div
            className={`badge badge-${tagStyle(car.tag)}`}
            style={{ cursor: 'pointer' }}
            onClick={() => setVisible(true)}
          >
            <small>{car.tag ? <span>Tag: {car.tag}</span> : 'Settings'}</small>
          </div>
        </div>
      )}
      <Dialog
        header={`Edit ${car.name}`}
        visible={visible}
        style={{ width: '50vw' }}
        breakpoints={{ '960px': '75vw', '641px': '100vw' }}
        onHide={() => setVisible(false)}
        dismissableMask={true}
      >
        <div className="form-group">
          <label>Car Tag</label>
          <Select
            options={
              availableTags &&
              availableTags.map((o) => {
                return { value: o, label: o ? o : 'No Tag' }
              })
            }
            onChange={(e) => setTag(e.value)}
            isClearable={true}
            defaultValue={{ label: car.tag, value: car.tag }}
            placeholder="Tag..."
            styles={selectStyle}
            menuPortalTarget={document.body}
          />
          <small className="form-text text-muted">New tags can be added in Car Setting</small>
        </div>
        <CreatableSelect
          label={'Car Features'}
          requred={false}
          hint={
            'These features are in addition to the redbook features. Many features (eg. Sunroof or Apple Carplay) are already available from the Redbook data.'
          }
          options={customFeatureOptions.map((o) => {
            return { value: o, label: o }
          })}
          onChange={(e) => {
            setCustomFeatures(e.map((a) => a.value))
          }}
          isClearable={true}
          isMulti={true}
          defaultValue={car.custom_features?.map((o) => {
            return { value: o, label: o }
          })}
          placeholder="Car Features..."
          styles={selectStyle}
          menuPortalTarget={document.body}
        />
        <div className="form-group">
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id={`customSwitch1-${car.id}`}
              checked={certified}
              onChange={() => setCertified(!certified)}
            />
            <label className="custom-control-label" htmlFor={`customSwitch1-${car.id}`}>
              Manufacturer Certified
            </label>
          </div>
        </div>
        {certified && car.make === 'BMW' && (
          <div className="form-group">
            <label>Certified Type</label>
            <Select
              options={certificationType['bmw'].map((o) => {
                return { value: o, label: o }
              })}
              onChange={(e) => setCertifiedType(e?.value)}
              isClearable={true}
              value={certifiedType ? { label: certifiedType, value: certifiedType } : ''}
              placeholder="Certified Type..."
              styles={selectStyle}
              menuPortalTarget={document.body}
            />
          </div>
        )}
        <div className="form-group">
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className={'custom-control-input' + (loading ? ' disabled' : '')}
              id={`customSwitch2-${car.id}`}
              checked={reservable}
              onChange={() => setReservable(!reservable)}
              disabled={loading}
            />
            <label className="custom-control-label" htmlFor={`customSwitch2-${car.id}`}>
              Reservable
            </label>
          </div>
        </div>
        <div className="form-group">
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className={'custom-control-input' + (loading ? ' disabled' : '')}
              id={`canShowFinance-${car.id}`}
              checked={canShowFinance}
              onChange={() => setCanShowFinance(!canShowFinance)}
              disabled={loading}
            />
            <label className="custom-control-label" htmlFor={`canShowFinance-${car.id}`}>
              Can Show Finance
            </label>
          </div>
        </div>
        <button
          onClick={() => Submit()}
          className={`btn btn-outline-success btn-block mt-2 ${loading ? 'disabled' : ''}`}
          disabled={loading}
        >
          {loading ? (
            <>
              <i className="fa fa-circle-notch fa-spin mr-2" />
              Loading...
            </>
          ) : (
            'Update'
          )}
        </button>
        {error && <p>{error}</p>}
      </Dialog>
    </>
  )
}

export default CarSettings
