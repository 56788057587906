import { useState } from 'react'

import { InputText } from 'primereact/inputtext'
import { useParams } from 'react-router-dom'

const DataTableSearch = ({ filters, setFilters, dt }) => {
  const [globalFilterValue, setGlobalFilterValue] = useState('')

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = { ...filters }

    _filters['global'].value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly })
  }

  let { dealershipSlug } = useParams()

  return (
    <div className="d-flex justify-content-end">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Keyword Search"
        />
      </span>
      <div className="ml-auto">
        {dealershipSlug && dt && (
          <div
            className="btn btn-outline-primary"
            onClick={() => {
              exportCSV(false)
            }}
          >
            Download CSV
          </div>
        )}
      </div>
    </div>
  )
}

export const DownloadCsvHeader = ({ dt }) => {
  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly })
  }

  return (
    <div className="d-flex justify-content-end">
      <div className="ml-auto">
        {dt && (
          <div
            className="btn btn-outline-primary"
            onClick={() => {
              exportCSV(false)
            }}
          >
            Download CSV
          </div>
        )}
      </div>
    </div>
  )
}

export default DataTableSearch
