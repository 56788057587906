import { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Configure, InstantSearch, Pagination, SearchBox } from 'react-instantsearch'
import { useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import Loading from '../Loading'
import MenuSelect from '../entries/MenuSelect'
import { meilisearchClient, standardHeaders } from '../entries/utils'
import CustomHits from './customHits'

const App = ({ searchFilters }) => {
  let [sort, setSort] = useState('created_at:desc')
  const { dealershipSlug } = useParams()

  const searchClient = meilisearchClient(dealershipSlug)

  return (
    <div className="p-3">
      <InstantSearch
        indexName={`Valuation_${process.env.RAILS_ENV}:${sort}`}
        searchClient={searchClient}
        routing={true}
      >
        <Configure filters={searchFilters} hitsPerPage={40} />
        <div className="box p-3 mb-3">
          <div className="row">
            <div className="col-md-3 mb-2">
              <SearchBox className="mb-0" placeholder="Search Valuations" />
            </div>
            <div className="col-md-3 mb-2">
              <MenuSelect attribute="location_name" limit={30} seeAllOption="All Locations" />
            </div>
            <div className="col-md-3 mb-2">
              <MenuSelect attribute="make" limit={30} seeAllOption="All Makes" />
            </div>
            <div className="col-md-3 mb-2">
              <MenuSelect
                attribute="lead_status"
                limit={30}
                seeAllOption="All Lead Cluster Statuses"
              />
            </div>
            <div className="col-md-3 mb-2">
              <MenuSelect attribute="provider" limit={30} seeAllOption="All Providers" />
            </div>
          </div>
        </div>
        <CustomHits setSort={setSort} sort={sort} />
        <div className="py-3">
          <Pagination />
        </div>
      </InstantSearch>
    </div>
  )
}

const fetchValuations = async (dealershipSlug) => {
  const res = await fetch(Routes.dealership_valuations_path(dealershipSlug), {
    headers: standardHeaders,
  })
  if (!res.ok) {
    throw new Error('Failed to fetch valuations')
  }
  return res.json()
}

const Wrapper = () => {
  const { dealershipSlug } = useParams()

  const { data, isLoading, error } = useQuery({
    queryKey: ['valuations', dealershipSlug],
    queryFn: () => fetchValuations(dealershipSlug),
    staleTime: 1000 * 60 * 5, // Cache data for 5 minutes
  })

  if (isLoading) {
    return (
      <div className="p-2">
        <Loading />
      </div>
    )
  }

  if (error) {
    return <div className="p-2 text-red-500">Error loading valuations</div>
  }

  return <App searchFilters={data.search_filters} />
}

export default Wrapper
