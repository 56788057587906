import { useEffect, useRef, useState } from 'react'

import _ from 'lodash'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { InputSwitch } from 'primereact/inputswitch'
import { Toast } from 'primereact/toast'
import { classNames } from 'primereact/utils'
import Select from 'react-select'

import showToast from '../../shared/ShowToast'
import TickingTimestamp from '../../shared/TickingTimestamp'
import { Dialog, InputLabel, Sidebar, cleanHtml } from '../common'
import { WebsiteDetailsProps } from '../types'
import {
  PageUrlSection,
  PageVisibilityButton,
  PreviewButton,
  SaveDraftButton,
  SettingsButton,
  UndoButton,
} from './ToolbarComponents'
import {
  ExcludeWebsitesTooltip,
  MetaDescriptionWarningTooltip,
  MetaTitleWarningTooltip,
  SEOIndexableTooltip,
  TitleWarningTooltip,
  WebsiteNameNoticeTooltip,
} from './ToolbarTooltips'
import {
  SERPPreview,
  cleanSlug,
  generateFullPath,
  getMetaTitle,
  getPageCategoryOptions,
  handleDeleteImage,
  handleDraftAndPublishedStates,
  handleSlugFormatting,
  handleWebsiteNameVariableCheck,
  loadAvailableAuthors,
  togglePageVisibility,
  validateTitle,
} from './ToolbarUtils'

const App = ({ ...props }) => {
  const notification = useRef(null)

  const {
    csrf,
    pageJsonUrl,
    updateUrl,
    deleteImageUrl,
    dealershipId,
    developmentEnv,
    showTemplateOverrides,
    newFieldsEnabled,
    websites,
    manufacturerId,
    websiteName,
    togglePageVisibilityUrl,
    templateType,
    isAdmin,
    websiteUrl,
  } = props

  // Sidebar Inputs
  const [title, setTitle] = useState('')
  const [slugOverride, setSlugOverride] = useState('')
  const [slug, setSlug] = useState('')
  const [usePagesPath, setUsePagesPath] = useState(false)
  const [canonicalUrl, setCanonicalUrl] = useState('')
  const [uploadedFile, setUploadedFile] = useState(null)
  const [selectedWebsites, setSelectedWebsites] = useState([])
  const [metaTitle, setMetaTitle] = useState('')
  const [metaDescription, setMetaDescription] = useState('')
  const [homePage, setHomePage] = useState(false)
  const [seoIndexable, setSeoIndexable] = useState(true)
  const [visible, setVisible] = useState(false)
  const [excludeWebsites, setExcludeWebsites] = useState(false)
  const [displayedAuthor, setDisplayedAuthor] = useState(null)
  const [pageCategory, setPageCategory] = useState(null)
  const [templateOverride, setTemplateOverride] = useState(null)
  // Dev specific input
  const [draftBlocksJson, setDraftBlocksJson] = useState(props?.draftBlocksJson ?? '{}')

  // State management variables
  const [draftStateLoaded, setDraftStateLoaded] = useState(false)
  const [isPublished, setIsPublished] = useState(false)
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false) // Modal
  const [shouldSaveDraft, setShouldSaveDraft] = useState(false) // Flag to trigger saving the draft to the server after undoing draft changes.
  const [showRefreshButton, setShowRefreshButton] = useState(false)
  const [showTemplateRefreshModal, setShowTemplateRefreshModal] = useState(false)
  const [showSlugOverride, setShowSlugOverride] = useState(false)
  const [showCanonicalUrl, setShowCanonicalUrl] = useState(false)
  const [showWebsiteNameVariableWarning, setShowWebsiteNameVariableWarning] = useState({
    title: false,
    metaTitle: false,
  })

  // Page data values
  const [pageUrl, setPageUrl] = useState('')
  const [previewUrl, setPreviewUrl] = useState('')
  const [imageThumbUrl, setImageThumbUrl] = useState(null)
  const [lastLiveUpdatedAt, setLastLiveUpdatedAt] = useState(null)
  const [lastDraftEditedAt, setLastDraftEditedAt] = useState(null)
  const [blocksJson, setBlocksJson] = useState('{}')
  const [availableTemplates, setAvailableTemplates] = useState([])
  const [availableAuthors, setAvailableAuthors] = useState([])

  if (availableTemplates?.length > 0) {
    availableTemplates.unshift({ value: null, label: '' })
  }

  // Used for UNDO functionality
  const draftBlocks = JSON.parse(draftBlocksJson)?.blocks ?? []
  // Check if blocksJson is not empty and is not just an empty object '{}'. This prevents parsing errors.
  const blocks = !_.isEmpty(blocksJson) && blocksJson !== '{}' ? JSON.parse(blocksJson).blocks : []

  useEffect(() => {
    if (pageJsonUrl) {
      refreshPageData()
    }

    const handleEditorSave = (event) => {
      setDraftBlocksJson(event.detail)
    }
    window.addEventListener('editorSave', handleEditorSave)

    return () => {
      window.removeEventListener('editorSave', handleEditorSave)
    }
  }, [])

  useEffect(() => {
    // Update loading/published/saved states
    handleDraftAndPublishedStates(draftBlocksJson, blocksJson, setDraftStateLoaded, setIsPublished)
  }, [draftBlocksJson, blocksJson])

  // Use effect to handle saving the draft to the server after undoing changes
  useEffect(() => {
    if (shouldSaveDraft) {
      submitForm('save_draft', 'undo')
        .then(() => {
          setShouldSaveDraft(false)
          window.location.reload() // Reflect changes on UI.
        })
        .catch((error) => {
          console.error('Failed to undo changes: ', error)
        })
    }
  }, [shouldSaveDraft])

  // Hide the template refresh button if there are no refresh options
  useEffect(() => {
    if (show) {
      const templateRefreshChildren = document.getElementById('template-refresh-children')
      // Check if the div is empty
      if (
        (templateRefreshChildren && templateRefreshChildren.innerHTML.trim() === '') ||
        templateRefreshChildren.innerHTML.trim() === undefined
      ) {
        setShowRefreshButton(false)
      } else {
        setShowRefreshButton(true)
      }
    } else {
      setShowRefreshButton(false)
    }
  }, [show])

  return (
    <div id="pages-settings-toolbar">
      <ConfirmDialog />
      <Toast ref={notification} />
      <Dialog
        title="Refresh Template Options"
        show={showTemplateRefreshModal}
        closeClickHandler={() => setShowTemplateRefreshModal(false)}
        forceMount
      >
        <div id="template-refresh-children"></div>
      </Dialog>
      <div className="border-top border-bottom p-2 d-flex flex-column flex-lg-row justify-content-between align-items-center position-relative">
        <div className="d-flex flex-column flex-lg-row flex-wrap align-items-center">
          <div className="editorjs-title mr-0 mr-lg-3">
            <span className="font-weight-bold">Title:</span> {cleanHtml(title, websiteName)}
          </div>
          {isPublished && pageUrl && <PageUrlSection pageUrl={pageUrl} />}
        </div>
        <div className="d-flex flex-row flex-wrap flex-md-nowrap justify-content-center align-items-center">
          {!_.isEqual(draftBlocks, blocks) && lastDraftEditedAt && (
            <UndoButton
              blocksJson={blocksJson}
              setDraftBlocksJson={setDraftBlocksJson}
              setShouldSaveDraft={setShouldSaveDraft}
            />
          )}
          {previewUrl && lastDraftEditedAt && <PreviewButton previewUrl={previewUrl} />}
          {draftStateLoaded && <SaveDraftButton loading={loading} submitForm={submitForm} />}
          {draftStateLoaded && lastDraftEditedAt && (
            <button
              disabled={loading}
              className="btn btn-danger mt-2 mt-lg-0 mr-2"
              onClick={() => submitForm('publish')}
            >
              {loading && <span>Loading...</span>}
              {!loading && <span>Publish</span>}
            </button>
          )}
          {isPublished && (
            <PageVisibilityButton
              togglePageVisibilityUrl={togglePageVisibilityUrl}
              csrf={csrf}
              visible={visible}
              setVisible={setVisible}
              notification={notification}
            />
          )}
          <SettingsButton setShow={setShow} />
        </div>
      </div>
      <Sidebar title="Page Settings" show={show} closeClickHandler={() => setShow(false)}>
        <div className="form-group text pt-3">
          <InputLabel
            label="Title"
            htmlFor="page_title"
            itemName="title"
            labelClassname="form-control-label string optional mb-0"
            tooltip={[
              manufacturerId ? TitleWarningTooltip : undefined,
              showWebsiteNameVariableWarning.title ? WebsiteNameNoticeTooltip : undefined,
            ]}
          />
          <input
            id="page_title"
            name="title"
            className="form-control string optional"
            type="text"
            value={title}
            onChange={(e) => {
              validateTitle('page', e.target.value, setTitle, setShowWebsiteNameVariableWarning)
            }}
            onBlur={() => {
              if (title === '') {
                setTitle('Untitled')
              }
            }}
          />
        </div>
        {newFieldsEnabled && (
          <>
            {/* Unavailable for non-search template pages unless Admin */}
            {(templateType === 'default' || templateType === 'search' || isAdmin) &&
              !templateOverride?.value &&
              !homePage && (
                <div className="mb-3">
                  <div className="form-group d-flex align-items-center justify-content-between mb-0">
                    <label
                      className="form-control-label boolean optional mb-0"
                      htmlFor="page_show_override_slug"
                    >
                      Override page slug
                    </label>
                    <InputSwitch
                      id="page_show_override_slug"
                      checked={showSlugOverride}
                      onChange={(e) => {
                        // Default to the current slug if no slug override is set
                        setSlugOverride(e.value ? slug : '')
                        setShowSlugOverride(e.value)
                      }}
                    />
                  </div>
                  {showSlugOverride && (
                    <div className="form-group text mt-2 mb-0">
                      <label className="form-control-label string optional">Slug Override</label>
                      <input
                        id="page_slug_override"
                        name="slug_override"
                        className="form-control string optional"
                        type="text"
                        value={slugOverride}
                        onBlur={() =>
                          cleanSlug(
                            slugOverride,
                            setSlugOverride,
                            templateType === 'search' ? 'search' : 'default'
                          )
                        }
                        onChange={(e) =>
                          handleSlugFormatting(
                            e.target.value,
                            setSlugOverride,
                            templateType === 'search' ? 'search' : 'default'
                          )
                        }
                      />
                      <div className="small text-info mt-1">{`${websiteUrl}/${generateFullPath(
                        usePagesPath,
                        templateType,
                        pageCategory?.value,
                        templateOverride?.value,
                        slugOverride
                      )}`}</div>
                    </div>
                  )}
                </div>
              )}
            <div className="form-group">
              <label className="form-control-label">Featured Image</label>
              {imageThumbUrl ? (
                <>
                  <img className="d-block mb-2" src={imageThumbUrl} alt="Uploaded Thumbnail" />
                  <button
                    className="btn btn-danger"
                    onClick={() =>
                      handleDeleteImage(
                        deleteImageUrl,
                        csrf,
                        setImageThumbUrl,
                        setUploadedFile,
                        notification
                      )
                    }
                    title="Delete this image"
                  >
                    <i className="fa fa-danger fa-trash"></i>
                  </button>
                </>
              ) : (
                <input
                  type="file"
                  className="form-control-file"
                  onChange={(e) => setUploadedFile(e.target.files[0])}
                />
              )}
            </div>
            <div className="form-group text">
              {/* Only show tooltip warning on Manufacturer level */}
              <InputLabel
                label="Meta Title"
                htmlFor="page_meta_title"
                itemName="meta_title"
                labelClassname="form-control-label string optional mb-0"
                tooltip={[
                  manufacturerId ? MetaTitleWarningTooltip : undefined,
                  // Only show website name warning if not home page
                  !!homePage
                    ? undefined
                    : showWebsiteNameVariableWarning.metaTitle
                      ? WebsiteNameNoticeTooltip
                      : undefined,
                ]}
              />
              <input
                id="page_meta_title"
                name="meta_title"
                className="form-control string optional"
                type="text"
                value={metaTitle}
                onChange={(e) =>
                  handleWebsiteNameVariableCheck(
                    'metaTitle',
                    e.target.value,
                    setMetaTitle,
                    setShowWebsiteNameVariableWarning
                  )
                }
              />
              <span
                className={classNames(
                  'small',
                  getMetaTitle(title, metaTitle, websiteName, homePage).length > 60
                    ? 'text-danger'
                    : 'text-muted'
                )}
              >
                {getMetaTitle(title, metaTitle, websiteName, homePage).length}
                /60 characters
              </span>
            </div>
            <div className="form-group text">
              {/* Only show tooltip warning on Manufacturer level */}
              <InputLabel
                label="Meta Description"
                htmlFor="page_meta_description"
                itemName="meta_description"
                labelClassname="form-control-label string optional mb-0"
                tooltip={manufacturerId ? MetaDescriptionWarningTooltip : undefined}
              />
              <textarea
                className="form-control string optional"
                onChange={(e) => setMetaDescription(e.target.value)}
                value={metaDescription}
              />
              <span
                className={classNames(
                  'small',
                  metaDescription.length > 160 ? 'text-danger' : 'text-muted'
                )}
              >
                {metaDescription.length}/160 characters
              </span>
            </div>
            <SERPPreview
              title={getMetaTitle(title, metaTitle, websiteName, homePage)}
              description={metaDescription}
              url={`${websiteUrl}/${
                homePage
                  ? ''
                  : generateFullPath(
                      usePagesPath,
                      templateType,
                      pageCategory?.value,
                      templateOverride?.value,
                      slugOverride || slug
                    )
              }`}
            />
            <div className="form-group d-flex align-items-center justify-content-between">
              <InputLabel
                label="SEO Indexable"
                itemName="seo_indexable"
                htmlFor="page_seo_indexable"
                labelClassname="form-control-label boolean optional mb-0 d-flex align-items-center"
                containerClassName="d-flex align-items-center mb-0"
                tooltip={SEOIndexableTooltip}
              />
              <InputSwitch
                id="page_seo_indexable"
                checked={seoIndexable}
                onChange={(e) => setSeoIndexable(e.value)}
              />
            </div>
          </>
        )}
        {!homePage && (
          <div className="mb-3">
            <div className="form-group d-flex align-items-center justify-content-between mb-0">
              <label
                className="form-control-label boolean optional mb-0"
                htmlFor="page_show_canonical_url"
              >
                Use custom canonical URL
              </label>
              <InputSwitch
                id="page_show_canonical_url"
                checked={showCanonicalUrl}
                onChange={(e) => {
                  // Default to the current url if no canonical url is set
                  setCanonicalUrl(
                    e.value
                      ? generateFullPath(
                          usePagesPath,
                          templateType,
                          pageCategory?.value,
                          templateOverride?.value,
                          slug
                        )
                      : ''
                  )
                  setShowCanonicalUrl(e.value)
                }}
              />
            </div>
            {showCanonicalUrl && (
              <div className="form-group text mt-2 mb-0">
                <label className="form-control-label string optional">Canonical URL</label>
                <input
                  id="page_canonical_url"
                  name="canonical_url"
                  className="form-control string optional"
                  type="text"
                  value={canonicalUrl}
                  onBlur={() => cleanSlug(canonicalUrl, setCanonicalUrl, 'canonical')}
                  onChange={(e) =>
                    handleSlugFormatting(e.target.value, setCanonicalUrl, 'canonical')
                  }
                />
                <div className="small text-info mt-1">{`${websiteUrl}/${canonicalUrl}`}</div>
              </div>
            )}
          </div>
        )}
        {/* Not allowed on template or manufacturer pages */}
        {templateType === 'default' && !manufacturerId && (
          <>
            {/* TODO: Replace homepage switch with a template option on page creation */}
            <div className="form-group d-flex align-items-center justify-content-between">
              <label className="form-control-label boolean optional mb-0" htmlFor="page_home_page">
                Home Page
              </label>
              <InputSwitch
                id="page_home_page"
                checked={homePage}
                onChange={(e) => setHomePage(e.value)}
              />
            </div>
            {!homePage && !templateOverride?.value && (
              <div className="form-group select optional">
                <label className="form-control-label select optional" htmlFor="page_category">
                  Page Category
                </label>
                <Select
                  options={getPageCategoryOptions()}
                  value={pageCategory}
                  isClearable={true}
                  onChange={(e) => setPageCategory(e)}
                />
              </div>
            )}
            {(templateType === 'default' || isAdmin) &&
              // Force show input if page has a value (allows removal of "null")
              (showTemplateOverrides || templateOverride?.value) && (
                <div className="form-group select optional">
                  <label
                    className="form-control-label select optional"
                    htmlFor="page_template_override"
                  >
                    Template override
                  </label>
                  <Select
                    options={availableTemplates}
                    value={templateOverride}
                    isClearable={true}
                    onChange={(e) => setTemplateOverride(e)}
                  />
                </div>
              )}
          </>
        )}
        {/* Only shown on manufacturer pages */}
        {websites?.length > 0 && (
          <>
            <div className="form-group select optional mb-2">
              <label className="form-control-label select optional" htmlFor="websites">
                Websites
              </label>
              <Select
                id="websites"
                name="websites"
                options={websites}
                isClearable={true}
                placeholder={
                  excludeWebsites ? 'Select websites to exclude' : 'Select websites to include'
                }
                value={selectedWebsites}
                onChange={(e) => setSelectedWebsites([...e])} // readonly -> mutable
                isMulti
              />
            </div>
            <div className="form-group d-flex align-items-center justify-content-between">
              <InputLabel
                label="Exclude selected websites"
                itemName="exclude_websites"
                labelClassname="form-control-label boolean optional mb-0 d-flex align-items-center"
                containerClassName="d-flex align-items-center mb-0"
                tooltip={ExcludeWebsitesTooltip}
              />
              <InputSwitch
                id="websites_exclude_toggle"
                checked={excludeWebsites}
                onChange={(e) => setExcludeWebsites(!!e.value)}
              />
            </div>
          </>
        )}
        {availableAuthors && (
          <div className="form-group select optional">
            <label className="form-control-label select optional" htmlFor="page_displayed_author">
              Displayed Author
            </label>
            <Select
              options={availableAuthors}
              value={displayedAuthor}
              isClearable={true}
              onChange={(e) => setDisplayedAuthor(e)}
            />
          </div>
        )}
        {developmentEnv ? (
          <div className="form-group jsonb optional page_draft_blocks_json form-group-valid">
            <label className="form-control-label jsonb optional">Draft blocks json</label>
            <textarea
              className="form-control is-valid jsonb optional"
              id="draft-blocks-json"
              defaultValue={draftBlocksJson}
            />
          </div>
        ) : (
          <input type="hidden" id="draft-blocks-json" defaultValue={draftBlocksJson} />
        )}
        {isPublished && (
          <div className="form-group d-flex align-items-center justify-content-between">
            <span>Page Visibility</span>
            <InputSwitch
              id="page_visibility_toggle"
              checked={visible}
              onChange={(e) =>
                togglePageVisibility(
                  'page',
                  e,
                  togglePageVisibilityUrl,
                  csrf,
                  visible,
                  setVisible,
                  notification
                )
              }
            />
          </div>
        )}
        {showRefreshButton && isAdmin && (
          <button
            id="refresh-template-button"
            onClick={() => {
              setShow(false)
              setShowTemplateRefreshModal(true)
            }}
            className="btn btn-success btn-block mt-4"
          >
            Refresh Template
          </button>
        )}
        {draftStateLoaded && (
          <>
            <button
              disabled={loading}
              className="btn btn-primary btn-block mt-4"
              id="save-draft-button"
              onClick={() => submitForm('save_draft')}
            >
              {loading && <span>Loading...</span>}
              {!loading && <span>Save</span>}
            </button>
            {lastDraftEditedAt && (
              <TickingTimestamp
                label="Draft last edited"
                date={lastDraftEditedAt}
                interval={5000}
              />
            )}
          </>
        )}
        {draftStateLoaded && lastDraftEditedAt && (
          <>
            <button
              disabled={loading}
              id="publish-button"
              className="btn btn-danger btn-block mt-2"
              onClick={() => submitForm('publish')}
            >
              {loading && <span>Loading...</span>}
              {!loading && <span>Publish</span>}
            </button>
            {lastLiveUpdatedAt && (
              <TickingTimestamp
                label="Page last published"
                date={lastLiveUpdatedAt}
                interval={5000}
              />
            )}
          </>
        )}
      </Sidebar>
      {/* This must exist in the DOM for page blocks to render correctly */}
      <input type="hidden" id="draft-blocks-json" defaultValue={draftBlocksJson} />
    </div>
  )

  function refreshPageData() {
    setLoading(true)

    try {
      fetch(pageJsonUrl, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then(
          (data) => {
            // Handle slug redirect on saving of a new page
            if (data?.message === 'Resource moved permanently') {
              showToast(
                notification,
                'error',
                'Sorry, the page you are saving requires a reload',
                "Don't worry, your changes won't be lost"
              )

              // Reload the page after a delay to allow the toast message to be read by the user
              setTimeout(() => {
                window.location.reload()
              }, 3200) // Delay added to allow for the user to read the toast`
            }

            // Set page data
            setTitle(data.title ?? '')
            setMetaDescription(data.meta_description ?? '')
            if (data.pageable_type === 'Website') {
              loadAvailableAuthors(dealershipId, setLoading, setAvailableAuthors)
            }

            if (newFieldsEnabled) {
              const thumbUrl = data.image?.image?.thumb?.url
              if (thumbUrl) {
                setImageThumbUrl(thumbUrl)
              }
              setMetaTitle(data.meta_title ?? '')
              setShowSlugOverride(!!data.slug_override && data.slug_override !== '')
              setSlugOverride(data.slug_override ?? '')
              setSeoIndexable(data.seo_indexable)
            }

            setVisible(data.visible)
            setHomePage(data.home_page)
            setPageUrl(data.page_url)
            setPreviewUrl(data.preview_url)
            setBlocksJson(data.blocks_json ?? '{}')
            setTemplateOverride({ value: data.template_override, label: data.template_override })
            setLastLiveUpdatedAt(data.last_live_updated_at)
            setLastDraftEditedAt(data.last_draft_edited_at)
            // undefined by default
            setExcludeWebsites(!!data.exclude_websites)
            if (data.exclude_websites) {
              // The selected websites are the ones that are not in the data.websites array
              const activeWebsiteIds = data.websites?.map(
                (website: WebsiteDetailsProps) => website.id
              )
              setSelectedWebsites(
                websites.filter(
                  (website: { value: number; label: string }) =>
                    !activeWebsiteIds.includes(website.value)
                )
              )
            } else {
              // The selected websites are the ones that are in the data.websites array
              setSelectedWebsites(
                data.websites?.map((website: { id: number; name: string }) => ({
                  value: website.id,
                  label: website.name,
                }))
              )
            }
            setAvailableTemplates(
              data.available_templates?.map((slug: string) => ({ value: slug, label: slug }))
            )
            setPageCategory({ label: data.page_category, value: data.page_category })
            setDisplayedAuthor({
              value: data.displayed_author_id,
              label: data.displayed_author_name,
            })
            setShowCanonicalUrl(!!data.canonical_url && data.canonical_url !== '')
            setCanonicalUrl(data.canonical_url ?? '')
            setSlug(data.slug ?? '')
            setUsePagesPath(data.use_pages_path)

            setLoading(false)
          },
          (error) => {
            console.error('Error fetching page data:', error)
            setLoading(false)
            showToast(notification, 'error', 'Error fetching page data', '')
          }
        )
    } catch (error) {
      console.error('Unexpected error:', error)
      // Handle unexpected errors
      const alert = `Sorry, there was an error loading the page. Please try refreshing.\n\nIf the problem persists, please send the following error message to support.\n\nError: ${error}`
      showToast(notification, 'error', 'Error loading page', alert)
    }
  }

  async function submitForm(saveMode: string, action: string = '') {
    const formData = new FormData()

    const isVisible = saveMode === 'publish' ? true : visible

    if (newFieldsEnabled) {
      formData.append('page[slug_override]', slugOverride)
      formData.append('page[meta_title]', metaTitle)
      if (uploadedFile) {
        formData.append('page[image_attributes][image]', uploadedFile)
      }
      formData.append('page[seo_indexable]', seoIndexable.toString())
    }

    formData.append('page[title]', title)
    formData.append('page[meta_description]', metaDescription)
    formData.append('page[visible]', isVisible.toString())
    // Undefined (default) will transform to false
    formData.append('page[exclude_websites]', Boolean(excludeWebsites).toString())
    // Allow for no websites to be selected
    if (!excludeWebsites && selectedWebsites?.length === 0) {
      formData.append('page[website_ids][]', '')
    } else {
      websites?.forEach((website: { label: string; value: string }) => {
        const isSelected = selectedWebsites?.some(
          (selectedWebsite) => selectedWebsite.value === website.value
        )
        if (excludeWebsites ? !isSelected : isSelected) {
          formData.append('page[website_ids][]', website.value)
        }
      })
    }
    formData.append('page[draft_blocks_json]', draftBlocksJson)
    formData.append('page[home_page]', homePage.toString())

    formData.append('page[template_override]', templateOverride?.value || '')
    formData.append('page[displayed_author_id]', displayedAuthor?.value || '')
    formData.append('page[page_category]', pageCategory?.value || '')
    formData.append('page[canonical_url]', canonicalUrl)
    formData.append('save_mode', saveMode)

    try {
      setLoading(true)
      const response = await fetch(updateUrl, {
        method: 'PUT',
        headers: {
          'X-CSRF-Token': csrf,
          Accept: 'application/json',
        },
        body: formData,
      })

      setLoading(false)

      if (!response.ok) {
        const errors = await response.json()
        showToast(notification, 'error', 'Error saving the page', 'Please try again')
        throw errors
      } else {
        let toastTitle = ''
        let toastDescription = ''
        if (saveMode === 'save_draft') {
          if (action === 'undo') {
            toastTitle = 'Saved changes successfully undone'
          } else {
            toastTitle = 'Draft saved successfully'
            toastDescription = 'Remember to publish your changes'
          }
        } else if (saveMode === 'publish') {
          toastTitle = 'Page published!'
          toastDescription = 'Your page has been successfully published'
        }
        showToast(notification, 'success', toastTitle, toastDescription)
        refreshPageData()
      }
    } catch (errors) {
      showToast(notification, 'error', 'Error saving the page', 'Please try again')
      setLoading(false)
      throw errors
    }
  }
}

export default App
