import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Link } from 'react-router-dom'

import * as Routes from '../../routes'
import { SectionHeading, standardHeaders } from '../entries/utils'

const Created = ({ created_at }) => moment(created_at).fromNow()

const Name = (rowData) => {
  return <Link to={Routes.webhook_recipient_path(rowData.id)}>{rowData.name}</Link>
}

const WebhookRecipients = () => {
  let { data, isLoading } = useQuery({
    queryKey: ['webhookRecipients'],
    queryFn: () =>
      fetch(Routes.webhook_recipients_path(), { headers: standardHeaders }).then((res) =>
        res.json()
      ),
  })

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <div className="container-fluid py-3">
      <SectionHeading title="Webhook Recipients">
        <a href={Routes.new_webhook_recipient_path()} className="btn btn-outline-primary">
          New Webhook Recipient
        </a>
      </SectionHeading>
      <p>
        Webhook recipients are the endpoints that will receive the inventory webhook notifications.
      </p>
      <div className="box">
        <DataTable value={data}>
          <Column field="id" header="ID" />
          <Column field="name" header="Name" body={Name} />
          <Column field="basic_username" header="Basic Username" />
          <Column field="basic_password" header="Basic Password" />
          <Column field="bearer_token" header="Bearer Token" />
          <Column field="api_key" header="API key" />
          <Column field="created_at" header="Created" body={Created} />
          <Column
            body={(rowData) => <a href={Routes.edit_webhook_recipient_path(rowData.id)}>Edit</a>}
          />
          <Column
            body={(rowData) => (
              <a href={Routes.webhook_recipient_path(rowData.id)} data-method="delete">
                Destroy
              </a>
            )}
          />
        </DataTable>
      </div>
    </div>
  )
}

export default WebhookRecipients
