import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import * as Routes from '../../../routes'
import Loading from '../../Loading'
import { standardHeaders } from '../../entries/utils'
import { ErrorScreen } from '../../shared/Error'
import { GarageSettingsForm } from './GarageSettingsForm'
import type { GarageSettingsData } from './garageSettingsFormInputs'

const GarageSettings = () => {
  const { websiteSlug } = useParams()

  const { data, isLoading, error } = useQuery<GarageSettingsData>({
    queryKey: ['garageSettings', websiteSlug],
    queryFn: () =>
      fetch(Routes.garage_settings_website_path(websiteSlug), {
        method: 'GET',
        headers: standardHeaders,
      })
        .then((response) => response.json())
        .catch((error) => {
          throw new Error('Error loading garage settings data: ' + error.message)
        }),
  })

  if (isLoading) {
    return (
      <div className="p-3">
        <h4>Garage Settings</h4>
        <div className="box p-3">
          <Loading />
        </div>
      </div>
    )
  }

  if (error) {
    return <ErrorScreen heading="Error loading garage settings" />
  }

  return (
    <div className="p-3">
      <h4>Garage Settings</h4>
      <div className="box p-3">
        <GarageSettingsForm data={data} />
      </div>
    </div>
  )
}

export default GarageSettings
