import React, { useEffect, useState } from 'react'

import { time_ago } from '../entries/utils'

function TickingTimestamp({ label, date, interval }) {
  const [currentDate, setCurrentDate] = useState(new Date())
  if (!label) {
    label = 'Last updated'
  }

  if (!interval) {
    interval = 5000 // 5s
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date())
    }, interval)

    return () => clearInterval(intervalId)
  }, [])

  return (
    <div className="timestamps text-center p-1 text-muted" style={{ fontSize: 12 }}>
      {label}: {time_ago(date)}
    </div>
  )
}

export default TickingTimestamp
