import { useContext } from 'react'

import { LeadClusterContext } from '../contexts'
import Tags from './Tags'

const TagBadges = () => {
  const { leadCluster, setLeadCluster } = useContext(LeadClusterContext)

  return (
    <>
      {leadCluster.tags && leadCluster.tags.length > 0 && (
        <>
          {leadCluster.tags.map((tag) => (
            <div className="badge badge-secondary mr-1" key={`${leadCluster.id}-${tag}`}>
              {tag}
            </div>
          ))}
        </>
      )}
      <Tags
        leadCluster={leadCluster}
        setLeadCluster={setLeadCluster}
        renderButton={(setVisible) => (
          <div
            className="badge badge-secondary"
            onClick={() => setVisible(true)}
            style={{ cursor: 'pointer' }}
          >
            <i className="fa fa-plus" /> tag
          </div>
        )}
      />
    </>
  )
}

export default TagBadges
