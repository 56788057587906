import { useContext, useEffect, useState } from 'react'

import { Checkbox } from 'primereact/checkbox'
import { Dialog } from 'primereact/dialog'
import { useHits } from 'react-instantsearch'
import { useParams } from 'react-router-dom'
import Select from 'react-select'

import { SearchContext } from '../contexts'
import { standardHeaders } from '../entries/utils'
import showToast from '../shared/ShowToast'
import { canManageCars } from './utils'

export const SelectedHitsWrapper = ({ children }) => {
  const [selectedHits, setSelectedHits] = useState([])

  // Add in UI notifcation to let users know the job is queued
  function handleSelectHit(carId) {
    setSelectedHits((prevSelectedHits) => {
      if (prevSelectedHits.includes(carId)) {
        return prevSelectedHits.filter((id) => id !== carId)
      } else {
        return [...prevSelectedHits, carId]
      }
    })
  }

  return (
    <SearchContext.Provider value={{ handleSelectHit, selectedHits, setSelectedHits }}>
      {children}
    </SearchContext.Provider>
  )
}

let selectStyle = {
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
}

export const SelectAllHits = () => {
  let [checked, setChecked] = useState(false)
  let { setSelectedHits } = useContext(SearchContext)
  let { items } = useHits()

  const handleCheckboxChange = (e) => {
    setChecked(e.target.checked)
    if (e.target.checked) {
      setSelectedHits(items.map((hit) => hit.id))
    } else {
      setSelectedHits([])
    }
  }

  return (
    <div className="mt-2">
      <Checkbox
        inputId="select-all-hits"
        value="Select All"
        onChange={handleCheckboxChange}
        checked={checked}
      />
      <label htmlFor="select-all-hits" className="ml-2 mb-0">
        Select All ({items.length})
      </label>
    </div>
  )
}

const TagMultiple = ({ selectedHits, setRecentlyUpdatedCars }) => {
  let [visible, setVisible] = useState(false)
  let [availableTags, setAvailableTags] = useState([])
  let [tag, setTag] = useState(null)
  let [loading, setLoading] = useState(false)
  let { dealershipSlug } = useParams()
  let [loadingSubmit, setLoadingSubmit] = useState(false)
  let [action, setAction] = useState(null) // Track the action type

  useEffect(() => {
    if (visible && action === 'tag') {
      setLoading(true)
      fetch(`/api/v1/dealerships/${dealershipSlug}/tags.json`)
        .then((res) => res.json())
        .then((res) => {
          setAvailableTags(res.tags)
          setLoading(false)
        })
    }
  }, [visible, action])

  const Submit = () => {
    setLoadingSubmit(true)

    let requestBody = { car_ids: selectedHits, attribute: action }

    if (action === 'tag') {
      requestBody.tag = tag
    }

    fetch(`/dealerships/${dealershipSlug}/cars/update_multiple`, {
      method: 'PUT',
      headers: standardHeaders,
      body: JSON.stringify(requestBody),
    })
      .then((res) => res.json())
      .then((res) => {
        const carMap = new Map(res.cars.map((c) => [c.id, c.tag]))

        setRecentlyUpdatedCars((prevCars) =>
          prevCars.map((car) => (carMap.has(car.id) ? { ...car, tag: carMap.get(car.id) } : car))
        )
        setLoadingSubmit(false)
        setVisible(false)
      })
  }

  return (
    <>
      <Dialog
        header={action === 'tag' ? 'Tag Multiple Cars' : 'Ignore Third Party Restrictions'}
        visible={visible}
        style={{ width: '50vw' }}
        onHide={() => setVisible(false)}
        dismissableMask
      >
        <p className="font-weight-bold">Updating: {selectedHits.length} cars</p>

        {action === 'tag' && (
          <>
            {loading ? (
              'Loading tags...'
            ) : (
              <Select
                options={availableTags?.map((o) => ({ value: o, label: o || 'No Tag' }))}
                onChange={(e) => setTag(e.value)}
                isClearable={true}
                placeholder="Select a tag..."
                styles={selectStyle}
                menuPortalTarget={document.body}
              />
            )}
          </>
        )}

        <button
          onClick={Submit}
          className={`btn btn-outline-${action === 'tag' ? 'success' : 'warning'} btn-block mt-2 ${loadingSubmit ? 'disabled' : ''}`}
          disabled={loadingSubmit}
        >
          {loadingSubmit ? (
            <>
              <i className="fa fa-circle-notch fa-spin mr-2" />
              {action === 'tag' ? 'Tagging...' : 'Ignoring...'}
            </>
          ) : action === 'tag' ? (
            'Tag Cars'
          ) : (
            'Ignore Restrictions'
          )}
        </button>
      </Dialog>

      {/* Dropdown Buttons */}
      <button
        className="dropdown-item"
        onClick={() => {
          setVisible(true)
          setAction('tag')
        }}
      >
        Tag Multiple
      </button>
      <button
        className="dropdown-item"
        onClick={() => {
          setVisible(true)
          setAction('ignore_manufacturer_restriction')
        }}
      >
        Ignore Third Party Restrictions
      </button>
    </>
  )
}

export const MultiSelectDropdown = ({ notification, setRecentlyUpdatedCars }) => {
  let { selectedHits } = useContext(SearchContext)

  function printSelectedHits(selectedHits) {
    if (selectedHits.length === 0) {
      showToast(
        notification,
        'warning',
        'No documents have been selected',
        'Please select documents to export.'
      )
      return
    }
    fetch('/cars/export_pdfs', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ car_ids: selectedHits }),
    })
      .then((response) => response.json())
      .then((data) => {
        showToast(
          notification,
          'success',
          'Documents are being exported',
          'You will receive an email when they are ready.'
        )
      })
      .catch((error) => {
        console.error(error)
        showToast(notification, 'error', 'Error exporting documents', 'Please contact support.')
      })
  }

  return (
    <div className="dropdown ml-2">
      <button
        className={`btn btn-info dropdown-toggle ${selectedHits.length === 0 ? 'disabled' : ''}`}
        disabled={selectedHits.length === 0}
        data-toggle="dropdown"
      >
        {selectedHits.length} Cars
      </button>
      <div className="dropdown-menu dropdown-menu-right">
        <button className="dropdown-item" onClick={() => printSelectedHits(selectedHits)}>
          Print Selected Hits
        </button>
        {canManageCars() && (
          <TagMultiple
            selectedHits={selectedHits}
            setRecentlyUpdatedCars={setRecentlyUpdatedCars}
          />
        )}
      </div>
    </div>
  )
}
