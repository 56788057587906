import React, { useEffect, useState } from 'react'

import { ProgressSpinner } from 'primereact/progressspinner'
import { Steps } from 'primereact/steps'

import * as Routes from '../../routes'

const PhoneNumberForm = ({
  dealership,
  phoneNumber,
  currentUser,
  providers,
  sources,
  numberTypes,
  onSave,
}) => {
  const [formData, setFormData] = useState({
    id: '',
    number: '',
    provider: 'avanser',
    redirectTo: '',
    locationId: '',
    source: '',
    enableLead: false,
    enableTranscript: false,
    enableSms: false,
    exportPhoneCallData: false,
    numberType: '',
  })
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(1)
  const [availableNumbers, setAvailableNumbers] = useState([])

  useEffect(() => {
    if (phoneNumber) {
      setFormData({
        id: phoneNumber?.id || '',
        number: phoneNumber?.number || '',
        provider: phoneNumber?.provider || '',
        redirectTo: phoneNumber?.redirect_to || '',
        locationId: phoneNumber?.location_id || '',
        source: phoneNumber?.source || '',
        enableLead: phoneNumber?.enable_lead || false,
        enableTranscript: phoneNumber?.enable_transcript || false,
        enableSms: phoneNumber?.enable_sms || false,
        exportPhoneCallData: phoneNumber?.export_phone_call_data || false,
        numberType: phoneNumber?.number_type || 'MOBILE',
      })
    }
  }, [phoneNumber])

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    })
    if (name === 'numberType') {
      setAvailableNumbers([]) // Reset availableNumbers when numberType changes
    }
  }

  const handleSearchNumber = async () => {
    if (formData.numberType === '') {
      alert('Please select a number type')
      return
    }
    if (formData.numberType == 'GEO' && formData.locationId === '') {
      alert('Please select a location')
      return
    }
    setLoading(true)
    getAvailableNumbers(formData.numberType).then((data) => {
      if (data.available_phone_numbers.length > 0) {
        setLoading(false)
        setAvailableNumbers(data.available_phone_numbers)
      } else {
        alert('No available numbers found')
        setLoading(false)
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      await onSave(formData)
    } catch (error) {
      console.error('Error saving form data:', error)
      alert('There was an error saving the form.')
    }
    setLoading(false)
  }

  // get Available Numbers from Avanser
  const getAvailableNumbers = async (type) => {
    if (type === 'GEO' && formData.locationId === '') {
      alert('Please select a location')
      return
    }
    const url = `${Routes.available_numbers_dealership_phone_numbers_path(dealership.id)}?number_type=${type}`
    setLoading(true)
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute('content'),
      },
    })
    if (!response.ok) {
      throw new Error('Failed to fetch available numbers')
    } else {
      return response.json()
    }
  }

  const getNumberTypeLabel = (type) => {
    const numberTypeLabels = {
      GEO: 'Landline',
      MOBILE: 'Mobile',
      TOLLFREE: '1300',
    }

    return numberTypeLabels[type] || null // Return null for types not in the list
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        {currentUser?.admin && formData.id == '' && (
          <Steps
            model={[{ label: 'Search Number' }, { label: 'Fill Details' }]}
            activeIndex={step - 1}
          />
        )}
        {formData.id !== '' ? ( // Show all fields if admin and id is not empty
          <>
            <div className="form-group">
              <label htmlFor="number">Number</label>
              <input
                type="text"
                id="number"
                name="number"
                value={formData.number}
                onChange={handleChange}
                placeholder="Must start with +61"
                className="form-control"
                disabled={formData.provider === 'avanser'}
              />
            </div>
            <div className="form-group">
              <label htmlFor="redirectTo">Redirect To</label>
              <input
                type="text"
                id="redirectTo"
                name="redirectTo"
                value={formData.redirectTo}
                onChange={handleChange}
                placeholder="Must start with +61"
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label htmlFor="locationId">Location</label>
              <select
                id="locationId"
                name="locationId"
                value={formData.locationId}
                onChange={handleChange}
                className="form-control"
              >
                {formData.locationId === '' && (
                  <option value="" disabled>
                    Select a location
                  </option>
                )}

                {dealership.locations
                  .filter((location) => !['service', 'parts'].includes(location.locationType))
                  .map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="source">Source</label>
              <select
                id="source"
                name="source"
                value={formData.source}
                onChange={handleChange}
                className="form-control"
              >
                {sources.map((source) => (
                  <option key={source} value={source}>
                    {source}
                  </option>
                ))}
              </select>
              <small className="form-text text-muted">
                This will be used as the lead source when leads are generated from phone calls
              </small>
            </div>
            <div className="form-group form-check">
              <input
                type="checkbox"
                id="enableLead"
                name="enableLead"
                checked={formData.enableLead}
                onChange={handleChange}
                className="form-check-input"
              />
              <label htmlFor="enableLead" className="form-check-label">
                Enable Lead
              </label>
              <small className="form-text text-muted">
                If checked, Phone calls to this phone number will be used for lead generation
              </small>
            </div>
            <div className="form-group form-check">
              <input
                type="checkbox"
                id="enableTranscript"
                name="enableTranscript"
                checked={formData.enableTranscript}
                onChange={handleChange}
                className="form-check-input"
              />
              <label htmlFor="enableTranscript" className="form-check-label">
                Enable Transcript
              </label>
              <small className="form-text text-muted">
                If checked, Phone calls to this phone number will be transcribed
              </small>
            </div>
            <div className="form-group form-check">
              <input
                type="checkbox"
                id="enableSms"
                name="enableSms"
                checked={formData.enableSms}
                onChange={handleChange}
                className="form-check-input"
              />
              <label htmlFor="enableSms" className="form-check-label">
                Enable SMS
              </label>
              <small className="form-text text-muted">
                If checked, SMS to this phone number will be enabled
              </small>
            </div>
            <div className="form-group form-check">
              <input
                type="checkbox"
                id="exportPhoneCallData"
                name="exportPhoneCallData"
                checked={formData.exportPhoneCallData}
                onChange={handleChange}
                className="form-check-input"
              />
              <label htmlFor="exportPhoneCallData" className="form-check-label">
                Export Phone Call Data
              </label>
              <small className="form-text text-muted">
                If checked, Phone calls to this phone number will be exported to Total Selling
                solutions
              </small>
            </div>
          </>
        ) : step === 1 ? ( // Step 1: Search Number
          <>
            <div className="form-group">
              <label htmlFor="numberType">Number Type</label>
              <select
                id="numberType"
                name="numberType"
                value={formData.numberType}
                onChange={handleChange}
                className="form-control"
              >
                {formData.numberType === '' && (
                  <option value="" disabled>
                    Select a number type
                  </option>
                )}
                {numberTypes.map((numberType) => (
                  <option key={numberType} value={numberType}>
                    {getNumberTypeLabel(numberType)}
                  </option>
                ))}
              </select>
            </div>
            {formData.numberType == 'GEO' && (
              <div className="form-group">
                <label htmlFor="locationId">Location</label>
                <select
                  id="locationId"
                  name="locationId"
                  value={formData.locationId}
                  onChange={handleChange}
                  className="form-control"
                >
                  {formData.locationId === '' && (
                    <option value="" disabled>
                      Select a location
                    </option>
                  )}
                  {dealership.locations
                    .filter((location) => !['service', 'parts'].includes(location.locationType))
                    .map((location) => (
                      <option key={location.id} value={location.id}>
                        {location.name}
                      </option>
                    ))}
                </select>
              </div>
            )}
            {availableNumbers.length > 0 && (
              <div className="form-group">
                <label htmlFor="availableNumbers">Available Numbers</label>
                <select
                  id="availableNumbers"
                  name="availableNumbers"
                  value={formData.number || ''}
                  onChange={(e) => {
                    if (e.target.value) {
                      setFormData({ ...formData, number: e.target.value })
                      setStep(2)
                    }
                  }}
                  className="form-control"
                >
                  <option value="" disabled>
                    Select an available number
                  </option>
                  {availableNumbers.map((number) => (
                    <option key={number} value={number}>
                      {number}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div className="d-flex justify-content-between align-items-center">
              <button
                type="button"
                onClick={handleSearchNumber}
                className="btn btn-outline-primary"
              >
                Search Number
              </button>
              {loading && ( // Show spinner when loading
                <ProgressSpinner style={{ width: '50px', height: '50px' }} />
              )}
            </div>
          </>
        ) : (
          // Step 2: Fill Form
          <>
            <div className="form-group">
              <label htmlFor="number">Number</label>
              <input
                type="text"
                id="number"
                name="number"
                value={formData.number}
                onChange={handleChange}
                placeholder="Must start with +61"
                className="form-control"
                disabled={formData.provider === 'avanser'}
              />
              <small className="form-text text-success">
                <i className="pi pi-info-circle mr-2"></i>
                New phone numbers can take up to 15 minutes to provision and become active.
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="locationId">Location</label>
              <select
                id="locationId"
                name="locationId"
                value={formData.locationId}
                onChange={handleChange}
                className="form-control"
              >
                {formData.locationId === '' && (
                  <option value="" disabled>
                    Select a location
                  </option>
                )}
                {dealership.locations
                  .filter((location) => !['service', 'parts'].includes(location.locationType))
                  .map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="redirectTo">Redirect To</label>
              <input
                type="text"
                id="redirectTo"
                name="redirectTo"
                value={formData.redirectTo}
                onChange={handleChange}
                placeholder="Must start with +61"
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label htmlFor="source">Source</label>
              <select
                id="source"
                name="source"
                value={formData.source}
                onChange={handleChange}
                className="form-control"
              >
                {sources.map((source) => (
                  <option key={source} value={source}>
                    {source}
                  </option>
                ))}
              </select>
              <small className="form-text text-muted">
                This will be used as the lead source when leads are generated from phone calls
              </small>
            </div>
            <div className="form-group form-check">
              <input
                type="checkbox"
                id="enableLead"
                name="enableLead"
                checked={formData.enableLead}
                onChange={handleChange}
                className="form-check-input"
              />
              <label htmlFor="enableLead" className="form-check-label">
                Enable Lead
              </label>
              <small className="form-text text-muted">
                If checked, Phone calls to this phone number will be used for lead generation
              </small>
            </div>
            <div className="form-group form-check">
              <input
                type="checkbox"
                id="enableTranscript"
                name="enableTranscript"
                checked={formData.enableTranscript}
                onChange={handleChange}
                className="form-check-input"
              />
              <label htmlFor="enableTranscript" className="form-check-label">
                Enable Transcript
              </label>
              <small className="form-text text-muted">
                If checked, Phone calls to this phone number will be transcribed
              </small>
            </div>
            <div className="form-group form-check">
              <input
                type="checkbox"
                id="enableSms"
                name="enableSms"
                checked={formData.enableSms}
                onChange={handleChange}
                className="form-check-input"
              />
              <label htmlFor="enableSms" className="form-check-label">
                Enable SMS
              </label>
              <small className="form-text text-muted">
                If checked, SMS to this phone number will be enabled
              </small>
            </div>
            <div className="form-group form-check">
              <input
                type="checkbox"
                id="exportPhoneCallData"
                name="exportPhoneCallData"
                checked={formData.exportPhoneCallData}
                onChange={handleChange}
                className="form-check-input"
              />
              <label htmlFor="exportPhoneCallData" className="form-check-label">
                Export Phone Call Data
              </label>
              <small className="form-text text-muted">
                If checked, Phone calls to this phone number will be exported to Total Selling
                solutions
              </small>
            </div>
          </>
        )}
        {formData.id !== '' || step === 2 ? ( // Show submit button only in edit or step 2
          <button type="submit" className="btn btn-outline-primary" disabled={loading}>
            {loading ? 'Submitting...' : 'Submit'}
          </button>
        ) : null}
      </form>
    </>
  )
}

export default PhoneNumberForm
