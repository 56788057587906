import { useRef, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { SplitButton } from 'primereact/splitbutton'
import { Toast } from 'primereact/toast'
import { useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { standardHeaders } from '../entries/utils'
import showToast from '../shared/ShowToast'
import SlideIn from '../shared/SlideIn'

let CreatedAt = (rowData) => {
  return <div>{moment(rowData.created_at).fromNow()}</div>
}

const fetchReport = async (queryParams, id) => {
  let { dealershipSlug, dealershipGroupId } = queryParams

  let url
  if (dealershipSlug) {
    url = Routes.dealership_notification_path(dealershipSlug, id)
  }
  if (dealershipGroupId) {
    url = Routes.dealership_group_notification_path(dealershipGroupId, id)
  }

  let res = await fetch(url, {
    headers: standardHeaders,
  })
  return res.json()
}

const Status = (rowData) => {
  if (rowData.status === 'sent') {
    return <span className="badge badge-success">Sent</span>
  } else {
    return <span className="badge">{rowData}</span>
  }
}

const ReportCreatedAt = (rowData) => {
  return (
    <>
      <div>{moment(rowData.created_at).fromNow()}</div>
      <small className="text-secondary">
        {moment(rowData.created_at).format('DD/MM/YYYY hh:mm A')}
      </small>
    </>
  )
}

const Emails = (rowData) => {
  return (
    <div>
      {rowData.emails.map((email) => (
        <div key={email} className="badge badge-secondary badge-sm mr-2">
          {email}
        </div>
      ))}
    </div>
  )
}

const ShowReport = ({ visible, setVisible, rowData }) => {
  let queryParams = useParams()

  let { data, isLoading } = useQuery({
    queryKey: ['report', rowData.id],
    enabled: visible,
    queryFn: () => fetchReport(queryParams, rowData.id),
  })

  return (
    <SlideIn isOpen={visible} setIsOpen={setVisible} header="Report History">
      {isLoading && <div>Loading...</div>}
      <div className="box my-4">
        <DataTable value={data?.dealer_emails} loading={isLoading}>
          <Column field="created_at" header="Created" body={ReportCreatedAt} />
          <Column field="emails" header="Emails" body={Emails} />
          <Column field="status" header="Status" body={Status} />
        </DataTable>
      </div>
    </SlideIn>
  )
}

const Actions = ({ rowData, refetch }) => {
  let [visible, setVisible] = useState(false)
  let { dealershipSlug, dealershipGroupId } = useParams()
  const notification = useRef(null)

  const sendNow = async ({ currentUser } = { currentUser: false }) => {
    currentUser = currentUser ?? false // Ensure it's always defined

    let url
    if (dealershipSlug) {
      url = Routes.send_now_dealership_notification_path(dealershipSlug, rowData.id, {
        current_user: currentUser,
      })
    } else {
      url = Routes.send_now_dealership_group_notification_path(dealershipGroupId, rowData.id, {
        current_user: currentUser,
      })
    }

    await fetch(url, {
      method: 'PUT',
      headers: standardHeaders,
    })
      .then((response) => {
        if (response.ok) {
          showToast(notification, 'success', 'Notification sent successfully', '')
        } else {
          showToast(notification, 'error', 'Failed to send notification', '')
        }
      })
      .catch((error) => {
        showToast(notification, 'error', 'Failed to send notification', '')
      })
  }

  let destroyUrl
  if (dealershipSlug) {
    destroyUrl = Routes.dealership_notification_path(dealershipSlug, rowData.id)
  }
  if (dealershipGroupId) {
    destroyUrl = Routes.dealership_group_notification_path(dealershipGroupId, rowData.id)
  }

  const items = [
    {
      label: 'Edit',
      icon: 'pi pi-edit',
      command: () => (window.location.href = editUrl),
    },
    {
      label: 'Send Now',
      icon: 'pi pi-send',
      command: () => sendNow(),
    },
    {
      label: 'Send Now to self',
      icon: 'pi pi-send',
      command: () => sendNow({ currentUser: true }),
    },
    {
      label: 'Delete',
      icon: 'pi pi-trash',
      command: () => {
        fetch(destroyUrl, {
          method: 'DELETE',
          headers: standardHeaders,
        })
          .then((response) => {
            if (response.ok) {
              showToast(notification, 'success', 'Notification deleted successfully', '')
              refetch()
            } else {
              showToast(notification, 'error', 'Failed to delete notification', '')
            }
          })
          .catch((error) => {
            showToast(notification, 'error', 'Failed to delete notification', '')
          })
      },
    },
  ]

  let editUrl
  if (dealershipSlug) {
    editUrl = Routes.edit_dealership_notification_path(dealershipSlug, rowData.id)
  } else if (dealershipGroupId) {
    editUrl = Routes.edit_dealership_group_notification_path(dealershipGroupId, rowData.id)
  }

  return (
    <>
      <Toast ref={notification} />
      <SplitButton
        label="View"
        model={items}
        onClick={(e) => {
          setVisible(true)
        }}
        size="small"
        className="p-button-outlined"
      />
      <ShowReport visible={visible} setVisible={setVisible} rowData={rowData} />
    </>
  )
}

const Frequency = (rowData) => {
  return (
    <div>
      <span>
        {rowData.frequency} at {rowData.send_time}
      </span>
    </div>
  )
}

const fetchReports = async (dealershipSlug, manufacturerSlug, dealershipGroupId) => {
  let url
  if (dealershipSlug) {
    url = Routes.reports_dealership_notifications_path(dealershipSlug)
    // } else if (manufacturerSlug) {
    //   url = Routes.reports_manufacturer_manufacturer_path(manufacturerSlug)
  } else if (dealershipGroupId) {
    url = Routes.reports_dealership_group_notifications_path(dealershipGroupId)
  }

  let res = await fetch(url, {
    headers: standardHeaders,
  })
  return res.json()
}

const Reports = () => {
  let { dealershipSlug, manufacturerSlug, dealershipGroupId } = useParams()

  let { data, isLoading, refetch } = useQuery({
    queryKey: ['reports', dealershipSlug, manufacturerSlug, dealershipGroupId],
    queryFn: () => fetchReports(dealershipSlug, manufacturerSlug, dealershipGroupId),
  })

  let new_path
  if (dealershipSlug) {
    new_path = Routes.new_dealership_notification_path(dealershipSlug)
  } else if (manufacturerSlug) {
    new_path = Routes.new_manufacturer_notification_path(manufacturerSlug)
  } else if (dealershipGroupId) {
    new_path = Routes.new_dealership_group_notification_path(dealershipGroupId)
  }

  return (
    <div className="p-3">
      <div className="d-flex mb-2">
        <h3>Reports</h3>
        <div className="ml-auto">
          <a className="btn btn-outline-primary" href={new_path}>
            <i className="fa fa-plus mr-2" />
            Email Report
          </a>
        </div>
      </div>
      <div className="box">
        <DataTable value={data} className="p-datatable-sm" loading={isLoading}>
          <Column field="id" header="ID" />
          <Column
            header="Users"
            body={(rowData) => rowData.users.map((user) => user.name).join(', ')}
          />
          <Column field="name" header="Name" sortable />
          <Column field="frequency" header="Frequency" sortable body={Frequency} />
          {dealershipSlug && (
            <Column
              header="Locations"
              body={(rowData) => rowData.locations.map((location) => location.name).join(', ')}
            />
          )}
          <Column field="created_at" header="Created At" body={CreatedAt} sortable />
          <Column
            field="actions"
            header="Actions"
            body={(rowData) => <Actions rowData={rowData} refetch={refetch} />}
          />
        </DataTable>
      </div>
    </div>
  )
}

export default Reports
