import { useQuery } from '@tanstack/react-query'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { SectionHeading, standardHeaders } from '../entries/utils'

const Locations = (rowData) => {
  return (
    <>
      {rowData.locations.map((location) => (
        <div key={location.id} className="badge badge-secondary badge-sm mr-1">
          <div>{location.name}</div>
        </div>
      ))}
    </>
  )
}

const GmbProfiles = () => {
  let { dealershipSlug } = useParams()

  let { data, isLoading } = useQuery({
    queryKey: ['gmb_profiles'],
    queryFn: () =>
      fetch(Routes.dealership_gmb_profiles_path(dealershipSlug), { headers: standardHeaders }).then(
        (res) => res.json()
      ),
  })

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <div>
      <SectionHeading title="Google My Business Profiles" />
      <div className="box">
        <DataTable value={data}>
          <Column field="name" header="Name" sortable />
          <Column field="review_url" header="Review URL" />
          <Column field="reviews_count" header="Count" sortable />
          <Column field="locations" header="Locations" body={Locations} />
          <Column
            body={(rowData) => (
              <a href={Routes.edit_dealership_gmb_profile_path(dealershipSlug, rowData.id)}>Edit</a>
            )}
          />
          <Column
            body={(rowData) => (
              <a
                href={Routes.dealership_gmb_profile_path(dealershipSlug, rowData.id)}
                data-method="delete"
              >
                Destroy
              </a>
            )}
          />
        </DataTable>
      </div>
    </div>
  )
}

export default GmbProfiles
