import React from 'react'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { useFormContext } from 'react-hook-form'

import { standardHeaders } from '../../entries/utils'
import { Form, FormInput, FormSwitch, FormTextarea, handleResponse } from '../../formUtils'
import type { SearchPageTemplate } from './SearchPagesTable'
import { searchPageTemplateShortcodes, shortcodeDescriptions } from './searchPageTemplateShortcodes'

interface SearchPagesFormProps {
  template: SearchPageTemplate
  website: any
  onClose: () => void
  notification: React.RefObject<any>
}

const CustomHeadingInputs = () => {
  const { watch } = useFormContext()
  const enabled = watch('use_custom_heading')
  return (
    <>
      <div className="form-row">
        <div className="col-md-12 mb-3">
          <FormSwitch name="use_custom_heading" label="Use a custom page heading" />
        </div>
      </div>
      {enabled && (
        <div className="form-row">
          <div className="col-md-12 mb-3">
            <FormTextarea name="heading_template" label="Heading Template" />
          </div>
        </div>
      )}
    </>
  )
}
const SearchPagesForm: React.FC<SearchPagesFormProps> = ({
  template,
  website,
  onClose,
  notification,
}) => {
  const queryClient = useQueryClient()
  const { mutate, isPending } = useMutation({
    mutationFn: async (data: SearchPageTemplate) => {
      // Determine if this is a new template or an existing one
      // If it has an id property, it's an existing template
      const isExisting = 'id' in template && template.id

      const url = isExisting
        ? `/websites/${website.id}/search_page_templates/${template.id}`
        : `/websites/${website.id}/search_page_templates`

      const method = isExisting ? 'PUT' : 'POST'

      const response = await fetch(url, {
        method,
        headers: standardHeaders,
        body: JSON.stringify({ search_page_template: data }),
      })

      await handleResponse(response)
    },
    onSuccess: () => {
      notification.current?.show({
        severity: 'success',
        summary: 'Saved',
        detail: 'Template has been saved successfully',
        life: 5000,
      })
      queryClient.invalidateQueries({ queryKey: ['searchPageTemplates', website.slug] })
      onClose()
    },
    onError: (error) => {
      notification.current?.show({
        severity: 'error',
        summary: 'Failed to save template',
        detail: error.message,
      })
      onClose()
    },
  })

  const validateShortcodes = (value: string) => {
    const matches = value.match(/\[([^\]]+)\]/g) || []

    // Check if all extracted shortcodes are in the allowed list
    const invalidShortcodes = matches.filter(
      // @ts-ignore TS is attempting to assert the key, but this is unnecessary
      (match) => !searchPageTemplateShortcodes.includes(match)
    )

    // If there are invalid shortcodes, return an error message
    if (invalidShortcodes.length > 0) {
      return `Invalid shortcode(s): ${invalidShortcodes.join(', ')}. Please use only the allowed shortcodes.`
    }

    return true
  }

  const handleSubmit = async (data: SearchPageTemplate) => {
    mutate(data)
  }

  return (
    <div className="search-pages-form">
      <Form onSubmit={handleSubmit} defaultValues={template}>
        <div className="form-row">
          <div className="col-md-12 mb-3">
            <FormInput
              name="title_template"
              label="Title Template"
              required
              rules={{
                required: 'Title template is required',
                maxLength: {
                  value: 100,
                  message: 'Title should be less than 100 characters for SEO',
                },
                validate: validateShortcodes,
              }}
            />
            <small className="form-text text-muted">
              Recommended length: 50-60 characters. Use variables like [count], [make], etc.
            </small>
          </div>
        </div>

        <div className="form-row">
          <div className="col-md-12 mb-3">
            <FormTextarea
              name="description_template"
              label="Description Template"
              required
              rules={{
                required: 'Description template is required',
                maxLength: {
                  value: 320,
                  message: 'Description should be less than 320 characters for SEO',
                },
                validate: validateShortcodes,
              }}
              rows={4}
            />
            <small className="form-text text-muted">
              Recommended length: 150-160 characters. Use variables like [count], [make], etc.
            </small>
          </div>
        </div>

        <CustomHeadingInputs />

        <div className="form-row">
          <div className="col-md-12 mb-3">
            <FormSwitch name="enabled" label="Enable this search page template" />
          </div>
        </div>

        <div className="form-row mt-4">
          <div className="col-md-12">
            <Accordion>
              <AccordionTab header="View All Available Template Variables">
                <div className="row">
                  <ul>
                    {Object.entries(shortcodeDescriptions).map(([shortcode, description]) => (
                      <li key={shortcode}>
                        <code>{shortcode}</code> - {description}
                      </li>
                    ))}
                  </ul>
                </div>
              </AccordionTab>
            </Accordion>
          </div>
        </div>

        <div className="form-row mt-4">
          <div className="col-md-12 d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
              disabled={isPending}
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-primary" disabled={isPending}>
              {isPending ? 'Saving...' : 'Save Template'}
            </button>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default SearchPagesForm
