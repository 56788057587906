import { useQuery } from '@tanstack/react-query'

const fetchLeadCluster = async ({ dealershipSlug, clusterId }) => {
  if (!dealershipSlug || !clusterId) throw new Error('Missing parameters')

  const response = await fetch(`/dealerships/${dealershipSlug}/lead_clusters/${clusterId}.json`)
  if (!response.ok) {
    if (response.status === 404) throw new Error('Not Found')
    throw new Error('An error occurred')
  }

  // Ensure the response is valid JSON
  const data = await response.json()
  if (!data || typeof data !== 'object') throw new Error('Invalid API response')

  return data
}

export const useFetchLeadCluster = (dealershipSlug, clusterId) => {
  const {
    data = {},
    error,
    isLoading,
  } = useQuery({
    queryKey: ['leadCluster', dealershipSlug, clusterId],
    queryFn: () => fetchLeadCluster({ dealershipSlug, clusterId }),
    enabled: Boolean(dealershipSlug && clusterId), // Only fetch if both are present
    retry: false, // Avoid retrying on 404 errors
  })

  let status = 'loading'
  if (!isLoading) {
    if (error?.message === 'Not Found') {
      status = 'not_found'
    } else if (error) {
      status = 'error'
    } else {
      status = 'completed'
    }
  }

  return { data, status }
}
