import { useEffect, useState } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import * as Routes from '../../routes'
import { FormContext } from '../contexts'
import { Errors } from '../entries/FormElements'
import { standardHeaders } from '../entries/utils'
import HookFormField from '../shared/hookFormField'

let attributes = {
  name: {
    type: 'text',
    label: 'Name',
    required: true,
  },
  position: {
    type: 'text',
    label: 'Position',
  },
  email: {
    type: 'email',
    label: 'Email',
    required: true,
  },
  phone: {
    type: 'phone',
    label: 'phone',
    required: true,
  },
}

const EditMyDetails = ({ isConfirmable, pendingReconfirmation, user, fetchUser }) => {
  let [loading, setLoading] = useState(false)
  let [errors, setErrors] = useState({})
  let [formData, setFormData] = useState({})
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: user?.email || '',
      name: user?.name || '',
      phone: user?.phone || '',
      position: user?.position || '',
      twenty_four_hour_clock: user?.twenty_four_hour_clock || false,
    },
  })

  const onSubmit = (data) => {
    fetch(Routes.user_registration_path(), {
      method: 'PATCH',
      headers: standardHeaders,
      body: JSON.stringify({
        user: data,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((res) => {
            if (response.status === 422) {
              setErrors(res.errors) // Handle validation errors
              setLoading(false)
              return Promise.reject(new Error('Validation error')) // Prevent success flow
            }
            return Promise.reject(new Error('Network response was not ok')) // Handle other errors
          })
        }
        return response.json()
      })
      .then((res) => {
        alert('Details updated successfully')
        fetchUser()
      })
      .catch((error) => {
        console.error('Error:', error)
        setLoading(false)
        alert(error.message) // Show appropriate error message
      })
  }

  const disableTwoFactor = () => {
    fetch(Routes.two_factor_settings_path(), {
      method: 'DELETE',
      headers: standardHeaders,
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(new Error('Network response was not ok')) // Handle other errors
        }
        return response.json()
      })
      .then((res) => {
        alert('Two-factor authentication disabled successfully')
      })
      .catch((error) => {
        console.error('Error:', error)
        alert(error.message) // Show appropriate error message
      })
  }

  return (
    <div className="container py-4">
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-10">
          <div className="card mb-3">
            <div className="card-header">
              <h5 className="mb-0">Edit My Details</h5>
            </div>
            <div className="card-body">
              <FormContext.Provider value={{ control, errors, attributes, formData }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <HookFormField attribute="name" />
                  <HookFormField attribute="email" />
                  <HookFormField attribute="phone" />
                  <HookFormField attribute="position" />

                  <Controller
                    name="twenty_four_hour_clock"
                    control={control}
                    render={({ field }) => (
                      <div className="mb-3 form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          {...field}
                          checked={field.value}
                          id="twenty_four_hour_clock"
                        />
                        <label className="form-check-label" htmlFor="twenty_four_hour_clock">
                          Use 24-hour clock
                        </label>
                      </div>
                    )}
                  />

                  {isConfirmable && pendingReconfirmation && (
                    <div>Currently waiting confirmation for: {user.unconfirmedEmail}</div>
                  )}

                  <Errors errors={errors} />

                  <button type="submit" className="btn btn-secondary mt-3">
                    Update
                  </button>
                </form>
              </FormContext.Provider>
            </div>
          </div>
          <div className="box mb-3">
            <div className="card-header">
              <h5 className="mb-0">Two-Factor Authentication</h5>
            </div>
            <div className="card-body">
              {user?.otp_required_for_login ? (
                <>
                  <p className="text-success">
                    <i className="fa fa-check mr-2"></i>Two-factor authentication is enabled.
                  </p>
                  <p>
                    <a
                      href="#"
                      className="btn btn-danger"
                      onClick={() => {
                        if (
                          confirm('Are you sure you want to disable two-factor authentication?')
                        ) {
                          disableTwoFactor()
                        }
                      }}
                    >
                      Disable Two-Factor Authentication
                    </a>
                  </p>
                </>
              ) : (
                <>
                  <p>Two-factor authentication is NOT enabled.</p>
                  <p>
                    <Link to={Routes.new_two_factor_settings_path()} className="btn btn-primary">
                      Enable Two-Factor Authentication
                    </Link>
                  </p>
                </>
              )}
            </div>
          </div>
          <div className="card mb-3">
            <div className="card-header">
              <h5 className="mb-0">Edit Password</h5>
            </div>
            <div className="card-body">
              <a href={Routes.edit_password_users_path()} className="btn btn-secondary">
                Edit Password
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Wrapper = () => {
  let [user, setUser] = useState(null)
  let [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchUser()
  }, [])

  const fetchUser = () => {
    setLoading(true)
    fetch(Routes.edit_user_registration_path(), {
      headers: standardHeaders,
    })
      .then((response) => response.json())
      .then((data) => {
        setUser(data)
        setLoading(false)
      })
  }

  if (loading) {
    return <div>Loading...</div>
  }

  return <EditMyDetails user={user} fetchUser={fetchUser} />
}

export default Wrapper
