import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

const DateRangeSelector = ({ startDate, endDate, onChange }) => {
  const selectionRange = {
    startDate: startDate || new Date(),
    endDate: endDate || new Date(),
    key: 'selection',
  }

  return (
    <div>
      <DateRangePicker ranges={[selectionRange]} onChange={onChange} />
    </div>
  )
}

export default DateRangeSelector
