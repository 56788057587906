import { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useParams } from 'react-router-dom'
import Select from 'react-select'

import * as Routes from '../../routes'
import { BarChart, PieChart } from '../AnalyticsDashboard/features/Charts/components/Charts'
import { chartColors } from '../AnalyticsDashboard/features/Charts/utils'
import { getInitials, humanize, statusClassNames } from '../entries/utils'
import SlideIn from '../shared/SlideIn'

const AvatarInitials = ({ name }) => {
  let initials = getInitials(name)
  return (
    <div
      className={`AvatarInitials ${initials[0]} mr-2`}
      style={{ height: 25, width: 25, fontSize: 10 }}
    >
      {initials}
    </div>
  )
}

const UserName = ({ rowData }) => {
  let [visible, setVisible] = useState(false)
  let { dealershipSlug } = useParams()
  let [data, setData] = useState([])
  let [loading, setLoading] = useState(false)
  const [onHover, setOnHover] = useState(false)

  useEffect(() => {
    if (visible) {
      setLoading(true)
      fetch(
        Routes.dealership_analytics_blocks_path(dealershipSlug, {
          module: 'lead_cluster_by_status',
          user_id: rowData.id,
        })
      )
        .then((response) => response.json())
        .then((data) => {
          setData(data)
          setLoading(false)
        })
    }
  }, [visible])

  let chartConfig = {
    labels: data.lead_cluster_by_status?.map((l) => l[0]),
    datasets: [
      {
        data: data.lead_cluster_by_status?.map((l) => l[1]),
        backgroundColor: data.lead_cluster_by_status?.map((l, i) => l[3]),
      },
    ],
  }

  return (
    <>
      <div
        className={
          'd-flex align-items-center rounded' + (onHover ? ' bg-light py-1 px-2' : ' py-1 px-2')
        }
        onClick={() => setVisible(!visible)}
        style={{ cursor: 'pointer' }}
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
      >
        <AvatarInitials name={rowData.name} />
        <b>{rowData.name}</b>
      </div>
      <SlideIn isOpen={visible} setIsOpen={setVisible} header={rowData.name}>
        {loading ? (
          <div className="p-3">Loading...</div>
        ) : (
          <div className="p-3">{data && <BarChart chartDataConfig={chartConfig} />}</div>
        )}
      </SlideIn>
    </>
  )
}

const LeadBreakdownTable = ({ data, leadBreakdown, statusClassNames, loading }) => {
  // Generate dynamic columns for lead attributes
  const renderHeader = () => {
    if (data?.users?.length > 0) {
      return Object.keys(data.users[0].leads).map((key) => ({
        field: key,
        header: (
          <div className={`badge badge-${statusClassNames?.[key] || 'secondary'}`}>
            {humanize(key)}
          </div>
        ),
      }))
    }
    return []
  }

  const renderLeadCell = (rowData, key) => {
    const value = rowData.leads?.[key] || 0
    return (
      <>
        {value}
        {value > 0 && key !== 'total' && (
          <small className="text-secondary ml-1">
            {Math.round((value / (rowData.leads?.total || 1)) * 100)}%
          </small>
        )}
      </>
    )
  }

  const renderSourceCell = (source, key) => {
    const value = data?.count_by_source?.[source]?.[key] || 0
    return (
      <>
        {key === 'total' ? <b>{value}</b> : value}
        {value > 0 && !['new', 'total', 'total_active'].includes(key) && (
          <small className="text-secondary ml-1">
            {Math.round((value / (data?.count_by_source?.[source]?.['total_active'] || 1)) * 100)}%
          </small>
        )}
      </>
    )
  }

  return (
    <DataTable
      value={
        leadBreakdown === 'user' ? data?.users || [] : Object.keys(data?.count_by_source || {})
      }
      showGridlines
      loading={loading}
    >
      <Column
        header="Name"
        sortable
        field="name"
        body={(rowData) =>
          leadBreakdown === 'user' ? <UserName rowData={rowData} /> : <b>{rowData || 'Unknown'}</b>
        }
      />
      {renderHeader().map(({ field, header }) => (
        <Column
          key={field}
          field={field}
          header={header}
          sortable
          body={(rowData) =>
            leadBreakdown === 'user'
              ? renderLeadCell(rowData, field)
              : renderSourceCell(rowData, field)
          }
        />
      ))}
    </DataTable>
  )
}

const fetchTeamAnalytics = async (
  dealershipSlug,
  timelineStart,
  timelineEnd,
  timeline,
  locationIds,
  categories
) => {
  if (!dealershipSlug) return null

  let url = `/dealerships/${dealershipSlug}/leads/team_analytics.json?start=${timelineStart}&end=${timelineEnd}&timeline_name=${timeline}`

  if (locationIds.length > 0) {
    url += `&location_ids=${locationIds.join(',')}`
  }

  if (categories.length > 0) {
    url += `&categories=${categories.join(',')}`
  }

  const response = await fetch(url)
  if (!response.ok) throw new Error('Error fetching team analytics')

  return response.json()
}

export const useFetchTeamAnalytics = (
  dealershipSlug,
  timelineStart,
  timelineEnd,
  timeline,
  locationIds,
  categories
) => {
  return useQuery({
    queryKey: [
      'teamAnalytics',
      dealershipSlug,
      timelineStart,
      timelineEnd,
      timeline,
      locationIds,
      categories,
    ], // Cache key
    queryFn: () =>
      fetchTeamAnalytics(
        dealershipSlug,
        timelineStart,
        timelineEnd,
        timeline,
        locationIds,
        categories
      ),
    enabled: !!dealershipSlug, // Only fetch when `dealershipSlug` is available
    staleTime: 5 * 60 * 1000, // Keep data fresh for 5 minutes
    cacheTime: 30 * 60 * 1000, // Cache data for 30 minutes
  })
}

const App = () => {
  const { dealershipSlug } = useParams()

  const [timelineStart, setTimelineStart] = useState()
  const [timelineEnd, setTimelineEnd] = useState()
  const [timeline, setTimeline] = useState()
  const [leadBreakdown, setLeadBreakdown] = useState('user')
  const [locationIds, setLocationIds] = useState([])
  const [categories, setCategories] = useState([])

  const { data, isLoading } = useFetchTeamAnalytics(
    dealershipSlug,
    timelineStart,
    timelineEnd,
    timeline,
    locationIds,
    categories
  )

  const handleTimelineSelection = (option) => {
    setTimelineStart(option.start)
    setTimelineEnd(option.end)
    setTimeline(option.text)
  }

  const chartConfig = {
    labels: data?.source_count?.map((l) => l[0]),
    datasets: [
      {
        data: data?.source_count?.map((l) => l[1]),
        backgroundColor: data?.source_count?.map((l, i) => chartColors[i]),
      },
    ],
  }

  return (
    <div className="p-3">
      <div className="d-flex">
        <h4>Team Analytics</h4>
        <div className="ml-auto d-flex">
          <Select
            options={data?.locations || []}
            placeholder="Select Location"
            className="mr-2"
            isMulti
            onChange={(selectedOptions) => setLocationIds(selectedOptions.map((o) => o.value))}
          />
          <Select
            options={data?.categories || []}
            placeholder="Select Categories"
            isMulti
            onChange={(selectedCategories) => setCategories(selectedCategories.map((o) => o.value))}
          />
          <div className="dropdown">
            <button
              className="btn btn-sm btn-outline-secondary dropdown-toggle ml-2"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {timeline || 'Select Timeline'}
            </button>
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
              {data?.timeline_options?.map((option) => (
                <div
                  key={option.text}
                  className="dropdown-item"
                  onClick={() => handleTimelineSelection(option)}
                >
                  {option.text}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="box p-3 mb-3">
            <h4>Leads by source</h4>
            <div className="d-flex m-3 justify-content-center">
              <div className="position-relative" style={{ height: 280 }}>
                {data?.source_count && <PieChart chartDataConfig={chartConfig} />}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6"></div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="box">
            <div className="p-3">
              <div className="d-flex">
                <div className="mr-auto">
                  <h6>{isLoading ? 'Loading...' : `Leads by ${leadBreakdown}`}</h6>
                  {['user', 'source'].map((lb) => (
                    <div
                      key={lb}
                      className={`btn btn-sm btn-outline-primary mr-1 ${lb === leadBreakdown ? 'active' : ''}`}
                      onClick={() => setLeadBreakdown(lb)}
                    >
                      {humanize(lb)}
                    </div>
                  ))}
                </div>
                <div className="text-secondary ml-auto">
                  {data?.start} - {data?.end}
                </div>
              </div>
            </div>
            <LeadBreakdownTable
              data={data}
              leadBreakdown={leadBreakdown}
              statusClassNames={statusClassNames}
              loading={isLoading}
            />
            <div className="px-3 py-2">
              {leadBreakdown === 'source' && (
                <div className="small text-secondary">
                  ^ Percentage of total active leads (excludes leads in status "New")
                </div>
              )}
              <div className="small text-secondary">* Excludes spam leads</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
