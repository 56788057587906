import { useContext } from 'react'

import * as Routes from '../../routes'
import { DealershipContext } from '../contexts'

const checkPermission = async (userId, dealershipId, permissions) => {
  const permissionList = Array.isArray(permissions) ? permissions : [permissions]

  const response = await fetch(Routes.api_v1_permissions_check_path(), {
    method: 'POST', // Use POST to send JSON data
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_id: userId,
      permissions: permissionList,
      dealership_id: dealershipId,
    }),
  })

  if (!response.ok) return {}

  const data = await response.json()
  return data.permissions || {}
}

export default checkPermission
