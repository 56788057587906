import { useContext, useRef, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import moment from 'moment-timezone'
import { PrimeReactProvider } from 'primereact/api'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { SplitButton } from 'primereact/splitbutton'
import { Toast } from 'primereact/toast'
import { Tooltip } from 'primereact/tooltip'
import { useParams, useSearchParams } from 'react-router-dom'
import Select from 'react-select'

import * as Routes from '../../routes'
import { PromotionContext } from '../contexts'
import DataTableSearch from '../entries/DataTableSearch'
import { SectionHeading, standardHeaders } from '../entries/utils'
import { humanize } from '../entries/utils'
import AuditTrailSlideIn from '../shared/AuditTrailSlideIn'
import showToast from '../shared/ShowToast'
import { PromotionService } from './promotionsService'

const Action = (promotion) => {
  const { notification, loadData } = useContext(PromotionContext)
  const [showSlideIn, setShowSlideIn] = useState(false)
  const handleHideSlideIn = () => {
    setShowSlideIn(false)
  }

  const items = [
    {
      label: 'Expire',
      icon: 'fa fa-calendar-times',
      disabled: !promotion.promotion_expire_url,
      command: () => {
        fetch(promotion.promotion_expire_url, {
          method: 'POST',
          headers: standardHeaders,
        }).then((res) => {
          if (res.ok) {
            showToast(notification, 'success', 'Promotion Expired Successfully')
            loadData()
          } else {
            showToast(notification, 'error', 'Error Expiring Promotion')
          }
        })
      },
    },
    {
      label: 'Extend',
      icon: 'fa fa-calendar-plus',
      command: () => {
        fetch(promotion.promotion_extend_url, {
          method: 'POST',
          headers: standardHeaders,
        }).then(() => {
          showToast(notification, 'success', 'Promotion Extended Successfully')
          loadData()
        })
      },
    },
    {
      label: promotion.disabled_promotion ? 'Un-hide promotion' : 'Hide promotion',
      icon: promotion.disabled_promotion ? 'fas fa-eye' : ' fas fa-eye-slash',
      command: () => {
        const action = promotion.disabled_promotion ? 'Visible' : 'Hidden'
        fetch(promotion.hide_promotion_url, {
          method: 'POST',
          headers: standardHeaders,
        }).then(() => {
          const message = `Promotion made ${action} successfully`
          showToast(notification, 'success', message)
          loadData()
        })
      },
    },
    {
      label: 'Destroy',
      icon: 'fa fa-trash',
      disabled: !promotion.can_update_promotion,
      command: async () => {
        const userConfirmation = window.confirm('Are you sure?')

        if (!userConfirmation) {
          return
        }

        try {
          const response = await fetch(promotion.destroy_promotion_url, {
            method: 'DELETE',
            headers: standardHeaders,
          })

          if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`)
          }

          showToast(notification, 'success', 'Promotion destroyed successfully')
          loadData()
        } catch (error) {
          console.error('Failed to destroy promotion:', error)
          showToast(notification, 'error', 'Failed to destroy promotion. Please try again.')
        }
      },
    },
  ]
  const adminItems = [
    {
      label: 'Audit Trail',
      icon: 'fa fa-history',
      disabled: !promotion.promotion_versions_present,
      command: () => {
        setShowSlideIn(true)
      },
    },
  ]

  const showItems = [
    {
      label: promotion.disabled_promotion ? 'Un-hide promotion' : 'Hide promotion',
      icon: promotion.disabled_promotion ? 'fas fa-eye' : ' fas fa-eye-slash',
      command: () => {
        const action = promotion.disabled_promotion ? 'Visible' : 'Hidden'
        fetch(promotion.hide_promotion_url, {
          method: 'POST',
          headers: standardHeaders,
        }).then(() => {
          const message = `Promotion made ${action} successfully`
          showToast(notification, 'success', message)
          loadData()
        })
      },
    },
  ]

  const mergedItems = promotion.user_is_admin ? [...items, ...adminItems] : [...items]

  if (promotion.user_is_admin || promotion.can_manage_promotion) {
    return (
      <div style={{ position: 'relative' }}>
        <SplitButton
          label="Edit"
          buttonProps={{ id: 'edit-button' }}
          onClick={() => {
            window.location.href = promotion.edit_promotion_url
          }}
          model={mergedItems}
          menuButtonProps={{ id: 'action-button' }}
          outlined
          rounded
        />

        {showSlideIn && (
          <AuditTrailSlideIn
            AuditTrailObject={promotion}
            AuditTrailObjectName="Slide"
            Url={promotion.audit_trail_url}
            ShowSlideIn={showSlideIn}
            onHide={handleHideSlideIn}
          />
        )}
      </div>
    )
  } else if (promotion.can_toggle_visibility) {
    return (
      <div style={{ position: 'relative' }}>
        <SplitButton
          label="Show"
          buttonProps={{ id: 'show-button' }}
          onClick={() => {
            window.location.href = promotion.promotion_show_url
          }}
          model={showItems}
          menuButtonProps={{ id: 'action-button' }}
          outlined
          rounded
        />
      </div>
    )
  }
}

const Image = (promotion) => {
  if (promotion.promotion_image_present) {
    return (
      <div className="justify-content-center d-flex flex-column">
        <div className="text-center">
          <img
            className="img-fluid"
            src={promotion.promotion_image_url}
            alt={promotion.name}
            width="150"
          />
          <div className="small text-center">{promotion.promotion_image_dimensions}</div>
        </div>
      </div>
    )
  }

  if (promotion.promotion_video_present) {
    return (
      <div className="justify-content-center d-flex flex-column">
        <div className="text-center">
          <video width="150" src={promotion.promotion_video_url}></video>
        </div>
      </div>
    )
  }
}

const OwnerDetails = (promotion) => {
  return (
    <div className="d-flex align-items-center">
      <a href={promotion.owner_url}>{promotion.owner_name}</a>
      {promotion.oem_promotion && (
        <div className="badge badge-success ml-2">
          {!promotion.can_update_promotion && <i className="fa fa-lock"> &nbsp;</i>}
          OEM
        </div>
      )}
    </div>
  )
}

const ModelDetails = (promotion) => {
  return (
    <div className="d-flex align-items-center">
      <a href={promotion.model_url}>{promotion.model_name}</a>
    </div>
  )
}

const PromotionDetails = (promotion) => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return (
    <div>
      <h3>{promotion.name}</h3>
      <p>{promotion.promotion_expiry_date_in_words}</p>
      <p>
        <b>Created By:</b> {promotion.promotion_created_by} on{' '}
        {moment(promotion.created_at).tz(userTimezone).format('DD/MM/YYYY')}
        <br />
        <small className="text-secondary">{promotion.created_at_wording}</small>
      </p>

      {promotion.website_present && (
        <p>
          <b>Website:&nbsp;</b>
          <span className="badge badge-primary">{promotion.promotion_website_name}</span>
        </p>
      )}
      {promotion.disabled_promotion && (
        <p>
          <b>Status:&nbsp;</b>
          <span className="badge badge-secondary">Hidden</span>
        </p>
      )}
    </div>
  )
}

const position = (promotion) => {
  const { notification, loadData } = useContext(PromotionContext)

  const handleMove = async (url, position, successMessage) => {
    if ((position === 'top' || position === 'up') && promotion.position === 1) {
      showToast(notification, 'error', 'Promotion is already at the top')
      return
    }

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: standardHeaders,
        body: JSON.stringify({ position }),
      })

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`)
      }

      showToast(notification, 'success', successMessage)
      loadData()
    } catch (error) {
      console.error(`Failed to move promotion:`, error)
      showToast(notification, 'error', `Failed to move promotion. Please try again.`)
    }
  }

  return (
    <div>
      <div className="d-flex justify-content-center">
        <button
          className={
            'btn btn-sm move-higher btn-outline-primary' +
            (promotion.position === 1 ? ' disabled' : '')
          }
          onClick={() =>
            handleMove(
              promotion.promotion_move_higher_url,
              'up',
              'Promotion moved higher successfully'
            )
          }
        >
          <i className="fa fa-arrow-up"></i>
        </button>
        <Tooltip target={`.move-higher`} content="Move higher" position="bottom" />
        <button
          className={
            'btn btn-sm move-to-top btn-outline-primary' +
            (promotion.position === 1 ? ' disabled' : '')
          }
          onClick={() =>
            handleMove(
              promotion.promotion_move_top_url,
              'top',
              'Promotion moved to top successfully'
            )
          }
        >
          <i className="fa fa-angle-double-up"></i>
        </button>
        <Tooltip target={`.move-to-top`} content="Move to top" position="bottom" />
        <button
          className="btn btn-sm move-to-bottom btn-outline-primary"
          onClick={() =>
            handleMove(
              promotion.promotion_move_bottom_url,
              'bottom',
              'Promotion moved to bottom successfully'
            )
          }
        >
          <i className="fa fa-angle-double-down"></i>
        </button>
        <Tooltip target={`.move-to-bottom`} content="Move to bottom" position="bottom" />
        <button
          className="btn btn-sm move-lower btn-outline-primary"
          onClick={() =>
            handleMove(
              promotion.promotion_move_lower_url,
              'down',
              'Promotion moved lower successfully'
            )
          }
        >
          <i className="fa fa-arrow-down"></i>
        </button>
        <Tooltip target={`.move-lower`} content="Move lower" position="bottom" />
      </div>
    </div>
  )
}

const App = ({ ownerId = '', ownerClass = '', type = '' }) => {
  const notification = useRef(null)

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: 'contains' },
  })

  // Fetch promotions with React Query
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['promotions', type, ownerId, ownerClass], // Cache key
    queryFn: async () => {
      let params = { page: 0, type }
      const response = await PromotionService.getPromotions(params, ownerId, ownerClass)
      return response.data.promotions
    },
  })

  // Function to manually refetch data
  const loadData = () => refetch()

  const header = DataTableSearch({ filters, setFilters })

  return (
    <PromotionContext.Provider value={{ notification, loadData }}>
      <div className="App box">
        <Toast ref={notification} />
        <PrimeReactProvider>
          <DataTable
            value={data || []}
            tableStyle={{ minWidth: '50rem' }}
            paginator
            rows={10}
            globalFilterFields={['name', 'category']}
            header={header}
            loading={isLoading}
            filters={filters}
          >
            <Column body={Image} field="image" header="" style={{ maxWidth: '20rem' }}></Column>
            <Column field="name" body={PromotionDetails} header="Details" sortable></Column>
            <Column field="owner_name" body={OwnerDetails} header="Owner" sortable></Column>
            <Column field="model_name" body={ModelDetails} header="Model" sortable></Column>
            <Column body={position} header=""></Column>
            <Column body={Action} header="Actions"></Column>
          </DataTable>
        </PrimeReactProvider>
      </div>
    </PromotionContext.Provider>
  )
}

let types = ['expired', 'current', 'future', 'recently_expired', 'hidden']
let typeOptions = types.map((t) => ({ value: t, label: humanize(t) }))

const Wrapper = () => {
  let { websiteSlug, manufacturerSlug } = useParams()
  const [searchParams] = useSearchParams()
  const type = searchParams.get('type') || 'current'
  let [selectedType, setSelectedType] = useState(type)

  let ownerClass = 'Website'
  let new_url

  if (websiteSlug) {
    new_url = Routes.new_website_promotion_path(websiteSlug)
  } else if (manufacturerSlug) {
    ownerClass = 'Manufacturer'
    new_url = Routes.new_manufacturer_promotion_path(manufacturerSlug)
  }

  return (
    <div className="px-4 py-2 mt-2">
      <SectionHeading title={`${humanize(type) || 'Current'} Slides`}>
        <div className="d-flex">
          <Select
            options={typeOptions}
            value={typeOptions.find((t) => t.value === selectedType)}
            className="promotions-type-select"
            onChange={(e) => {
              setSelectedType(e.value)
              searchParams.set('type', e.value)
              window.history.pushState({}, '', `${window.location.pathname}?${searchParams}`)
            }}
          />
          <a href={new_url} className="btn btn-outline-success ml-2" data-remote={true}>
            <i className="fa fa-plus"></i> New Slide
          </a>
        </div>
      </SectionHeading>
      <App ownerClass={ownerClass} ownerId={websiteSlug || manufacturerSlug} type={type} />
    </div>
  )
}

export default Wrapper
