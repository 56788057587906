import { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import Select from 'react-select'

import Loading from '../Loading'
import KanbanBoard from './Kanban/KanbanBoard'

const periodOptions = [
  { label: '7 days', value: 7 },
  { label: '14 days', value: 14 },
  { label: '30 days', value: 30 },
  { label: '60 days', value: 60 },
  { label: '90 days', value: 90 },
  { label: '120 days', value: 120 },
]

const CustomHitsBeta = () => {
  let [onlyMe, setOnlyMe] = useState(false)
  let [selectedCategories, setSelectedCategories] = useState([])
  let [selectedLocations, setSelectedLocations] = useState([])
  let [timePeriodDays, setTimePeriodDays] = useState(30)
  let [users, setUsers] = useState([])

  let { pipelineId, dealershipSlug } = useParams()

  const fetchLeadsData = async () => {
    let url = `/dealerships/${dealershipSlug}/leads/trello_data.json?only_me=${onlyMe}&user_ids=${users}&categories=${selectedCategories}&location_ids=${selectedLocations}&time_period_days=${timePeriodDays}`

    if (pipelineId) {
      url += `&pipeline_id=${pipelineId}`
    }

    const response = await fetch(url)
    if (!response.ok) throw new Error('Network response was not ok')
    return response.json()
  }

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      'leadsData',
      dealershipSlug,
      onlyMe,
      users,
      selectedCategories,
      selectedLocations,
      timePeriodDays,
      pipelineId,
    ],
    queryFn: fetchLeadsData,
    staleTime: 1 * 60 * 1000, // Cache for 5 minutes
    keepPreviousData: true, // Keep the old data while fetching new
  })

  if (isLoading)
    return (
      <div className="py-3">
        <Loading />
      </div>
    )

  return (
    <div className="py-3">
      {isLoading ? (
        <p className="mb-2 text-secondary">Loading...</p>
      ) : (
        <p className="mb-2 text-secondary">Only showing leads from last {timePeriodDays} days</p>
      )}
      <div className="box p-2 d-flex align-items-center">
        <div className="custom-control custom-switch mr-3">
          <input
            type="checkbox"
            className={'custom-control-input' + (isLoading ? ' disabled' : '')}
            checked={onlyMe}
            onChange={() => setOnlyMe(!onlyMe)}
            disabled={isLoading}
            id={`switch-only-me`}
          />
          <label className="custom-control-label" htmlFor={`switch-only-me`}>
            Only show my leads
          </label>
        </div>
        <div className="ml-auto" style={{ marginRight: '10px' }}>
          <div className="d-flex">
            {data?.categories && (
              <div className="mr-2">
                <Select
                  options={data.categories.map((category) => ({
                    value: category,
                    label: category,
                  }))}
                  onChange={(e) => setSelectedCategories(e.map((e) => e.value))}
                  isMulti
                  placeholder="Filter by Category"
                />
              </div>
            )}
            {data?.locations && (
              <div className="mr-2">
                <Select
                  options={data.locations.map((location) => ({
                    value: location.id,
                    label: `${location.name} - ${location.location_type}`,
                  }))}
                  onChange={(e) => setSelectedLocations(e.map((e) => e.value))}
                  isMulti
                  placeholder="Filter by Location"
                />
              </div>
            )}
            <Select
              options={periodOptions}
              placeholder="Filter by period"
              onChange={(selection) => setTimePeriodDays(selection.value)}
              value={periodOptions.find((o) => o.value === timePeriodDays)}
              className="mr-2"
            />
            <Select
              options={[
                { value: 'unassigned', label: 'Unassigned' },
                ...(data?.users?.map((user) => ({ value: user.id, label: user.name })) || []),
              ]}
              onChange={(e) => setUsers(e.map((user) => user.value))}
              isMulti
              placeholder="Filter by user"
            />
          </div>
        </div>
      </div>
      <KanbanBoard columns={data?.columns} leads={data?.lead_clusters} refreshData={refetch} />
    </div>
  )
}

export default CustomHitsBeta
