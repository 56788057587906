import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import { standardHeaders } from '../../entries/utils'
import { ErrorScreen } from '../../shared/Error'
import { type SearchPageTemplate, SearchPagesTable } from './SearchPagesTable'

interface SearchPageTemplatesResponse {
  templates: SearchPageTemplate[]
}

export default function SearchPages() {
  const websiteSlug = useParams().websiteSlug

  const { data, isLoading, error } = useQuery<SearchPageTemplatesResponse>({
    queryKey: ['searchPageTemplates', websiteSlug],
    queryFn: async () => {
      const res = await fetch(`/websites/${websiteSlug}/search_page_templates`, {
        headers: standardHeaders,
      })
      return await res.json()
    },
  })

  if (error) {
    return <ErrorScreen heading="Error loading search page templates" />
  }

  return (
    <div className="p-4">
      <h4>Search Page Templates</h4>
      <SearchPagesTable data={data} isLoading={isLoading} />
    </div>
  )
}
