import { useEffect, useRef, useState } from 'react'

import _ from 'lodash'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { InputSwitch } from 'primereact/inputswitch'
import { Toast } from 'primereact/toast'
import { classNames } from 'primereact/utils'
import DatePicker from 'react-datepicker'
import Select from 'react-select'

import * as Routes from '../../../routes'
import showToast from '../../shared/ShowToast'
import TickingTimestamp from '../../shared/TickingTimestamp'
import { InputLabel, Sidebar, parameterize } from '../common'
import {
  PageUrlSection,
  PageVisibilityButton,
  PreviewButton,
  SaveDraftButton,
  SettingsButton,
  UndoButton,
} from './ToolbarComponents'
import { SEOIndexableTooltip } from './ToolbarTooltips'
import {
  SERPPreview,
  cleanSlug,
  handleDeleteImage,
  handleDraftAndPublishedStates,
  handleSlugFormatting,
  loadAvailableAuthors,
  togglePageVisibility,
  validateTitle,
} from './ToolbarUtils'

const App = ({ ...props }) => {
  const notification = useRef(null)

  const {
    csrf,
    websiteId,
    dealershipId,
    developmentEnv,
    blogId,
    blogCategories,
    newFieldsEnabled,
    websiteName,
    websiteUrl,
    toggleBlogVisibilityUrl,
  } = props

  // Sidebar Inputs
  const [title, setTitle] = useState('')
  const [slug, setSlug] = useState('')
  const [slugOverride, setSlugOverride] = useState('')
  const [publishDate, setPublishDate] = useState(new Date())
  const [uploadedFile, setUploadedFile] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [metaTitle, setMetaTitle] = useState('')
  const [metaDescription, setMetaDescription] = useState('')
  const [displayedAuthor, setDisplayedAuthor] = useState(null)
  const [featured, setFeatured] = useState(false)
  const [visible, setVisible] = useState(false)
  const [seoIndexable, setSeoIndexable] = useState(true)
  // Dev specific input
  const [draftBlocksJson, setDraftBlocksJson] = useState(props?.draftBlocksJson ?? '{}')

  // State management variables
  const [draftStateLoaded, setDraftStateLoaded] = useState(false)
  const [isPublished, setIsPublished] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [show, setShow] = useState(false) // Modal
  const [shouldSaveDraft, setShouldSaveDraft] = useState(false) // Flag to trigger saving the draft to the server after undoing draft changes.
  const [showSlugOverride, setShowSlugOverride] = useState(false)

  // Blog data values
  const [liveUrl, setLiveUrl] = useState('')
  const [previewUrl, setPreviewUrl] = useState('')
  const [imageThumbUrl, setImageThumbUrl] = useState(null)
  const [lastLiveUpdatedAt, setLastLiveUpdatedAt] = useState(null)
  const [lastDraftEditedAt, setLastDraftEditedAt] = useState(null)
  const [blocksJson, setBlocksJson] = useState('{}')
  const [availableAuthors, setAvailableAuthors] = useState([])

  // Used for UNDO functionality
  const draftBlocks = JSON.parse(draftBlocksJson)?.blocks ?? []
  // Check if blocksJson is not empty and is not just an empty object '{}'. This prevents parsing errors.
  const blocks = !_.isEmpty(blocksJson) && blocksJson !== '{}' ? JSON.parse(blocksJson).blocks : []

  useEffect(() => {
    refreshBlogData()

    const handleEditorSave = (event) => {
      setDraftBlocksJson(event.detail)
    }
    window.addEventListener('editorSave', handleEditorSave)

    return () => {
      window.removeEventListener('editorSave', handleEditorSave)
    }
  }, [])

  useEffect(() => {
    // Update loading/published/saved states
    handleDraftAndPublishedStates(draftBlocksJson, blocksJson, setDraftStateLoaded, setIsPublished)
  }, [draftBlocksJson, blocksJson])

  // Use effect to handle saving the draft to the server after undoing changes
  useEffect(() => {
    if (shouldSaveDraft) {
      submitForm('save_draft', 'undo')
        .then(() => {
          setShouldSaveDraft(false)
          window.location.reload() // Reflect changes on UI.
        })
        .catch((error) => {
          console.error('Failed to undo changes: ', error)
        })
    }
  }, [shouldSaveDraft])

  return (
    <div id="blogs-settings-toolbar">
      <ConfirmDialog />
      <Toast ref={notification} />
      <div className="border-top border-bottom p-2 d-flex flex-column flex-lg-row justify-content-between align-items-center position-relative">
        <div className="d-flex flex-column flex-lg-row flex-wrap align-items-center">
          <div className="editorjs-title mr-0 mr-lg-3">
            <span className="font-weight-bold">Title:</span> {title}
          </div>
          {isPublished && liveUrl && <PageUrlSection pageUrl={liveUrl} />}
        </div>
        <div className="d-flex flex-row flex-wrap flex-md-nowrap justify-content-center align-items-center">
          {!_.isEqual(draftBlocks, blocks) && lastDraftEditedAt && (
            <UndoButton
              blocksJson={blocksJson}
              setDraftBlocksJson={setDraftBlocksJson}
              setShouldSaveDraft={setShouldSaveDraft}
            />
          )}
          {previewUrl && lastDraftEditedAt && <PreviewButton previewUrl={previewUrl} />}
          {draftStateLoaded && <SaveDraftButton loading={loading} submitForm={submitForm} />}
          {draftStateLoaded && lastDraftEditedAt && (
            <button
              disabled={loading}
              className="btn btn-danger mt-2 mt-lg-0 mr-2"
              onClick={() => submitForm('publish')}
            >
              {loading && <span>Loading...</span>}
              {!loading && <span>Publish</span>}
            </button>
          )}
          {isPublished && (
            <PageVisibilityButton
              type="blog"
              togglePageVisibilityUrl={toggleBlogVisibilityUrl}
              csrf={csrf}
              visible={visible}
              setVisible={setVisible}
              notification={notification}
            />
          )}
          <SettingsButton setShow={setShow} />
        </div>
      </div>
      <Sidebar title="Blog Settings" show={show} closeClickHandler={() => setShow(false)}>
        <div className="form-group text pt-3">
          <label className="form-control-label string optional">Title</label>
          <input
            id="blog_title"
            name="title"
            className="form-control string optional"
            type="text"
            value={title}
            onChange={(e) => validateTitle('blog', e.target.value, setTitle)}
            onBlur={() => {
              if (title === '') {
                setTitle('Untitled')
              }
            }}
          />
        </div>
        {newFieldsEnabled && (
          <>
            <div className="mb-3">
              <div className="form-group d-flex align-items-center justify-content-between mb-0">
                <label
                  className="form-control-label boolean optional mb-0"
                  htmlFor="page_show_override_slug"
                >
                  Override page slug
                </label>
                <InputSwitch
                  id="page_show_override_slug"
                  checked={showSlugOverride}
                  onChange={(e) => {
                    // Default to the current slug if no slug override is set
                    setSlugOverride(e.value ? slug : '')
                    setShowSlugOverride(e.value)
                  }}
                />
              </div>
              {showSlugOverride && (
                <div className="form-group text mt-2 mb-0">
                  <label className="form-control-label string optional">Slug Override</label>
                  <input
                    id="page_slug_override"
                    name="slug_override"
                    className="form-control string optional"
                    type="text"
                    value={slugOverride}
                    onBlur={() => cleanSlug(slugOverride, setSlugOverride)}
                    onChange={(e) => handleSlugFormatting(e.target.value, setSlugOverride)}
                  />
                  <div className="small text-info mt-1">{`${websiteUrl}/blog/${slugOverride}`}</div>
                </div>
              )}
            </div>
            <div className="form-group">
              <label className="form-control-label">Featured Image</label>
              {imageThumbUrl ? (
                <>
                  <img
                    className="d-block mb-2 w-100"
                    src={imageThumbUrl}
                    alt="Uploaded Thumbnail"
                  />
                  <button
                    className="btn btn-danger"
                    onClick={() =>
                      handleDeleteImage(
                        Routes.delete_featured_image_website_blog_path(websiteId, blogId),
                        csrf,
                        setImageThumbUrl,
                        setUploadedFile,
                        notification
                      )
                    }
                    title="Delete this image"
                  >
                    <i className="fa fa-danger fa-trash"></i>
                  </button>
                </>
              ) : (
                <input
                  type="file"
                  className="form-control-file"
                  onChange={(e) => setUploadedFile(e.target.files[0])}
                />
              )}
            </div>
            <div className="form-group text">
              <label className="form-control-label string optional">Meta Title</label>
              <input
                id="blog_meta_title"
                name="meta_title"
                className="form-control string optional"
                type="text"
                value={metaTitle}
                onChange={(e) => setMetaTitle(e.target.value)}
              />
              <span
                className={classNames(
                  'small',
                  `${metaTitle || title} | ${websiteName === 'N/A' ? 'Website Name' : websiteName}`
                    .length > 60
                    ? 'text-danger'
                    : 'text-muted'
                )}
              >
                {
                  `${metaTitle || title} | ${websiteName === 'N/A' ? 'Website Name' : websiteName}`
                    .length
                }
                /60 characters
              </span>
            </div>
            <div className="form-group text">
              <label className="form-control-label string optional">Meta description</label>
              <textarea
                className="form-control string optional"
                placeholder="If blank will default to blog content"
                onChange={(e) => setMetaDescription(e.target.value)}
                value={metaDescription}
              />
              <span
                className={classNames(
                  'small',
                  metaDescription.length > 160 ? 'text-danger' : 'text-muted'
                )}
              >
                {metaDescription.length}/160 characters
              </span>
            </div>
            <SERPPreview
              title={`${metaTitle || title} | ${websiteName || 'Website Name'}`}
              description={metaDescription}
              url={`${websiteUrl}/blog/${
                selectedCategory?.label ? `${parameterize(selectedCategory?.label)}/` : ''
              }${slugOverride || slug}`}
            />
            <div className="form-group d-flex align-items-center justify-content-between">
              <InputLabel
                label="SEO Indexable"
                itemName="seo_indexable"
                htmlFor="page_seo_indexable"
                labelClassname="form-control-label boolean optional mb-0 d-flex align-items-center"
                containerClassName="d-flex align-items-center mb-0"
                tooltip={SEOIndexableTooltip}
              />
              <InputSwitch
                id="page_seo_indexable"
                checked={seoIndexable}
                onChange={(e) => setSeoIndexable(e.value)}
              />
            </div>
          </>
        )}
        <div className="form-group d-flex align-items-center justify-content-between">
          <InputLabel
            label="Featured"
            itemName="featured"
            htmlFor="blog_featured"
            labelClassname="form-control-label boolean optional mb-0 d-flex align-items-center"
            containerClassName="mb-0"
          />
          <InputSwitch
            id="blog_featured"
            checked={featured}
            onChange={(e) => setFeatured(e.value)}
          />
        </div>
        {blogCategories?.length > 0 && (
          <div className="form-group select optional">
            <label className="form-control-label select optional" htmlFor="blog_category_id">
              Blog Category
            </label>
            <Select
              id="blog_category_id"
              name="blog_category_id"
              options={blogCategories.map((category) => ({
                value: category.id,
                label: category.name,
              }))}
              isClearable={true}
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e)}
            />
          </div>
        )}
        <div className="form-group date">
          <label className="form-control-label date optional">Displayed Publish Date</label>
          {/* @ts-ignore */}
          <DatePicker
            selected={publishDate}
            // @ts-ignore date is typed as [Date, Date], but only returns Date
            onChange={(date) => setPublishDate(date)}
            placeholderText="dd/mm/yyyy"
            className="form-control"
            wrapperClassName="w-100"
          />
          <small className="form-text text-muted">Optional</small>
        </div>
        {availableAuthors && (
          <div className="form-group select optional">
            <label className="form-control-label select optional" htmlFor="blog_displayed_author">
              Displayed Author
            </label>
            <Select
              options={availableAuthors}
              value={displayedAuthor}
              isClearable={true}
              onChange={(e) => setDisplayedAuthor(e)}
            />
          </div>
        )}
        {developmentEnv ? (
          <div className="form-group jsonb optional blog_draft_blocks_json form-group-valid">
            <label className="form-control-label jsonb optional">Draft blocks json</label>
            <textarea
              className="form-control is-valid jsonb optional"
              id="draft-blocks-json"
              defaultValue={draftBlocksJson}
            />
          </div>
        ) : (
          <input type="hidden" id="draft-blocks-json" defaultValue={draftBlocksJson} />
        )}
        {isPublished && (
          <div className="form-group d-flex align-items-center justify-content-between">
            <span>Blog Visibility</span>
            <InputSwitch
              id="blog_visibility_toggle"
              checked={visible}
              onChange={(e) =>
                togglePageVisibility(
                  'blog',
                  e,
                  toggleBlogVisibilityUrl,
                  csrf,
                  visible,
                  setVisible,
                  notification
                )
              }
            />
          </div>
        )}
        {draftStateLoaded && (
          <>
            <button
              disabled={loading}
              className="btn btn-primary btn-block mt-3"
              id="save-draft-button"
              onClick={() => submitForm('save_draft')}
            >
              {loading && <span>Loading...</span>}
              {!loading && <span>Save Draft</span>}
            </button>
            {lastDraftEditedAt && (
              <TickingTimestamp
                label="Draft last edited"
                date={lastDraftEditedAt}
                interval={5000}
              />
            )}
          </>
        )}
        {draftStateLoaded && lastDraftEditedAt && (
          <>
            <button
              disabled={loading}
              className="btn btn-danger btn-block mt-3"
              id="publish-button"
              onClick={() => submitForm('publish')}
            >
              {loading && <span>Loading...</span>}
              {!loading && <span>Publish</span>}
            </button>
            {lastLiveUpdatedAt && (
              <TickingTimestamp
                label="Blog last published"
                date={lastLiveUpdatedAt}
                interval={5000}
              />
            )}
          </>
        )}
      </Sidebar>
      {/* This must exist in the DOM for page blocks to render correctly */}
      <input type="hidden" id="draft-blocks-json" defaultValue={draftBlocksJson} />
    </div>
  )

  function refreshBlogData() {
    setLoading(true)

    try {
      fetch(Routes.website_blog_path(websiteId, blogId) + '.json', {
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then(
          (data) => {
            // Set blog data
            const thumbUrl = data.featured_image?.src

            if (newFieldsEnabled) {
              setMetaTitle(data.meta_title ?? '')
              setSeoIndexable(data.seo_indexable)
              if (thumbUrl) {
                setImageThumbUrl(thumbUrl)
              }
            }
            loadAvailableAuthors(dealershipId, setLoading, setAvailableAuthors)

            setTitle(data.title ?? '')
            setPublishDate(data.publish_date ? new Date(data.publish_date) : new Date())
            setSelectedCategory({ value: data.blog_category?.id, label: data.blog_category?.name })
            setMetaDescription(data.meta_description ?? '')
            setFeatured(data.featured)
            setVisible(data.visible)
            setLiveUrl(data.live_url)
            setPreviewUrl(data.preview_url)
            setBlocksJson(data.blocks_json ?? '{}')
            setShowSlugOverride(!!data.slug_override && data.slug_override !== '')
            setSlugOverride(data.slug_override ?? '')
            setLastLiveUpdatedAt(data.last_live_updated_at)
            setLastDraftEditedAt(data.last_draft_edited_at)
            setDisplayedAuthor({
              value: data.displayed_author_id,
              label: data.displayed_author_name,
            })
            setSlug(data.slug ?? '')
            setLoading(false)
          },
          (error) => {
            console.error('Error fetching blog data:', error)
            setLoading(false)
            setError(error)
          }
        )
    } catch {
      console.error('Unexpected error:', error)
      // Handle unexpected errors
      const message = `Sorry, there was an error loading the blog. Please try refreshing.\n\nIf the problem persists, please send the following error message to support.\n\nError: ${error}`

      showToast(notification, 'error', 'Error loading the blog!', message)
    }
  }

  async function submitForm(saveMode, action = '') {
    const formData = new FormData()

    const isVisible = saveMode === 'publish' ? true : visible

    if (newFieldsEnabled) {
      formData.append('blog[seo_indexable]', seoIndexable.toString())
      if (uploadedFile) {
        formData.append('blog[featured_image_attributes][image]', uploadedFile)
        setUploadedFile(null)
      }
    }

    formData.append('blog[title]', title)
    formData.append('blog[meta_title]', metaTitle)
    formData.append('blog[meta_description]', metaDescription)
    formData.append('blog[visible]', isVisible ? isVisible.toString() : 'false')
    formData.append('blog[featured]', featured ? featured.toString() : 'false')
    formData.append('blog[draft_blocks_json]', draftBlocksJson)
    formData.append('blog[slug_override]', slugOverride)
    formData.append('blog[publish_date]', publishDate.toString())
    formData.append('blog[seo_indexable]', seoIndexable.toString())
    formData.append('blog[displayed_author_id]', displayedAuthor?.value || '')
    if (selectedCategory?.value) {
      formData.append('blog[blog_category_id]', selectedCategory.value)
    }
    formData.append('save_mode', saveMode)

    try {
      setLoading(true)
      const response = await fetch(Routes.website_blog_path(websiteId, blogId) + '.json', {
        method: 'PUT',
        headers: {
          'X-CSRF-Token': csrf,
          Accept: 'application/json',
        },
        body: formData,
      })

      setLoading(false)

      if (!response.ok) {
        const errors = await response.json()
        throw errors
      } else {
        let toastTitle = ''
        let toastDescription = ''
        if (saveMode === 'save_draft') {
          if (action === 'undo') {
            toastTitle = 'Saved changes successfully undone'
          } else {
            toastTitle = 'Draft saved successfully'
            toastDescription = 'Remember to publish your changes'
          }
        } else if (saveMode === 'publish') {
          toastTitle = 'Blog published!'
          toastDescription = 'Your blog has been successfully published'
        }
        showToast(notification, 'success', toastTitle, toastDescription)
        refreshBlogData()
      }
    } catch (errors) {
      showToast(notification, 'error', 'Error saving the blog!', 'Please try again.')
      setLoading(false)
      throw errors
    }
  }
}

export default App
