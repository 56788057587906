import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import Loading from '../Loading'
import { useFetchManufacturer } from '../dataHooks'
import { standardHeaders } from '../entries/utils'

const ModelsList = ({ title, models }) => {
  if (!models.length) return null

  return (
    <div>
      <h5 className="text-secondary">{title}</h5>
      <div className="row">
        {models.map((model) => (
          <div key={model.id} className="col col-md-3">
            <div className="box p-3 mb-3 text-center">
              {model.image_url && (
                <a href={Routes.model_path(model.new_slug)}>
                  <img
                    src={model.image_url}
                    alt={model.full_name}
                    height="100"
                    style={{ maxWidth: '100%', objectFit: 'contain' }}
                  />
                </a>
              )}
              <div className="text-center">
                <a href={Routes.model_path(model.new_slug)}>{model.full_name}</a>
              </div>
              {model.aftermarket_manufacturer && (
                <span className="badge badge-danger">{model.aftermarket_manufacturer.name}</span>
              )}
              <p className="small my-2">
                {model.published && (
                  <>
                    {model['uptodate?'] ? (
                      <>
                        <i className="fa fa-check-circle text-success"></i> Up To Date
                      </>
                    ) : (
                      <>
                        <i className="fa fa-times-circle text-danger"></i> Needs Update
                      </>
                    )}
                  </>
                )}
              </p>
              <p className="small mb-0">
                <span className={model.model_variants?.length === 0 ? 'text-danger' : ''}>
                  {model.model_variants?.length} variants
                </span>{' '}
                <span className={model?.features.length === 0 ? 'text-danger' : ''}>
                  {model.features?.length} features
                </span>
              </p>
              {model.body?.length === 0 && <p className="small text-danger">Missing body.</p>}
              {model.missing_hero_and_banner && (
                <p className="small text-danger">
                  <i className="fa fa-image"></i> Missing banner/video
                </p>
              )}
              {model.colours?.length === 0 && <p className="small text-danger">Missing colours</p>}
              <p className="small">
                Last Update {moment(model.updated_at).fromNow()} by {model.last_update_by}
              </p>
              {!model.published && (
                <div className="text-danger">
                  <i className="fa fa-exclamation-triangle"></i> <span>Not published!</span>
                </div>
              )}
              <div className="text-center mt-2">
                <a
                  href={`/models/${model.new_slug}/edit`}
                  className="btn btn-outline-secondary btn-sm mr-2"
                >
                  Edit
                </a>
                <a
                  href={Routes.model_path(model.id)}
                  className="btn btn-outline-secondary btn-sm"
                  data-method="delete"
                  data-confirm="Are you sure?"
                >
                  Destroy
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

const fetchModels = async (manufacturerSlug) => {
  const response = await fetch(Routes.show_react_manufacturer_models_path(manufacturerSlug), {
    headers: standardHeaders,
  })
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  return response.json()
}

const ModelSearch = () => {
  let { manufacturerSlug } = useParams()
  let { manufacturer } = useFetchManufacturer()

  const {
    data: models = [],
    error,
    isLoading,
  } = useQuery({
    queryKey: ['models', manufacturerSlug],
    queryFn: () => fetchModels(manufacturerSlug),
  })

  if (isLoading) return <Loading />
  if (error) return <p>Error: {error.message}</p>

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2 className="mb-0">{manufacturer?.name} models</h2>
        <a
          href={`${Routes.new_model_path()}?manufacturer_id=${manufacturerSlug}`}
          className="btn btn-primary"
        >
          New Model
        </a>
      </div>
      <ModelsList models={models.published} />
      {models.aftermarket.length > 0 && (
        <ModelsList models={models.aftermarket} title="Aftermarket models" />
      )}
      {models.unpublished.length > 0 && (
        <ModelsList models={models.unpublished} title="Unpublished models" />
      )}
    </>
  )
}

const Wrapper = () => {
  return (
    <div className="container py-3">
      <ModelSearch />
    </div>
  )
}

export default Wrapper
