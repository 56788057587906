const WebsiteNameNoticeTooltip = {
  content: (
    <div className="small">
      <p className="mb-0">
        Please note: '{'{website.name}'}' will be included at the end of your meta title
        automatically, so there's no need to add it yourself.
      </p>
    </div>
  ),
  variant: 'notice',
}

const TitleWarningTooltip = {
  content: (
    <div className="small">
      <p className="mb-0">
        Titles are shared across all Manufacturer websites, ensure no business specific text or
        location information is added.
      </p>
    </div>
  ),
  variant: 'warning',
}

const MetaTitleWarningTooltip = {
  content: (
    <div className="small">
      <p className="mb-0">
        Meta titles are shared across all Manufacturer websites, ensure no business specific
        information is added.
      </p>
    </div>
  ),
  variant: 'warning',
}

const MetaDescriptionWarningTooltip = {
  content: (
    <div className="small">
      <p className="mb-0">
        Meta descriptions are shared across all Manufacturer websites, ensure no business specific
        text or location information is added.
      </p>
    </div>
  ),
  variant: 'warning',
}

const ExcludeWebsitesTooltip = {
  content: (
    <div className="small">
      <p className="mb-0">
        Turn this on if you want to include this page on{' '}
        <span className="font-weight-bold">ALL</span> OEM websites{' '}
        <span className="font-weight-bold">EXCEPT</span> the ones you have selected.
      </p>
    </div>
  ),
  variant: 'info',
}

const SEOIndexableTooltip = {
  content: (
    <div className="small">
      <p className="mb-0">
        Turn this off if you do not want this page to be indexed by search engines.
      </p>
    </div>
  ),
  variant: 'info',
}

export {
  WebsiteNameNoticeTooltip,
  TitleWarningTooltip,
  MetaTitleWarningTooltip,
  MetaDescriptionWarningTooltip,
  ExcludeWebsitesTooltip,
  SEOIndexableTooltip,
}
