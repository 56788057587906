import { useEffect, useState } from 'react'

import { DndContext, rectIntersection } from '@dnd-kit/core'
import { useParams } from 'react-router-dom'

import { KanbanContext } from '../../contexts'
import { updateLeadStatusOptionId } from '../../leads/utils'
import KanbanLane from './KanbanLane'
import ScrollBars from './ScrollBars'

const KanbanBoard = ({ leads, columns, refreshData }) => {
  let [tasks, setTasks] = useState(leads)
  let { dealershipSlug } = useParams()

  useEffect(() => {
    setTasks(leads)
  }, [leads])

  const updateLeadCluster = async ({ leadClusterId, leadStatusOptionId }) => {
    await updateLeadStatusOptionId(leadClusterId, leadStatusOptionId, dealershipSlug)
    await refreshData()
  }

  const onDragEnd = (e) => {
    const container = e.over?.id
    const leadStatusOptionId = e.over?.data.current.leadStatusOptionId

    if (container !== undefined) {
      let newTasks = tasks.map((task) => {
        if (task.id === e.active.data.current.id) {
          return { ...task, column: container, loading: true }
        } else {
          return task
        }
      })
      setTasks(newTasks)
    }

    updateLeadCluster({ leadClusterId: e.active.data.current.id, leadStatusOptionId })
  }

  const onDragOver = (e) => {
    const container = e.over?.id

    if (container !== undefined) {
      let newTasks = tasks.map((task) => {
        if (task.id === e.active.data.current.id) {
          return { ...task, column: container }
        } else {
          return task
        }
      })
      setTasks(newTasks)
    }
  }

  return (
    <KanbanContext.Provider value={{ refreshData }}>
      <DndContext
        collisionDetection={rectIntersection}
        onDragEnd={onDragEnd}
        onDragOver={onDragOver}
      >
        <ScrollBars>
          {columns.map(({ id, title, colour_class_name }, key) => (
            <KanbanLane
              title={title}
              lead_status_option_id={id}
              colour_class_name={colour_class_name}
              items={tasks.filter((task) => task.column === title)}
              key={key}
            />
          ))}
        </ScrollBars>
      </DndContext>
    </KanbanContext.Provider>
  )
}

export default KanbanBoard
