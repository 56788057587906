import { RefObject } from 'react'

import { Toast } from 'primereact/toast'

import { standardHeaders } from '../entries/utils'
import showToast from '../shared/ShowToast'
import { moduleComponents } from './config'
import { rootUrl } from './constants'
import type {
  AnalyticsBlock,
  ModuleComponentConfig,
  ModuleItem,
  NotificationType,
  TabFilters,
} from './types'

export function getModuleComponentFromName(name: string): ModuleComponentConfig {
  return moduleComponents[name]
}

// Fetch the module layout
export function fetchModuleLayoutByTab(
  tabIndex: number,
  analyticsBlock: AnalyticsBlock
): ModuleItem[] {
  return analyticsBlock?.data?.tabs?.find((tab) => tab.id === tabIndex)?.layout
}

// Get tabs
export function getTabTitles(analyticsBlock: AnalyticsBlock): string[] {
  const tabTitles = analyticsBlock?.data?.tabs?.map((tab) => tab.title)
  return tabTitles?.length ? tabTitles : ['Tab 1']
}

// Get tab filters
export function getTabFilters(analyticsBlock: AnalyticsBlock): { [key: number]: TabFilters } {
  const tabFilters = analyticsBlock?.data?.tabs?.map((tab) => tab.filters)
  return tabFilters
}

// convert leads_chart to Leads Chart for example
export function snakeCaseToTitleCase(module: string): string {
  return module
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

// convert orange-motor-group to Orange Motor Group for example
export function slugToTitle(module: string): string {
  return module
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

// Merges objects based on lodash merging
export function mergeDeep(...objects: Array<object>): object {
  const isObject = (obj: any) =>
    obj !== null && obj !== undefined && typeof obj === 'object' && !Array.isArray(obj)

  return objects.filter(isObject).reduce((prev, obj) => {
    Object.keys(obj).forEach((key) => {
      const pVal = prev[key]
      const oVal = obj[key]

      if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal, oVal)
      } else {
        prev[key] = oVal
      }
    })

    return prev
  }, {})
}

export const defaultResponsiveLayoutSizeStyle = { height: '18vw' }

export const handleNotificationEvent = (
  notification: RefObject<Toast>,
  message: string,
  type: NotificationType
): void => {
  showToast(notification, type, capitalize(type.toString()), message)
}

export function linkFacebookAccount(dealershipSlug: string | undefined): void {
  const apiUrl = 'https://www.facebook.com/v5.0/dialog/oauth'
  const params = new URLSearchParams({
    client_id: '305713216982753',
    redirect_uri: `${rootUrl}/facebook/oauth`,
    scope: 'ads_read,read_insights',
    state: `asset_type=facebook_ad_account&dealership_slug=${dealershipSlug}`,
  })

  window.open(`${apiUrl}?${params.toString()}`, '_blank')
}

export const isEmptyObject = (value: unknown): boolean => {
  return typeof value === 'object' && value !== null && Object.keys(value).length === 0
}

export const getGroupedModules = (group: string): ModuleItem[] => {
  return Object.keys(moduleComponents)
    .filter((key) => moduleComponents[key].group === group)
    .map((key) => ({ module: key }))
}

export function getColor(value: string | number, reverse: boolean = false): string {
  const parsedValue = typeof value === 'string' ? parseFloat(value) : value
  if (parsedValue > 0) {
    return reverse ? 'text-danger' : 'text-success'
  } else if (parsedValue < 0) {
    return reverse ? 'text-success' : 'text-danger'
  } else {
    return ''
  }
}

export function capitalize(string: string) {
  if (typeof string !== 'string') return string
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function fetchResultData(url: string, method: string = 'GET', body?: JSON): Promise<any> {
  let requestOptions: RequestInit = {
    method: method,
    headers: standardHeaders,
  }
  requestOptions = body ? { ...requestOptions, body: JSON.stringify(body) } : requestOptions

  return fetch(url, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json()
    })
    .catch((err) => {
      throw new Error(`Error fetching data: ${err.message}`)
    })
}

export const ga4TestingData = {
  'Orange Motor Group': [
    ['AUG 24', 2796],
    ['SEP 24', 3053],
    ['OCT 24', 11],
    ['NOV 24', 5],
    ['DEC 24', 3303],
    ['JAN 25', 5534],
    ['FEB 25', 5020],
    ['MAR 25', 3388],
  ],
  'Orange Subaru': [
    ['MAY 24', 926],
    ['JUN 24', 2459],
    ['JUL 24', 2220],
    ['AUG 24', 2249],
    ['SEP 24', 1481],
    ['DEC 24', 580],
    ['JAN 25', 1708],
    ['FEB 25', 1461],
    ['MAR 25', 1389],
  ],
  'Orange Ssangyong': [
    ['MAR 24', 2969],
    ['APR 24', 7870],
    ['MAY 24', 6095],
    ['JUN 24', 6358],
    ['JUL 24', 5602],
    ['AUG 24', 4559],
    ['SEP 24', 13078],
    ['OCT 24', 17190],
    ['NOV 24', 14482],
    ['DEC 24', 13268],
    ['JAN 25', 14848],
    ['FEB 25', 12584],
    ['MAR 25', 8287],
  ],
  'Orange Kia': [
    ['MAR 24', 2969],
    ['APR 24', 7870],
    ['MAY 24', 6095],
    ['JUN 24', 6358],
    ['JUL 24', 5602],
    ['AUG 24', 4559],
    ['SEP 24', 13078],
    ['OCT 24', 17190],
    ['NOV 24', 14482],
    ['DEC 24', 13268],
    ['JAN 25', 14848],
    ['FEB 25', 12584],
    ['MAR 25', 8287],
  ],
  'Macarthur Kia': [
    ['MAR 24', 945],
    ['APR 24', 2829],
    ['MAY 24', 3056],
    ['JUN 24', 3120],
    ['JUL 24', 2587],
    ['AUG 24', 3268],
    ['SEP 24', 2985],
    ['OCT 24', 3451],
    ['NOV 24', 3005],
    ['DEC 24', 3132],
    ['JAN 25', 2858],
    ['FEB 25', 4016],
    ['MAR 25', 1898],
  ],
  'Macarthur Renault': [
    ['MAR 24', 424],
    ['APR 24', 1304],
    ['MAY 24', 1562],
    ['JUN 24', 1655],
    ['JUL 24', 426],
    ['AUG 24', 2791],
    ['SEP 24', 4112],
    ['OCT 24', 2958],
    ['NOV 24', 3066],
    ['DEC 24', 3312],
    ['JAN 25', 3266],
    ['FEB 25', 1808],
    ['MAR 25', 1548],
  ],
  'Macarthur Ford Narellan': [
    ['MAR 24', 1511],
    ['APR 24', 2479],
    ['MAY 24', 2641],
    ['JUN 24', 2773],
    ['JUL 24', 2370],
    ['AUG 24', 2855],
    ['SEP 24', 3348],
    ['OCT 24', 2903],
    ['NOV 24', 3551],
    ['DEC 24', 3604],
    ['JAN 25', 4655],
    ['FEB 25', 4019],
    ['MAR 25', 2783],
  ],
  'Macarthur Ford Campbelltown': [
    ['MAR 24', 1481],
    ['APR 24', 2717],
    ['MAY 24', 3094],
    ['JUN 24', 3309],
    ['JUL 24', 2858],
    ['AUG 24', 3088],
    ['SEP 24', 3066],
    ['OCT 24', 3133],
    ['NOV 24', 3318],
    ['DEC 24', 2951],
    ['JAN 25', 4960],
    ['FEB 25', 4012],
    ['MAR 25', 2647],
  ],
  'Macarthur LDV': [
    ['MAR 24', 2969],
    ['APR 24', 7870],
    ['MAY 24', 6095],
    ['JUN 24', 6358],
    ['JUL 24', 5602],
    ['AUG 24', 4559],
    ['SEP 24', 13078],
    ['OCT 24', 17190],
    ['NOV 24', 14482],
    ['DEC 24', 13268],
    ['JAN 25', 14848],
    ['FEB 25', 12584],
    ['MAR 25', 8287],
  ],
  'Macarthur Nissan': [
    ['MAR 24', 2969],
    ['APR 24', 7870],
    ['MAY 24', 6095],
    ['JUN 24', 6358],
    ['JUL 24', 5602],
    ['AUG 24', 4559],
    ['SEP 24', 13078],
    ['OCT 24', 17190],
    ['NOV 24', 14482],
    ['DEC 24', 13268],
    ['JAN 25', 14848],
    ['FEB 25', 12584],
    ['MAR 25', 8287],
  ],
  'Macarthur Mazda': [
    ['MAR 24', 2969],
    ['APR 24', 7870],
    ['MAY 24', 6095],
    ['JUN 24', 6358],
    ['JUL 24', 5602],
    ['AUG 24', 4559],
    ['SEP 24', 13078],
    ['OCT 24', 17190],
    ['NOV 24', 14482],
    ['DEC 24', 13268],
    ['JAN 25', 14848],
    ['FEB 25', 12584],
    ['MAR 25', 8287],
  ],
  'Macarthur MG': [
    ['MAR 24', 2969],
    ['APR 24', 7870],
    ['MAY 24', 6095],
    ['JUN 24', 6358],
    ['JUL 24', 5602],
    ['AUG 24', 4559],
    ['SEP 24', 13078],
    ['OCT 24', 17190],
    ['NOV 24', 14482],
    ['DEC 24', 13268],
    ['JAN 25', 14848],
    ['FEB 25', 12584],
    ['MAR 25', 8287],
  ],
  'Peter Warren Suzuki': [
    ['MAR 24', 165],
    ['APR 24', 932],
    ['MAY 24', 458],
    ['JUN 24', 867],
    ['JUL 24', 860],
    ['AUG 24', 1504],
    ['SEP 24', 1883],
    ['OCT 24', 417],
    ['NOV 24', 919],
    ['DEC 24', 550],
    ['JAN 25', 610],
    ['FEB 25', 523],
    ['MAR 25', 1048],
  ],
  'Peter Warren Automotive': [
    ['MAR 24', 6016],
    ['APR 24', 16088],
    ['MAY 24', 14876],
    ['JUN 24', 15670],
    ['JUL 24', 14358],
    ['AUG 24', 13650],
    ['SEP 24', 26165],
    ['OCT 24', 14849],
    ['NOV 24', 3],
    ['DEC 24', 15791],
    ['JAN 25', 30186],
    ['FEB 25', 25773],
    ['MAR 25', 16822],
  ],
  'Peter Warren Ford': [
    ['MAR 24', 1567],
    ['APR 24', 3893],
    ['MAY 24', 4260],
    ['JUN 24', 4786],
    ['JUL 24', 4100],
    ['AUG 24', 6319],
    ['SEP 24', 6232],
    ['OCT 24', 6023],
    ['NOV 24', 6610],
    ['DEC 24', 7948],
    ['JAN 25', 11569],
    ['FEB 25', 9972],
    ['MAR 25', 6686],
  ],
  'Peter Warren Kia': [
    ['MAR 24', 1224],
    ['APR 24', 3216],
    ['MAY 24', 3589],
    ['JUN 24', 3457],
    ['JUL 24', 3278],
    ['AUG 24', 3154],
    ['SEP 24', 3437],
    ['OCT 24', 3891],
    ['NOV 24', 3477],
    ['DEC 24', 3084],
    ['JAN 25', 3608],
    ['FEB 25', 3532],
    ['MAR 25', 2325],
  ],
  'Peter Warren ŠKODA': [
    ['MAR 24', 329],
    ['APR 24', 767],
    ['MAY 24', 971],
    ['JUN 24', 1072],
    ['JUL 24', 672],
    ['AUG 24', 706],
    ['SEP 24', 1726],
    ['OCT 24', 2356],
    ['NOV 24', 2628],
    ['DEC 24', 2600],
    ['JAN 25', 932],
    ['FEB 25', 782],
    ['MAR 25', 467],
  ],
  'Peter Warren Isuzu UTE': [
    ['MAR 24', 524],
    ['APR 24', 1198],
    ['MAY 24', 1301],
    ['JUN 24', 1290],
    ['JUL 24', 1233],
    ['AUG 24', 1211],
    ['SEP 24', 1295],
    ['OCT 24', 1651],
    ['NOV 24', 1703],
    ['DEC 24', 1876],
    ['JAN 25', 1034],
    ['FEB 25', 901],
    ['MAR 25', 651],
  ],
  'Peter Warren Renault': [
    ['MAR 24', 404],
    ['APR 24', 1031],
    ['MAY 24', 1323],
    ['JUN 24', 1445],
    ['JUL 24', 1565],
    ['AUG 24', 1297],
    ['SEP 24', 1866],
    ['OCT 24', 973],
    ['NOV 24', 4181],
    ['DEC 24', 3632],
    ['JAN 25', 1241],
    ['FEB 25', 939],
    ['MAR 25', 596],
  ],
  'North Shore Ford': [
    ['MAR 24', 1025],
    ['APR 24', 2730],
    ['MAY 24', 3650],
    ['JUN 24', 3647],
    ['JUL 24', 3098],
    ['AUG 24', 3074],
    ['SEP 24', 4902],
    ['OCT 24', 5661],
    ['NOV 24', 3470],
    ['DEC 24', 2726],
    ['JAN 25', 4375],
    ['FEB 25', 3028],
    ['MAR 25', 1995],
  ],
  'Peter Warren Toyota': [
    ['MAR 24', 5783],
    ['APR 24', 15129],
    ['MAY 24', 15128],
    ['JUN 24', 15088],
    ['JUL 24', 13497],
    ['AUG 24', 12813],
    ['SEP 24', 12932],
    ['OCT 24', 8171],
    ['NOV 24', 8658],
    ['DEC 24', 7326],
    ['JAN 25', 8513],
    ['FEB 25', 9563],
    ['MAR 25', 6802],
  ],
  'Peter Warren Peugeot': [
    ['MAR 24', 77],
    ['APR 24', 224],
    ['MAY 24', 288],
    ['JUN 24', 217],
    ['JUL 24', 89],
    ['AUG 24', 289],
    ['SEP 24', 124],
    ['OCT 24', 3],
    ['NOV 24', 4],
    ['DEC 24', 3],
    ['JAN 25', 4],
    ['FEB 25', 4],
    ['MAR 25', 1],
  ],
  'Peter Warren Honda': [],
  'Sydney North Shore Automotive': [
    ['MAR 24', 336],
    ['APR 24', 832],
    ['MAY 24', 1091],
    ['JUN 24', 934],
    ['JUL 24', 888],
    ['AUG 24', 792],
    ['SEP 24', 915],
    ['OCT 24', 952],
    ['NOV 24', 1028],
    ['DEC 24', 806],
    ['JAN 25', 1225],
    ['FEB 25', 1212],
    ['MAR 25', 697],
  ],
  'Geely Liverpool': [
    ['FEB 25', 436],
    ['MAR 25', 1596],
  ],
}

export const getColumnHeights = (
  containerRef: React.RefObject<HTMLDivElement>
): { firstColumnLongest: boolean; firstColumnHeight: number; longestColumnHeight: number } => {
  // Checks if the first column is the longest excluding the last module in the first column
  if (!containerRef.current)
    return { firstColumnLongest: false, firstColumnHeight: 0, longestColumnHeight: 0 }

  const columns = containerRef.current.children

  let firstColumnHeight = 0
  let secondColumnHeight = 0
  let thirdColumnHeight = 0
  let fourthColumnHeight = 0

  Array.from(columns).forEach((column, columnIndex) => {
    if (column instanceof HTMLElement) {
      const modules = Array.from(column.children)
      modules.forEach((module, moduleIndex) => {
        if (module instanceof HTMLElement) {
          if (columnIndex === 0) {
            if (moduleIndex !== modules.length - 1) {
              firstColumnHeight += module.clientHeight
            }
          } else if (columnIndex === 1) {
            secondColumnHeight += module.clientHeight
          } else if (columnIndex === 2) {
            thirdColumnHeight += module.clientHeight
          } else if (columnIndex === 3) {
            fourthColumnHeight += module.clientHeight
          }
        }
      })
    }
  })

  const firstColumnLongest =
    firstColumnHeight >= secondColumnHeight &&
    firstColumnHeight >= thirdColumnHeight &&
    firstColumnHeight >= fourthColumnHeight

  // Get the height on the longest column
  const longestColumnHeight = Math.max(
    firstColumnHeight,
    secondColumnHeight,
    thirdColumnHeight,
    fourthColumnHeight
  )

  return { firstColumnLongest, firstColumnHeight, longestColumnHeight }
}
