import { useContext, useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { formatDistanceToNow } from 'date-fns'
import { toZonedTime } from 'date-fns-tz'
import { useParams } from 'react-router-dom'
import Select from 'react-select'

import * as Routes from '../../routes'
import Loading from '../Loading'
import { LeadClusterContext } from '../contexts'
import { CurrentUserContext } from '../contexts'
import { formatSimpleDuration } from '../entries/timeAgo'
import { standardHeaders, truncateString } from '../entries/utils'
import LeadSourceBadge from './LeadSourceBadge'
import SlideIn from './SlideIn'

const SlideInComponent = ({ cluster, buttonComponent }) => {
  let [leadCluster, setLeadCluster] = useState(cluster)

  return (
    <LeadClusterContext.Provider value={{ leadCluster, setLeadCluster }}>
      <SlideIn leadCluster={leadCluster} buttonComponent={buttonComponent} />
    </LeadClusterContext.Provider>
  )
}

const LeadClusterBox = ({ leadCluster }) => {
  const itemStyle = {
    flex: '0 0 auto', // Ensures items don’t shrink or grow and maintain their width
    width: '225px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    overflow: 'hidden',
  }

  const renderButton = ({ setVisible }) => (
    <div className="btn btn-outline-primary btn-sm btn-block" onClick={() => setVisible(true)}>
      View
    </div>
  )
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const createdAtInUserTimezone = toZonedTime(leadCluster.created_at, userTimezone)
  const followupDateInUserTimezone = leadCluster.next_due_event
    ? toZonedTime(leadCluster.next_due_event.followup_datetime, userTimezone)
    : null

  // Calculate time from now if followupDateInUserTimezone is defined
  const timeFromNow = followupDateInUserTimezone
    ? formatDistanceToNow(followupDateInUserTimezone, { addSuffix: true })
    : 'No due date'

  return (
    <div style={itemStyle}>
      <div className="border-bottom p-2">
        <div className="d-flex" style={{ flexWrap: 'wrap' }}>
          <h6 dangerouslySetInnerHTML={{ __html: truncateString(leadCluster.title, 23) }} />
          <div className="text-secondary small ml-auto">
            {formatSimpleDuration(createdAtInUserTimezone, new Date())} ago
          </div>
        </div>
      </div>
      <div className="p-2 border-bottom d-flex align-items-center">
        {leadCluster.car && leadCluster.car.make ? (
          <>
            <img
              src={leadCluster.car.primary_image_url}
              height="40"
              className="rounded"
              loading="lazy"
            />
            <div className="ml-2 text-secondary">
              <small>
                {leadCluster.car.year} {leadCluster.car.make} {leadCluster.car.model}
              </small>
              <div className="small">{leadCluster.car.stocknum}</div>
              <div className="small">${leadCluster.car.price.toLocaleString()}</div>
            </div>
          </>
        ) : (
          <div className="text-secondary">
            <i className="fas fa-exclamation-triangle mr-1"></i>
            No Item
          </div>
        )}
      </div>
      <div className="p-2 d-flex align-items-center border-bottom">
        {leadCluster.user ? (
          <div className="badge badge-info">{leadCluster.user?.name}</div>
        ) : (
          <div className="badge badge-danger">
            <i className="fas fa-exclamation-triangle mr-1"></i>
            Unassigned
          </div>
        )}
      </div>
      <div className="p-2 d-flex align-items-center border-bottom">
        <div
          className={`badge badge-${leadCluster.lead_status_option?.colour_class_name || 'secondary'}`}
        >
          {leadCluster.lead_status}
        </div>
      </div>
      {leadCluster.next_due_event && leadCluster.next_due_event.followup_datetime && (
        <div className="p-2 d-flex align-items-center border-bottom">
          <div className="badge badge-danger">
            <i className="fas fa-exclamation-triangle mr-1"></i>
            Due {timeFromNow}
          </div>
        </div>
      )}
      <div className="p-2 d-flex align-items-center">
        <div>
          <div className="badge badge-sm badge-secondary">{leadCluster.category}</div>
          <div>
            <LeadSourceBadge lead={leadCluster} />
          </div>
        </div>
        <SlideInComponent cluster={leadCluster} buttonComponent={renderButton} />
      </div>
    </div>
  )
}

const LeadsBox = ({
  title,
  activeBoxes,
  setActiveBoxes,
  className,
  filter,
  user,
  showActiveLeadsOnly,
}) => {
  let { dealershipSlug } = useParams()
  let active = activeBoxes.includes(title)
  let [activeLeadsOnly, setActiveLeadsOnly] = useState(true)

  const fetchLeadClusters = async () => {
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const url = Routes.dealership_lead_clusters_path(dealershipSlug, {
      filter,
      user_id: user,
      timezone: currentTimeZone,
      active_leads_only: activeLeadsOnly,
    })

    const response = await fetch(url, { method: 'GET', headers: { Accept: 'application/json' } })
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    return response.json()
  }

  const { data, isLoading, error } = useQuery({
    queryKey: ['leadClusters', dealershipSlug, filter, user, activeLeadsOnly], // Unique cache key
    queryFn: fetchLeadClusters,
    enabled: active, // Only fetch when active
    staleTime: 5 * 60 * 1000, // Cache data for 10 minutes
    cacheTime: 5 * 60 * 1000,
  })

  const toggleActive = () => {
    setActiveBoxes((prev) =>
      prev.includes(title) ? prev.filter((box) => box !== title) : [...prev, title]
    )
  }

  return (
    <div className="box mb-3">
      <div className="px-3 py-2 border-bottom">
        <div className="d-flex align-items-center">
          <h5 className={`m-0 badge badge-${className}`}>{title}</h5>
          <div className="ml-auto d-flex align-items-center">
            {showActiveLeadsOnly && (
              <div
                className={
                  'btn mr-2 btn-sm btn-outline-success' + (activeLeadsOnly ? ' active' : '')
                }
                onClick={() => setActiveLeadsOnly(!activeLeadsOnly)}
              >
                Active Leads Only
              </div>
            )}
            <button className="btn btn-sm btn-outline-primary" onClick={toggleActive}>
              {active ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
            </button>
          </div>
        </div>
      </div>
      {active && (
        <div
          style={{
            display: 'flex',
            overflowX: 'auto',
            whiteSpace: 'nowrap',
            gap: '10px',
            padding: '20px 10px',
          }}
        >
          {isLoading ? (
            <Loading />
          ) : error ? (
            <div>Error fetching data</div>
          ) : data.lead_clusters.length > 0 ? (
            data.lead_clusters.map((leadCluster) => (
              <LeadClusterBox key={leadCluster.id} leadCluster={leadCluster} />
            ))
          ) : (
            <div>You have no events {title}!</div>
          )}
        </div>
      )}
    </div>
  )
}

const taskBoxes = {
  new: { title: 'New', className: 'warning' },
  unactioned: { title: 'Unactioned', className: 'warning' },
  overdue: { title: 'Overdue', className: 'danger', showActiveLeadsOnly: true },
  dueToday: { title: 'Due Today', className: 'success' },
  dueThisWeek: { title: 'Due This Week', className: 'secondary' },
  dueNextWeek: { title: 'Due Next Week', className: 'secondary' },
  converted: { title: 'Converted', className: 'success' },
  spamLost: { title: 'Spam/Lost', className: 'danger' },
}

const TasksView = ({ data, currentUser }) => {
  let [activeBoxes, setActiveBoxes] = useState(['New', 'Unactioned', 'Overdue', 'Due Today'])

  let [user, setUser] = useState(currentUser?.id)

  let userOptions = data.users.map((user) => ({ value: user.id, label: user.name }))

  let sharedProps = { activeBoxes, setActiveBoxes, user }

  return (
    <div>
      <div className="d-flex mb-1">
        <h3>Tasks</h3>
        <div className="ml-auto">
          <Select
            options={userOptions}
            defaultValue={userOptions.find((u) => u.value === currentUser.id)}
            onChange={(o) => setUser(o.value)}
            placeholder="Filter by user"
          />
        </div>
      </div>
      {/* Loop through the taskBoxes object */}
      {Object.keys(taskBoxes).map((key) => (
        <LeadsBox key={key} {...taskBoxes[key]} filter={key} {...sharedProps} />
      ))}
    </div>
  )
}

const fetchTasks = async ({ queryKey }) => {
  const [, dealershipSlug] = queryKey
  const response = await fetch(Routes.tasks_dealership_lead_clusters_path(dealershipSlug), {
    headers: standardHeaders,
  })
  if (!response.ok) throw new Error('Failed to fetch tasks')
  return response.json()
}

const Wrapper = () => {
  let { dealershipSlug } = useParams()
  let currentUser = useContext(CurrentUserContext)

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['tasks', dealershipSlug],
    queryFn: fetchTasks,
    staleTime: 10 * 60 * 1000, // Cache data for 10 minutes
    cacheTime: 10 * 60 * 1000,
  })

  if (!currentUser) return <Loading />
  if (isLoading) return <Loading />
  if (isError) return <div className="p-3 text-danger">Error: {error.message}</div>

  return <TasksView data={data} currentUser={currentUser} />
}

export default Wrapper
