import { useContext, useState } from 'react'

import { Sidebar } from 'primereact/sidebar'

import { DealershipContext, SidebarContext } from '../contexts'
import Redbook from './redbook'

const sidebarStyle = () => ({
  height: '100%',
  width: '100%',
  maxHeight: '100vh',
  maxWidth: '1200px',
  borderRadius: 0,
  backgroundColor: '#fff',
})

const RedbookMissing = ({ hit, toggleVisible }) => (
  <>
    {!hit.has_redbook && (
      <a href="#" onClick={toggleVisible}>
        <div className="badge badge-danger mr-1" id={`redbook-missing-btn-${hit.id}`}>
          REDBOOK MISSING
        </div>
      </a>
    )}
  </>
)

const NvicMissing = ({ hit, toggleVisible }) => (
  <>
    {!hit.has_nvic && (
      <a href="#" onClick={toggleVisible}>
        <div className="badge badge-danger">NVIC MISSING</div>
      </a>
    )}
  </>
)

const CatalogueBadges = ({ hit, toggleVisible }) => {
  const { dealership } = useContext(DealershipContext)

  if (!dealership)
    // if we are on non dealership pages (e.g. all cars page, or manufacturer cars page, show both badges)
    return (
      <>
        <RedbookMissing hit={hit} toggleVisible={toggleVisible} />
        <NvicMissing hit={hit} toggleVisible={toggleVisible} />
      </>
    )

  return (
    <>
      {hit.item_type === 'car' && dealership?.preferred_catalogue === 'redbook' ? (
        <RedbookMissing hit={hit} toggleVisible={toggleVisible} />
      ) : (
        <NvicMissing hit={hit} toggleVisible={toggleVisible} />
      )}
    </>
  )
}

export const CatalogueWarning = ({ hit, setCar }) => {
  let [visible, setVisible] = useState(false)

  const toggleVisible = (e) => {
    e.preventDefault()
    setVisible(!visible)
  }

  return (
    <>
      <CatalogueBadges hit={hit} toggleVisible={toggleVisible} />
      <SidebarContext.Provider value={{ visible, setVisible, setCar, hit }}>
        <Sidebar
          visible={visible}
          onHide={() => {
            setVisible(!visible)
          }}
          header="Select Variant"
          position="right"
          style={{ ...sidebarStyle() }}
        >
          {visible && <Redbook hitId={hit.id} />}
        </Sidebar>
      </SidebarContext.Provider>
    </>
  )
}

export default CatalogueWarning
