import { useRef, useState } from 'react'

import { Toast } from 'primereact/toast'
import Select from 'react-select'

import showToast from '../shared/ShowToast'

const OwnerSelect = ({ hit, selectedValue, onOwnerChange, disabled, className, users = false }) => {
  let [loading, setLoading] = useState(false)
  const [unassigned, setUnassigned] = useState(false)
  const notification = useRef(null)
  let usersForSelect = []
  if (users) {
    usersForSelect = users
  } else {
    usersForSelect = hit.available_users
  }

  let options = usersForSelect

  let locationSpecificUsers = usersForSelect?.filter((u) =>
    u.location_ids?.includes(hit.location_id)
  )

  if (hit.location_id && locationSpecificUsers?.length > 0) {
    options = [
      {
        label: hit.location_name,
        options: locationSpecificUsers,
      },
      {
        label: 'others',
        options: usersForSelect.filter((u) => !u.location_ids?.includes(hit.location_id)),
      },
    ]
  }

  const onChange = async ({ value }) => {
    try {
      setLoading(true)

      await onOwnerChange(hit.id, value) // This must fully succeed before proceeding

      if (value === null) {
        setUnassigned(true)
        showToast(notification, 'success', 'Lead unassigned')
      } else {
        setUnassigned(false)
        showToast(notification, 'success', 'Lead assigned')
      }
    } catch (error) {
      console.error('Error updating owner:', error)
      showToast(
        notification,
        'error',
        'Failed to update lead cluster assignment. Please try again.'
      )
    } finally {
      setLoading(false)
    }
  }

  if (unassigned) {
    selectedValue = { label: 'Unassigned', value: null }
  }

  let currentValue = usersForSelect?.find((u) => u.value === (selectedValue || hit.user_id))

  const customStyles = {
    singleValue: (provided, state) => {
      let custom = {}
      if (!currentValue) {
        custom = {
          color: 'var(--red)',
        }
      }

      return { ...provided, ...custom }
    },
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensure dropdown is above the modal
    }),
  }

  const Unassigned = () => (
    <span>
      <i className="fa fa-exclamation-triangle mr-1" style={{ color: 'var(--red)' }}></i>
      Unassigned
    </span>
  )

  return (
    <div>
      <Toast ref={notification} />
      <Select
        options={[{ label: 'Unassigned', value: null }, ...options]}
        name="user"
        isLoading={loading}
        value={currentValue || { label: <Unassigned />, value: null }}
        placeholder="Unassigned"
        onChange={onChange}
        isDisabled={disabled}
        styles={customStyles}
        className={className || 'user-select'}
        menuPortalTarget={document.body}
      />
    </div>
  )
}

export default OwnerSelect
