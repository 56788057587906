import { useContext, useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'

import { LeadClusterContext } from '../contexts'
import { CurrentUserContext } from '../contexts'
import { DealershipContext } from '../contexts'
import { humanize } from '../entries/utils'
import { standardHeaders } from '../entries/utils'
import { canManageLeads } from '../leads/utils'
import checkPermission from '../shared/PermissionsChecker'
import LeadEventForm from './LeadEventForm'

const MarkAsCompleted = ({ event, setLeadEvents }) => {
  let [markingAsCompleted, setMarkingAsCompleted] = useState(false)

  const { leadCluster } = useContext(LeadClusterContext)
  const { dealership } = useContext(DealershipContext)
  let currentUser = useContext(CurrentUserContext)

  let canMarkAsCompleted =
    currentUser.admin || currentUser?.dealerships[dealership?.id]?.role === 'manager'

  const markAsCompleted = () => {
    if (!canMarkAsCompleted) {
      return
    }

    setMarkingAsCompleted(true)
    fetch(`/lead_clusters/${leadCluster.id}/lead_events/${event.id}`, {
      headers: standardHeaders,
      method: 'PATCH',
      body: JSON.stringify({
        lead_event: {
          followup_status: 'completed',
        },
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setLeadEvents(data.leadEvents)
        setMarkingAsCompleted(false)
      })
  }

  if (event.followup_status === 'completed') {
    return (
      <div className="small text-success">
        <i className="fa fa-check mr-2"></i>
        Completed {event.followup_completed_at && moment(event.followup_completed_at).fromNow()}
      </div>
    )
  }

  return (
    <>
      <div
        className={`btn btn-sm btn-outline-success mt-2 ${canMarkAsCompleted ? '' : ' disabled'}`}
        onClick={markAsCompleted}
        disabled={!canMarkAsCompleted}
        id="mark-as-completed"
      >
        {markingAsCompleted ? (
          <i className="fas fa-spinner fa-spin mr-2"></i>
        ) : (
          <>
            <i className="fa fa-check mr-2"></i>
            Mark as completed
          </>
        )}
      </div>
      <Tooltip anchorSelect={`#mark-as-completed`} place="bottom" style={{ zIndex: 10000 }}>
        <div className="text-left">Only managers can mark lead events as completed</div>
        <div className="text-left">Non-managers must add a new lead event below</div>
      </Tooltip>
    </>
  )
}

const LeadEvent = ({ event, setLeadEvents }) => {
  const { leadCluster } = useContext(LeadClusterContext)
  const { dealership } = useContext(DealershipContext)
  const currentUser = useContext(CurrentUserContext)

  // Fetch permission using useQuery
  const { data: permissions, isLoading } = useQuery({
    queryKey: [
      `lead_cluster_${leadCluster?.id}`,
      `dealership_${dealership?.id}`,
      `user_${currentUser?.id}`,
      'permissions',
      'LeadEvent:destroy',
    ],
    queryFn: () => checkPermission(currentUser?.id, dealership?.id, 'LeadEvent:destroy'),
  })

  const canRemoveEvents = permissions ? permissions['LeadEvent:destroy'] : false

  let [loading, setLoading] = useState(false)

  const removeEvent = () => {
    if (!window.confirm('Delete the item?')) {
      return
    }

    setLoading(true)

    const url = `/lead_clusters/${leadCluster.id}/lead_events/${event.id}`

    fetch(url, {
      method: 'DELETE',
      headers: standardHeaders,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.errors) {
          console.log(data)
          throw new Error('An error occured')
        }

        setLoading(false)
        setLeadEvents(leadCluster.leadEvents.filter((leadEvent) => leadEvent.id !== event.id))
      })
  }

  return (
    <li className={`pb-1 ${event.event_type}`}>
      <div className="float-right text-right mr-3">
        <div>
          <div className="text-secondary" id={`event-${event.id}`}>
            {moment(event.created_at).fromNow()}
          </div>
          {canRemoveEvents && (
            <div className="small text-secondary">
              <a onClick={removeEvent} style={{ cursor: 'pointer' }}>
                {loading ? 'Loading...' : 'Remove'}
              </a>
            </div>
          )}
          {event.followup_reason && <MarkAsCompleted event={event} setLeadEvents={setLeadEvents} />}
        </div>
      </div>
      <Tooltip anchorSelect={`#event-${event.id}`} place="bottom" style={{ zIndex: 10000 }}>
        {moment(event.created_at).format('DD/MM/YYYY HH:mm')}
      </Tooltip>
      <h6>{humanize(event.event_type)}</h6>
      <div>{event.description}</div>
      {event.followup_reason && (
        <>
          <div className={`small text-${overdue(event) ? 'danger' : 'secondary'}`}>
            <b>{overdue(event) ? 'Overdue:' : 'Followup:'}</b> {event.followup_reason} at{' '}
            {moment(event.followup_datetime).format('DD/MM/YYYY HH:mm')}
          </div>
        </>
      )}
      <span className="small text-secondary">
        Event created by: {event.user?.name || event.external_service}
      </span>
    </li>
  )
}

function overdue(event) {
  if (event.followup_status === 'completed') return false

  return moment(event.followup_datetime) < moment()
}

const LeadEvents = ({ leadEvents, setLeadEvents, loading }) => {
  return (
    <div className="px-3 py-1">
      <div className="box">
        <div className="px-3 pt-3">
          <h5 className="text-secondary">Lead Events</h5>
        </div>
        {loading && (
          <div className="p-3">
            <i className="fas fa-spinner fa-spin mr-2"></i>
            Loading...
          </div>
        )}
        <ul className="timeline">
          {leadEvents &&
            leadEvents.map((leadEvent) => (
              <LeadEvent
                event={leadEvent}
                setLeadEvents={setLeadEvents}
                key={`event-${leadEvent.id}`}
              />
            ))}
        </ul>
      </div>
    </div>
  )
}

const LeadEventsContainer = () => {
  const { leadCluster, setLeadCluster } = useContext(LeadClusterContext)
  const { leadEvents: defaultLeadEvents } = leadCluster
  let [leadEvents, setLeadEvents] = useState(defaultLeadEvents || false)
  let [loading, setLoading] = useState(false)
  let { dealershipSlug } = useParams()

  useEffect(() => {
    if (defaultLeadEvents) {
      return
    }

    const url = `/dealerships/${dealershipSlug}/lead_clusters/${leadCluster.id}`

    setLoading(true)
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setLeadEvents(data.leadEvents)
        setLeadCluster({ ...leadCluster, event_counts: data.event_counts })
        setLoading(false)
      })
  }, [])

  return (
    <>
      <LeadEvents leadEvents={leadEvents} setLeadEvents={setLeadEvents} loading={loading} />
      {canManageLeads() && (
        <div className="px-3 py-1">
          <div className="box">
            <LeadEventForm setLeadEvents={setLeadEvents} />
          </div>
        </div>
      )}
    </>
  )
}

export default LeadEventsContainer
