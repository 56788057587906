import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'

import LoadingBoxes from '../../../../entries/LoadingBoxes'
import { useAnalyticsDashboard, usePreviewData } from '../../../contexts/hooks'
import { useViewportData } from '../../../hooks'
import { ModuleProps } from '../../../types'
import { getColor, isEmptyObject } from '../../../utils'

type Campaign = {
  campaign_name: string
  clicks: string
  impressions: string
  phone_calls: string
  spend: string
  date_start: string
  date_stop: string
  leads: string
  cost_per_lead: string
  leads_difference: string
  cost_per_lead_difference: string
  clicks_difference: string
  spend_difference: string
  impressions_difference: string
  phone_calls_difference: string
}

const EngagementByCampaign: React.FC<ModuleProps> = ({ item, timeRange, selectedLocation }) => {
  const { data, loading, viewportRef } = useViewportData(item?.module, timeRange, selectedLocation)
  const { editingPreviewData } = usePreviewData()
  const { editModeEnabled } = useAnalyticsDashboard()

  const columns = [
    {
      field: 'campaign_name',
      header: 'Campaign Name',
      className: 'small',
      isCurrency: false,
      differenceField: null,
    },
    {
      field: 'clicks',
      header: 'Clicks',
      isCurrency: false,
      differenceField: 'clicks_difference',
    },
    {
      field: 'impressions',
      header: 'Impressions',
      isCurrency: false,
      differenceField: 'impressions_difference',
    },
    {
      field: 'phone_calls',
      header: 'Phone Calls',
      isCurrency: false,
      differenceField: 'phone_calls_difference',
    },
    {
      field: 'leads',
      header: 'Total Leads',
      isCurrency: false,
      differenceField: 'leads_difference',
    },
    {
      field: 'cost_per_lead',
      header: 'Cost Per Lead',
      isCurrency: true,
      differenceField: 'cost_per_lead_difference',
    },
    {
      field: 'spend',
      header: 'Amount Spent',
      isCurrency: true,
      differenceField: 'spend_difference',
    },
  ]

  function format(value: string, currency: boolean = false): string {
    // Convert string to number
    const parsedValue = parseFloat(value)
    // If value is not a number, return empty string
    if (isNaN(parsedValue)) {
      return ''
    }
    // If value is negative, return formatted string with negative sign
    if (parsedValue < 0) {
      if (currency) {
        const stringValue = parsedValue.toFixed(2).toString()
        return '-$' + stringValue.slice(1)
      } else {
        return parsedValue.toString()
      }
      // If value is positive, return formatted string with positive sign
    } else if (parsedValue > 0) {
      if (currency) {
        return '+$' + parsedValue.toFixed(2).toString()
      } else {
        return '+' + parsedValue.toString()
      }
    }
    return ''
  }

  // TODO: Improve this logic or maybe move to BE
  // Show preview data first, if it exists and isn't empty. Then check is data exists and isn't empty. If they are empty set to []
  const notEmptyPreviewData = isEmptyObject(editingPreviewData?.[item?.module])
    ? []
    : editingPreviewData?.[item?.module]

  const notEmptyData = isEmptyObject(data?.[item?.module]) ? [] : data?.[item?.module]

  const dataToShow =
    editModeEnabled && editingPreviewData?.[item?.module] ? notEmptyPreviewData : notEmptyData

  const valueTemplate = (
    data: Campaign,
    field: keyof Campaign,
    difference: string,
    isCurrency: boolean = false
  ) => (
    <>
      <b>
        {isCurrency && !isNaN(parseFloat(data[field] as string))
          ? `$${parseFloat(data[field] as string).toFixed(2)}`
          : (data[field] as string)?.toLocaleString()}
      </b>
      <br />
      <small className={getColor(data[difference] as string, isCurrency)}>
        {format(data[difference] as string, isCurrency)}
      </small>
    </>
  )

  return (
    <div ref={viewportRef}>
      {!loading && dataToShow ? (
        <div>
          <DataTable
            value={dataToShow}
            className="table table-sm table-striped mt-3"
            scrollable
            scrollHeight="400px"
            sortMode="multiple"
          >
            {columns.map(({ field, header, className, isCurrency, differenceField }) => (
              <Column
                key={field}
                field={field}
                header={header}
                className={className + ' text-center'}
                sortable
                body={
                  differenceField
                    ? (data) =>
                        valueTemplate(data, field as keyof Campaign, differenceField, isCurrency)
                    : undefined
                }
              />
            ))}
          </DataTable>
        </div>
      ) : (
        <LoadingBoxes />
      )}
    </div>
  )
}

export default EngagementByCampaign
