import { CSSProperties, HTMLAttributes, forwardRef, useEffect, useState } from 'react'

import { moduleComponents } from '../../../config'
import {
  useAnalyticsDashboard,
  useConfig,
  useItems,
  useTabs,
  useTimeRangeLocation,
} from '../../../contexts/hooks'
import type { Location, ModuleItem, TimeRange } from '../../../types'
import ModuleWrapperComponent from '../../AnalyticsModules/components/ModuleWrapperComponent'
import { useValidExpandPositions } from '../../AnalyticsModules/components/hooks'

type ItemProps = HTMLAttributes<HTMLDivElement> & {
  item?: ModuleItem
  withOpacity?: boolean
  isDragging?: boolean
  isOverlay?: boolean
  draghandleProps?: any
  itemIndex?: number
  rowWidth?: number
}

// Uses forwardRef to reference the DOM element
const Item = forwardRef<HTMLDivElement, ItemProps>(
  (
    {
      id,
      item,
      withOpacity,
      isDragging,
      isOverlay = false,
      draghandleProps,
      style,
      itemIndex,
      rowWidth,
      ...props
    },
    ref
  ) => {
    const { items, handleRemoveItem, expandToRowWidth, setExpandToRowWidth } = useItems()
    const { config } = useConfig()
    const { timeRange, selectedLocation } = useTimeRangeLocation()
    const { editModeEnabled } = useAnalyticsDashboard()
    const { selectedTab } = useTabs()

    const [timeRangeType, setTimeRangeType] = useState<string>('')

    const toggleExpandToRowWidth = (): void => {
      setExpandToRowWidth(!expandToRowWidth)
    }

    const validExpandPositions = useValidExpandPositions(itemIndex)

    useEffect(() => {
      setTimeRangeType(timeRange?.type)
    }, [timeRange])

    useEffect(() => {
      if (config?.fixedMode && validExpandPositions) setExpandToRowWidth(true)
    }, [config, selectedTab, items])

    const inlineStyles: CSSProperties = {
      opacity: withOpacity ? '0.5' : '1',
      width: expandToRowWidth && validExpandPositions ? rowWidth : '100%',
      borderRadius: '10px',
      cursor: isOverlay ? (isDragging ? 'grabbing' : 'grab') : null,
      backgroundColor: '#fff',
      minHeight:
        config?.layoutSize === 'small' ? '13vw' : config?.layoutSize === 'medium' ? '17vw' : '20vw',
      height: editModeEnabled
        ? config?.layoutSize === 'small'
          ? '17vw'
          : config?.layoutSize === 'medium'
            ? '20vw'
            : '25vw'
        : 'auto',
      boxShadow: isDragging
        ? 'rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px'
        : 'rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px',
      border: isDragging ? '2px solid #007bff' : 'none',
      position: 'relative',
      zIndex: '99',
      ...style,
    }

    return (
      <div ref={ref} style={inlineStyles} className="DragAndDropModule" {...props}>
        <div
          style={{
            padding: `1rem 1rem ${editModeEnabled ? '1.5rem' : '1rem'} 1rem`,
            minHeight: 'inherit',
            height: '100%',
          }}
        >
          {renderChart({
            item,
            itemIndex,
            timeRange,
            selectedLocation,
            isOverlay,
            isDragging,
            draghandleProps,
            timeRangeType,
            expandToRowWidth,
            handleRemoveItem,
            toggleExpandToRowWidth,
          })}
        </div>
      </div>
    )
  }
)

function renderChart({
  item,
  itemIndex,
  timeRange,
  selectedLocation,
  isOverlay,
  isDragging,
  draghandleProps,
  timeRangeType,
  expandToRowWidth,
  handleRemoveItem,
  toggleExpandToRowWidth,
}: {
  item: ModuleItem
  itemIndex: number
  timeRange: TimeRange
  selectedLocation: Location
  isOverlay?: boolean
  isDragging?: boolean
  draghandleProps?: any
  timeRangeType?: string
  expandToRowWidth?: boolean
  handleRemoveItem: (item: ModuleItem) => void
  toggleExpandToRowWidth: () => void
}): JSX.Element | null {
  if (item?.module && moduleComponents[item?.module]) {
    const ModuleComponent = moduleComponents[item?.module]?.component
    const { title, showTimeFrame, button, Icon } = moduleComponents[item?.module]
    return ModuleComponent ? (
      <ModuleWrapperComponent
        timeRange={timeRange}
        item={item}
        itemIndex={itemIndex}
        expandToRowWidth={expandToRowWidth}
        toggleExpandToRowWidth={toggleExpandToRowWidth}
        isDragging={isDragging}
        draghandleProps={draghandleProps}
        title={title}
        showTimeFrame={showTimeFrame}
        button={button}
        Icon={Icon}
      >
        <ModuleComponent
          isOverlay={isOverlay}
          selectedLocation={selectedLocation}
          timeRange={timeRange}
          item={item}
          timeRangeType={timeRangeType}
        />
      </ModuleWrapperComponent>
    ) : null
  } else {
    handleRemoveItem(item)
    return null
  }
}

export default Item
