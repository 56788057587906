import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Link, useParams } from 'react-router-dom'

import * as Routes from '../../../routes'
import { canManageCars } from '../../cars/utils'
import { useFetchDealership } from '../../dataHooks'
import { Contact, CreatedAt, LastEditBy, Location, Status, Title } from '../Search'

const editButton = (testDrive) => {
  let { dealershipSlug } = useParams()

  if (!dealershipSlug) return null

  return (
    <Link
      className="btn btn-outline-primary btn-sm"
      to={Routes.edit_dealership_test_drive_path(dealershipSlug, testDrive.id)}
    >
      <i className="fa fa-edit mr-1"></i>
      Edit
    </Link>
  )
}

const TestDrivesBox = ({ testDrives, ownerType, ownerId }) => {
  let { dealership } = useFetchDealership()
  const firstColumnComponent = ownerType === 'contact' ? Title : Contact
  const firstColumnHeader = ownerType === 'contact' ? 'Title' : 'Contact'
  const firstColumnField = ownerType === 'contact_name' ? 'title' : 'contact_name'

  if (!dealership) return null

  let url

  if (ownerType === 'contact') {
    url = Routes.new_dealership_test_drive_path(dealership?.slug, {
      contact_id: ownerId,
    })
  } else {
    url = Routes.new_dealership_test_drive_path(dealership?.slug, {
      car_id: ownerId,
    })
  }

  // TODO: Implement a "Add Car Loan" button that prefills the form with car or contact info, depending on the ownerType
  return (
    <div className="box mb-3 overflow-hidden">
      <div className="p-3">
        <div className="d-flex">
          <h4 className="mb-1">
            <i className="fa fa-steering-wheel mr-1"></i>Test Drives
          </h4>
          {canManageCars() && (
            <div className="ml-auto">
              <Link to={url} className="btn btn-outline-primary btn-sm">
                <i className="fa fa-plus mr-1"></i>
                Add Test Drive
              </Link>
            </div>
          )}
        </div>
      </div>
      <DataTable value={testDrives} tableStyle={{ minWidth: '50rem' }} paginator rows={10}>
        <Column
          sortable
          body={firstColumnComponent}
          field={firstColumnField}
          header={firstColumnHeader}
        ></Column>
        <Column sortable field="status" header="Status" body={Status} />
        <Column
          sortable
          body={Location}
          field="location"
          header="Location"
          showFilterMenu={false}
          filterMenuStyle={{ width: '14rem' }}
        />
        <Column
          sortable
          body={CreatedAt}
          field="created_at"
          header="Creation Date"
          dataType="date"
        />
        <Column
          sortable
          body={LastEditBy}
          field="last_edit_by"
          header="Last Edited By"
          dataType="date"
        ></Column>
        {canManageCars() && <Column body={(testDrive) => editButton(testDrive)}></Column>}
      </DataTable>
    </div>
  )
}

export default TestDrivesBox
