import React, { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { TimeRange } from '../AnalyticsDashboard/types'
import { fetchResultData } from '../AnalyticsDashboard/utils'
import TimeRangePicker from '../entries/TimeRange'
import { showLoading } from '../entries/utils'
import ChartSection from './components/ChartSection'
import DataAccuracy from './components/DataAccuracy'
import RecentLeads from './components/RecentLeads'

const fetchMarketingData = async (dealershipSlug: string, timeRange: TimeRange) => {
  if (!dealershipSlug) return null

  const queryString = new URLSearchParams(timeRange as any).toString()
  const URL = `${Routes.data_dealership_leads_path(dealershipSlug)}.json?${queryString}`
  return fetchResultData(URL)
}

const MarketingAnalytics: React.FC = () => {
  const { dealershipSlug } = useParams()
  const [timeRange, setTimeRange] = useState<TimeRange>({
    start: 0,
    end: 0,
    timeframe: '',
    type: '',
  })

  const {
    data: marketingData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['marketingAnalytics', dealershipSlug, timeRange],
    queryFn: () => fetchMarketingData(dealershipSlug as string, timeRange),
    enabled: !!dealershipSlug, // Ensure the query only runs when dealershipSlug is available
    staleTime: 5 * 60 * 1000, // Cache data for 5 minutes
    refetchOnWindowFocus: false, // Prevent automatic refetching on window focus
  })

  const handleTimeRangeChange = (dateRange: TimeRange): void => {
    setTimeRange(dateRange)
  }

  const subText =
    'Only shows website leads - All other lead sources are excluded (eg. Chatbot, Carsales etc.)'

  return (
    <div className="w-100 p-4">
      <div>
        <div className="row">
          <h4 className="col-6">Marketing Analytics</h4>
          <div className="col-6 d-flex justify-content-end">
            <TimeRangePicker handleUpdateTimeRange={handleTimeRangeChange} />
          </div>
        </div>
      </div>

      {isLoading ? (
        showLoading()
      ) : error ? (
        <p className="text-danger">Error loading marketing data</p>
      ) : marketingData ? (
        <div>
          <div className="row">
            <div className="col-md-6">
              <ChartSection
                title="Marketing Source"
                subtitle={marketingData?.timeframe?.name || 'Last 30 days'}
                chartData={marketingData?.marketing_performance?.data}
                chartType="Pie"
                details={marketingData?.marketing_performance?.details}
                subText={subText}
              />
              <RecentLeads
                leadsBySource={marketingData?.recent_leads}
                dealershipSlug={dealershipSlug}
              />
            </div>
            <div className="col-md-6">
              <ChartSection
                title="Device"
                subtitle={marketingData?.timeframe?.name || 'Last 30 days'}
                chartData={marketingData?.device_performance?.data}
                chartType="Pie"
                details={marketingData?.device_performance?.details}
                subText={subText}
              />
              <ChartSection
                title="Leads By Source"
                subtitle={marketingData?.timeframe?.name || 'Last 30 days'}
                chartData={marketingData?.leads_by_source}
                chartType="Pie"
              />
            </div>
          </div>
          <DataAccuracy />
        </div>
      ) : (
        <p>No marketing data available</p>
      )}
    </div>
  )
}

export default MarketingAnalytics
