import { useEffect, useRef, useState } from 'react'

import moment from 'moment'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Sidebar } from 'primereact/sidebar'
import { Toast } from 'primereact/toast'
import { useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { Select } from '../entries/FormElements'
import { standardHeaders } from '../entries/utils'
import { CopyableText } from '../shared/CopyableText'
import showToast from '../shared/ShowToast'

const JsonBox = ({ title, json, notification }) => {
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        notification.current.show({
          severity: 'success',
          summary: 'Copied',
          detail: 'Text copied to clipboard!',
        })
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err)
      })
  }

  return (
    <div className="box mb-2 p-2">
      <div className="d-flex">
        <h6>{title}</h6>
        <div className="ml-auto">
          {json && (
            <div onClick={() => copyToClipboard(json)} style={{ cursor: 'pointer' }}>
              <i className="fa fa-copy text-secondary" />
            </div>
          )}
        </div>
      </div>
      <pre>{JSON.stringify(json, null, 2)}</pre>
    </div>
  )
}

const CreatedAt = ({ created_at }) => {
  return <>{moment(created_at).fromNow()}</>
}

export const Apicall = (apicall, rowData) => {
  let [visible, setVisible] = useState(false)
  let [apicallDetails, setApicallDetails] = useState(null)
  let [loading, setLoading] = useState(false)
  let { dealershipSlug } = useParams()
  const notification = useRef(null)

  useEffect(() => {
    fetchApicall()
  }, [apicall.id, visible])

  let fetchApicall = () => {
    if (!visible) return
    if (!apicall.id) return

    setLoading(true)
    fetch(Routes.apicall_path(apicall.id), {
      headers: standardHeaders,
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then((data) => {
        setApicallDetails(data)
        setLoading(false)
      })
      .catch((error) => console.error('Error:', error))
  }

  const exportCarFeeds = () => {
    const feedId = apicall?.feed_id
    const carSlug = apicall?.car?.slug
    if (!feedId) return
    const fetchUrl = `${Routes.export_webhook_feed_dealership_feed_path(dealershipSlug, feedId)}?car_slug=${carSlug}`
    setLoading(true)
    fetch(fetchUrl, {
      headers: standardHeaders,
      method: 'POST',
    }).then((res) => {
      if (res.ok) {
        setLoading(false)
        showToast(
          notification,
          'success',
          'The car has been successfully scheduled for export. Refresh the page to view the update.'
        )
      } else {
        setLoading(false)
        alert('Error exporting feed for car ID: ' + carSlug)
      }
    })
  }

  const Details = () => (
    <>
      <div className="mb-2">
        <strong>ID:</strong> <CopyableText text={apicallDetails?.id} />
      </div>
      <div className="mb-2">
        <strong>Service:</strong> {apicallDetails?.service}
      </div>
      <div className="mb-2">
        <strong>Endpoint URL:</strong> <CopyableText text={apicallDetails?.endpoint_url} />
      </div>
      <div className="mb-2">
        <strong>Response Code:</strong>{' '}
        <span className={apicallDetails?.response_code >= 300 ? ' text-danger' : ' text-success'}>
          {apicallDetails?.response_code}
        </span>
      </div>
      <div className="mb-2">
        <strong>Status:</strong> {apicallDetails?.status}
      </div>
      <div className="mb-2">
        <strong>Created At:</strong> {apicallDetails?.created_at}
      </div>
      <div className="mt-3">
        <JsonBox title="Body" json={apicallDetails?.body} notification={notification} />
        <JsonBox
          title="Request Body"
          json={apicallDetails?.request_body}
          notification={notification}
        />
        <JsonBox
          title="Response Body"
          json={apicallDetails?.response_body}
          notification={notification}
        />
      </div>
    </>
  )

  return (
    <>
      <Toast ref={notification} />
      <Sidebar
        visible={visible}
        style={{ width: '60vw' }}
        onHide={() => setVisible(false)}
        header={`Apicall Details - ${apicallDetails?.service}`}
        position="right"
      >
        <div className="p-3">{loading ? <div>Loading...</div> : <Details />}</div>
      </Sidebar>
      <div className="row">
        <div className="col-md-6">
          <div className="btn btn-sm btn-outline-secondary" onClick={() => setVisible(true)}>
            Show
          </div>
        </div>
        {apicall?.feed_id && (
          <div className="col-md-6">
            <div className="btn btn-sm btn-outline-secondary" onClick={exportCarFeeds}>
              Export
            </div>
          </div>
        )}
      </div>
    </>
  )
}

const ResponseCode = ({ response_code }) => {
  let textClass = 'text-success'

  if (response_code >= 300) {
    textClass = 'text-danger'
  }

  return <span className={textClass}>{response_code}</span>
}

const Apicalls = () => {
  let [apicalls, setApicalls] = useState([])
  let [loading, setLoading] = useState(false)
  let [page, setPage] = useState(1)
  let [initialised, setInitialised] = useState(false)
  let [totalCount, setTotalCount] = useState(0)
  let [categories, setCategories] = useState([])
  let [dealerships, setDealerships] = useState([])
  let [service, setService] = useState('')
  let [dealership, setDealership] = useState('')
  const rows = 25

  let { carId } = useParams()

  useEffect(() => {
    if (!initialised) return

    setLoading(true)

    const params = new URLSearchParams()

    // Conditionally append parameters if they are defined
    if (page !== undefined && page !== null && page !== '') {
      params.append('page', page)
    }
    if (service !== undefined && service !== null && service !== '') {
      params.append('service', service)
    }
    if (dealership !== undefined && dealership !== null && dealership !== '') {
      params.append('dealership_id', dealership)
    }

    if (carId) {
      params.append('car_id', carId)
    }

    // Construct the full URL with the query string
    let url = `/admin/apicalls.json?${params.toString()}`
    if (carId) {
      url = Routes.apicalls_car_path(carId, {
        page: page,
        service: service,
        dealership_id: dealership,
      })
    }

    fetch(url, {
      headers: standardHeaders,
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then((data) => {
        setApicalls(data.apicalls)
        setTotalCount(data.total_count)
        setLoading(false)
        setCategories(data.services)
        setDealerships(data.dealerships)
      })
      .catch((error) => console.error('Error:', error))
  }, [page, service, dealership, initialised])

  // Sync state with URL parameters
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const params = {
      page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
      service: searchParams.get('service') || '',
      dealership: searchParams.get('dealership') || '',
    }

    setPage(params.page)
    setDealership(params.dealership)
    setService(params.service)

    setInitialised(true)
  }, [])

  // Update the URL when filters change
  useEffect(() => {
    if (!initialised) return

    const params = {
      page,
      per_page: rows,
      service,
      dealership,
    }
    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(([_, value]) => value != null)
    )
    const queryString = new URLSearchParams(filteredParams).toString()
    const currentUrl = `${window.location.pathname}?${queryString}`
    window.history.replaceState(null, '', currentUrl)
  }, [page, service, dealership, initialised])

  const onPageChange = (event) => {
    setPage(event.page + 1) // PrimeReact pages are 0-based; Kaminari pages are 1-based
  }

  let categoryOptions = categories?.map((category) => ({
    label: category,
    value: category,
  }))

  let dealershipOptions = dealerships?.map((dealership) => ({
    label: dealership.name,
    value: dealership.id,
  }))

  return (
    <div className="container py-3">
      <h4>Apicalls</h4>

      <div className="mb-3">
        <div className="row">
          <div className="col-md-6">
            <Select
              label="Service"
              options={categoryOptions}
              onChange={(o) => setService(o?.value || null)}
              value={categoryOptions.find((o) => o.value === service)}
              isClearable
            />
          </div>
          <div className="col-md-6">
            {dealershipOptions && dealershipOptions.length > 0 && (
              <Select
                label="Dealership"
                options={dealershipOptions}
                onChange={(o) => setDealership(o?.value || null)}
                value={dealershipOptions.find((o) => o.value === parseInt(dealership))}
                isClearable
              />
            )}
          </div>
        </div>
      </div>

      <div className="box">
        <DataTable
          value={apicalls}
          paginator
          rows={rows}
          loading={loading}
          first={(page - 1) * rows}
          totalRecords={totalCount}
          onPage={onPageChange}
          lazy
        >
          <Column field="created_at" header="Created" body={CreatedAt} />
          <Column field="id" header="ID" />
          <Column field="service" header="Service" />
          <Column field="dealership.name" header="Dealership" />
          <Column field="response_code" header="Response Code" body={ResponseCode} />
          <Column header="Actions" body={Apicall} />
        </DataTable>
      </div>
    </div>
  )
}

export default Apicalls
