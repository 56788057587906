import { useRef, useState } from 'react'

import { Content, Provider, Root, Trigger } from '@radix-ui/react-tooltip'
import { Dialog } from 'primereact/dialog'
import { InputSwitch } from 'primereact/inputswitch'

import { useAnalyticsDashboard } from '../../../contexts/hooks'
import { useTabs } from '../../../contexts/hooks'
import { TabFilters } from '../../../types'
import { TooltipContentStyles } from '../../AnalyticsModules/components/ModuleWrapperComponent'

const EditTab: React.FC = () => {
  const { editModeEnabled, dashboardLevel } = useAnalyticsDashboard()
  const { selectedTab, tabFilters, setTabFilters } = useTabs()
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false)

  const handleUpdateTabFilters = (filters: TabFilters) => {
    setTabFilters((prevTabFilters) => ({
      ...prevTabFilters,
      [selectedTab]: filters,
    }))
  }

  return (
    <>
      {editModeEnabled ? (
        <i
          className="fal fa-cog text-primary"
          onClick={() => setIsDialogVisible(!isDialogVisible)}
        ></i>
      ) : null}
      <Dialog
        header={`Editing Tab`}
        visible={isDialogVisible}
        style={{ width: '90%', maxWidth: '600px' }}
        onHide={() => setIsDialogVisible(false)}
        dismissableMask
      >
        <EditTabTitle />
        {dashboardLevel === 'Dealership' ? (
          <div className="d-flex align-items-center mb-3">
            <h5 className="mr-3 mb-0">Enable GA4 website dropdown:</h5>
            <InputSwitch
              checked={tabFilters?.[selectedTab]?.enableGa4WebsiteDropdown ?? false}
              className="rounded-input-switch"
              onChange={(e) =>
                handleUpdateTabFilters({
                  enableGa4WebsiteDropdown: e.value,
                })
              }
            />
            <Provider delayDuration={50}>
              <Root>
                <Trigger asChild>
                  <i className="fas fa-info-circle ml-4" style={{ cursor: 'pointer' }}></i>
                </Trigger>
                <Content style={TooltipContentStyles} sideOffset={5}>
                  This setting allows you to enable a dropdown to select a GA4 website to filter
                  data for all GA4 modules in the tab. Note this will only work for GA4 modules.
                </Content>
              </Root>
            </Provider>
          </div>
        ) : null}
      </Dialog>
    </>
  )
}

const EditTabTitle: React.FC = () => {
  const { selectedTab, tabTitles, handleTabNameChange } = useTabs()
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <div className="d-flex text-left pb-2">
      <h5 className="mr-3 mb-0">Title:</h5>
      <input
        ref={inputRef}
        type="text"
        value={tabTitles[selectedTab]}
        onChange={(e) => handleTabNameChange(e.target.value)}
        className="flex-grow-1"
        onClick={() => inputRef.current?.focus()}
      />
    </div>
  )
}

export default EditTab
