import { useQuery } from '@tanstack/react-query'
import { Dropdown } from 'primereact/dropdown'
import { useParams } from 'react-router-dom'

import Loading from '../../../../Loading'
import { fetchGa4Profiles } from '../../../api'
import { useTabs, useTimeRangeLocation } from '../../../contexts/hooks'

const Ga4WebsiteDropdown: React.FC = () => {
  const { tabFilters, selectedTab } = useTabs()
  const { dealershipSlug } = useParams()
  const { selectedGaWebsitePerTab, setSelectedGaWebsitePerTab } = useTimeRangeLocation()

  const {
    data: gaProfiles,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['ga4-profiles'],
    queryFn: () => fetchGa4Profiles(dealershipSlug),
    enabled: !!dealershipSlug,
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
  })

  if (!tabFilters?.[selectedTab]?.enableGa4WebsiteDropdown) {
    return null
  }

  if (isLoading) {
    return <Loading />
  }

  if (isError) {
    return <Dropdown disabled placeholder="Error fetching GA4 websites..." />
  }

  return (
    <>
      <Dropdown
        placeholder="Select GA4 Website"
        options={[
          { label: 'All websites', value: null },
          ...gaProfiles
            ?.sort((a, b) => a.website_name.localeCompare(b.website_name))
            ?.map((gaProfile) => ({
              label: gaProfile.website_name,
              value: gaProfile.website_name,
            })),
        ]}
        value={selectedGaWebsitePerTab[selectedTab]}
        onChange={(e) => {
          const value = e.target.value.label === 'All websites' ? null : e.target.value
          setSelectedGaWebsitePerTab({ ...selectedGaWebsitePerTab, [selectedTab]: value })
        }}
        panelClassName="ga4-website-dropdown"
      />
    </>
  )
}

export default Ga4WebsiteDropdown
