import { QueryObserverResult } from '@tanstack/react-query'

const ModuleError: React.FC<{ refetch: () => Promise<QueryObserverResult<any, Error>> }> = ({
  refetch,
}) => (
  <div
    className="d-flex align-items-center justify-content-center flex-column gap-3 w-100 position-absolute-desktop"
    style={{ top: 0, bottom: 0, left: 0, right: 0 }}
  >
    <div className="d-flex align-items-center text-danger">
      <i className="fa fa-exclamation-triangle mr-1"></i>
      <span>Error loading module data</span>
    </div>
    <button
      onClick={() => refetch()}
      className="btn btn-danger btn-sm ms-2 rounded-pill"
      style={{
        transition: 'all 0.2s ease',
        boxShadow: '0 1px 3px rgba(0,0,0,0.08)',
        backgroundColor: '#ffb3b3',
        borderColor: '#ffb3b3',
        color: '#d42626',
        fontWeight: 500,
        padding: '0.35rem 0.9rem',
      }}
    >
      Retry
    </button>
  </div>
)

export default ModuleError
