import { useEffect, useState } from 'react'

import { InputOtp } from 'primereact/inputotp'
import { useForm } from 'react-hook-form'

import * as Routes from '../../routes'
import { standardHeaders } from '../entries/utils'

const TwoFactorAuth = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()
  const [qrCode, setQrCode] = useState('')
  const [otpSecret, setOtpSecret] = useState('')

  useEffect(() => {
    // Fetch QR Code URI and OTP Secret from backend
    const fetchTwoFactorData = async () => {
      try {
        const response = await fetch(Routes.new_two_factor_settings_path(), {
          headers: standardHeaders,
        })
        if (!response.ok) throw new Error('Failed to fetch 2FA data')
        const data = await response.json()
        setOtpSecret(data.otp_secret)
        setQrCode(data.qr_code)
      } catch (error) {
        console.error('Error fetching 2FA data:', error)
      }
    }

    fetchTwoFactorData()
  }, [])

  const onSubmit = async (data) => {
    try {
      const response = await fetch(Routes.two_factor_settings_path(), {
        method: 'POST',
        headers: standardHeaders,
        body: JSON.stringify({ two_fa: data }),
      })

      if (!response.ok) throw new Error('Failed to enable 2FA')

      alert('Two-Factor Authentication Enabled!')
      window.location.href = Routes.edit_user_registration_path()
    } catch (error) {
      alert('Error enabling 2FA: ' + error.message)
    }
  }

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-12">
          <h2>Two Factor Authentication</h2>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card-deck">
            {/* Step 1: Scan QR Code */}
            <div className="card">
              <div className="card-header">1. Scan QR Code</div>
              <div className="card-body">
                <p>
                  Please scan the below QR code using an OTP compatible app (such as Google
                  Authenticator or Authy).
                </p>
                <hr />
                <p className="text-center">
                  {qrCode ? (
                    <div dangerouslySetInnerHTML={{ __html: qrCode }} />
                  ) : (
                    'Loading QR Code...'
                  )}
                </p>
                <hr />
                <p className="text-center">
                  If you cannot scan, please enter the following code manually:{' '}
                  <code>{otpSecret}</code>
                </p>
              </div>
            </div>

            {/* Step 2: Confirm OTP Code */}
            <div className="card">
              <div className="card-header">2. Confirm OTP Code</div>
              <div className="card-body">
                <p>
                  Please confirm that your authentication application is working by entering a
                  generated code below.
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-3">
                    <label className="form-label">Code</label>
                    <InputOtp length={6} onChange={(e) => setValue('code', e.value)} integerOnly />
                    {errors.code && <div className="invalid-feedback">{errors.code.message}</div>}
                  </div>

                  <div className="mb-3">
                    {/* Hidden field to prevent the code from being autofilled by browsers */}
                    <input
                      type="email"
                      name="fake_email"
                      style={{ display: 'none' }}
                      autoComplete="username"
                    />
                    <label className="form-label" for="password">
                      Enter your current password
                    </label>
                    <input
                      type="password"
                      id="password"
                      placeholder="Current Password"
                      className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                      {...register('password', { required: 'Password is required' })}
                    />
                    {errors.password && (
                      <div className="invalid-feedback">{errors.password.message}</div>
                    )}
                  </div>

                  <button type="submit" className="btn btn-primary">
                    Confirm and Enable Two Factor
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TwoFactorAuth
