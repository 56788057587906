import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Column } from 'primereact/column'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { DataTable } from 'primereact/datatable'
import { Link, useParams } from 'react-router-dom'

import * as Routes from '../../../routes'
import { standardHeaders } from '../../entries/utils'

export const WebsiteDealershipsTable = ({ data, website, isLoading }) => {
  const queryClient = useQueryClient()
  const { websiteSlug } = useParams()
  const dealershipWebsites = data?.dealership_websites || []

  /**
   * Unlink a dealership's stock from the website
   */
  const unlinkMutation = useMutation({
    mutationFn: (dealershipId) =>
      // @ts-expect-error
      fetch(Routes.website_dealership_website_path(websiteSlug, dealershipId), {
        method: 'DELETE',
        headers: standardHeaders,
      }).then((res) => {
        if (!res.ok) throw new Error('Failed to unlink')
        return res
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['stock', website.slug] })
    },
  })

  const handleUnlink = (rowData) => {
    confirmDialog({
      message: `Are you sure you want to unlink ${rowData.dealership.name}?`,
      header: 'Confirm Unlink',
      icon: 'pi pi-exclamation-triangle',
      acceptClassName: 'p-button-danger',
      accept: () => {
        unlinkMutation.mutate(rowData.id)
      },
    })
  }

  const allowLinkTemplate = (rowData) => {
    return rowData.allow_link_from_dealership_websites_to_website ? (
      <i className="fa fa-check text-success"></i>
    ) : (
      <i className="fa fa-times text-danger"></i>
    )
  }

  const actionsTemplate = (rowData) => {
    return (
      <>
        <a
          href={Routes.edit_website_dealership_website_path(website?.id, rowData.id)}
          className="btn btn-outline-primary btn-sm"
          data-remote="true"
        >
          Edit
        </a>
        <div className="btn btn-outline-danger btn-sm ml-2" onClick={() => handleUnlink(rowData)}>
          Unlink
        </div>
      </>
    )
  }
  return (
    <div className="box mb-3">
      <ConfirmDialog />
      <DataTable value={dealershipWebsites} loading={isLoading}>
        <Column
          field="dealership.name"
          header="Dealership"
          body={(rowData) => (
            <Link to={Routes.dealership_path(rowData.dealership?.id)}>
              {rowData.dealership?.name}
            </Link>
          )}
        />
        <Column field="cars_count" sortable header="Cars" body={(rowData) => rowData.cars_count} />
        <Column header="Allow dealership to link to website?" body={allowLinkTemplate} />
        <Column header="Actions" body={actionsTemplate} />
      </DataTable>
      <div className="px-3 pb-3">
        <a
          href={Routes.new_website_dealership_website_path(website.slug)}
          className="btn btn-outline-primary"
          data-remote="true"
          data-disable-with="Loading..."
        >
          Add Dealership
        </a>
      </div>
    </div>
  )
}

export const WebsitesDealershipsTableLoading = () => {
  return (
    <DataTable value={[]} loading={true} className="p-datatable-sm">
      <Column field="dealership.name" header="Dealership" />
      <Column field="cars_count" header="Cars" />
      <Column
        field="allow_link_from_dealership_websites_to_website"
        header="Allow Link"
        style={{ width: '150px' }}
      />
      <Column header="Actions" style={{ width: '100px' }} />
    </DataTable>
  )
}
