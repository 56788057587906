import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Link, useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { SectionHeading, standardHeaders } from '../entries/utils'

const fetchPrizes = async (dealershipSlug) => {
  const response = await fetch(Routes.dealership_prizes_path(dealershipSlug), {
    headers: standardHeaders,
  })
  if (!response.ok) throw new Error('Failed to fetch prizes')
  return response.json()
}

const Contact = (rowData) => {
  const { dealershipSlug } = useParams()

  return (
    <Link to={Routes.dealership_contact_path(dealershipSlug, rowData.contact.id)}>
      {rowData.contact.first_name} {rowData.contact.last_name}
    </Link>
  )
}

const Prizes = () => {
  const { dealershipSlug } = useParams()

  const {
    data: prizes = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ['prizes', dealershipSlug],
    queryFn: () => fetchPrizes(dealershipSlug),
    staleTime: 60000, // Cache for 1 minute
  })

  if (isLoading) return <p>Loading prizes...</p>
  if (error) return <p>Error loading prizes: {error.message}</p>

  return (
    <>
      <SectionHeading title="Prizes"></SectionHeading>
      <div className="box">
        <DataTable value={prizes} stripedRows>
          <Column
            field="created_at"
            header="Created At"
            body={(rowData) => moment(rowData.created_at).fromNow()}
          />
          <Column field="name" header="Prize Name" />
          <Column field="contact.first_name" header="Contact Name" body={Contact} />
        </DataTable>
      </div>
    </>
  )
}

const Wrapper = () => (
  <div className="p-3">
    <Prizes />
  </div>
)

export default Wrapper
