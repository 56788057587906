import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Dialog } from 'primereact/dialog'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { FormContext } from '../contexts'
import { Errors } from '../entries/FormElements'
import { standardHeaders } from '../entries/utils'
import HookFormField from '../shared/hookFormField'

let attributes = {
  label: { type: 'text', label: 'Label', required: true },
  location_id: { type: 'select', label: 'Location', options: 'locations' },
  url: { type: 'text', label: 'URL', required: true },
}

const QrCodeForm = ({ qr_code, visible, setVisible, refetch, formData }) => {
  let [errors, setErrors] = useState({})
  let [loading, setLoading] = useState(false)
  let { dealershipSlug } = useParams()

  const { control, handleSubmit } = useForm({
    defaultValues: {
      label: qr_code?.label || '',
      location_id: qr_code?.location_id || '',
      url: qr_code?.url || '',
    },
  })

  const onSubmit = (data) => {
    setLoading(true)

    fetch(Routes.dealership_qr_codes_path(dealershipSlug), {
      method: 'POST',
      headers: standardHeaders,
      body: JSON.stringify({ qr_code: data }),
    })
      .then((response) => {
        if (!response.ok) {
          // If the response status is 422, handle it separately
          if (response.status === 422) {
            return response.json().then((res) => {
              setErrors(res) // Assuming the API returns errors in `res.errors`
              setLoading(false)
            })
          }
          throw new Error('Network response was not ok')
        }
        return response.json()
      })
      .then((res) => {
        if (res && res.id) {
          setVisible(false)
          refetch()
        }
      })
      .catch((error) => {
        console.error('Error:', error)
        setLoading(false)
      })
  }

  return (
    <FormContext.Provider value={{ control, errors, attributes, formData }}>
      <Dialog
        header="Add QR Code"
        visible={visible}
        style={{ width: '50vw' }}
        onHide={() => setVisible(false)}
        dismissableMask={true}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <HookFormField attribute="label" />
          <HookFormField attribute="location_id" />
          <HookFormField attribute="url" />
          <Errors errors={errors} />
          <button className="btn btn-block btn-outline-success" type="submit">
            {loading ? 'Loading...' : 'Create QR Code'}
          </button>
        </form>
      </Dialog>
    </FormContext.Provider>
  )
}

const fetchFormData = async (dealershipSlug) => {
  let res = await fetch(Routes.new_dealership_qr_code_path(dealershipSlug), {
    headers: standardHeaders,
  })
  return res.json()
}

const NewFormWrapper = ({ visible, setVisible, refetch }) => {
  let { dealershipSlug } = useParams()
  let { data, isLoading } = useQuery({
    queryKey: ['qr_code_form_data', dealershipSlug],
    queryFn: () => fetchFormData(dealershipSlug),
  })

  if (isLoading) return null

  return <QrCodeForm visible={visible} setVisible={setVisible} refetch={refetch} formData={data} />
}

export default NewFormWrapper
