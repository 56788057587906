import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { standardHeaders } from '../entries/utils'
import ExportDestination from './ExportDestination'

const fetchFeedAttributes = async (dealershipSlug) => {
  const res = await fetch(Routes.feed_eligibility_criteria_dealership_feeds_path(dealershipSlug), {
    headers: standardHeaders,
  })
  return res.json()
}

function canShowThirdPartySwitch(thirdParty, carSwitches, data, car) {
  if (!carSwitches.includes(thirdParty)) {
    return false
  }

  if (!data) {
    return true
  }

  return data[thirdParty].some((feed) => {
    let location_id_included =
      feed.location_ids.includes(car.location_id) ||
      feed.location_ids.length === 0 ||
      !car.location_id
    let car_type_included = feed.car_types.includes(car.car_type) || feed.car_types.length === 0

    return location_id_included && car_type_included
  })
}

const ExportDestinations = ({ car, setCar, hit, carSwitches }) => {
  let { dealershipSlug } = useParams()

  let canExportToThirdParty =
    (hit.restrict_third_party && !hit.ignore_manufacturer_restriction) || hit.status === 'sold'

  let canShowOnDealerWebsite =
    hit.restricted_on_all_websites_from_stock_rule && !hit.ignore_manufacturer_restriction

  let { data } = useQuery({
    queryKey: ['feed_feed_eligibility_criteria', dealershipSlug],
    queryFn: () => fetchFeedAttributes(dealershipSlug),
    staleTime: 10 * 60 * 1000,
  })

  return (
    <>
      <ExportDestination
        car={car}
        destination="Website"
        fieldName="show_on_dealer_website"
        disabled={canShowOnDealerWebsite || !dealershipSlug}
        setCar={setCar}
      />
      {carSwitches.includes('autogate') && (
        <ExportDestination
          car={car}
          destination="Autogate"
          fieldName="load_to_autogate"
          disabled={canExportToThirdParty || !dealershipSlug}
          setCar={setCar}
        />
      )}
      {canShowThirdPartySwitch('autotrader', carSwitches, data, car) && (
        <ExportDestination
          car={car}
          destination="Autotrader"
          fieldName="load_to_autotrader"
          disabled={canExportToThirdParty || !dealershipSlug}
          setCar={setCar}
        />
      )}
      {canShowThirdPartySwitch('drive', carSwitches, data, car) && (
        <ExportDestination
          car={car}
          destination="Drive"
          fieldName="load_to_drive"
          disabled={canExportToThirdParty || !dealershipSlug}
          setCar={setCar}
        />
      )}
      {carSwitches.includes('facebook') && (
        <ExportDestination
          car={car}
          destination="Facebook"
          fieldName="load_to_facebook"
          setCar={setCar}
        />
      )}
    </>
  )
}

export default ExportDestinations
