import { CSSProperties } from 'react'

import { Chart, registerables } from 'chart.js'
import { Bar, Line, Pie } from 'react-chartjs-2'

import { ChartDataConfig, ChartDataConfigDataset } from '../types'

Chart.register(...registerables)

const PieChart = ({
  chartDataConfig,
  chartOptionsConfig = {},
}: {
  chartDataConfig: ChartDataConfig
  chartOptionsConfig?: object
}) => {
  return chartDataConfig?.datasets?.some(
    (dataset: ChartDataConfigDataset) => dataset.data?.length > 0
  ) ? (
    <>
      <div className="d-flex justify-content-center w-100" style={{ maxHeight: '320px' }}>
        <Pie data={chartDataConfig} options={chartOptionsConfig} />
      </div>
      <div className="custom-legend d-flex flex-wrap py-3">
        {chartDataConfig.labels?.map((label, index) => (
          <div key={index} className="d-flex align-items-center p-1">
            <div
              className="flex-shrink-0 mr-1"
              style={{
                width: '16px',
                height: '16px',
                backgroundColor: chartDataConfig.datasets[0].backgroundColor[index],
              }}
            />
            <span className="text-break small">{label}</span>
          </div>
        ))}
      </div>
    </>
  ) : (
    <p className="text-center text-secondary">No Data Available</p>
  )
}

const BarChart = ({
  chartDataConfig,
  chartOptionsConfig = {},
  responsiveLayoutSizeStyles,
}: {
  chartDataConfig: ChartDataConfig
  chartOptionsConfig?: object
  responsiveLayoutSizeStyles?: CSSProperties
}) => {
  return chartDataConfig?.datasets?.some(
    (dataset: ChartDataConfigDataset) =>
      dataset.data?.length > 0 && dataset.data?.some((dataItem) => dataItem !== 0)
  ) ? (
    <div style={responsiveLayoutSizeStyles}>
      <Bar data={chartDataConfig} options={chartOptionsConfig} />
    </div>
  ) : (
    <p className="text-center text-secondary">No Data Available</p>
  )
}

const LineChart = ({
  chartDataConfig,
  chartOptionsConfig = {},
  responsiveLayoutSizeStyles,
}: {
  chartDataConfig: ChartDataConfig
  chartOptionsConfig?: object
  responsiveLayoutSizeStyles?: CSSProperties
}) => {
  return chartDataConfig?.datasets?.some(
    (dataset: ChartDataConfigDataset) =>
      dataset.data?.length > 0 && dataset.data?.some((dataItem) => dataItem !== 0)
  ) ? (
    <div style={responsiveLayoutSizeStyles}>
      <Line data={chartDataConfig} options={chartOptionsConfig} />
    </div>
  ) : (
    <p className="text-center text-secondary">No Data Available</p>
  )
}
export { BarChart, LineChart, PieChart }
