import { useContext, useEffect, useState } from 'react'

import { ProgressSpinner } from 'primereact/progressspinner'
import { Link } from 'react-router-dom'

import * as Routes from '../../routes'
import { CurrentUserContext } from '../contexts'
import { standardHeaders } from '../entries/utils'
import LoginForm from './LoginForm'
import WestSideAutoIndexPage from './WestSideAutoIndexPage'

const isGardx = window.location.hostname.includes('gardx')

const Anchor = ({ to, children, className }) => (
  <a href={to} className={className}>
    {children}
  </a>
)

const Dealership = ({ dealership }) => {
  let currentUser = useContext(CurrentUserContext)

  const LinkComponent = isGardx ? Anchor : Link

  const link = isGardx
    ? Routes.dealership_warranties_path(dealership.slug)
    : Routes.dealership_path(dealership.slug)

  return (
    <div className="index-dealership-box">
      <div className="card w-100 mb-2 overflow-hidden">
        <LinkComponent to={link} className="index-dealership-box">
          <div className="card-body mb-0 pb-0 d-flex align-items-center">
            {dealership.logo_url && (
              <div className="mr-3">
                <img
                  src={dealership.logo_url}
                  height={30}
                  className="rounded brand-logo"
                  style={{ maxHeight: '30px' }}
                  alt={dealership.name}
                />
              </div>
            )}
            <h6 className="m-0 text-dark unstyled">{dealership.name}</h6>
            <div className="ml-auto mr-10">
              <i className="fa fa-angle-right"></i>
            </div>
          </div>
        </LinkComponent>
        <hr className="mb-0" />
        {currentUser?.dealerships[dealership.id]?.manager && (
          <div className="bg-light p-2">
            {dealership.websites?.map((website) => (
              <div key={website.id}>
                <Link to={`/dealerships/${dealership.slug}/websites/${website.slug}`}>
                  {website.name}
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

const IndexPage = ({ ssoEnabled, identityProvidersJson }) => {
  let [data, setData] = useState(null)
  let [loading, setLoading] = useState(false)
  let currentUser = useContext(CurrentUserContext)

  useEffect(() => {
    setLoading(true)
    fetch(Routes.welcome_app_details_path(), {
      headers: standardHeaders,
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data)
        setLoading(false)
      })
  }, [])

  const renderDealerships = () => {
    return data?.dealerships?.map((dealership) => (
      <Dealership dealership={dealership} key={`dealership-${dealership.id}`} />
    ))
  }

  const renderIndexPage = () => {
    return data?.app_name == 'Stockhub' ? (
      <WestSideAutoIndexPage />
    ) : (
      <div className="flex-fill d-flex align-items-center justify-content-center">
        <div className="container">
          <div className="row d-flex">
            <div className="col-md-6 align-items-center d-flex mh-100">
              <div className="d-flex align-items-center justify-content-center flex-fill">
                <div className="text-center">
                  <img src={data?.image_url} className="py-4 logo-index" alt={data?.app_name} />
                  <div className="mb-4">
                    <h1>{data?.app_name}</h1>
                    <h5 className="text-secondary">{data?.subtitle}</h5>
                    <div className="d-flex justify-content-center mt-4">
                      <a
                        href="https://apps.apple.com/au/app/dealer-studio/id6504888047"
                        className="app-link"
                        target="_blank"
                      >
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Download_on_the_App_Store_Badge.svg/640px-Download_on_the_App_Store_Badge.svg.png"
                          alt="Download on the App Store"
                          className="mr-2"
                          width="140px"
                        />
                      </a>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.dealerstudio.app&hl=en_AU&pli=1"
                        target="_blank"
                        className="app-link"
                      >
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/640px-Google_Play_Store_badge_EN.svg.png"
                          alt="Get it on Google Play"
                          width="140px"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-center">
              {currentUser ? (
                <div className="d-flex flex-column w-100">{renderDealerships()}</div>
              ) : (
                <div className="card w-100">
                  <div className="card-header">
                    <h2>Log in</h2>
                  </div>
                  <LoginForm
                    ssoEnabled={ssoEnabled}
                    identityProvidersJson={identityProvidersJson}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (loading) {
    return (
      <div className="flex-fill d-flex align-items-center justify-content-center">
        <ProgressSpinner />
      </div>
    )
  }

  return <>{renderIndexPage()}</>
}

export default IndexPage
