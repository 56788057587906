import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Link, useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import Loading from '../Loading'
import { humanize } from '../entries/utils'

const fetchPayments = async ({ queryKey }) => {
  const [, dealershipSlug, page, rows] = queryKey
  const response = await fetch(
    Routes.dealership_payments_path(dealershipSlug, {
      page: page,
      per_page: rows,
    })
  )
  if (!response.ok) throw new Error('Failed to fetch payments')
  return response.json()
}

const CreatedAt = ({ created_at }) => <span>{moment(created_at).fromNow()}</span>

const Car = ({ car }) => {
  let { dealershipSlug } = useParams()
  return (
    <div>
      {car?.slug && (
        <Link to={Routes.dealership_car_path(dealershipSlug, car?.slug)}>{car?.name}</Link>
      )}
    </div>
  )
}
const Lead = ({ lead }) => {
  let { dealershipSlug } = useParams()
  return (
    <div>
      {lead?.lead_cluster_id && (
        <Link to={Routes.dealership_lead_cluster_path(dealershipSlug, lead?.lead_cluster_id)}>
          {lead.name}
        </Link>
      )}
    </div>
  )
}

const Actions = (rowData) => {
  let { dealershipSlug } = useParams()
  if (!rowData) return false
  return (
    <div>
      <a href={`/dealerships/${dealershipSlug}/payments/${rowData?.id}`}>View</a>
    </div>
  )
}

const Amount = ({ amount }) => (
  <span>
    {amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })}
  </span>
)

const CarStatus = ({ car }) => {
  let className = 'text text-success'
  if (car.status === 'sold') {
    className = 'text text-danger'
  }
  return <span className={className}>{humanize(car.status)}</span>
}

const Payments = () => {
  let { dealershipSlug } = useParams()
  const [page, setPage] = useState(1)
  const rows = 25

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['payments', dealershipSlug, page, rows],
    queryFn: fetchPayments,
    keepPreviousData: true, // Enables smooth pagination without flickering
  })

  if (isLoading) {
    return (
      <div className="p-3">
        <Loading />
      </div>
    )
  }

  if (isError) {
    return <div className="p-3 text-danger">Error: {error.message}</div>
  }

  const { payments, total_count } = data

  const onPageChange = (event) => {
    setPage(event.page + 1) // PrimeReact pages are 0-based; Kaminari pages are 1-based
  }

  return (
    <div className="p-3">
      <h3>Payments</h3>
      <div className="box">
        <DataTable
          value={payments}
          paginator
          rows={rows}
          first={(page - 1) * rows}
          totalRecords={total_count}
          onPage={onPageChange}
          lazy={true}
        >
          <Column field="created_at" header="Created At" body={CreatedAt} />
          <Column field="amount" header="Amount" body={Amount} />
          <Column header="Lead" body={Lead} />
          <Column header="Car" body={Car} />
          <Column field="car.status" header="Car Status" body={CarStatus} />
          <Column field="car.reserved" header="Car Reserved" />
          <Column body={Actions} />
        </DataTable>
      </div>
    </div>
  )
}

export default Payments
