import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Link } from 'react-router-dom'

import * as Routes from '../../routes'
import { standardHeaders } from '../entries/utils'

const fetchErrors = async () => {
  const response = await fetch(Routes.errors_path(), { headers: standardHeaders })
  if (!response.ok) {
    throw new Error('Failed to fetch errors')
  }
  return response.json()
}

const DealershipName = ({ dealership }) => {
  return <Link to={Routes.dealership_path(dealership.slug)}>{dealership.name}</Link>
}

const CreatedAt = ({ created_at }) => {
  return moment(created_at).fromNow()
}

const Errors = () => {
  const { data, error, isLoading } = useQuery({ queryKey: ['errors'], queryFn: fetchErrors })

  if (isLoading) return <p>Loading errors...</p>
  if (error) return <p>Error: {error.message}</p>

  return (
    <div className="container py-3">
      <h4 className="text-lg font-bold mb-4">Errors</h4>
      <div className="box overflow-hidden">
        <DataTable value={data} paginator rows={10}>
          <Column field="created_at" header="Created" body={CreatedAt} />
          <Column field="dealership.name" header="Dealership" body={DealershipName} />
          <Column field="title" header="Title" />
          <Column field="description" header="Description" />
        </DataTable>
      </div>
    </div>
  )
}

export default Errors
