type FormInput = {
  defaultValue?: boolean | string
  type: 'boolean' | 'string'
  label: string
}

export type FormTabs<T extends object> = Record<
  string,
  {
    label: string
    formInputs: Partial<Record<keyof T, FormInput>>
  }
>

/**
 * Convert the formTabs object to an array of form inputs for a specific tab.
 * @param key - The key of the tab to convert.
 * @param formTabs - The formTabs object.
 * @returns An array of form inputs for the specific tab.
 */
export function formInputFromTabs<T extends object>(key: string, formTabs: FormTabs<T>) {
  const formInputs = Object.entries(formTabs[key].formInputs) as [keyof T, FormInput][]
  return formInputs.map(([key, value]) => ({
    ...value,
    key,
  }))
}

/**
 * Extract all the form inputs from the formTabs object into a single object.
 * @param formInputsTabs - The formTabs object.
 * @returns An object containing all the form inputs from the formTabs object.
 */
export function formInputsFromAllTabs(formInputsTabs: FormTabs<object>) {
  const tabs = Object.keys(formInputsTabs)
  return tabs.flatMap((tab) => formInputFromTabs(tab, formInputsTabs))
}
