import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'

import { Select } from '../entries/FormElements'
import { standardHeaders } from '../entries/utils'

let filterOptions = [
  { label: 'Make unmatched', value: 'manufacturer_unmatched' },
  { label: 'Family unmatched', value: 'family_unmatched' },
]

const NvicFamilies = () => {
  let [filter, setFilter] = useState('manufacturer_unmatched')

  const { isLoading, error, data } = useQuery({
    queryKey: ['nvicFamilies', filter],
    queryFn: async () => {
      // Construct the URL with the filter as a query parameter.
      const url = new URL(Routes.nvic_families_path(), window.location.origin)
      url.searchParams.set('filter', filter)

      const response = await fetch(url, { headers: standardHeaders })
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json()
    },
  })

  if (isLoading) return <div>Loading...</div>
  if (error) return <div>Error loading NVIC Families.</div>

  return (
    <>
      <p>{data.length} NVIC Families</p>
      <Select
        options={filterOptions}
        value={filterOptions.find((o) => o.value === filter)}
        onChange={(o) => setFilter(o.value)}
      />
      <div className="box overflow-hidden">
        <DataTable value={data} paginator rows={50}>
          <Column field="name" header="Name" />
          <Column field="nvic_manufacturer_name" header="Make" />
          <Column field="manufacturer_present" header="Make Matched" />
          <Column field="family_present" header="Family Matched" />
        </DataTable>
      </div>
    </>
  )
}

const Wrapper = () => (
  <div className="container py-3">
    <h1>NVIC Families</h1>
    <NvicFamilies />
  </div>
)

export default Wrapper
