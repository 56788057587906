import { useEffect, useRef, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Toast } from 'primereact/toast'
import { Link, useParams } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

import * as Routes from '../../routes'
import Loading from '../Loading'
import { Apicall } from '../admin/Apicalls'
import { humanize } from '../entries/utils'
import { standardHeaders } from '../entries/utils'
import Changes from '../shared/Changes'
import { CopyableText } from '../shared/CopyableText'
import showToast from '../shared/ShowToast'
import SlideIn from '../shared/SlideIn'
import Form from './Form'
import Logs from './logs'

const CarsCount = (feedRun) => {
  let { dealershipSlug } = useParams()

  return (
    <a href={`/dealerships/${dealershipSlug}/feed_runs/${feedRun.id}`}>
      {feedRun.cars_count || 0} cars
    </a>
  )
}

const CreatedAt = (feedRun) => {
  return (
    <>
      <div>{moment(feedRun.created_at).fromNow()}</div>
      <small className="text-secondary">
        {moment(feedRun.created_at).format('DD/MM/YY HH:mm')}
      </small>
    </>
  )
}

const ErrorsCount = (feedRun) => {
  return (
    <span className={feedRun.errors_count > 0 ? 'text-danger' : 'text-success'}>
      {feedRun.errors_count}
    </span>
  )
}

const Status = (feedRun) => {
  return (
    <span className={feedRun.status === 'success' ? 'text-success' : 'text-danger'}>
      {feedRun.skip_inventory ? 'Skipped' : humanize(feedRun.status)}
    </span>
  )
}

const DownloadCsv = (feedRun) => {
  return <a href={feedRun.file_url}>Download CSV</a>
}

const DownloadHtml = (pentanaFeed) => {
  let { dealershipSlug } = useParams()
  let url = `/dealerships/${dealershipSlug}/pentana_feeds/${pentanaFeed.id}/raw`

  return <a href={url}>Download HTML</a>
}

const ViewLogs = (feedRun) => {
  return <Logs feedRun={feedRun} />
}

const ViewPentanaLogs = (pentanaFeed) => {
  let { dealershipSlug } = useParams()

  return <a href={`/dealerships/${dealershipSlug}/pentana_feeds/${pentanaFeed.id}`}>View Logs</a>
}

const ResponseCode = (apicall) => {
  return (
    <span className={apicall.response_code >= 300 ? 'text-danger' : 'text-success'}>
      {apicall.response_code}
    </span>
  )
}

const Reprocess = ({ rowData, rowIndex }) => {
  let { dealershipSlug } = useParams()
  let [loading, setLoading] = useState(false)

  if (rowIndex > 0) {
    return null
  }

  if (rowData?.status !== 'success') {
    return null
  }

  const reprocess = () => {
    setLoading(true)
    fetch(Routes.reprocess_dealership_feed_run_path(dealershipSlug, rowData.id), {
      headers: standardHeaders,
      method: 'POST',
    })
      .then((response) => response.json())
      .then(() => {
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error reprocessing feed run:', error)
        setLoading(false)
      })
  }

  return (
    <button className="btn btn-outline-secondary btn-sm" onClick={reprocess}>
      {loading && <i className="fas fa-spinner fa-spin mr-1"></i>}
      Reprocess
    </button>
  )
}

const FeedRuns = ({ feed, page, setPage, loading }) => {
  let rows = 25

  const onPageChange = (event) => {
    setPage(event.page + 1) // PrimeReact pages are 0-based; Kaminari pages are 1-based
  }

  return (
    <>
      {feed.feed_runs && feed.feed_runs.length > 0 && (
        <>
          <div className="d-flex mb-2 align-items-center">
            <h5>Feed Runs</h5>
          </div>
          <div className="box">
            <DataTable
              value={feed.feed_runs}
              paginator
              lazy
              rows={rows}
              first={(page - 1) * rows}
              totalRecords={feed.total_feed_runs}
              onPage={onPageChange}
              loading={loading}
            >
              <Column header="Created At" field="created_at" body={CreatedAt} />
              <Column header="Count" field="cars_count" body={CarsCount} />
              <Column header="Status" field="status" body={Status} />
              <Column header="Errors" field="errors_count" body={ErrorsCount} />
              <Column header="Download" body={DownloadCsv} />
              <Column header="Logs" body={ViewLogs} />
              {['import', 'email'].includes(feed.feed_type) && (
                <Column
                  header="Reprocess"
                  body={(rowData, { rowIndex }) => (
                    <Reprocess rowData={rowData} rowIndex={rowIndex} />
                  )}
                />
              )}
            </DataTable>
          </div>
        </>
      )}
      {feed.pentana_feeds && feed.pentana_feeds.length > 0 && (
        <>
          <h5>HTML Feeds / Pentana Feeds</h5>
          <div className="box">
            <DataTable value={feed.pentana_feeds}>
              <Column header="Created At" field="created_at" body={CreatedAt} />
              <Column header="Subject" field="subject" />
              <Column header="Count" field="number_of_items" />
              <Column header="Processed" field="processed" />
              <Column header="Download" body={DownloadHtml} />
              <Column header="Logs" body={ViewPentanaLogs} />
            </DataTable>
          </div>
        </>
      )}
      {feed.file_uploads && feed.file_uploads.length > 0 && (
        <>
          <h5>Photo File Uploads</h5>
          <div className="box">
            <DataTable value={feed.file_uploads}>
              <Column header="Created At" field="created_at" body={CreatedAt} />
              <Column header="Filename" field="filename" />
              <Column header="Status" field="status" />
            </DataTable>
          </div>
        </>
      )}
      {feed.apicalls && feed.apicalls.length > 0 && (
        <>
          <h5>Apicalls</h5>
          <div className="box">
            <DataTable
              value={feed.apicalls}
              paginator
              lazy
              rows={rows}
              first={(page - 1) * rows}
              totalRecords={feed.total_apicalls}
              onPage={onPageChange}
              loading={loading}
            >
              <Column header="Created At" field="created_at" body={CreatedAt} />
              <Column header="Car" field="car.name" />
              <Column header="Stocknum" field="car.stocknum" />
              <Column header="Response" field="response_code" body={ResponseCode} />
              <Column header="Actions" body={Apicall} />
            </DataTable>
          </div>
        </>
      )}
    </>
  )
}

const FeedCars = ({ feedId }) => {
  let { dealershipSlug } = useParams()
  let [cars, setCars] = useState([])
  let [loading, setLoading] = useState(false)

  useEffect(() => {
    fetch(`/dealerships/${dealershipSlug}/feeds/${feedId}/cars.json`)
      .then((response) => response.json())
      .then((data) => {
        setCars(data)
      })
  }, [])

  return (
    <div className="box mt-3">
      <DataTable value={cars} loading={loading}>
        <Column field="stocknum" header="Stocknum" />
        <Column field="make" header="Make" />
        <Column field="model" header="Model" />
      </DataTable>
    </div>
  )
}

const fetchFeedData = async (dealershipSlug, feedId, page) => {
  const response = await fetch(`/dealerships/${dealershipSlug}/feeds/${feedId}.json?page=${page}`)
  if (!response.ok) throw new Error('Network response was not ok')
  return response.json()
}

const Show = ({ feedId, isOpen = true }) => {
  const notification = useRef(null)
  let [loadingExport, setLoadingExport] = useState(false)
  let [refreshLoading, setRefreshLoading] = useState(false)
  let [page, setPage] = useState(1)

  let { dealershipSlug } = useParams()

  const {
    data: feed,
    isLoading: loading,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ['feed', dealershipSlug, feedId, page],
    queryFn: () => fetchFeedData(dealershipSlug, feedId, page),
    enabled: isOpen,
  })

  const resetFeed = () => {
    setRefreshLoading(true)
    fetch(Routes.reset_dealership_feed_path(dealershipSlug, feedId), {
      method: 'POST',
      headers: standardHeaders,
    })
      .then((response) => response.json())
      .then(() => {
        showToast(notification, 'success', 'Feed reset')
        refetch()
        setRefreshLoading(false)
      })
  }

  const exportFeed = () => {
    setLoadingExport(true)
    fetch(Routes.export_dealership_feed_path(dealershipSlug, feedId), {
      method: 'PUT',
      headers: standardHeaders,
    })
      .then((response) => response.json())
      .then((data) => {
        showToast(notification, 'success', 'Feed scheduled for export')
        refetch()
        setLoadingExport(false)
      })
  }

  return (
    <div>
      <Toast ref={notification} />
      {loading ? (
        <div className="text-center">
          <Loading />
          Loading...
        </div>
      ) : (
        <>
          <div className="box mb-3 p-3">
            <div className="mb-3">
              <h4>{feed.name}</h4>
              <div className="mb-1">
                <b>Feed ID:</b> <CopyableText text={feed.id} />
              </div>
              {['export', 'loopit', 'orbee', 'autotrader', 'url-only', 'webhook'].includes(
                feed.feed_type
              ) && (
                <>
                  {feed.schema && (
                    <div className="mb-1">
                      <b>Schema:</b> {feed.schema}
                    </div>
                  )}
                  <div className="mb-1">
                    <b>Current cars:</b> {feed.current_cars}
                  </div>
                </>
              )}
              {feed.email && (
                <div className="mb-1">
                  <b>Email:</b> {feed.email}
                </div>
              )}
              {feed.csv_filename && (
                <div className="mb-1">
                  <b>Filename:</b> <CopyableText text={feed.csv_filename} />
                </div>
              )}
              {feed.reset && (
                <div className="mb-1">
                  <b>Reset at: </b>
                  {moment(feed.reset).fromNow()}
                </div>
              )}
              {feed.lockable_fields && feed.lockable_fields.length > 0 && (
                <div className="mb-1">
                  <b>Ignored Fields:</b>{' '}
                  {feed.lockable_fields.map((f) => (
                    <div className="badge badge-sm badge-secondary mr-1">{f}</div>
                  ))}
                </div>
              )}
              {feed.feed_columns && feed.feed_columns.length > 0 && (
                <div className="mb-1">
                  <b>Custom CSV Feed Columns:</b>{' '}
                  <ul>
                    {feed.feed_columns.map((f) => (
                      <li>
                        {f.header_name}: {f.attribute_name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="d-flex">
              <div>
                <button
                  className={'btn btn-outline-secondary btn-sm' + (feed.reset ? ' disabled' : '')}
                  onClick={resetFeed}
                  disabled={feed.reset}
                >
                  {refreshLoading && <i className="fas fa-spinner fa-spin mr-1"></i>}
                  Reset Feed
                </button>
                <a
                  href={`/dealerships/${dealershipSlug}/feeds/${feedId}/edit`}
                  className="btn btn-outline-secondary btn-sm ml-2"
                >
                  Edit
                </a>
                {feed.feed_type === 'export' && (
                  <button onClick={exportFeed} className="btn btn-outline-secondary btn-sm ml-2">
                    {loadingExport && <i className="fas fa-spinner fa-spin mr-1"></i>}
                    Export
                  </button>
                )}
                <a
                  href={`/dealerships/${dealershipSlug}/feeds/${feedId}/stock_filters`}
                  className="btn btn-outline-secondary btn-sm ml-2"
                >
                  Stock Filters
                </a>
                <p className="small mt-1 text-secondary mb-0">
                  Reseting the feed will force the feed to run and not be skipped for the next 24
                  hours.
                </p>
              </div>
              <div className="ml-auto">
                <button className="btn btn-outline-secondary btn-sm ml-auto" onClick={refetch}>
                  {isFetching ? (
                    'Loading...'
                  ) : (
                    <>
                      <i className="fas fa-sync"></i> Refresh Data
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="mb-3 position-relative lead-event-form">
            <Tabs>
              <div className="box p-2">
                <TabList>
                  <Tab>Feed Runs</Tab>
                  <Tab>Changelog</Tab>
                  {['import', 'pentana_feeds', 'email'].includes(feed.feed_type) && <Tab>Cars</Tab>}
                  <Tab>Edit</Tab>
                </TabList>
              </div>
              <TabPanel>
                <div className="mt-2">
                  <FeedRuns
                    feed={feed}
                    page={page}
                    setPage={setPage}
                    loading={loading || isFetching}
                  />
                </div>
              </TabPanel>
              <TabPanel>
                <Changes
                  object={feed}
                  url={`/dealerships/${dealershipSlug}/feeds/${feed.id}/changes`}
                  objectName="Feed"
                  wrapperClassName="mt-2"
                />
              </TabPanel>
              {['import', 'pentana_feeds', 'email'].includes(feed.feed_type) && (
                <TabPanel>
                  <FeedCars feedId={feedId} />
                </TabPanel>
              )}
              <TabPanel>
                <Form feed={feed} refetch={refetch} />
              </TabPanel>
            </Tabs>
          </div>
        </>
      )}
    </div>
  )
}

export const FeedShow = () => {
  let { dealershipSlug, feedId } = useParams()

  return (
    <>
      <Link
        className="btn btn-outline-secondary mb-2"
        to={Routes.dealership_feeds_path(dealershipSlug)}
      >
        Back
      </Link>
      <Show feedId={feedId} />
    </>
  )
}

const Wrapper = ({ feedId, isOpen, setIsOpen }) => {
  return (
    <SlideIn isOpen={isOpen} setIsOpen={setIsOpen} header="View Feed">
      <div className="p-3">
        <Show feedId={feedId} />
      </div>
    </SlideIn>
  )
}

export default Wrapper
