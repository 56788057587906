import { useEffect, useState } from 'react'

import moment from 'moment'
import { useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import Loading from '../Loading'
import { standardHeaders } from '../entries/utils'

function deploymentStateColor(state) {
  switch (state) {
    case 'BUILDING':
      return 'warning'
    case 'READY':
      return 'success'
    case 'ERROR':
      return 'danger'
    case 'CANCELED':
      return 'danger'
    default:
      return 'secondary'
  }
}

const BuildTimer = ({ deployment }) => {
  const [elapsedTime, setElapsedTime] = useState('')

  if (deployment.state !== 'BUILDING') {
    return null
  }

  let buildingAt = deployment.buildingAt

  useEffect(() => {
    const updateElapsedTime = () => {
      const duration = moment.duration(moment().diff(moment(buildingAt)))
      const formattedTime = `${duration.minutes()}m ${duration.seconds()}s`
      setElapsedTime(formattedTime)
    }

    // Update every second
    const interval = setInterval(updateElapsedTime, 1000)

    // Cleanup interval on component unmount
    return () => clearInterval(interval)
  }, [buildingAt])

  if (!elapsedTime) {
    return null
  }

  return (
    <div className="badge badge-warning">
      <i className="fa fa-spinner fa-spin mr-1"></i>
      Building {elapsedTime}
    </div>
  )
}

const DeleteDeployment = ({ deployment, vercelAccountId, fetchDeployments }) => {
  let [loading, setLoading] = useState(false)

  if (!['BUILDING', 'QUEUED'].includes(deployment.state)) {
    return null
  }

  const handleClick = (event) => {
    event.preventDefault()
    setLoading(true)

    const url = `${Routes.admin_delete_deployment_path()}?id=${encodeURIComponent(deployment.uid)}&vercel_account_id=${encodeURIComponent(vercelAccountId)}`

    fetch(url, {
      method: 'DELETE',
      headers: standardHeaders,
    })
      .then((res) => res.json())
      .then(() => {
        fetchDeployments()
      })
  }

  return (
    <a
      href={'#'}
      onClick={handleClick}
      className="btn btn-outline-danger btn-sm delete-deployment ml-2"
    >
      {loading ? <i className="fa fa-spinner fa-spin mr-1"></i> : <>Delete</>}
    </a>
  )
}

const Deployment = ({ deployment, vercelAccountId, fetchDeployments }) => {
  return (
    <div className="box mb-2 w-100 p-3">
      <div className="d-flex">
        <div style={{ maxWidth: '60%' }}>
          <div>
            <b>{deployment.name}</b>
          </div>
          <div className="small text-secondary">
            {deployment.meta.githubCommitMessage.length > 200
              ? `${deployment.meta.githubCommitMessage.substring(0, 200)}...`
              : deployment.meta.githubCommitMessage}
          </div>
          <div className={`badge badge-${deploymentStateColor(deployment.state)} mr-1`}>
            {deployment.state}
          </div>
          <div className="badge badge-secondary mr-2">
            Created {moment(deployment.createdAt).fromNow()}
          </div>
          <BuildTimer deployment={deployment} />
        </div>
        <div className="ml-auto">
          <a
            href={deployment.inspectorUrl}
            target="_blank"
            rel="noreferrer"
            className="btn btn-outline-primary btn-sm"
          >
            <i className="fa fa-external-link mr-2"></i>
            Deployment
          </a>
          <a
            href={`https://www.github.com/dealerstudio/${deployment.meta.githubRepo}`}
            target="_blank"
            rel="noreferrer"
            className="btn btn-outline-primary btn-sm ml-2"
          >
            <i className="fa fa-external-link mr-2"></i>
            {deployment.meta.githubRepo}
          </a>
          <DeleteDeployment
            deployment={deployment}
            vercelAccountId={vercelAccountId}
            fetchDeployments={fetchDeployments}
          />
        </div>
      </div>
    </div>
  )
}

export const Deployments = ({ websiteSlug }) => {
  let [deployments, setDeployments] = useState([])
  let [dealership, setDealership] = useState(null)
  let [loading, setLoading] = useState(true)
  let [vercelAccountId, setVercelAccountId] = useState(null)
  let [loadingRedbuild, setLoadingRebuild] = useState(false)

  const fetchDeployments = () => {
    setLoading(true)
    fetch(Routes.deployments_website_path(websiteSlug), {
      headers: standardHeaders,
    })
      .then((res) => res.json())
      .then((data) => {
        setDeployments(data.deployments)
        setVercelAccountId(data.vercel_account_id)
        setDealership(data.dealership)
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchDeployments()
  }, [])

  if (loading) {
    return (
      <div className="p-3">
        <Loading />
      </div>
    )
  }

  const rebuild = () => {
    setLoadingRebuild(true)
    fetch(`/dealerships/${dealership?.slug}/websites/${websiteSlug}/rebuild.json`, {
      method: 'POST',
      headers: standardHeaders,
    })
      .then((res) => res.json())
      .then(() => {
        setLoadingRebuild(false)
        fetchDeployments()
      })
  }

  return (
    <div>
      <div className="d-flex my-2">
        <h3>Website Deployments</h3>
        <div className="ml-auto">
          <button className="btn btn-outline-primary btn-sm mr-2" onClick={fetchDeployments}>
            Refresh
          </button>
          <button className="btn btn-outline-primary btn-sm" onClick={rebuild}>
            {loadingRedbuild ? <i className="fa fa-spin fa-spinner" /> : 'Rebuild'}
          </button>
        </div>
      </div>
      {deployments &&
        deployments.length > 0 &&
        deployments.map((deployment) => (
          <Deployment
            key={deployment.uid}
            deployment={deployment}
            vercelAccountId={vercelAccountId}
            fetchDeployments={fetchDeployments}
          />
        ))}
    </div>
  )
}

const Wrapper = () => {
  let { websiteSlug } = useParams()

  return (
    <div className="p-3 w-100">
      <Deployments websiteSlug={websiteSlug} />
    </div>
  )
}

export default Wrapper
