import { useContext, useEffect, useRef, useState } from 'react'

import { FilterMatchMode, PrimeReactProvider } from 'primereact/api'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { SplitButton } from 'primereact/splitbutton'
import { Toast } from 'primereact/toast'
import { useParams, useSearchParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { OfferContext } from '../contexts'
import DataTableSearch from '../entries/DataTableSearch'
import { SectionHeading, standardHeaders } from '../entries/utils'
import AuditTrailSlideIn from '../shared/AuditTrailSlideIn'
import showToast from '../shared/ShowToast'
import { OfferService } from './offerService'

const Action = (offer) => {
  const { notification, loadData } = useContext(OfferContext)
  const [showSlideIn, setShowSlideIn] = useState(false)
  const handleHideSlideIn = () => {
    setShowSlideIn(false)
  }

  const items = [
    {
      label: 'Edit',
      icon: 'fas fa-edit',
      disabled: !offer.can_update_offer,
      command: () => {
        window.open(offer.edit_offer_url, '_self')
      },
    },
    {
      label: offer.disabled_offer ? 'Un-hide offer' : 'Hide offer',
      icon: offer.disabled_offer ? 'fas fa-eye' : ' fas fa-eye-slash',
      command: () => {
        const action = offer.disabled_offer ? 'Visible' : 'Hidden'
        fetch(offer.hide_offer_url, {
          method: 'POST',
          headers: standardHeaders,
        }).then(() => {
          const message = `Offer made ${action} successfully`
          showToast(notification, 'success', message)
          loadData()
        })
      },
    },
    {
      label: 'Duplicate',
      icon: 'fas fa-copy',
      disabled: !offer.can_update_offer,
      command: () => {
        const userConfirmation = window.confirm('Are you sure?')

        if (!userConfirmation) {
          return
        }
        window.open(offer.duplicate_offer_url, '_self')
      },
    },
    {
      label: 'Audit Trail',
      icon: 'fa fa-history',
      disabled: !offer.offer_versions_present,
      command: () => {
        setShowSlideIn(true)
      },
    },
    {
      label: 'Destroy',
      icon: 'fa fa-trash',
      disabled: !offer.can_update_offer,
      command: () => {
        const userConfirmation = window.confirm('Are you sure?')

        if (!userConfirmation) {
          return
        }
        fetch(offer.destroy_offer_url, {
          method: 'DELETE',
          headers: standardHeaders,
        }).then(() => {
          showToast(notification, 'success', 'Offer destroyed successfully')
          loadData()
        })
      },
    },
  ]
  const oemItems = [
    {
      label: 'Expire',
      icon: 'fa fa-calendar-times',
      disabled: !offer.offer_expire_url,
      command: () => {
        fetch(offer.offer_expire_url, {
          method: 'POST',
          headers: standardHeaders,
        }).then(() => {
          showToast(notification, 'success', 'Offer Expired Successfully')
          loadData()
        })
      },
    },
    {
      label: 'Extend 1 month',
      icon: 'fa fa-calendar-plus',
      disabled: !offer.manufacturer_present,
      command: () => {
        fetch(offer.offer_extend_url, {
          method: 'POST',
          headers: standardHeaders,
        }).then(() => {
          showToast(notification, 'success', 'Offer Extended Successfully')
          loadData()
        })
      },
    },
  ]

  const mergedItems = offer.manufacturer_present ? [...items, ...oemItems] : [...items]

  if (offer.user_is_admin || offer.can_show_offer) {
    return (
      <div style={{ position: 'relative' }}>
        <SplitButton
          label="Show"
          buttonProps={{ id: 'show-button' }}
          onClick={() => {
            window.location.href = offer.offer_show_url
          }}
          model={mergedItems}
          menuButtonProps={{ id: 'action-button' }}
          outlined
          rounded
        />
        {showSlideIn && (
          <AuditTrailSlideIn
            AuditTrailObject={offer}
            AuditTrailObjectName="Offer"
            Url={offer.audit_trail_url}
            ShowSlideIn={showSlideIn}
            onHide={handleHideSlideIn}
          />
        )}
      </div>
    )
  }
}

const Image = (offer) => {
  return (
    <div className="justify-content-center d-flex flex-column">
      <div className="text-center">
        <img className="img-fluid" src={offer.offer_image_url} alt={offer.name} />
        <div className="small text-center">{offer.offer_image_dimensions}</div>
      </div>
    </div>
  )
}

const OfferDetails = (offer) => {
  return (
    <div>
      <h3>
        {offer.name}
        {offer.disabled_offer && (
          <small>
            <span className="badge badge-danger ml-2">Hidden</span>
          </small>
        )}
      </h3>
      <h5>
        <div className="d-flex align-items-center">
          {offer.category}
          {offer.oem_offer && (
            <div className="badge badge-success ml-2">
              {!offer.can_edit_oem_offer && <i className="fa fa-lock"> &nbsp;</i>}
              OEM
            </div>
          )}
        </div>
      </h5>
      <div className="text-secondary" dangerouslySetInnerHTML={{ __html: offer.description }}></div>
      <p>{offer.offer_expiry_date_in_words}</p>
      <p>
        <b>Created By:</b> {offer.offer_created_by}
      </p>
      {offer.offer_state_present && (
        <div className="badge badge-danger">{offer.offer_state_sentence}</div>
      )}
      {/* read_from_follower_db function call and related logic is not directly convertible to JSX */}
      <a data-toggle="collapse" data-target={`#${offer.id}-collapse`}>
        &nbsp;Terms
      </a>
      {offer.website_present && (
        <p>
          <b>Website:&nbsp;</b>
          <span className="badge badge-primary">{offer.offer_website_name}</span>
        </p>
      )}
      {offer.disabled_offer && (
        <p>
          <b>Status:&nbsp;</b>
          <span className="badge badge-secondary">Hidden</span>
        </p>
      )}
      <div className="collapse" id={`${offer.id}-collapse`}>
        <div className="text-secondary">
          <small dangerouslySetInnerHTML={{ __html: offer.disclaimer }}></small>
        </div>
      </div>
    </div>
  )
}

const position = (offer) => {
  const { notification, loadData } = useContext(OfferContext)
  return offer.user_is_admin ? (
    <div>
      <div className="d-flex justify-content-center">
        <button
          className="btn btn-sm btn-outline-primary"
          onClick={() => {
            fetch(offer.offer_move_higher_url, {
              method: 'POST',
              headers: standardHeaders,
              body: JSON.stringify({ position: 'up' }),
            }).then(() => {
              showToast(notification, 'success', 'Offer moved higher successfully')
              loadData()
            })
          }}
        >
          <i className="fa fa-arrow-up"></i>
        </button>
        <button
          className="btn btn-sm btn-outline-primary"
          onClick={() => {
            fetch(offer.offer_move_top_url, {
              method: 'POST',
              headers: standardHeaders,
              body: JSON.stringify({ position: 'top' }),
            }).then(() => {
              showToast(notification, 'success', 'Offer moved to top successfully')
              loadData()
            })
          }}
        >
          <i className="fa fa-angle-double-up"></i>
        </button>
        <button
          className="btn btn-sm btn-outline-primary"
          onClick={() => {
            fetch(offer.offer_move_bottom_url, {
              method: 'POST',
              headers: standardHeaders,
              body: JSON.stringify({ position: 'bottom' }),
            }).then(() => {
              showToast(notification, 'success', 'Offer moved to bottom successfully')
              loadData()
            })
          }}
        >
          <i className="fa fa-angle-double-down"></i>
        </button>
        <button
          className="btn btn-sm btn-outline-primary"
          onClick={() => {
            fetch(offer.offer_move_lower_url, {
              method: 'POST',
              headers: standardHeaders,
              body: JSON.stringify({ position: 'down' }),
            }).then(() => {
              showToast(notification, 'success', 'Offer moved lower successfully')
              loadData()
            })
          }}
        >
          <i className="fa fa-arrow-down"></i>
        </button>
      </div>
    </div>
  ) : null
}

const App = ({ ownerId = '', ownerClass = '', defaultType = 'current' }) => {
  const notification = useRef(null)
  const [offers, setOffers] = useState([])
  const [filteredOffers, setFilteredOffers] = useState([])
  let [offerStatus, setOfferStatus] = useState(defaultType)
  const [totalRecords, setTotalRecords] = useState(0)
  const [data, setData] = useState([])
  let [offerLocation, setOfferLocation] = useState()
  let rows = 10
  let { websiteSlug, manufacturerSlug } = useParams()
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  })

  const [lazyState, setlazyState] = useState({
    filters: {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    },
    page: 1,
  })

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    loadData()
  }, [lazyState.page, offerStatus, offerLocation])

  // Apply client-side filtering whenever the filter or offers change
  useEffect(() => {
    if (!offers.length) return

    const globalFilterValue = lazyState.filters.global.value

    if (globalFilterValue) {
      const searchTerm = globalFilterValue.toLowerCase()
      const filtered = offers.filter((offer) => {
        return (
          (offer.name && offer.name.toLowerCase().includes(searchTerm)) ||
          (offer.category && offer.category.toLowerCase().includes(searchTerm)) ||
          (offer.description && offer.description.toLowerCase().includes(searchTerm))
        )
      })
      setFilteredOffers(filtered)
    } else {
      setFilteredOffers(offers)
    }
  }, [offers, lazyState.filters.global.value])

  const loadData = () => {
    setLoading(true)
    let params = {
      page: lazyState.page,
      per_page: rows,
      type: offerStatus,
    }

    if (offerLocation) {
      params.state = offerLocation
    }

    try {
      OfferService.getOffers(params, ownerId, ownerClass, offerStatus).then((data) => {
        setOffers(data.data.offers)
        setFilteredOffers(data.data.offers) // Initialize filtered offers with all offers
        setTotalRecords(data.data.total_count)
        setData(data.data)
        setLoading(false)
      })
    } catch (error) {
      console.error(error)
    }
  }

  const onPageChange = (event) => {
    setlazyState({
      ...lazyState,
      page: event.page + 1,
    })
  }

  const onFilter = (e) => {
    const newFilters = { ...e.filters }
    setlazyState({
      ...lazyState,
      filters: newFilters,
      page: 1,
    })
  }

  let newUrl
  if (websiteSlug) {
    newUrl = Routes.new_website_offer_path(websiteSlug)
  } else if (manufacturerSlug) {
    newUrl = Routes.new_manufacturer_offer_path(manufacturerSlug)
  }

  const header = DataTableSearch({
    filters: lazyState.filters,
    setFilters: (newFilters) => {
      setlazyState({
        ...lazyState,
        filters: newFilters,
        page: 1,
      })
    },
  })
  return (
    <div className="p-3">
      <SectionHeading title={`${offers.length} offers found`}>
        <>
          {data.can_create_offer && (
            <a href={newUrl} className="btn btn-outline-success" data-remote={true}>
              <i className="fa fa-plus mr-1"></i>
              Offer
            </a>
          )}
          <div
            className="btn btn-secondary ml-2"
            onClick={() => {
              setOfferStatus(offerStatus === 'current' ? 'expired' : 'current')
            }}
          >
            {offerStatus === 'current' ? 'Expired' : 'Current'}
          </div>
        </>
      </SectionHeading>
      {manufacturerSlug && data.states && (
        <div className="mb-3">
          {data?.states?.map((state) => (
            <div
              key={state}
              className={
                'btn btn-outline-primary mr-2' + (state === offerLocation ? ' active' : '')
              }
              onClick={() => {
                if (state === offerLocation) {
                  setOfferLocation()
                  return
                }

                setOfferLocation(state)
              }}
            >
              {state}
            </div>
          ))}
        </div>
      )}
      <OfferContext.Provider value={{ notification, loadData }}>
        <div className="App box">
          <Toast ref={notification} />
          <PrimeReactProvider>
            <DataTable
              value={filteredOffers}
              tableStyle={{ minWidth: '50rem' }}
              paginator
              rows={rows}
              globalFilterFields={['name', 'category']}
              header={header}
              loading={loading}
              filters={lazyState.filters}
              onFilter={onFilter}
              totalRecords={totalRecords}
              first={(lazyState.page - 1) * rows}
              page={lazyState.page}
              onPage={onPageChange}
              lazy
            >
              <Column body={Image} field="image" header="" style={{ maxWidth: '20rem' }}></Column>
              <Column field="name" body={OfferDetails} header=""></Column>
              <Column body={position} header=""></Column>
              <Column body={Action} header="Actions"></Column>
            </DataTable>
          </PrimeReactProvider>
        </div>
      </OfferContext.Provider>
    </div>
  )
}

const Wrapper = () => {
  let { websiteSlug, manufacturerSlug } = useParams()
  const [searchParams] = useSearchParams()
  const type = searchParams.get('type')

  let ownerId = websiteSlug || manufacturerSlug
  let ownerClass = 'Website'
  if (manufacturerSlug) {
    ownerClass = 'Manufacturer'
  }

  return <App ownerId={ownerId} ownerClass={ownerClass} defaultType={type} />
}

export default Wrapper
