import { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { standardHeaders } from '../entries/utils'
import Form from '../shared/Form'
import SlideIn from '../shared/SlideIn'
import HookFormField from '../shared/hookFormField'

const yearOptions = Array.from({ length: new Date().getFullYear() - 1990 + 1 }, (_, i) => {
  const year = 1990 + i
  return { label: year.toString(), value: year }
}).reverse()

let attributes = {
  manufacturer_id: {
    type: 'manufacturer_select',
    label: 'Make',
    required: true,
    options: 'manufacturerOptions',
  },
  raw_model: {
    type: 'model_select',
    label: 'Model',
    required: true,
  },
  rego: {
    type: 'text',
    label: 'Rego',
    required: true,
  },
  year: {
    type: 'select',
    label: 'Year',
    required: true,
    options: 'yearOptions',
    id: 'year-select',
  },
}

const NewVehicleForm = ({ vehicle, manufacturerOptions, getContact }) => {
  let [visible, setVisible] = useState(false)
  let { dealershipSlug, contactId } = useParams()

  let defaultValues = {
    manufacturer_id: vehicle?.manufacturer_id || '',
    raw_model: vehicle?.raw_model || '',
    contact_id: contactId,
    rego: vehicle?.rego || '',
    year: vehicle?.year || '',
  }

  let formData = { manufacturerOptions, yearOptions }

  return (
    <>
      <SlideIn header="Create Vehicle" isOpen={visible} setIsOpen={setVisible}>
        <div className="py-3">
          <Form
            defaultValues={defaultValues}
            attributes={attributes}
            formData={formData}
            submitUrl={Routes.dealership_vehicles_path(dealershipSlug)}
            afterSubmit={() => {
              getContact()
              setVisible(false)
            }}
          >
            <HookFormField attribute="manufacturer_id" />
            <HookFormField attribute="raw_model" />
            <HookFormField attribute="rego" />
            <HookFormField attribute="year" />
          </Form>
        </div>
      </SlideIn>
      <button
        className="btn btn-outline-primary"
        id="new-vehicle-btn"
        onClick={() => {
          setVisible(true)
        }}
      >
        <i className="fa fa-plus mr-1"></i>
        Vehicle
      </button>
    </>
  )
}

const Wrapper = ({ getContact }) => {
  let [manufacturers, setManufacturers] = useState([])

  useEffect(() => {
    fetch(Routes.manufacturers_path(), {
      headers: standardHeaders,
    })
      .then((res) => res.json())
      .then((data) => {
        setManufacturers(data)
      })
  }, [])

  let manufacturerOptions = manufacturers?.map((manufacturer) => ({
    value: manufacturer.id,
    label: manufacturer.name,
  }))

  return <NewVehicleForm manufacturerOptions={manufacturerOptions} getContact={getContact} />
}

export default Wrapper
