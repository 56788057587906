import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { Dialog } from 'primereact/dialog'
import { Tooltip } from 'primereact/tooltip'
import { classNames } from 'primereact/utils'
import { useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { CurrentUserContext } from '../contexts'
import { AVAILABLE_TEMPLATE_PAGES } from '../editor/templates/templateUtils'
import { ManufacturerProps, ModelProps } from '../editor/types'
import { Label, Select } from '../entries/FormElements'
import { csrfToken, standardHeaders } from '../entries/utils'

function getPageTypeOptions(
  ownerClass: string,
  isAdmin: boolean,
  manufacturers: ManufacturerProps[]
) {
  const pageTypeOptions = [
    { value: 'blankPage', label: 'Blank Page' },
    { value: 'duplicatedPage', label: 'Duplicate a Page' },
  ]
  // Only show template pages if there are any available or user is an admin
  if (
    AVAILABLE_TEMPLATE_PAGES.some((template) => template.availability === 'all' || isAdmin) &&
    ownerClass === 'Website'
  ) {
    pageTypeOptions.push({ value: 'templatePage', label: 'Template Page' })
  }
  // Only show manufacturer and model pages if there are linked manufacturers
  if (manufacturers.length >= 1 && isAdmin) {
    if (ownerClass === 'Website') {
      pageTypeOptions.push({ value: 'manufacturerPage', label: 'Manufacturer Page' })
    }
    pageTypeOptions.push({ value: 'modelPage', label: 'Model Page' })
  }
  return pageTypeOptions
}

const App = ({
  ownerClass,
  ownerId,
  isAdmin,
  allPages,
  manufacturers,
  hasHomePage,
}: {
  ownerClass: string
  ownerId: number
  isAdmin: boolean
  allPages: { value: number; label: string }[]
  manufacturers: ManufacturerProps[]
  hasHomePage: boolean
}) => {
  const [opened, setOpened] = useState(false)
  const [loading, setLoading] = useState(false)
  const [title, setTitle] = useState('')
  const [pageType, setPageType] = useState('blankPage')
  const [isHomePage, setIsHomePage] = useState(false)
  const [pages, setPages] = useState([])
  const [models, setModels] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [selectedTemplateType, setSelectedTemplateType] = useState(null)
  const defaultManufacturer = manufacturers.length === 1 ? manufacturers[0].id : null
  const [selectedManufacturer, setSelectedManufacturer] = useState(defaultManufacturer)
  const [selectedModel, setSelectedModel] = useState(null)
  const [selectedPage, setSelectedPage] = useState(null)

  const pageTypeOptions = getPageTypeOptions(ownerClass, isAdmin, manufacturers)

  // TODO: Add back in once home page templates are live
  // const selectedTemplateData = useMemo(() => {
  //   if (selectedTemplate) {
  //     const template = AVAILABLE_TEMPLATE_PAGES.find(
  //       (template) => template.value === selectedTemplate
  //     )
  //     return template
  //   }
  //   return null
  // }, [selectedTemplate])

  // Check if form is completed before enabling submit button
  const formCompleted = useMemo(() => {
    switch (pageType) {
      case 'blankPage':
        // Title added
        return title.length >= 1
      case 'duplicatedPage':
        // Title added, page selected
        return title.length >= 1 && selectedPage
      case 'manufacturerPage':
        // Title added, manufacturer selected
        return title.length >= 1 && selectedManufacturer
      case 'modelPage':
        // Title added, manufacturer selected, model selected
        return title.length >= 1 && selectedManufacturer && selectedModel
      case 'templatePage':
        // Title added, template selected
        return (
          title.length >= 1 &&
          selectedTemplate &&
          (!isHomePage || (isHomePage && selectedTemplateType))
        )
    }
  }, [
    title,
    pageType,
    selectedManufacturer,
    selectedModel,
    selectedPage,
    selectedTemplate,
    selectedTemplateType,
  ])

  const resetDefaultValues = () => {
    setPages([])
    setModels([])
    setSelectedManufacturer(defaultManufacturer)
    setSelectedModel(null)
    setSelectedPage(null)
    setSelectedTemplate(null)
  }

  const fetchModels = (updatedManufacturer: number) => {
    setLoading(true)
    fetch(Routes.manufacturer_models_path(updatedManufacturer) + '.json')
      .then((res) => res.json())
      .then((res) => {
        setLoading(false)
        setModels(res.map((model: ModelProps) => ({ value: model.id, label: model.name })))
        setSelectedModel(false)
      })
  }

  const submitRequest = async (data: object) => {
    const response = await fetch(`/${ownerClass.toLowerCase()}s/${ownerId}/pages/prepare`, {
      method: 'POST',
      headers: standardHeaders,
      body: JSON.stringify(data),
    })
    if (!response.ok) throw new Error('Something went wrong')
    return response.json()
  }

  const submit = useCallback(async () => {
    try {
      setLoading(true)
      const data = {
        page: {
          pageable_id: ownerId,
          pageable_type: ownerClass,
          title,
          home_page: isHomePage,
        },
        page_type: pageType,
        template: {
          type: selectedTemplateType || selectedTemplate,
          manufacturer_id: selectedManufacturer,
          page_id: selectedPage,
          model_id: selectedModel,
        },
        authenticity_token: csrfToken,
      }

      const result = await submitRequest(data)
      if (result.edit_url) {
        window.location = result.edit_url
      }
    } catch (error) {
      console.error('Error submitting page:', error)
      // Handle error state if needed
    } finally {
      setLoading(false)
    }
  }, [
    ownerClass,
    ownerId,
    isHomePage,
    title,
    pageType,
    selectedManufacturer,
    selectedModel,
    selectedPage,
    selectedTemplate,
    selectedTemplateType,
    loading,
  ])

  return (
    <>
      <div className="ml-auto">
        <button
          id="new-page-button"
          className="btn btn-outline-primary"
          onClick={() => {
            // Set default values when opening modal
            if (!opened) {
              setPageType('blankPage')
              resetDefaultValues()
            }
            setOpened(!opened)
          }}
        >
          <i className="fa fa-plus mr-1" />
          New Page
        </button>
      </div>
      <Dialog
        header="Add New Page"
        visible={opened}
        onHide={() => setOpened(false)}
        dismissableMask
        style={{ width: '500px' }}
      >
        <div className="form-group mb-0">
          {(pageType != 'templatePage' ||
            (pageType == 'templatePage' && selectedTemplate == 'search')) &&
            pageType != 'manufacturerPage' &&
            pageType != 'modelPage' && (
              <div className="form-group string page_title">
                <Label label="Page Title" required additionalClasses="" />
                <input
                  className="form-control string optional"
                  type="text"
                  name="page[title]"
                  id="page_title"
                  placeholder="Enter a title for your page..."
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
            )}
          <div className="form-group string page_type">
            <Select
              id="page_type"
              label="Page Type"
              required
              hint=""
              options={pageTypeOptions}
              value={pageTypeOptions.find((option) => option.value === pageType)}
              isClearable={false}
              disabled={loading}
              onChange={(e: { value: string }) => {
                setPageType(e.value)
                if (e.value == 'duplicatedPage') {
                  setPages(allPages)
                  setSelectedManufacturer(defaultManufacturer)
                  setSelectedModel(false)
                  setSelectedPage(false)
                } else if (selectedManufacturer && e.value == 'modelPage') {
                  fetchModels(selectedManufacturer)
                } else {
                  resetDefaultValues() // Reset default values when page type changes
                }
                // If default manufacturer is set, set title to manufacturer name
                if (e.value == 'manufacturerPage' && selectedManufacturer) {
                  setTitle(
                    manufacturers.find((manufacturer) => manufacturer.id === selectedManufacturer)
                      .name
                  )
                }
              }}
              menuPortalTarget={document.body}
            />
          </div>
          {pageType == 'duplicatedPage' && (
            <div className="form-group string duplicated_page">
              <Select
                id="duplicated_page"
                label="Page to Duplicate"
                required
                hint=""
                options={pages}
                placeholder={loading ? 'Loading...' : 'Select a Page...'}
                disabled={loading}
                onChange={(e: { value: number }) => setSelectedPage(e.value)}
                menuPortalTarget={document.body}
              />
            </div>
          )}
          {pageType == 'templatePage' && (
            <>
              <div className="form-group string template_page">
                <Select
                  id="template_page"
                  label="Template"
                  required
                  hint=""
                  options={AVAILABLE_TEMPLATE_PAGES.filter(
                    // Only allow 1 home page per website
                    (template) => template.value !== 'home_page' || !hasHomePage
                  ).map((template) => {
                    if (template.availability === 'all' || isAdmin) {
                      return { value: template.value, label: template.label }
                    }
                  })}
                  placeholder={loading ? 'Loading...' : 'Select a template...'}
                  disabled={loading}
                  onChange={(e: { value: string; label: string }) => {
                    setTitle(e.value === 'search' ? '' : e.label)
                    setSelectedTemplate(e.value)
                    setIsHomePage(e.value === 'home_page') // Automatically set home page to true if template is home page
                  }}
                  menuPortalTarget={document.body}
                />
              </div>
              {/* TODO: Add back in once home page templates are live */}
              {/* {selectedTemplateData?.template_type && (
                <>
                  <div className="form-group string template_type">
                    <div id="template_type" className="d-flex">
                      <Select
                        id="template_type"
                        label="Template Type"
                        required
                        hint=""
                        wrapperClass="flex-grow-1 mb-0"
                        className="flex-grow-1"
                        options={selectedTemplateData.template_type.map((type) => {
                          return { value: type.value, label: type.label }
                        })}
                        placeholder={loading ? 'Loading...' : 'Select a template type...'}
                        disabled={loading}
                        onChange={(e: { value: string }) => {
                          setSelectedTemplateType(e.value)
                        }}
                        menuPortalTarget={document.body}
                      />
                      {selectedTemplateType &&
                        selectedTemplateData?.template_type?.find(
                          (type) => type.value === selectedTemplateType
                        )?.url && (
                          <a
                            className="ml-2 btn btn-info"
                            href={
                              selectedTemplateData?.template_type?.find(
                                (type) => type.value === selectedTemplateType
                              )?.url
                            }
                            target="_blank"
                          >
                            Preview
                          </a>
                        )}
                    </div>
                  </div>
                </>
              )} */}
            </>
          )}
          {(pageType == 'manufacturerPage' || pageType == 'modelPage') &&
            manufacturers.length >= 1 &&
            isAdmin && (
              <div className="form-group string manufacturer">
                <Select
                  id="manufacturer"
                  label="Manufacturer"
                  required
                  hint=""
                  options={manufacturers.map((manufacturer) => ({
                    value: manufacturer.id,
                    label: manufacturer.name,
                  }))}
                  placeholder={loading ? 'Loading...' : 'Select a Manufacturer...'}
                  // Default to first manufacturer if only one
                  defaultValue={
                    selectedManufacturer
                      ? {
                          label: manufacturers.find(
                            (manufacturer) => manufacturer.id === selectedManufacturer
                          ).name,
                          value: selectedManufacturer,
                        }
                      : undefined
                  }
                  isClearable={false}
                  disabled={loading}
                  onChange={(e: { value: number; label: string }) => {
                    if (pageType == 'manufacturerPage') {
                      setTitle(e.label)
                    } else if (pageType == 'modelPage') {
                      setSelectedModel(null) // Reset model when manufacturer changes
                      fetchModels(e.value)
                    }
                    setSelectedManufacturer(e.value)
                  }}
                  menuPortalTarget={document.body}
                />
              </div>
            )}
          {pageType == 'modelPage' &&
            (manufacturers.length === 1 || selectedManufacturer) &&
            isAdmin && (
              <div className="form-group string model">
                <Select
                  id="model"
                  label="Model"
                  required
                  hint=""
                  options={models}
                  placeholder={loading ? 'Loading...' : 'Select a Model...'}
                  value={
                    selectedModel ? models.find((model) => model.value === selectedModel) : null
                  }
                  disabled={loading}
                  onChange={(e: { value: number; label: string }) => {
                    setTitle(
                      `${
                        manufacturers.find(
                          (manufacturer) => manufacturer.id === selectedManufacturer
                        ).name
                      } ${e.label}`
                    )
                    setSelectedModel(e.value)
                  }}
                  menuPortalTarget={document.body}
                />
              </div>
            )}
          <div
            // This is required for disabled elements to show tooltips
            id="new-page-submit-tooltip"
            data-pr-position="top"
            data-pr-at="center top-10"
            data-pr-my="center bottom"
            data-pr-event="both"
          >
            <button
              id="new-page-submit"
              onClick={() => submit()}
              className={classNames('btn btn-success btn-block mt-2', loading ? 'disabled' : '')}
              disabled={loading || !formCompleted}
            >
              {loading ? 'Loading...' : 'Start Building Page'}
            </button>
          </div>
          <Tooltip target="#new-page-submit-tooltip">
            {formCompleted ? null : (
              <>
                Please complete the following:
                <ul className="mb-0 pl-3">
                  {title.length < 1 &&
                    (pageType != 'templatePage' ||
                      (pageType == 'templatePage' && selectedTemplate == 'search')) &&
                    pageType != 'manufacturerPage' &&
                    pageType != 'modelPage' && <li>Add a title</li>}
                  {pageType == 'duplicatedPage' && !selectedPage && (
                    <li>Select a page to duplicate</li>
                  )}
                  {pageType == 'templatePage' && !selectedTemplate && (
                    <li>Select a Template page</li>
                  )}
                  {/* TODO: Add back in once home page templates are live */}
                  {/* {pageType == 'templatePage' && isHomePage && !selectedTemplateType && (
                    <li>Select a Template Type</li>
                  )} */}
                  {(pageType == 'manufacturerPage' || pageType == 'modelPage') &&
                    !selectedManufacturer && <li>Select a manufacturer</li>}
                  {pageType == 'modelPage' && !selectedModel && <li>Select a model</li>}
                </ul>
              </>
            )}
          </Tooltip>
        </div>
      </Dialog>
    </>
  )
}

const Wrapper = () => {
  const currentUser = useContext(CurrentUserContext)
  // @ts-ignore - Need to type CurrentUserContext at some point
  const isAdmin = currentUser?.admin

  const [manufacturers, setManufacturers] = useState([])
  const [allPages, setAllPages] = useState([])
  const [ownerId, setOwnerId] = useState(null)
  const [hasHomePage, setHasHomePage] = useState(false)

  const { websiteSlug, manufacturerSlug } = useParams()
  let url: string = null
  let ownerClass: string = null
  if (websiteSlug) {
    url = Routes.new_page_modal_website_pages_path(websiteSlug)
    ownerClass = 'Website'
  } else if (manufacturerSlug) {
    url = Routes.new_page_modal_manufacturer_pages_path(manufacturerSlug)
    ownerClass = 'Manufacturer'
  }

  useEffect(() => {
    if (!url) return

    fetch(url)
      .then((res) => res.json())
      .then((res) => {
        setManufacturers(res.manufacturers)
        setAllPages(res.allPages)
        setOwnerId(res.ownerId)
        setHasHomePage(res.hasHomePage)
      })
  }, [url])

  return (
    <App
      ownerClass={ownerClass}
      ownerId={ownerId}
      isAdmin={isAdmin}
      allPages={allPages}
      manufacturers={manufacturers}
      hasHomePage={hasHomePage}
    />
  )
}

export default Wrapper
