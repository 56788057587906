import { useEffect, useState } from 'react'

import { Form, FormMultiSelect } from '../formUtils'

const LeadClusterAssignmentRulesFormNew = ({
  dealershipSlug,
  handleBack,
  submitFunction,
  notification,
  showToast,
}) => {
  let [categories, setCategories] = useState([])
  let [dealershipUsers, setDealershipUsers] = useState([])
  let [locations, setLocations] = useState(undefined)

  useEffect(() => {
    fetchDealershipUsers(dealershipSlug)
    fetchLeadCategories()
    fetchLocations(dealershipSlug)
  }, [])

  const fetchDealershipUsers = async (dealershipSlug) => {
    try {
      const URL = Routes.dealership_dealership_users_path(dealershipSlug)
      const response = await fetch(URL, {
        headers: {
          Accept: 'application/json',
        },
      })
      const data = await response.json()
      let dealershipUserOptions = data.map(function (dealershipUser) {
        return {
          label: dealershipUser.email,
          value: dealershipUser.id,
        }
      })
      setDealershipUsers(dealershipUserOptions)
    } catch (error) {
      console.error('Error fetching dealership users:', error)
      showToast(notification, 'error', 'Error fetching dealership users.', '')
    }
  }

  const fetchLeadCategories = async () => {
    try {
      const URL = Routes.categories_leads_path()
      const response = await fetch(URL, {
        headers: {
          Accept: 'application/json',
        },
      })
      const data = await response.json()
      setCategories(data.categories)
    } catch (error) {
      console.error('Error fetching categories:', error)
      showToast(notification, 'error', 'Error fetching categories.', '')
    }
  }

  const fetchLocations = async (dealershipSlug) => {
    try {
      const URL = Routes.dealership_locations_path(dealershipSlug)
      const response = await fetch(URL, {
        headers: {
          Accept: 'application/json',
        },
      })
      const data = await response.json()
      let locationOptions = data.map(function (location) {
        return {
          label: location.name,
          value: location.id,
        }
      })
      setLocations(locationOptions)
    } catch (error) {
      console.error('Error fetching locations:', error)
      showToast(notification, 'error', 'Error fetching locations.', '')
    }
  }

  return (
    <Form onSubmit={submitFunction}>
      <div className="form-inputs">
        <div>
          {locations && (
            <FormMultiSelect
              name="location_ids"
              label="Locations"
              className="js-choice"
              options={locations}
              multiple
            />
          )}
        </div>
        <div>
          {categories && (
            <FormMultiSelect
              name="categories"
              label="Categories"
              className="js-choice"
              options={categories}
              multiple
            />
          )}
        </div>
        <div>
          {categories && (
            <FormMultiSelect
              name="dealership_user_ids"
              label="Users"
              className="js-choice"
              options={dealershipUsers}
              multiple
            />
          )}
        </div>
      </div>
      <div className="form-actions mt-3">
        <button onClick={handleBack} className="btn btn-link">
          Back
        </button>
        <button type="submit" className="btn btn-primary">
          Create
        </button>
      </div>
    </Form>
  )
}

export default LeadClusterAssignmentRulesFormNew
