import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Message } from 'primereact/message'
import { useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { standardHeaders } from '../entries/utils'

const fetchVariants = async (manufacturerSlug, page, rows) => {
  const data = await fetch(
    Routes.variants_manufacturer_path(manufacturerSlug, {
      page: page,
      per_page: rows,
    }),
    {
      headers: standardHeaders,
    }
  ).then((res) => res.json())
  return data
}

const Variants = () => {
  let { manufacturerSlug } = useParams()
  let rows = 25
  let [page, setPage] = useState(1)

  const { data, error, isLoading } = useQuery({
    queryKey: ['variants', manufacturerSlug, page, rows],
    queryFn: () => fetchVariants(manufacturerSlug, page, rows),
    enabled: !!manufacturerSlug, // Only run query if manufacturerSlug is available
  })

  if (error) return <p>Error loading variants</p>

  const onPageChange = (event) => {
    setPage(event.page + 1) // PrimeReact pages are 0-based; Kaminari pages are 1-based
  }

  return (
    <>
      <p className="font-weight-bold">
        {data?.manufacturer.name} redbook data was last updated:{' '}
        {data?.manufacturer.redbook_data_updated_at
          ? moment(data?.manufacturer.redbook_data_updated_at).fromNow()
          : 'never'}
      </p>
      <div className="box">
        <DataTable
          value={data?.variants}
          rows={rows}
          paginator
          loading={isLoading}
          first={(page - 1) * rows}
          totalRecords={data?.total_count}
          onPage={onPageChange}
          lazy
        >
          <Column field="description" header="Description" />
          <Column
            field="created_at"
            header="Created At"
            body={(rowData) => moment(rowData.created_at).fromNow()}
          />
        </DataTable>
      </div>
    </>
  )
}

const Wrapper = () => {
  return (
    <div className="container py-3">
      <div className="mb-3">
        <h4>Redbook Variants</h4>
        <Message
          severity="info"
          text="This pages helps to ensure that our Redbook variants are up to date"
        />
      </div>
      <Variants />
    </div>
  )
}

export default Wrapper
