import React from 'react'

const LoadingBoxes = () => (
  <div className="blink box p-3 mb-3">
    <img
      src="https://i.ibb.co/dG7bYq0/bar-skeleton.png"
      alt="loading"
      style={{ maxWidth: '100%', height: 'auto', width: '100%' }}
    />
  </div>
)

export default LoadingBoxes
