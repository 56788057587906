import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useParams } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

import * as Routes from '../../routes'
import { standardHeaders } from '../entries/utils'
import { CopyableText } from '../shared/CopyableText'
import SlideIn from '../shared/SlideIn'

const View = (rowData) => {
  let [visible, setVisible] = useState(false)
  let [loading, setLoading] = useState(false)
  let [comparisonData, setComparisonData] = useState(null)
  let [isComparing, setIsComparing] = useState(false)
  let { marketplaceId } = useParams()

  const fetchComparisonData = () => {
    setIsComparing(true)
    fetch(Routes.compare_listings_marketplace_path(marketplaceId, { dealer_id: rowData.id }), {
      headers: standardHeaders,
    })
      .then((res) => res.json())
      .then((data) => {
        setComparisonData(data)
        setIsComparing(false)
      })
      .catch((error) => {
        console.error('Error fetching comparison data:', error)
        setIsComparing(false)
      })
  }

  const migrate = () => {
    setLoading(true)
    fetch(Routes.migrate_marketplace_path(marketplaceId, { dealer_id: rowData.id }), {
      headers: standardHeaders,
      method: 'POST',
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.migrationStatus === 'IN_PROGRESS') {
          // Wait for a moment to allow migration to process
          setTimeout(() => {
            completeMigration()
          }, 5000) // 5 second delay before completing
        } else {
          setLoading(false)
          setVisible(false)
        }
      })
      .catch((error) => {
        console.error('Error during migration:', error)
        setLoading(false)
      })
  }

  const completeMigration = () => {
    // Step 8: Call migration API again to update from IN_PROGRESS to DONE
    fetch(
      Routes.migrate_marketplace_path(marketplaceId, {
        dealer_id: rowData.id,
        complete: true,
      }),
      {
        headers: standardHeaders,
        method: 'POST',
      }
    )
      .then((res) => res.json())
      .then(() => {
        setLoading(false)
        setVisible(false)
      })
      .catch((error) => {
        console.error('Error completing migration:', error)
        setLoading(false)
      })
  }

  const handleOpenReconcile = () => {
    setVisible(true)
    // Fetch comparison data when dialog opens
    if (!comparisonData) {
      fetchComparisonData()
    }
  }

  return (
    <>
      <button className="btn btn-outline-primary" onClick={handleOpenReconcile}>
        Reconcile
      </button>
      <SlideIn isOpen={visible} setIsOpen={setVisible} header="Reconcile Stock Count">
        {isComparing ? (
          <div className="text-center p-5">
            <i className="fa fa-spinner fa-spin mr-2"></i> Comparing inventory...
          </div>
        ) : comparisonData ? (
          <>
            <div className="mt-3">
              <h5>Missing Cars:</h5>
              {comparisonData.missing_cars.length === 0 ? (
                <p className="text-success">No missing cars found.</p>
              ) : (
                <ul>
                  {comparisonData.missing_cars.map((car) => (
                    <li key={car.id}>{car.name}</li>
                  ))}
                </ul>
              )}
            </div>

            <div>
              <h5>Extra Cars:</h5>
              {comparisonData.extra_cars.length === 0 ? (
                <p className="text-success">No extra cars found.</p>
              ) : (
                <ul>
                  {comparisonData.extra_cars.map((car) => (
                    <li key={car.id}>{car.name}</li>
                  ))}
                </ul>
              )}
            </div>

            <div className="mt-4">
              {comparisonData.ready_for_migration ? (
                <button className="btn btn-outline-success" onClick={migrate} disabled={loading}>
                  <i className={`fa ${loading ? 'fa-spinner fa-spin' : 'fa-check'} mr-2`}></i>
                  {loading ? 'Migrating...' : 'Migrate dealer'}
                </button>
              ) : (
                <div className="alert alert-warning">
                  <i className="fa fa-exclamation-triangle mr-2"></i>
                  Reconcile inventory differences before migration
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="text-center p-5">
            <p>Failed to load comparison data. Please define the dealer id.</p>
            <button className="btn btn-outline-primary" onClick={fetchComparisonData}>
              Retry
            </button>
          </div>
        )}
      </SlideIn>
    </>
  )
}

const MigrationStatus = (rowData) => {
  return (
    <span className={`badge badge-${rowData.migration_status === 'DONE' ? 'success' : 'warning'}`}>
      {rowData.migration_status}
    </span>
  )
}

const FeedId = (rowData) => {
  if (!rowData.feed?.id) {
    return (
      <span className="text-danger">
        <i className="fa fa-exclamation-triangle" />
        Missing
      </span>
    )
  }

  return <>{rowData.feed.id}</>
}

const DealerTable = ({ status }) => {
  let [query, setQuery] = useState('')
  let [page, setPage] = useState(1)
  let { marketplaceId } = useParams()
  let rows = 50

  let { data, isLoading, error } = useQuery({
    queryKey: ['dealer_list_marketplace', marketplaceId, status, page, query],
    queryFn: () =>
      fetch(
        Routes.dealer_list_marketplace_path(marketplaceId, {
          status: status,
          page: page,
          per_page: rows,
          query: query,
        }),
        {
          headers: standardHeaders,
        }
      ).then((res) => res.json()),
  })

  const onPageChange = (event) => {
    setPage(event.page + 1) // PrimeReact pages are 0-based; Kaminari pages are 1-based
  }

  return (
    <>
      <input
        className="form-control mb-2"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Search..."
      />
      <div className="box">
        <DataTable
          value={data?.accounts}
          loading={isLoading}
          paginator
          rows={50}
          first={(page - 1) * rows}
          totalRecords={data?.total_count}
          onPage={onPageChange}
          lazy
        >
          <Column
            field="name"
            header="Name"
            body={(rowData) => <CopyableText text={rowData.name} />}
          />
          <Column
            field="id"
            header="ATG ID"
            body={(rowData) => <CopyableText text={rowData.id} />}
          />
          <Column field="feed.id" header="DS Feed ID" body={FeedId} />
          <Column field="migration_status" header="Migration Status" body={MigrationStatus} />
          <Column field="actions" body={View}></Column>
        </DataTable>
      </div>
    </>
  )
}

const Reconciliation = () => {
  return (
    <>
      <div className="container py-4">
        <h4>Dealer Migration</h4>
        <p className="text-secondary">Reconcile the stock count for each dealer</p>

        <Tabs>
          <div className="box mb-3 position-relative lead-event-form overflow-hidden">
            <div className="p-2 border-bottom">
              <TabList>
                <Tab>
                  <i className="fa fa-clock mr-2"></i>
                  Not Ready
                </Tab>
                <Tab>
                  <i className="fa fa-hourglass-start mr-2"></i>
                  Ready
                </Tab>
                <Tab>
                  <i className="fa fa-check mr-2"></i>
                  In Progress
                </Tab>
                <Tab>
                  <i className="fa fa-check mr-2"></i>
                  Done
                </Tab>
              </TabList>
            </div>
          </div>
          <div className="mt-2">
            <TabPanel>
              <DealerTable status="NOT_READY" />
            </TabPanel>
            <TabPanel>
              <DealerTable status="READY" />
            </TabPanel>
            <TabPanel>
              <DealerTable status="IN_PROGRESS" />
            </TabPanel>
            <TabPanel>
              <DealerTable status="DONE" />
            </TabPanel>
          </div>
        </Tabs>
      </div>
    </>
  )
}

export default Reconciliation
