import { useCallback, useEffect, useRef, useState } from 'react'

import { Toast } from 'primereact/toast'
import { classNames } from 'primereact/utils'
import { useParams, useSearchParams } from 'react-router-dom'

import * as Routes from '../../routes'
import Loading from '../Loading'
import { useFetchWebsite } from '../dataHooks'
import { standardHeaders } from '../entries/utils'
import Error from '../shared/Error'
import { Editor } from './Editor'
import { BlogsSettingsToolbar, PagesSettingsToolbar } from './toolbar'
import { convertLegacyPage } from './utils/legacyPageConversion'

// TODO: Refactor to allow blogs and pages (website and OEM) to share the same layout
const EditorLayout = ({ type }: { type: 'page' | 'blog' }) => {
  const [settingsToolbarProps, setSettingsToolbarProps] = useState(null)
  const [editorProps, setEditorProps] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const notification = useRef(null)

  const { websiteSlug, pageSlug, manufacturerSlug, blogId } = useParams()
  const [searchParams] = useSearchParams()

  const { website } = useFetchWebsite()

  const getPageData = useCallback(async (): Promise<any> => {
    try {
      setLoading(true)
      const url = manufacturerSlug
        ? `${Routes.edit_manufacturer_page_path(manufacturerSlug, pageSlug)}.json`
        : `${Routes.edit_website_page_path(websiteSlug, pageSlug)}.json`
      const response = await fetch(url, {
        headers: standardHeaders,
      })
      const data = await response.json()
      return data
    } catch (error) {
      console.error('Error fetching page data:', error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [websiteSlug, pageSlug, manufacturerSlug])

  const processPageData = useCallback(
    (data: any) => {
      if (!data) return

      const tempSettingsToolbarProps = {
        editorType: type,
        isAdmin: `${data?.current_user?.admin}`,
        csrf: document.querySelector("meta[name='csrf-token']").getAttribute('content'),
        pageJsonUrl: data?.page_json_url,
        updateUrl: data?.update_url,
        deleteImageUrl: data?.delete_image_url,
        togglePageVisibilityUrl: data?.toggle_visibility_url,
        websiteName: website?.name || 'N/A',
        showTemplateOverrides: `${
          data?.current_user?.admin &&
          data?.available_templates?.length > 0 &&
          !data?.page?.template_manufacturer_id &&
          !data?.page?.template_model_id
        }`,
        developmentEnv: data?.development_env,
        draftBlocksJson:
          typeof data?.page?.draft_blocks_json === 'string'
            ? data?.page?.draft_blocks_json
            : JSON.stringify(data?.page?.draft_blocks_json),
        excludeWebsites: data?.exclude_websites,
        websites: data?.websites,
        dealershipId: data?.dealership_id || website?.dealership_id,
        newFieldsEnabled: 'true',
        templateType: data?.page?.template,
        websiteUrl: website?.url || 'www.previewurl.com.au',
        manufacturerId: manufacturerSlug,
        websiteId: website?.id,
      }
      setSettingsToolbarProps(tempSettingsToolbarProps)

      const tempEditorProps = {
        isAdmin: data?.current_user?.admin,
        templateType: data?.page?.template,
        imageUrl: manufacturerSlug
          ? Routes.upload_file_manufacturer_page_path(manufacturerSlug, pageSlug)
          : Routes.upload_file_website_page_path(websiteSlug, pageSlug),
        locations: website?.locations || [],
        brands: data?.brands || [],
        manufacturerId: data?.page?.template_manufacturer_id,
        modelSlug: data?.model_slug,
        primaryLocationId: data?.primary_location_id,
        websiteId: website?.id,
        ownerManufacturerId: manufacturerSlug,
        searchFilters: data?.searchFilters,
      }
      setEditorProps(tempEditorProps)
    },
    [type, website, manufacturerSlug, websiteSlug, pageSlug]
  )

  const getBlogData = useCallback(async (): Promise<any> => {
    try {
      const url = `${Routes.edit_website_blog_path(websiteSlug, blogId)}.json`
      const response = await fetch(url, {
        headers: standardHeaders,
      })
      const data = await response.json()
      return data
    } catch (error) {
      console.error('Error fetching blog data:', error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [websiteSlug, blogId])

  const processBlogData = useCallback(
    (data: any) => {
      if (!data) return

      const tempSettingsToolbarProps = {
        editorType: type,
        isAdmin: `${data?.current_user?.admin}`,
        csrf: document.querySelector("meta[name='csrf-token']").getAttribute('content'),
        pageJsonUrl: data?.blog_json_url,
        updateUrl: data?.update_url,
        deleteImageUrl: data?.delete_image_url,
        toggleBlogVisibilityUrl: data?.toggle_visibility_url,
        websiteName: website?.name || 'N/A',
        developmentEnv: data?.development_env,
        draftBlocksJson:
          typeof data?.blog?.draft_blocks_json === 'string'
            ? data?.blog?.draft_blocks_json
            : JSON.stringify(data?.blog?.draft_blocks_json),
        websites: data?.websites,
        dealershipId: data?.dealership_id || website?.dealership_id,
        newFieldsEnabled: 'true',
        templateType: 'default',
        websiteUrl: website?.url?.replace(/\/+$/, '') || 'www.previewurl.com.au',
        blogId: data?.blog?.id,
        blogCategories: data?.blog_categories,
        websiteId: website?.id,
      }
      setSettingsToolbarProps(tempSettingsToolbarProps)

      const tempEditorProps = {
        isAdmin: data?.current_user?.admin,
        templateType: 'default',
        imageUrl: Routes.upload_file_website_blog_path(websiteSlug, blogId),
        locations: website?.locations || [],
        brands: data?.brands || [],
        primaryLocationId: data?.primary_location_id,
        websiteId: website?.id,
        searchFilters: data?.website?.search_filters,
      }
      setEditorProps(tempEditorProps)
    },
    [type, website, websiteSlug, blogId]
  )

  useEffect(() => {
    if (type === 'page' && ((pageSlug && website) || manufacturerSlug)) {
      getPageData().then(processPageData)
    } else if (type === 'blog' && blogId && website) {
      getBlogData().then(processBlogData)
    }
  }, [
    type,
    website,
    manufacturerSlug,
    websiteSlug,
    pageSlug,
    blogId,
    getPageData,
    processPageData,
    getBlogData,
    processBlogData,
  ])

  if (loading) {
    return (
      <div className="p-3">
        <Loading />
      </div>
    )
  } else if (error) {
    return <Error />
  } else if (
    (type === 'page' && !website && !manufacturerSlug) ||
    (type === 'blog' && !website && !blogId) ||
    !editorProps ||
    !settingsToolbarProps
  ) {
    console.log('Editor Props', editorProps)
    console.log('Settings Toolbar Props', settingsToolbarProps)
    return <Error />
  }

  // Allow legacy page conversion
  if (type === 'page' && !searchParams.get('beta')) {
    return (
      <div className="pt-3 px-3 w-100 h-100">
        <Toast ref={notification} />
        <h1 className="mb-3">Editing Page</h1>
        <p>This page is using the legacy editor. Please convert it to the new editor.</p>
        <button
          className="btn btn-primary"
          onClick={() => {
            convertLegacyPage(
              { base_url: Routes.website_page_path(websiteSlug, pageSlug), page_url: undefined },
              notification
            )
          }}
        >
          Convert Legacy Page
        </button>
      </div>
    )
  }

  return (
    <div id="editor-layout" data-editor-type="page" className="h-100 w-100">
      <div
        className={classNames(
          'position-relative',
          manufacturerSlug ? 'mx-4 mt-3 border-left border-right' : ''
        )}
      >
        <div className="editorjs-menu row no-gutters">
          <div className="col-12">
            {type === 'page' ? (
              <PagesSettingsToolbar {...settingsToolbarProps} />
            ) : (
              <BlogsSettingsToolbar {...settingsToolbarProps} />
            )}
          </div>
        </div>
        <div className="row no-gutters">
          <div className="col-12">
            <div className="editorjs-container border-bottom pt-2">
              <Editor {...editorProps} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditorLayout
