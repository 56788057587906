import React, { ReactNode, createContext, useState } from 'react'

import { Location, TimeRange } from '../types'

type TimeRangeLocationContextType = {
  timeRange: TimeRange
  handleUpdateTimeRange: (newTimeRange: TimeRange) => void
  selectedLocation: Location | null
  handleUpdateSelectedLocation: (newLocation: Location) => void
  selectedGaWebsitePerTab: Record<number, string>
  setSelectedGaWebsitePerTab: (newSelectedGaWebsitePerTab: Record<number, string>) => void
}

// Possible rename to GlobalDropdownContext or GlobalFilterContext - the 3 dropdowns being locations, time range and website filtering.

export const TimeRangeLocationContext = createContext<TimeRangeLocationContextType>({
  timeRange: { start: 0, end: 0, timeframe: '', type: '' },
  handleUpdateTimeRange: (newTimeRange) => {},
  selectedLocation: null,
  handleUpdateSelectedLocation: (newLocation) => {},
  selectedGaWebsitePerTab: {},
  setSelectedGaWebsitePerTab: (newSelectedGaWebsitePerTab) => {},
})

export const TimeRangeLocationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [timeRange, setTimeRange] = useState<TimeRange>({
    start: 0,
    end: 0,
    timeframe: '',
    type: '',
  })
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null)
  // This doesn't exactly fit in this context. Maybe there will be a websites dropdown context in the future.
  const [selectedGaWebsitePerTab, setSelectedGaWebsitePerTab] = useState<Record<number, string>>({})

  const handleUpdateTimeRange = (newTimeRange: TimeRange) => {
    setTimeRange(newTimeRange)
  }

  const handleUpdateSelectedLocation = (newLocation: Location) => setSelectedLocation(newLocation)

  return (
    <TimeRangeLocationContext.Provider
      value={{
        timeRange,
        handleUpdateTimeRange,
        selectedLocation,
        handleUpdateSelectedLocation,
        selectedGaWebsitePerTab,
        setSelectedGaWebsitePerTab,
      }}
    >
      {children}
    </TimeRangeLocationContext.Provider>
  )
}
