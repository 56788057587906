import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import * as Routes from '../../../routes'
import Loading from '../../Loading'
import { useFetchWebsite } from '../../dataHooks'
import { StockSettingsForm, type WebsiteStockSettings } from './StockSettingsForm'
import { WebsiteDealershipsTable, WebsitesDealershipsTableLoading } from './WebsiteDealershipsTable'

const Stock = () => {
  const { websiteSlug } = useParams()
  const { website, loading } = useFetchWebsite()
  const { data, isLoading } = useQuery<WebsiteStockSettings>({
    queryKey: ['stock', websiteSlug],
    queryFn: () => fetch(Routes.stock_website_path(websiteSlug)).then((res) => res.json()),
  })

  /**
   * The default values for the stock settings form
   * `include_sold_cars_days` falls back to 3 days if not set
   * `default_search_path` falls back to an empty string if not set
   */
  const defaultValues = {
    include_sold_cars_days: data?.website?.include_sold_cars_days ?? 3,
    default_search_path: data?.website?.default_search_path ?? '',
  }

  const isLoadingWebsiteOrData = loading || isLoading

  return (
    <div className="pb-4 px-4 pt-2">
      <h4>Stock</h4>
      <p>
        This process determines which stock is displayed on the dealer's website. At times, we have
        the option to include stock from different dealerships, and the locations associated with
        each dealership are accessible for integration into the website.
      </p>
      {website ? (
        <WebsiteDealershipsTable data={data} website={website} isLoading={isLoading} />
      ) : (
        <WebsitesDealershipsTableLoading />
      )}
      {isLoadingWebsiteOrData ? (
        <div className="mt-5">
          <Loading />
        </div>
      ) : (
        <StockSettingsForm defaultValues={defaultValues} />
      )}
    </div>
  )
}

export default Stock
