import { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Link, useLocation, useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { standardHeaders } from './utils'

function setCookie(name, value, days) {
  var expires = ''
  if (days) {
    var date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

const Anchor = ({ to, children }) => <a href={to}>{children}</a>

const Children = ({ link }) => {
  const { pathname } = useLocation()

  return (
    <div>
      {link.children.map((child) => {
        let LinkComponent = child.reactRouter ? Link : Anchor
        let isCurrentPath = isActive(pathname, child.url)
        return (
          <LinkComponent to={child.url} className={isCurrentPath ? 'active' : 'test'}>
            <div className="sidemenu-item-child">
              <div className={`fa ${child.iconClass} mr-2`}></div>
              {child.name}
            </div>
          </LinkComponent>
        )
      })}
    </div>
  )
}

function isActive(pathname, path) {
  if (pathname === path) return true
  if (
    ['/cars/', '/contacts', '/lead_clusters'].some((p) => path.includes(p)) &&
    pathname.includes(path)
  )
    return true

  return false
}

const LinkItem = ({ link }) => {
  let [isOpen, setIsOpen] = useState(false)
  let LinkComponent = link.reactRouter ? Link : Anchor

  const toggleIsOpen = () => setIsOpen(!isOpen)

  const { pathname } = useLocation()

  const isCurrentPath = isActive(pathname, link.path)

  return (
    <li>
      <LinkComponent to={link.path} className={isCurrentPath ? 'active' : ''}>
        <div className="sidemenu-item">
          <div className={`fa mr-2 ${link.iconClass}`}></div>
          <div className="nav-link-title d-flex w-100 flex-column">
            <div className="d-flex align-items-center">
              {link.text}
              {link.error && (
                <span className="text-danger ml-auto">
                  <i className="fa fa-exclamation-triangle"></i>
                </span>
              )}
              {link.children && (
                <div
                  className="btn btn-outline-secondary btn-sm ml-auto"
                  onClick={(e) => {
                    e.preventDefault()
                    toggleIsOpen()
                  }}
                >
                  <i className={'fa fa-sm ' + (isOpen ? 'fa-angle-up' : 'fa-angle-down')}></i>
                </div>
              )}
            </div>
          </div>
        </div>
      </LinkComponent>
      {link.children && isOpen && <Children link={link} />}
      <div className="sidemenu-popover">{link.text}</div>
    </li>
  )
}

const DashboardLinks = ({ links, toggleIsOpen, isOpen }) => {
  return (
    <>
      {links.map((link) => (
        <LinkItem link={link} key={link.text} />
      ))}
      <li>
        <div className="sidemenu-item" onClick={toggleIsOpen} id="toggle-sidebar">
          <div className={'fa ' + (isOpen ? 'fa-caret-left' : 'fa-caret-right')}></div>
        </div>
      </li>
    </>
  )
}

const fetchSideMenuLinks = async ({ queryKey }) => {
  const [_, slug, websiteSlug, dealershipGroupId, marketplaceId] = queryKey
  let url

  if (slug) {
    url = Routes.sidemenu_links_dealership_path(slug)
  }

  if (websiteSlug) {
    url = Routes.sidemenu_links_website_path(websiteSlug)
  }

  if (dealershipGroupId) {
    url = Routes.sidemenu_links_dealership_group_path(dealershipGroupId)
  }

  if (marketplaceId) {
    url = Routes.sidemenu_links_marketplace_path(marketplaceId)
  }

  const response = await fetch(url, { headers: standardHeaders })
  if (!response.ok) throw new Error('Failed to fetch side menu links')

  return response.json()
}

const SideMenu = () => {
  const { dealershipSlug, websiteSlug, dealershipGroupId, marketplaceId } = useParams()
  const [isOpen, setIsOpen] = useState(false)
  const slug = dealershipSlug || window.dealership?.slug

  const { data, isLoading, error } = useQuery({
    queryKey: ['sidemenu_links', slug, websiteSlug, dealershipGroupId, marketplaceId],
    queryFn: fetchSideMenuLinks,
    enabled: !!(slug || websiteSlug || dealershipGroupId || marketplaceId), // Prevents query if no valid param
    staleTime: 10 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
  })

  console.log(error)

  useEffect(() => {
    if (data?.sidemenu_state === 'open' && !isOpen) {
      setIsOpen(true)
    }
  }, [data])

  if (!slug && !websiteSlug && !dealershipGroupId && !marketplaceId) return null

  const toggleIsOpen = () => {
    const newState = !isOpen
    setIsOpen(newState)
    setCookie('sidebar', newState ? 'open' : 'collapsed')
  }

  return (
    <div className="wrapper">
      <div className={`sidebar ${isOpen ? 'open' : 'collapsed'}`} id="sidebar">
        <ul>
          {isLoading && <i className="fa fa-spinner fa-spin p-3"></i>}
          {error && <p>Error loading menu</p>}
          {data?.links && (
            <DashboardLinks links={data.links} toggleIsOpen={toggleIsOpen} isOpen={isOpen} />
          )}
        </ul>
      </div>
    </div>
  )
}

export default SideMenu
