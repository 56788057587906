import type { FormTabs } from '../../formTabs'

export interface GarageSettingsData {
  garage_settings: GarageSettings
  garage_enabled: boolean
}

export interface GarageSettings {
  garageEnabled: boolean
  serviceHistory: boolean
  serviceSpinner: boolean
  allowBookService: boolean
  invertGarageLogo: boolean
  finishSignUpHeading: string
  finishSignUpSubtext: string
  signInGarageHeading: string
  signInGarageSubtext: string
  signInVerificationHeading: string
  signInVerificationSubtext: string
  watchlistSignInHeading: string
  tradeInSignInHeading: string
  financeQuoteSignInHeading: string
  financeQuoteSignInSubtext: string
  sendEmailIfSavedVehicleSold: boolean
  serviceCTALink: string
  serviceCTAText: string
  financeCTALink: string
  financeCTAText: string
  sellMyCarCTALink: string
  sellMyCarCTAText: string
}

export const garageSettingsFormTabs: FormTabs<GarageSettings> = {
  featuresEnabled: {
    label: 'Enabled Features',
    formInputs: {
      garageEnabled: { defaultValue: false, type: 'boolean', label: 'Garage/Portal Enabled' },
      serviceHistory: { defaultValue: false, type: 'boolean', label: 'Enable Service History' },
      serviceSpinner: { defaultValue: false, type: 'boolean', label: 'Enable Service Spinner' },
      allowBookService: {
        defaultValue: false,
        type: 'boolean',
        label: 'Allow Service Booking from portal',
      },
      sendEmailIfSavedVehicleSold: {
        defaultValue: true,
        type: 'boolean',
        label: 'Send Email If Saved Vehicle Sold',
      },
      invertGarageLogo: {
        defaultValue: false,
        type: 'boolean',
        label: 'Use Dark myGarage Logo',
      },
    },
  },

  customHeadings: {
    label: 'Custom Headings',
    formInputs: {
      signInGarageHeading: {
        defaultValue: 'Log in to access your garage',
        type: 'string',
        label: 'myGarage Sign In Form Heading',
      },
      signInGarageSubtext: {
        defaultValue: 'Simply enter your mobile number to receive a verification code.',
        type: 'string',
        label: 'myGarage Sign In Form Subheading',
      },
      signInVerificationHeading: {
        defaultValue: 'Verification',
        type: 'string',
        label: 'myGarage Verification Step Heading',
      },
      signInVerificationSubtext: {
        defaultValue: 'Please enter the code you received as an SMS.',
        type: 'string',
        label: 'myGarage Verification Step Subtext',
      },
      finishSignUpHeading: {
        defaultValue: 'Confirm your details',
        type: 'string',
        label: 'myGarage Complete Sign Up Heading',
      },
      finishSignUpSubtext: {
        defaultValue: 'Please enter your contact details to finish setting up your account.',
        type: 'string',
        label: 'myGarage Complete Sign Up Subtext',
      },
      watchlistSignInHeading: {
        defaultValue: 'Log in to access your watchlist',
        type: 'string',
        label: 'Watchlist Sign In Heading',
      },
      tradeInSignInHeading: {
        defaultValue: 'Verify your phone number to get a trade-in estimate',
        type: 'string',
        label: 'Trade-In Sign In Heading',
      },
      financeQuoteSignInHeading: {
        defaultValue: "Fill in a few details, and we'll provide a personalised quote just for you",
        type: 'string',
        label: 'Finance Quote Sign In Heading',
      },
      financeQuoteSignInSubtext: {
        defaultValue:
          'Verify your phone number to get a personalised finance estimate. [no_impact_credit_score]',
        type: 'string',
        label: 'Finance Quote Sign In Subtext',
      },
    },
  },
  ctaLinks: {
    label: 'CTA Links',
    formInputs: {
      serviceCTALink: {
        defaultValue: '/service',
        type: 'string',
        label: 'Service Link Url',
      },
      serviceCTAText: {
        defaultValue: 'Book Service',
        type: 'string',
        label: 'Service Link Text',
      },
      financeCTALink: {
        defaultValue: '/finance',
        type: 'string',
        label: 'Finance Quote Link Url',
      },
      financeCTAText: {
        defaultValue: 'Finance Quote',
        type: 'string',
        label: 'Finance Quote Link Text',
      },
      sellMyCarCTALink: {
        defaultValue: '/sell-my-car',
        type: 'string',
        label: 'Sell My Car Link Url',
      },
      sellMyCarCTAText: {
        defaultValue: 'Sell My Car',
        type: 'string',
        label: 'Sell My Car Link Text',
      },
    },
  },
}
