import { useContext, useEffect, useRef, useState } from 'react'

import { Toast } from 'primereact/toast'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { FormContext } from '../contexts'
import { CurrentUserContext } from '../contexts'
import { standardHeaders } from '../entries/utils'
import showToast from '../shared/ShowToast'
import HookFormField from '../shared/hookFormField'

const countryOptions = [
  { value: 'Australia', label: 'Australia' },
  { value: 'New Zealand', label: 'New Zealand' },
  { value: 'United States', label: 'United States' },
]

const timezoneOptions = [
  { value: 'Sydney', label: 'Sydney' },
  { value: 'Brisbane', label: 'Brisbane' },
  { value: 'Melbourne', label: 'Melbourne' },
  { value: 'Perth', label: 'Perth' },
  { value: 'Darwin', label: 'Darwin' },
  { value: 'Adelaide', label: 'Adelaide' },
  { value: 'Hobart', label: 'Hobart' },
]

const localeOptions = [
  { value: 'en-AU', label: 'English (Australia)' },
  { value: 'en-NZ', label: 'English (New Zealand)' },
  { value: 'en-US', label: 'English (United States)' },
]

let attributes = {
  name: { type: 'text', label: 'Name', required: true },
  country: { type: 'select', label: 'Country', options: 'countryOptions' },
  timezone: { type: 'select', label: 'Timezone', options: 'timezoneOptions' },
  locale: { type: 'select', label: 'Locale', options: 'localeOptions' },
  orbit: { type: 'switch', label: 'Orbit' },
  active: { type: 'switch', label: 'Active' },
  ai_sms_conversation: { type: 'switch', label: 'AI SMS Conversation' },
  tasks_view: {
    type: 'switch',
    label: 'Tasks View',
    hint: 'Enabled the BETA LMS tasks view feature',
  },
  unpaid_bills: {
    type: 'switch',
    label: 'Unpaid Bills',
    hint: 'If enabled, displays a red "unpaid bills" banner in Dealerstudio',
  },
  redbook_rego_search: {
    type: 'switch',
    label: 'Redbook Rego Search',
    hint: 'Adds ability to add cars by rego (rather than manual Redbook lookup). Costs DS $0.28 per lookup.',
  },
  background_remover: {
    type: 'switch',
    label: 'Background Remover',
    hint: 'Adds ability to remove backgrounds from car images',
  },
  enforce_two_factor: {
    type: 'switch',
    label: 'Enforce Two-Factor Authentication',
    hint: 'If enabled, all users will be required to use two-factor authentication',
  },
  prevent_customer_access_without_two_factor: {
    type: 'switch',
    label: 'Prevent user access to customer data without two-factor authentication',
  },
  car_sold_notification: {
    type: 'switch',
    label: 'Car Sold Notification',
    hint: 'If enabled, sends an email to customers who enquired on the car when the car is sold',
  },
}

let formData = { countryOptions, timezoneOptions, localeOptions }

const FormWrapper = ({ dealership, title, children, adminOnly }) => {
  const currentUser = useContext(CurrentUserContext)
  const notification = useRef(null)

  let [errors, setErrors] = useState({})
  let [loading, setLoading] = useState(false)
  let { dealershipSlug } = useParams()

  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: dealership?.name || '',
      country: dealership?.country || '',
      timezone: dealership?.timezone || '',
      locale: dealership?.locale || '',
      active: dealership?.active || '',
      orbit: dealership?.orbit || '',
      ai_sms_conversation: dealership?.ai_sms_conversation || '',
      tasks_view: dealership?.tasks_view || '',
      unpaid_bills: dealership?.unpaid_bills || '',
      redbook_rego_search: dealership?.redbook_rego_search || '',
      background_remover: dealership?.background_remover || '',
      enforce_two_factor: dealership?.enforce_two_factor || '',
      prevent_customer_access_without_two_factor:
        dealership?.prevent_customer_access_without_two_factor || '',
      car_sold_notification: dealership?.car_sold_notification || '',
    },
  })

  if (adminOnly && (currentUser === null || !currentUser?.admin)) {
    return <></>
  }

  const onSubmit = (data) => {
    setLoading(true)

    fetch(Routes.dealership_path(dealershipSlug), {
      method: 'PUT',
      headers: standardHeaders,
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        setLoading(false)
        showToast(notification, 'success', 'Dealership updated successfully')
      })
      .catch((error) => {
        console.error('Error:', error)
        showToast(notification, 'error', 'Error updating dealership')
        setLoading(false)
      })
  }

  return (
    <>
      <div className="d-flex mb-1">
        <h6 className="text-secondary">{title}</h6>
        {adminOnly && (
          <div className="ml-auto">
            <div className="badge badge-secondary">Admin only section</div>
          </div>
        )}
      </div>
      <div className="box p-3 mb-3">
        <FormContext.Provider value={{ control, errors, attributes, formData }}>
          <Toast ref={notification} />
          <form onSubmit={handleSubmit(onSubmit)}>
            {children}
            <button
              className={'btn btn-block btn-outline-success' + (loading ? ' disabled' : '')}
              type="submit"
            >
              {loading ? 'Loading...' : 'Save'}
            </button>
          </form>
        </FormContext.Provider>
      </div>
    </>
  )
}

const Wrapper = () => {
  let [dealership, setDealership] = useState({})
  let [loading, setLoading] = useState(true)
  let { dealershipSlug } = useParams()

  useEffect(() => {
    fetch(Routes.edit_dealership_path(dealershipSlug), {
      method: 'GET',
      headers: standardHeaders,
    })
      .then((response) => response.json())
      .then((res) => {
        setDealership(res)
        setLoading(false)
      })
      .catch((error) => console.error('Error:', error))
  }, [])

  if (loading) return <div>Loading...</div>

  return (
    <>
      <FormWrapper dealership={dealership} title="Details">
        <HookFormField attribute="name" />
        <HookFormField attribute="country" />
        <HookFormField attribute="timezone" />
        <HookFormField attribute="locale" />
      </FormWrapper>
      <FormWrapper dealership={dealership} title="Settings" adminOnly={true}>
        <HookFormField attribute="active" />
        <HookFormField attribute="orbit" />
        <HookFormField attribute="ai_sms_conversation" />
        <HookFormField attribute="tasks_view" />
        <HookFormField attribute="unpaid_bills" />
        <HookFormField attribute="redbook_rego_search" />
        <HookFormField attribute="background_remover" />
        <HookFormField attribute="enforce_two_factor" />
        <HookFormField attribute="prevent_customer_access_without_two_factor" />
      </FormWrapper>
      <FormWrapper dealership={dealership} title="CRM Settings" adminOnly={true}>
        <HookFormField attribute="car_sold_notification" />
      </FormWrapper>
    </>
  )
}

export default Wrapper
