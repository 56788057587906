import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { Select } from '../entries/FormElements'
import { humanize } from '../entries/utils'
import { fromNow } from '../entries/utils'
import { standardHeaders } from '../entries/utils'
import { formatPhone } from '../entries/utils'
import { formatDuration } from '../leadCluster/ActionedWithin'
import { CopyableText } from '../shared/CopyableText'

const LeadStatus = (rowData) => {
  let leadStatusOption = rowData.lead_cluster.lead_status_option

  return (
    <div className={`badge badge-${leadStatusOption.colour_class_name}`}>
      {humanize(leadStatusOption.name)}
    </div>
  )
}

const Created = (rowData) => {
  return <div>{fromNow(rowData.lead_cluster.created_at)}</div>
}

const FirstActionedWithin = (rowData) => {
  if (rowData.lead_cluster.first_actioned_within == null) {
    return
  }

  return <div>{formatDuration(rowData.lead_cluster.first_actioned_within)}</div>
}

const Assigned = (rowData) => {
  if (!rowData.lead_cluster?.user?.name) {
    return (
      <div className="text-danger">
        <i className="fa fa-exclamation-triangle mr-2" />
        <span>Unassigned</span>
      </div>
    )
  }

  return <span>{rowData.lead_cluster.user.name}</span>
}

const Phone = (rowData) => {
  return (
    <div className="small">
      <CopyableText text={formatPhone(rowData.contact.phone_number)} />
    </div>
  )
}

const Email = (rowData) => {
  return (
    <div className="small">
      <CopyableText text={formatPhone(rowData.contact.email)} />
    </div>
  )
}

const ManufacturerLeads = () => {
  let { manufacturerSlug } = useParams()
  let [page, setPage] = useState(1)
  let [source, setSource] = useState()
  let [leadStatusOptionId, setLeadStatusOptionId] = useState()
  let [dealershipId, setDealershipId] = useState()
  let rows = 20

  let { data, isLoading } = useQuery({
    queryKey: [
      'manufacturerLeads',
      page,
      manufacturerSlug,
      source,
      dealershipId,
      leadStatusOptionId,
    ],
    queryFn: async () => {
      const response = await fetch(
        Routes.leads_manufacturer_path(manufacturerSlug, {
          page: page,
          per_page: rows,
          source: source,
          dealership_id: dealershipId,
          lead_status_option_id: leadStatusOptionId,
        }),
        {
          headers: standardHeaders,
        }
      )
      const data = await response.json()
      return data
    },
  })

  const onPageChange = (event) => {
    setPage(event.page + 1) // PrimeReact pages are 0-based; Kaminari pages are 1-based
  }

  return (
    <div className="container-fluid py-3">
      <h4>Manufacturer Leads</h4>

      <div className="row">
        <div className="col-md-4">
          <Select options={data?.sources} label="Source" onChange={(o) => setSource(o?.value)} />
        </div>
        <div className="col-md-4">
          <Select
            options={data?.dealerships}
            label="Dealership"
            onChange={(o) => setDealershipId(o?.value)}
          />
        </div>
        <div className="col-md-4">
          <Select
            options={data?.lead_statuses}
            label="Status"
            onChange={(o) => setLeadStatusOptionId(o?.value)}
          />
        </div>
      </div>

      <div className="box">
        <DataTable
          value={data?.leads}
          loading={isLoading}
          paginator
          page={page}
          rows={rows}
          first={(page - 1) * rows}
          totalRecords={data?.total_count}
          onPage={onPageChange}
          lazy
        >
          <Column field="id" header="ID"></Column>
          <Column field="dealership.name" header="Dealer"></Column>
          <Column field="lead_cluster.source" header="Source" />
          <Column field="contact.email" header="Email" body={Email} />
          <Column field="contact.phone_number" header="Phone" body={Phone} />
          <Column field="lead_cluster.primary_car" header="Vehicle" />
          <Column field="lead_cluster.lead_status" header="State" body={LeadStatus} />
          <Column field="lead_cluster.user.name" header="Assigned To" body={Assigned} />
          <Column
            field="lead_cluster.first_actioned_within"
            body={FirstActionedWithin}
            header="Actioned In"
          />
          <Column field="lead_cluster.created_at" header="Created" body={Created} />
        </DataTable>
      </div>
    </div>
  )
}

export default ManufacturerLeads
