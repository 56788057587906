import { useRouteError } from 'react-router-dom'

export const ErrorScreen = ({
  heading = 'Oops!',
  message = 'Something went wrong. Please try refreshing the page.',
  refreshText = 'Refresh Page',
}: {
  heading?: string
  message?: string | React.ReactNode
  refreshText?: string
}) => {
  return (
    <div className="container  d-flex justify-content-center align-items-center">
      <div className="text-center">
        <div className="mt-5">
          <h1 className="display-4">{heading}</h1>
          {typeof message === 'string' ? <p>{message}</p> : message}
        </div>
        {refreshText && (
          <button className="btn btn-primary" onClick={() => window.location.reload()}>
            {refreshText}
          </button>
        )}
      </div>
    </div>
  )
}

const Error: React.FC = () => {
  const error = useRouteError()
  console.log(error)
  return (
    <ErrorScreen
      heading="Oops!"
      message={
        <>
          <p>Something went wrong. Please try refreshing the page.</p>
          <p>If the error persists, please contact our team.</p>
        </>
      }
    />
  )
}

export default Error
