import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { standardHeaders } from '../entries/utils'
import { SectionHeading } from '../entries/utils'
import { fromNow } from '../entries/utils'
import Form from './Form'

const Url = (props) => {
  let url = props.url
  return <div className="small">{url}</div>
}

const Actions = (props) => {
  let { dealershipSlug } = useParams()

  return (
    <div className="d-flex">
      <a
        href={Routes.dealership_qr_code_path(dealershipSlug, props.id)}
        className="btn btn-outline-primary btn-sm mr-1"
      >
        <i className="fa fa-eye"></i>
      </a>
      <a
        href={Routes.edit_dealership_qr_code_path(dealershipSlug, props.id)}
        className="btn btn-outline-primary btn-sm mr-1"
      >
        <i className="fa fa-pencil"></i>
      </a>
      <a
        href={Routes.dealership_qr_code_path(dealershipSlug, props.id)}
        data-method="delete"
        className="btn btn-outline-danger btn-sm"
      >
        <i className="fa fa-trash"></i>
      </a>
    </div>
  )
}

const fetchQrCodes = async ({ queryKey }) => {
  const [, dealershipSlug, page, rows, query] = queryKey
  const response = await fetch(
    Routes.dealership_qr_codes_path(dealershipSlug, {
      page: page,
      per_page: rows,
      query: query,
    }),
    {
      headers: standardHeaders,
    }
  )
  if (!response.ok) throw new Error('Failed to fetch QR codes')
  return response.json()
}

const QrCodes = () => {
  const { dealershipSlug } = useParams()
  const [page, setPage] = useState(1)
  let [newFormVisible, setNewFormVisible] = useState(false)
  let [query, setQuery] = useState('')
  const rows = 25

  // Fetch QR codes using TanStack Query
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ['qrCodes', dealershipSlug, page, rows, query],
    queryFn: fetchQrCodes,
    keepPreviousData: true, // Prevents flickering when changing pages
    staleTime: 1000 * 60 * 5, // Cache data for 5 minutes
  })

  const onPageChange = (event) => {
    setPage(event.page + 1) // PrimeReact pages are 0-based; Kaminari pages are 1-based
  }

  if (error) return <div className="p-3">Error loading QR Codes</div>

  return (
    <div className="p-3">
      <SectionHeading title="QR Codes">
        <button
          className="btn btn-outline-primary"
          onClick={() => setNewFormVisible(true)}
          id="new-qr-code"
        >
          <i className="fa fa-plus mr-1"></i>
          QR Code
        </button>
        <Form visible={newFormVisible} setVisible={setNewFormVisible} refetch={refetch} />
      </SectionHeading>
      <div className="mb-2">
        <input
          className="form-control"
          type="text"
          placeholder="Search..."
          onChange={(e) => setQuery(e.target.value)}
        />
      </div>
      <div className="box">
        <DataTable
          value={data?.qr_codes || []}
          paginator
          rows={rows}
          first={(page - 1) * rows}
          totalRecords={data?.total_count}
          onPage={onPageChange}
          loading={isLoading}
          lazy
        >
          <Column field="label" header="Label" />
          <Column field="url" header="URL" body={Url} />
          <Column field="views" header="Views" />
          <Column
            field="created_at"
            header="Created"
            body={(rowData) => fromNow(rowData.created_at)}
          />
          <Column field="actions" header="Actions" body={Actions} />
        </DataTable>
      </div>
    </div>
  )
}

export default QrCodes
