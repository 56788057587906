import { useContext, useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'

import * as Routes from '../../routes'
import { SidebarContext } from '../contexts'
import { useFetchDealership } from '../dataHooks'
import { range, standardHeaders } from '../entries/utils'
import NvicSearch from './NvicSearch'
import RedbookRegoSearch from './RedbookRegoSearch'

function VehicleDetails(v) {
  // Create an array of the values to display, conditionally including them only if they exist
  const details = [
    v.bodystyle,
    v.enginetype,
    v.geartype,
    v.fueltype,
    v.drive,
    v.enginesizelitres && `Engine Size (L) - ${v.enginesizelitres}`,
    v.doorno && `Doors - ${v.doorno}`,
    v.yeargroupmonthname && v.yeargroupyear && `${v.yeargroupmonthname} ${v.yeargroupyear}`,
    v.bodyconfiguration,
  ].filter(Boolean) // Remove any falsy values from the array (e.g., '', null, undefined)

  // Join the non-empty values with ' | ' to create the final string
  const detailString = details.join(' | ')

  return <p className="text-secondary">{detailString}</p>
}

const Variant = ({ v, carId }) => {
  let { dealershipSlug } = useParams()
  let [loading, setLoading] = useState(false)
  let { setVisible, hit, setCar } = useContext(SidebarContext)
  let navigate = useNavigate()

  const saveCar = () => {
    setLoading(true)
    fetch(Routes.dealership_car_path(dealershipSlug, carId), {
      headers: standardHeaders,
      method: 'PUT',
      body: JSON.stringify({ car: { variant_id: v.id } }),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false)
        if (setVisible) {
          setVisible(false)
          setCar({
            ...hit,
            has_redbook: true,
            make: res.make,
            model: res.model,
            badge: res.badge,
            year: res.year,
            name: res.name,
          })
        } else {
          navigate(Routes.dealership_car_path(dealershipSlug, carId))
        }
      })
  }

  return (
    <div className="box p-3 mb-2">
      <div className="row">
        <div className="col-md-9">
          <div className="mb-2">
            <h5 className="mb-0">
              {v.yeargroupyear} {v.familyname} {v.badge !== '(No Badge)' && v.badge} {v.series}{' '}
              {v.geartype} {v.modelyear}
            </h5>
            <small className="text-secondary">{v.description}</small>
          </div>
          <p className="text-secondary">{VehicleDetails(v)}</p>
        </div>
        <div className="col-md-3">
          <div className="float-right">
            {carId ? (
              <button
                className={'btn btn-outline-primary' + (loading ? ' disabled' : '')}
                onClick={() => saveCar(v.id)}
                id={`select-variant-${v.id}`}
              >
                {loading ? 'Saving...' : 'Select'}
              </button>
            ) : (
              <a
                className="btn btn-outline-primary"
                href={Routes.new_dealership_car_path(dealershipSlug, {
                  variant_id: v.id,
                })}
              >
                Select
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const Variants = ({ carId, variants }) => {
  return (
    <>
      <h4>
        Results
        <small className="text-secondary mb-2 float-right small">
          {variants.length} results found
        </small>
      </h4>
      {variants.length === 0 && <p className="text-secondary">No Results Found</p>}
      {variants.map((v) => (
        <Variant key={v.id} v={v} carId={carId} />
      ))}
    </>
  )
}

const itemTypeOptions = [
  { label: 'Car', value: 'car' },
  { label: 'Bike', value: 'bike' },
  { label: 'Heavy', value: 'heavy' },
  { label: 'Caravan', value: 'caravan' },
]

export const CarDetails = ({ manufacturer_id, title, subtitle, build_date, body }) => {
  return (
    <>
      <h1 className="h4">{manufacturer_id ? 'Edit Car' : 'New Car'}</h1>
      {title && <p>{title}</p>}
      {subtitle && <p className="small text-secondary">{subtitle}</p>}
      {build_date && (
        <p className="small text-secondary">
          Build Date: {moment(build_date).format('DD/MM/YYYY')}
        </p>
      )}
      {body && <p className="small text-secondary">Body: {body}</p>}
    </>
  )
}

const RedbookSearch = ({
  manufacturer_id,
  family_id,
  title,
  subtitle,
  body,
  defaultYear,
  drive_options,
  transmission_options,
  fuel_options,
  defaultBadge,
  default_engine_size_litres,
  default_bodyconfiguration,
  default_wheelbaseconfiguration,
  build_date,
  manufacturers,
  default_bodystyle,
  default_model_year,
  itemType,
  setItemType,
  carId,
}) => {
  let [manufacturer, setManufacturer] = useState(manufacturer_id)
  let [family, setFamily] = useState()
  let [year, setYear] = useState()
  let [families, setFamilies] = useState()
  let [familyOptions, setFamilyOptions] = useState()
  let [yearOptions, setYearOptions] = useState()
  let [variants, setVariants] = useState()
  let [facets, setFacets] = useState()
  let [geartype, setGeartype] = useState()
  let [badge, setBadge] = useState()
  let [fueltype, setFueltype] = useState()
  let [bodystyle, setBodystyle] = useState()
  let [modelYear, setModelYear] = useState()
  let [drive, setDrive] = useState()
  let [bodyconfiguration, setBodyconfiguration] = useState()
  let [wheelBaseConfiguration, setWheelBaseConfiguration] = useState()
  let [engineSizeLitres, setEngineSizeLitres] = useState()
  let [loading, setLoading] = useState(false)
  const [loadingFamilies, setLoadingFamilies] = useState(false)
  let manufacturer_options = manufacturers.map((man) => {
    return { value: man.id, label: man.name }
  })
  let { dealershipSlug } = useParams()

  useEffect(() => {
    if (!manufacturer || loadingFamilies) return // Avoid re-execution
    setFamilies(null)
    setFamilyOptions(null)
    setLoadingFamilies(true)

    let families_url = Routes.manufacturer_families_path(manufacturer)

    fetch(families_url, { headers: standardHeaders })
      .then((res) => res.json())
      .then((res) => {
        setFamilies(res)
        setFamilyOptions(res.map((m) => ({ value: m.id, label: m.name })))

        if (family_id) {
          setFamily(family_id)
        }

        setLoadingFamilies(false)
      })
  }, [manufacturer])

  useEffect(() => {
    setYear(null)
    resetFilters()
    if (family && families) {
      let current_family = families.filter((f) => f.id == family)[0]
      if (current_family && current_family.year_start) {
        let years = range(current_family.year_start, current_family.year_end, 1)
        setYearOptions(
          years
            .map((y) => {
              return { value: y, label: y }
            })
            .reverse()
        )
        if (defaultYear) {
          setYear(parseInt(defaultYear))
        }
      }
    }
  }, [family])

  const resetFilters = () => {
    setGeartype(null)
    setBadge(null)
    setFueltype(null)
    setBodystyle(null)
  }

  const search = () => {
    setLoading(true)
    setFacets({})
    resetFilters()
    let params = {
      manufacturer_id: manufacturer,
      family_id: family,
      year: year,
    }
    let searchParams = new URLSearchParams(params).toString()
    let variant_serach_url = `/variants/search?${searchParams}`
    fetch(variant_serach_url)
      .then((res) => res.json())
      .then((data) => {
        setVariants(data.variants)
        if (data.facets) {
          setFacets(data.facets)
        }

        if (defaultBadge && data.facets?.badge && data.facets?.badge.includes(defaultBadge)) {
          setBadge(defaultBadge)
        }

        if (
          default_engine_size_litres &&
          data.facets?.enginesizelitres &&
          data.facets?.enginesizelitres.includes(default_engine_size_litres)
        ) {
          setEngineSizeLitres(default_engine_size_litres)
        }

        if (
          default_model_year &&
          data.facets?.modelyear &&
          data.facets?.modelyear.includes(default_model_year)
        ) {
          setModelYear(default_model_year)
        }

        if (
          default_bodyconfiguration &&
          data.facets?.bodyconfiguration &&
          data.facets?.bodyconfiguration.includes(default_bodyconfiguration)
        ) {
          setBodyconfiguration(default_bodyconfiguration)
        }

        if (
          default_wheelbaseconfiguration &&
          data.facets?.wheelbaseconfiguration &&
          data.facets?.wheelbaseconfiguration.includes(default_wheelbaseconfiguration)
        ) {
          setWheelBaseConfiguration(default_wheelbaseconfiguration)
        }

        if (drive_options && drive_options.length > 0) {
          let d_options = JSON.parse(drive_options)
          if (d_options !== null && typeof d_options !== undefined) {
            let variant_drive_options = data.variants.map((v) => v.drive)
            let default_drive_option = d_options.filter((d) => variant_drive_options.includes(d))
            if (default_drive_option.length === 1) {
              setDrive(default_drive_option[0])
            }
          }
        }

        if (default_bodystyle && default_bodystyle.length > 0) {
          let d_options = JSON.parse(default_bodystyle)
          if (d_options !== null && typeof d_options !== undefined) {
            let variant_bodystyle_options = data.variants.map((v) => v.bodystyle)
            let default_drive_option = d_options.filter((d) =>
              variant_bodystyle_options.includes(d)
            )
            if (default_drive_option.length === 1) {
              setBodystyle(default_drive_option[0])
            }
          }
        }

        if (transmission_options && transmission_options.length > 0) {
          let t_options = JSON.parse(transmission_options)
          if (t_options) {
            let variant_transmission_options = data.variants.map((v) => v.geartype)
            let default_trans_option = t_options.filter((d) =>
              variant_transmission_options.includes(d)
            )
            if (default_trans_option.length === 1) {
              setGeartype(default_trans_option[0])
            }
          }
        }

        if (fuel_options && fuel_options.length > 0) {
          let t_options = JSON.parse(fuel_options)
          if (t_options) {
            let fuel_options = data.variants.map((v) => v.fueltype)
            let default_fuel_option = t_options.filter((d) => fuel_options.includes(d))
            if (default_fuel_option.length === 1) {
              setFueltype(default_fuel_option[0])
            }
          }
        }
        setLoading(false)
      })
  }

  // Apply filters
  let filterdVariants = variants

  const facetConfig = [
    { key: 'badge', value: badge, setValue: setBadge, placeholder: 'Badge...' },
    { key: 'geartype', value: geartype, setValue: setGeartype, placeholder: 'Geartype...' },
    { key: 'fueltype', value: fueltype, setValue: setFueltype, placeholder: 'Fuel...' },
    { key: 'bodystyle', value: bodystyle, setValue: setBodystyle, placeholder: 'Bodystyle...' },
    { key: 'drive', value: drive, setValue: setDrive, placeholder: 'Drive...' },
    {
      key: 'enginesizelitres',
      value: engineSizeLitres,
      setValue: setEngineSizeLitres,
      placeholder: 'Engine Size...',
    },
    {
      key: 'bodyconfiguration',
      value: bodyconfiguration,
      setValue: setBodyconfiguration,
      placeholder: 'Body Configuration...',
    },
    {
      key: 'wheelbaseconfiguration',
      value: wheelBaseConfiguration,
      setValue: setWheelBaseConfiguration,
      placeholder: 'Wheel Base Configuration...',
    },
    {
      key: 'modelyear',
      value: modelYear,
      setValue: setModelYear,
      placeholder: 'Model Year...',
    },
  ]

  facetConfig
    .filter((f) => f.value)
    .map((filter) => {
      filterdVariants = filterdVariants.filter((v) => v[filter.key] === filter.value)
    })

  let filteredVariantsExcept = {}

  facetConfig.forEach(({ key }) => {
    filteredVariantsExcept[key] = (variants || []).filter((v) =>
      facetConfig.filter((f) => f.value && f.key !== key).every((f) => v[f.key] === f.value)
    )
  })

  const SelectFilter = ({ options, value, onChange, placeholder }) => (
    <div className="mb-3">
      <Select
        options={options.map((opt) => ({ label: opt, value: opt }))}
        value={value ? { label: value, value: value } : null}
        onChange={(e) => onChange(e?.value)}
        isClearable={true}
        backspaceRemovesValue={true}
        placeholder={placeholder}
      />
    </div>
  )

  return (
    <div className="row">
      <div className="col-md-4 col-xl-3">
        <CarDetails
          manufacturer_id={manufacturer_id}
          title={title}
          subtitle={subtitle}
          build_date={build_date}
          body={body}
        />
        {!carId && <RedbookRegoSearch setVariants={setVariants} />}
        <div className="box px-3 py-4">
          <div className="mb-3">
            <Select
              options={itemTypeOptions}
              onChange={(e) => setItemType(e.value)}
              placeholder="Item Type..."
              defaultValue={itemTypeOptions.filter((o) => o.value == itemType)}
              id="item-type-select"
            />
          </div>
          <div className="mb-3">
            <Select
              options={manufacturer_options}
              onChange={(e) => setManufacturer(e.value)}
              placeholder="Make..."
              defaultValue={manufacturer_options.filter((o) => o.value == manufacturer_id)}
            />
          </div>
          <div className="mb-3">
            <Select
              options={familyOptions}
              isDisabled={!familyOptions}
              onChange={(e) => setFamily(e.value)}
              value={familyOptions && familyOptions.filter((o) => o.value == family)}
              placeholder="Model..."
            />
          </div>
          <div className="mb-3">
            <Select
              isDisabled={!yearOptions}
              options={yearOptions}
              value={yearOptions && yearOptions.filter((o) => o.value === year)}
              onChange={(e) => setYear(e.value)}
              placeholder="Year..."
            />
          </div>
          {manufacturer && family && year && (
            <div
              className={'btn btn-outline-primary btn-block mt-3' + (loading ? ' disabled' : '')}
              onClick={search}
              disabled={loading}
              id="redbook-search-btn"
            >
              {loading ? 'Loading...' : 'Next'}
            </div>
          )}
        </div>
        {facets && (
          <div className="box p-3 mt-2">
            <div className="text-center mb-2">
              <span className="small text-secondary">Additional filters</span>
            </div>
            {facetConfig.map(({ key, value, setValue, placeholder }) =>
              facets?.[key] ? (
                <SelectFilter
                  key={key}
                  facetKey={key}
                  options={facets[key].filter((item) =>
                    filteredVariantsExcept[key].some((variant) => variant[key] === item)
                  )}
                  value={value}
                  onChange={setValue}
                  placeholder={placeholder}
                />
              ) : null
            )}
          </div>
        )}
        {!carId && (
          <a
            href={`/dealerships/${dealershipSlug}/cars/new?manual=true&item_type=${itemType === 'heavy' ? 'truck' : itemType}&manufacturer_id=${manufacturer}`}
            className="btn btn-sm btn-outline-secondary btn-block mt-2"
            id="manual-add-car"
          >
            Manually Add Car
          </a>
        )}
      </div>
      <div className="col-md-8 col-xl-9">
        {variants && (
          <Variants carId={carId} variants={filterdVariants ? filterdVariants : variants} />
        )}
      </div>
    </div>
  )
}

const fetchCarData = async (dealershipSlug, carId) => {
  const res = await fetch(Routes.select_vehicle_dealership_car_path(dealershipSlug, carId), {
    headers: standardHeaders,
  })
  return res.json()
}

const fetchManufacturers = async (itemType) => {
  const res = await fetch(Routes.manufacturers_path({ segment: itemType }), {
    headers: standardHeaders,
  })
  return res.json()
}

const Redbook = ({ hitId }) => {
  const [itemType, setItemType] = useState('car')
  const [catalogue, setCatalogue] = useState('redbook')

  const { carId, dealershipSlug } = useParams()
  const finalCarId = carId || hitId
  const { dealership } = useFetchDealership()

  useEffect(() => {
    if (dealership?.preferred_catalogue) {
      setCatalogue(dealership.preferred_catalogue)
    }
  }, [dealership])

  const { data: manufacturers, isLoading: loadingManufacturers } = useQuery({
    queryKey: ['manufacturers', itemType],
    queryFn: () => fetchManufacturers(itemType),
  })

  const { data: carData, isLoading: loadingCar } = useQuery({
    queryKey: ['car', dealershipSlug, finalCarId],
    queryFn: () => fetchCarData(dealershipSlug, finalCarId),
    enabled: !!(dealershipSlug && finalCarId), // only run if both exist
  })

  if (loadingManufacturers || loadingCar || !manufacturers) {
    return (
      <div className="p-3">
        <p>Loading...</p>
      </div>
    )
  }

  return (
    <div className="p-3 w-100">
      {catalogue === 'redbook' ? (
        <RedbookSearch
          {...carData}
          defaultBadge={carData?.badge}
          manufacturers={manufacturers}
          itemType={itemType}
          setItemType={setItemType}
          setCatalogue={setCatalogue}
          carId={finalCarId}
        />
      ) : (
        <NvicSearch catalogue={catalogue} setCatalogue={setCatalogue} {...carData} />
      )}
    </div>
  )
}

export default Redbook
