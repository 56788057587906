import { useConfig, useItems } from '../../../contexts/hooks'
import { useWindowWidth } from '../../../hooks'
import { ModuleItem } from '../../../types'
import { isEmptyObject } from '../../../utils'

export const useValidExpandPositions = (itemIndex: number) => {
  const { config } = useConfig()
  const { itemsLength } = useItems()
  const windowWidth = useWindowWidth()

  // Helper to check if this is the last item in its row
  const isLastItemInRow = (index: number, numColumns: number) => {
    const nextIndex = index + 1
    return (
      nextIndex >= itemsLength ||
      Math.floor(nextIndex / numColumns) > Math.floor(index / numColumns)
    )
  }

  // For small layout:
  // 4 columns (>1600px): check if it's in first column and last in row
  // 3 columns (1400-1600px): check if it's in first column and last in row
  // 2 columns (1200-1400px): check if it's in first column and last in row
  // 1 column (<1200px): only last item is valid
  const validSmallLayoutExpandPositions =
    config?.layoutSize === 'small' &&
    ((windowWidth < 1200 && itemIndex === itemsLength - 1) || // 1 column - last item
      (windowWidth <= 1400 && itemIndex % 2 === 0 && isLastItemInRow(itemIndex, 2)) || // 2 columns
      (windowWidth <= 1600 && itemIndex % 3 === 0 && isLastItemInRow(itemIndex, 3)) || // 3 columns
      (windowWidth > 1600 && itemIndex % 4 === 0 && isLastItemInRow(itemIndex, 4))) // 4 columns

  // For medium layout:
  // 3 columns (>1400px): check if it's in first column and last in row
  // 2 columns (1200-1400px): check if it's in first column and last in row
  // 1 column (<1200px): only last item is valid
  const validMediumLayoutExpandPositions =
    config?.layoutSize === 'medium' &&
    ((windowWidth < 1200 && itemIndex === itemsLength - 1) || // 1 column - last item
      (windowWidth <= 1400 && itemIndex % 2 === 0 && isLastItemInRow(itemIndex, 2)) || // 2 columns
      (windowWidth > 1400 && itemIndex % 3 === 0 && isLastItemInRow(itemIndex, 3))) // 3 columns

  // For large layout:
  // 2 columns (≥1200px): check if it's in first column and last in row
  // 1 column (<1200px): only last item is valid
  const validLargeLayoutExpandPositions =
    config?.layoutSize === 'large' &&
    ((windowWidth < 1200 && itemIndex === itemsLength - 1) || // 1 column - last item
      (itemIndex % 2 === 0 && isLastItemInRow(itemIndex, 2))) // 2 columns

  return (
    validSmallLayoutExpandPositions ||
    validMediumLayoutExpandPositions ||
    validLargeLayoutExpandPositions
  )
}

export const useValidExpandPositionsArray = (totalCount: number) => {
  const { config } = useConfig()
  const { itemsLength } = useItems()
  const windowWidth = useWindowWidth()

  // Helper to check if this is the last item in its row
  const isLastItemInRow = (index: number, numColumns: number) => {
    const nextIndex = index + 1
    return (
      nextIndex >= itemsLength ||
      Math.floor(nextIndex / numColumns) > Math.floor(index / numColumns)
    )
  }

  return Array.from({ length: totalCount }, (_, itemIndex) => {
    // For small layout:
    // 4 columns (>1600px): check if it's in first column and last in row
    // 3 columns (1400-1600px): check if it's in first column and last in row
    // 2 columns (1200-1400px): check if it's in first column and last in row
    // 1 column (<1200px): only last item is valid
    const validSmallLayoutExpandPositions =
      config?.layoutSize === 'small' &&
      ((windowWidth < 1200 && itemIndex === itemsLength - 1) || // 1 column - last item
        (windowWidth <= 1400 && itemIndex % 2 === 0 && isLastItemInRow(itemIndex, 2)) || // 2 columns
        (windowWidth <= 1600 && itemIndex % 3 === 0 && isLastItemInRow(itemIndex, 3)) || // 3 columns
        (windowWidth > 1600 && itemIndex % 4 === 0 && isLastItemInRow(itemIndex, 4))) // 4 columns

    // For medium layout:
    // 3 columns (>1400px): check if it's in first column and last in row
    // 2 columns (1200-1400px): check if it's in first column and last in row
    // 1 column (<1200px): only last item is valid
    const validMediumLayoutExpandPositions =
      config?.layoutSize === 'medium' &&
      ((windowWidth < 1200 && itemIndex === itemsLength - 1) || // 1 column - last item
        (windowWidth <= 1400 && itemIndex % 2 === 0 && isLastItemInRow(itemIndex, 2)) || // 2 columns
        (windowWidth > 1400 && itemIndex % 3 === 0 && isLastItemInRow(itemIndex, 3))) // 3 columns

    // For large layout:
    // 2 columns (≥1200px): check if it's in first column and last in row
    // 1 column (<1200px): only last item is valid
    const validLargeLayoutExpandPositions =
      config?.layoutSize === 'large' &&
      ((windowWidth < 1200 && itemIndex === itemsLength - 1) || // 1 column - last item
        (itemIndex % 2 === 0 && isLastItemInRow(itemIndex, 2))) // 2 columns

    return (
      validSmallLayoutExpandPositions ||
      validMediumLayoutExpandPositions ||
      validLargeLayoutExpandPositions
    )
  })
}

export const useModuleData = (
  data: any,
  item: ModuleItem,
  editingPreviewData: { key: ModuleItem[] } | null,
  editModeEnabled: boolean
) => {
  // Show preview data first, if it exists and isn't empty. Then check is data exists and isn't empty. If they are empty set to []
  const notEmptyPreviewData = isEmptyObject(editingPreviewData?.[item?.module])
    ? []
    : editingPreviewData?.[item?.module]

  const notEmptyData = isEmptyObject(data?.[item?.module]) ? [] : data?.[item?.module]

  const dataToShow =
    editModeEnabled && editingPreviewData?.[item?.module] ? notEmptyPreviewData : notEmptyData

  return dataToShow
}
