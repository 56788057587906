import { useEffect, useRef, useState } from 'react'

import { Toast } from 'primereact/toast'
import Select from 'react-select'

import showToast from '../shared/ShowToast'

const SelectLocation = ({
  hit,
  attribute = 'location_id',
  placeholder = 'Location',
  onLocationChange,
  selectedValue,
  disabled,
  locationOptions = false,
  leadCluster,
  setLeadCluster,
}) => {
  if (!locationOptions) {
    return <></>
  }

  const notification = useRef(null)

  let [loading, setLoading] = useState(false)
  let [value, setValue] = useState(locationOptions.filter((u) => u.value === hit[attribute])[0])

  useEffect(() => {
    setValue(locationOptions.filter((u) => u.value === (selectedValue || hit[attribute]))[0])
  }, [selectedValue, hit[attribute]])

  const onChange = async ({ value, label }) => {
    try {
      setLoading(true)
      setValue({ value, label })

      await onLocationChange(hit.id, value, leadCluster, setLeadCluster)

      showToast(notification, 'success', 'Lead cluster location updated successfully')
    } catch (error) {
      console.error('Error updating location:', error)
      showToast(notification, 'error', 'Failed to update lead cluster location')
    } finally {
      setLoading(false) // Ensures loading is always reset
    }
  }

  const customStyles = {
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensure dropdown is above the modal
    }),
  }

  return (
    <>
      <Toast ref={notification} />
      <Select
        options={locationOptions}
        name={attribute}
        isLoading={loading}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        isDisabled={disabled}
        className="location-select"
        styles={customStyles}
        menuPortalTarget={document.body}
      />
    </>
  )
}

export default SelectLocation
