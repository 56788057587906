import { useState } from 'react'

import { Checkbox } from 'primereact/checkbox'
import { Dialog } from 'primereact/dialog'
import { useParams } from 'react-router-dom'

import { standardHeaders } from '../entries/utils'
import revolutionLogo from '../images/revolution.png'

const SendToRevolution = ({ leadCluster }) => {
  let { dealershipSlug } = useParams()

  if (!leadCluster.has_tune) {
    return null
  }

  let [visible, setVisible] = useState(false)
  let [confirmation, setConfirmation] = useState(false)
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)

  let lead_id = leadCluster?.leads[0].id

  if (!lead_id) {
    return null
  }

  const resendToThirdParties = () => {
    if (disabled) return

    setLoading(true)
    fetch(
      `/dealerships/${dealershipSlug}/leads/${lead_id}/resend_to_third_parties?medium=tune&manual_send=true`,
      {
        method: 'POST',
        headers: standardHeaders,
      }
    )
      .then((res) => res.json())
      .then(() => {
        setLoading(false)
        setSent(true)
      })
  }

  let disabled = loading || sent || !confirmation

  let outerButtonDisabled = leadCluster.source === 'Carsales'

  return (
    <div className="px-3 pt-2">
      <Dialog
        header="Send to Tune/Rev"
        visible={visible}
        onHide={() => setVisible(false)}
        style={{ width: '50vw' }}
        dismissableMask
      >
        <div className="d-flex">
          <Checkbox
            inputId="confirmation"
            name="confirmation"
            onChange={() => setConfirmation(!confirmation)}
            checked={confirmation}
          />
          <label htmlFor="confirmation" className="ml-2">
            By sending to Tune/Revolution you confirm that this customer has purchased a vehicle
            from your dealership. Sending to Tune is for the purpose of contract generation.
          </label>
        </div>
        <div
          className={'btn btn-outline-primary btn-block' + (disabled ? ' disabled' : '')}
          onClick={resendToThirdParties}
          disabled={disabled}
        >
          {sent ? (
            <i className="fa fa-check mr-2"></i>
          ) : (
            <img src={revolutionLogo} className="mr-2" height="25" />
          )}
          {sent ? 'Sent to Tune/Rev' : 'Send to Tune/Rev'}
        </div>
      </Dialog>
      <div
        onClick={() => {
          if (!outerButtonDisabled) {
            setVisible(true)
          }
        }}
        className={'btn btn-outline-primary btn-block' + (outerButtonDisabled ? ' disabled' : '')}
      >
        <img src={revolutionLogo} className="mr-2" height="25" />
        Send to Revolution
      </div>
    </div>
  )
}

export default SendToRevolution
