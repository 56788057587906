import { useCallback, useEffect, useRef, useState } from 'react'

import {
  DndContext,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext, arrayMove } from '@dnd-kit/sortable'

import LoadingBoxes from '../../../../entries/LoadingBoxes'
import { maxModules } from '../../../constants'
import { useConfig, useItems } from '../../../contexts/hooks'
import { useWindowWidth } from '../../../hooks'
import type { ModuleItem } from '../../../types'
import { getColumnHeights } from '../../../utils'
import { useValidExpandPositionsArray } from '../../AnalyticsModules/components/hooks'
import Item from './Item'
import SortableItem from './SortableItem'

interface LayoutProps {
  items: ModuleItem[]
  rowWidth: number | null
  layoutSize: string
  windowWidth: number
}

const Layout: React.FC<LayoutProps> = ({ items, rowWidth, layoutSize, windowWidth }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [firstColumnIsLongest, setFirstColumnIsLongest] = useState<boolean | null>(null)
  const [firstColumnHeight, setFirstColumnHeight] = useState<number>(0)
  const [longestColumnHeight, setLongestColumnHeight] = useState<number>(0)
  const { expandToRowWidth } = useItems()

  // Get number of columns based on layout size and window width
  const getNumColumns = () => {
    // Check for mobile/small screens first
    if (windowWidth < 1200) {
      return 1
    }

    if (layoutSize === 'small') {
      if (windowWidth > 1600) {
        return 4
      } else if (windowWidth > 1400) {
        return 3
      } else {
        return 2
      }
    }
    if (layoutSize === 'medium') {
      return windowWidth > 1400 ? 3 : 2
    }

    return 2 // large layout
  }

  useEffect(() => {
    const updateHeights = () => {
      const { firstColumnLongest, firstColumnHeight, longestColumnHeight } =
        getColumnHeights(containerRef)
      setFirstColumnIsLongest(firstColumnLongest)
      setFirstColumnHeight(firstColumnHeight)
      setLongestColumnHeight(longestColumnHeight)
    }

    const containerObserver = new ResizeObserver(updateHeights)
    if (containerRef.current) {
      containerObserver.observe(containerRef.current)
      containerRef.current.querySelectorAll('.p-3').forEach((module) => {
        containerObserver.observe(module)
      })
    }

    return () => containerObserver.disconnect()
  }, [items])

  const numColumns = getNumColumns()

  const validExpandPositions = useValidExpandPositionsArray(items.length)

  // Special case for single column - just render all items in sequence
  if (numColumns === 1) {
    return (
      <div ref={containerRef} className="d-flex w-100">
        <div className="p-0 col-12">
          {items.map((item, index) => (
            <div className="p-3" key={index}>
              <SortableItem id={item.module} item={item} itemIndex={index} rowWidth={rowWidth} />
            </div>
          ))}
        </div>
      </div>
    )
  }

  // Original multi-column layout code
  const columns = Array.from({ length: numColumns }, (_, colIndex) => (
    <div
      key={colIndex}
      className={`p-0 col-12 col-xl-6 ${
        numColumns >= 3 ? 'col-xxl-4' : ''
      } ${numColumns === 4 ? 'col-xxxl-3' : ''}`}
    >
      {items.map((item, index) => {
        const row = Math.floor(index / numColumns)
        const itemColumn = index - row * numColumns

        if (itemColumn !== colIndex) return null

        return (
          <div
            className="p-3"
            key={index}
            style={{
              marginTop:
                colIndex === 0 &&
                !firstColumnIsLongest &&
                expandToRowWidth &&
                validExpandPositions[index] &&
                items.length > 1
                  ? (longestColumnHeight - firstColumnHeight) * (index / (items.length - 1))
                  : 'auto',
            }}
          >
            <SortableItem
              id={item.module}
              item={item}
              itemIndex={index}
              rowWidth={colIndex === 0 ? rowWidth : undefined}
            />
          </div>
        )
      })}
    </div>
  ))

  return (
    <div ref={containerRef} className="d-flex w-100">
      {columns}
    </div>
  )
}

const DragAndDrop: React.FC<{}> = () => {
  const { config } = useConfig()
  const { items, setItems, handleDrop } = useItems()
  const windowWidth = useWindowWidth()
  const [activeId, setActiveId] = useState<string | null>(null)
  const [rowWidth, setRowWidth] = useState<number | null>(null)
  const rowRef = useRef<HTMLDivElement>(null)
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

  // Sets the activeId state when a drag starts
  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id as string) // Should always be a string // item.module
  }, [])

  // Updates the items state when a drag is over another item
  const handleDragOver = useCallback(
    (event: DragOverEvent) => {
      const { active, over } = event

      // If the active and over ids are different, update the items state
      if (over && active.id !== over?.id) {
        setItems((items) => {
          const oldIndex = items.findIndex((item) => item.module === active.id)
          const newIndex = items.findIndex((item) => item.module === over?.id)

          const updatedItems = arrayMove(items, oldIndex, newIndex)

          // Sync the tabLayouts state with the updated items
          handleDrop(updatedItems)

          return updatedItems
        })
      }
    },
    [handleDrop]
  )

  const handleDragEnd = useCallback(() => {
    // Always reset the activeId state
    setActiveId(null)
  }, [])

  // Set activeId to null if the drag is cancelled
  const handleDragCancel = useCallback(() => {
    setActiveId(null)
  }, [])

  // Sets the active item for the drag overlay
  const activeItem = items?.find((item) => item.module === activeId)

  // Update rowWidth on load and resize
  useEffect(() => {
    // Don't try to update width if we're not rendering the main content
    if (!items || items.length === 0) return

    const updateRowWidth = () => {
      if (rowRef.current) {
        const currentRowWidth = rowRef.current.offsetWidth
        const remInPx = parseFloat(getComputedStyle(document.documentElement).fontSize)
        setRowWidth(currentRowWidth - remInPx * 2) // Subtract 2rem padding
      }
    }

    updateRowWidth() // Set initial width
    window.addEventListener('resize', updateRowWidth) // Update on resize
    return () => window.removeEventListener('resize', updateRowWidth)
  }, [items]) // Add items as a dependency

  if (!items || items.length === 0) return <div />

  // Add a blank module to the end of the items array
  if (items[items.length - 1].module !== 'blank' && items.length < maxModules) {
    items.push({ module: 'blank' })
  }

  return (
    <>
      <div className="row DragAndDropRow overflow-hidden" ref={rowRef}>
        <DndContext
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragOver={handleDragOver}
          onDragCancel={handleDragCancel}
          collisionDetection={closestCenter}
          sensors={sensors}
        >
          <SortableContext items={items.map((item) => item.module)} strategy={() => null}>
            <Layout
              items={items}
              rowWidth={rowWidth}
              layoutSize={config?.layoutSize}
              windowWidth={windowWidth}
            />
          </SortableContext>
          <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
            {activeId ? (
              <>
                <Item id={activeId} item={activeItem} isDragging isOverlay />
              </>
            ) : (
              <LoadingBoxes />
            )}
          </DragOverlay>
        </DndContext>
      </div>
    </>
  )
}

export default DragAndDrop
