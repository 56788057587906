import { useEffect } from 'react'

import { useParams } from 'react-router-dom'

import { automateAddCampaigns, automateCampaignDeletion } from '../api'
import { useAnalyticsDashboard, useItems, usePreviewData, useTabs } from '../contexts/hooks'
import Ga4WebsiteDropdown from '../features/DashboardSettings/components/Ga4WebsiteDropdown'
import DragAndDrop from '../features/DragAndDrop/components/DragAndDrop'
import Tabs from '../features/Tabs/components/Tabs'
import { useSetModuleLayouts } from '../hooks'
import Header from './Header'

const AnalyticsDashboard: React.FC = () => {
  const { dashboardLevel, canView, editModeEnabled, website, dealership, analyticsBlock } =
    useAnalyticsDashboard()
  const { selectedTab, tabLayouts, tabTitles } = useTabs()
  const { handleUpdateItems } = useItems()
  const { setEditingPreviewData } = usePreviewData()
  const params = useParams()
  const { setModuleLayouts } = useSetModuleLayouts()

  const dealershipSlug = params.dealershipSlug || dealership?.slug || website?.dealership_slug

  const multipleTabs: boolean = Object.keys(tabLayouts).length > 1 && tabTitles.length > 1

  useEffect(() => {
    if (tabLayouts && selectedTab in tabLayouts) {
      handleUpdateItems(tabLayouts[selectedTab])
    }
  }, [tabLayouts, selectedTab])

  // useEffect that runs when the component mounts
  useEffect(() => {
    // If there is no data in the block, set the current items to the block
    const blockData = analyticsBlock?.data
    if (blockData && Object.keys(blockData).length === 0 && blockData.constructor === Object) {
      setModuleLayouts()
    }
    if (dealershipSlug && canView) {
      automateCampaignDeletion(dealershipSlug)
      if (dashboardLevel === 'Dealership' || dashboardLevel === 'Website') {
        automateAddCampaigns(dealershipSlug)
      }
    }
  }, [])

  // Set preview data to null when exiting edit mode
  useEffect(() => {
    if (!editModeEnabled) {
      setEditingPreviewData(null)
    }
  }, [editModeEnabled])

  if (!canView) {
    return null
  }

  return (
    <>
      <div className="p-4">
        <Header />
        {multipleTabs || editModeEnabled ? <Tabs /> : null}
        <Ga4WebsiteDropdown />
        <DragAndDrop />
      </div>
    </>
  )
}

export default AnalyticsDashboard
