import { useContext, useEffect, useState } from 'react'

import moment from 'moment'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import Select from 'react-select'

import * as Routes from '../../routes'
import { CurrentUserContext } from '../contexts'
import { standardHeaders } from '../entries/utils'
import { Toggle } from '../settings/leadSettings'
import { CopyableText } from '../shared/CopyableText'

const Name = (rowData) => {
  return (
    <div>
      {rowData.name}
      <div className="text-secondary small">{rowData.location?.name}</div>
      {rowData.photos_only && rowData.accepts_cars && (
        <div className="badge badge-danger badge-sm">Photos Only</div>
      )}
    </div>
  )
}

const Actions = (rowData) => {
  return (
    <div>
      <a href={`/carsales_accounts/${rowData.id}/edit`} className="btn btn-sm btn-outline-primary">
        Edit
      </a>
    </div>
  )
}

const Billing = (rowData) => {
  return (
    <Toggle
      attributeName="billing"
      objectType="carsales_account"
      rowData={rowData}
      url={`/carsales_accounts/${rowData.id}`}
    />
  )
}

const AcceptCars = (rowData) => {
  return (
    <Toggle
      attributeName="accept_cars"
      objectType="carsales_account"
      rowData={rowData}
      url={`/carsales_accounts/${rowData.id}`}
    />
  )
}

const CarsalesId = (rowData) => <CopyableText text={rowData.carsales_id} />

const filters = [
  { label: 'All', value: null },
  { label: 'Carsales Leads', value: 'carsales_leads' },
  { label: 'Carsales Inventory', value: 'carsales_inventory' },
  { label: 'Unmatched', value: 'unmatched' },
]

export const CarsalesAccountsTable = ({
  carsalesAccounts,
  totalCount,
  loading,
  page,
  setPage,
  rows,
}) => {
  const currentUser = useContext(CurrentUserContext)

  const onPageChange = (event) => {
    setPage(event.page + 1) // PrimeReact pages are 0-based; Kaminari pages are 1-based
  }

  return (
    <div className="box mt-3">
      <DataTable
        value={carsalesAccounts}
        loading={loading}
        paginator
        rows={rows}
        first={(page - 1) * rows}
        totalRecords={totalCount}
        onPage={onPageChange}
        lazy
      >
        <Column
          field="created_at"
          header="Created At"
          body={(rowData) => <>{moment(rowData.created_at).fromNow()}</>}
        />
        <Column field="name" header="Name" body={Name} />
        <Column field="carsales_id" header="Carsales GUID" body={CarsalesId} />
        <Column field="dealership.name" header="Dealership" sortable />
        {currentUser?.admin && (
          <Column body={AcceptCars} header="Accept Cars" sortable field="accept_cars" />
        )}
        {currentUser?.admin && <Column body={Billing} header="Billing" sortable field="billing" />}
        <Column body={Actions} />
      </DataTable>
    </div>
  )
}

const CarsalesAccounts = () => {
  let [loading, setLoading] = useState(true)
  let [carsalesAccounts, setCarsalesAccounts] = useState([])
  let [selectedFilter, setSelectedFilter] = useState(null)
  let [total, setTotal] = useState(0)
  let [page, setPage] = useState(1)
  const rows = 25

  useEffect(() => {
    setLoading(true)
    const params = { page, per_page: rows }

    if (selectedFilter) {
      params.filter = selectedFilter
    }

    const url = Routes.carsales_accounts_path(params)

    fetch(url, { headers: standardHeaders })
      .then((res) => res.json())
      .then((data) => {
        setCarsalesAccounts(data.carsales_accounts)
        setTotal(data.total_count)
        setLoading(false)
      })
  }, [selectedFilter, page])

  return (
    <div className="container py-3">
      <h2>Carsales Accounts</h2>
      <Select options={filters} placeholder="Filter" onChange={(e) => setSelectedFilter(e.value)} />
      <CarsalesAccountsTable
        carsalesAccounts={carsalesAccounts}
        totalCount={total}
        loading={loading}
        setPage={setPage}
        page={page}
        rows={rows}
      />
    </div>
  )
}

export default CarsalesAccounts
