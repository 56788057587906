import { useCallback, useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Dropdown } from 'primereact/dropdown'

import * as Routes from '../../../../../routes'
import { useAnalyticsDashboard } from '../../../contexts/hooks'
import { Location, User } from '../../../types'

type SelectLocationsProps = {
  handleUpdateSelectedLocation: (arg0: Location) => void
  currentUser: User
  analyticLocations?: Location[]
}

const SelectLocations: React.FC<SelectLocationsProps> = ({
  handleUpdateSelectedLocation,
  currentUser,
  analyticLocations = [],
}) => {
  const [selectedLocation, setSelectedLocation] = useState<Location>({ id: 0, name: '' })
  const { dashboardLevel, dashboardLevelLoaded, dealership, website } = useAnalyticsDashboard()

  const allLocations: Location = { id: -1, name: 'All Locations' }

  const fetchLocations = async (): Promise<Location[]> => {
    if (!dashboardLevelLoaded || !['Dealership', 'Website'].includes(dashboardLevel)) {
      return []
    }

    const website_id = dashboardLevel === 'Website' ? website?.id : null
    const fetchUrl = `${Routes.dealership_locations_path(dealership?.slug)}?with_lead=true${
      website_id ? `&website_id=${website_id}` : ''
    }`

    const response = await fetch(fetchUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })

    const locationData: Location[] = await response.json()

    const userLocations = currentUser?.admin
      ? []
      : (currentUser?.dealerships[dealership.id]?.locations ?? [])

    if (currentUser?.admin || userLocations.length === 0) {
      return [allLocations, ...locationData]
    } else {
      // Retrieve common locations between userLocations and locationData
      const commonLocations = locationData.filter((location) =>
        userLocations.some((userLocation) => userLocation.id === location.id)
      )

      if (commonLocations.length === 1) {
        setSelectedLocation(commonLocations[0])
        handleUpdateSelectedLocation(commonLocations[0])
      }

      return commonLocations.length > 0 ? [allLocations, ...commonLocations] : []
    }
  }

  const {
    data: locations = [],
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['locations', dealership?.slug, dashboardLevelLoaded],
    queryFn: fetchLocations,
    enabled: !!dealership?.slug && dashboardLevelLoaded,
    staleTime: 5 * 60 * 1000, // Cache data for 5 minutes
  })

  useEffect(() => {
    if (analyticLocations.length > 0 && selectedLocation?.id === 0) {
      setSelectedLocation(allLocations)
    }
  }, [analyticLocations])

  const onLocationChange = useCallback(
    (event: { target: { value: string } }): void => {
      const newSelectedLocation = locations.find((location) => location.name === event.target.value)
      if (newSelectedLocation) {
        setSelectedLocation(newSelectedLocation)
        handleUpdateSelectedLocation(newSelectedLocation)
      }
    },
    [locations, handleUpdateSelectedLocation]
  )

  if (isLoading || locations.length === 0) return null

  if (isError) {
    return <Dropdown disabled placeholder="Error fetching locations..." />
  }

  const locationOptions = locations.map((location) => ({
    label: location.name,
    value: location.name,
  }))

  return (
    <Dropdown
      placeholder="Location"
      value={selectedLocation?.name}
      options={locationOptions}
      onChange={onLocationChange}
      id="location-select"
      className="analytics-header-dropdown"
      pt={{
        wrapper: {
          style: {
            maxHeight: '80vh',
          },
        },
      }}
    />
  )
}

export default SelectLocations
