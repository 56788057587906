import * as Routes from '../../routes'
import { standardHeaders } from '../entries/utils'

export const PageService = {
  getPages(params, ownerId, ownerClass) {
    const queryParams = params
      ? Object.keys(params)
          .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
          .join('&')
      : ''
    let pagesRoute = ''

    if (ownerClass === 'Website') {
      pagesRoute = Routes.website_pages_path(ownerId)
    } else if (ownerClass == 'Manufacturer') {
      pagesRoute = Routes.manufacturer_pages_path(ownerId)
    } else if (ownerClass == 'Dealership') {
      pagesRoute = Routes.dealership_pages_path(ownerId)
    }
    return fetch(`${pagesRoute}.json?${queryParams}`, {
      method: 'GET',
      headers: standardHeaders,
      credentials: 'same-origin',
    }).then((res) => res.json())
  },
}
