import React, { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import { Paginator } from 'primereact/paginator'
import { Link, useParams } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'

import * as Routes from '../../../routes'
import { TimeRange } from '../../AnalyticsDashboard/types'
import Loading from '../../Loading'
import TimeRangePicker from '../../entries/TimeRange'
import { standardHeaders } from '../../entries/utils'

type AnalyticsDetail = {
  id: number
  name: string
  dealershipSlug: string
  slug: string
  conversionRate: string
  pageViews: number
  leads: number
  uniqueVisits: number
  gaProfileExists: boolean
}

const fetchOverviewData = async (url: string): Promise<AnalyticsDetail[]> => {
  const response = await fetch(url, {
    headers: standardHeaders,
  })

  if (!response.ok) {
    throw new Error('Network response was not ok')
  }

  const result = await response.json()
  return result.websites.map((detail: any) => ({
    id: detail.id,
    name: detail.name,
    dealershipSlug: detail.dealership_slug,
    slug: detail.slug,
    conversionRate: detail.conversion_rate,
    pageViews: detail.page_views,
    leads: detail.leads,
    uniqueVisits: detail.unique_visits,
    gaProfileExists: detail.ga_profile === true,
  }))
}

const Overview: React.FC = () => {
  const { manufacturerSlug, dealershipGroupId } = useParams()
  const [slug, setSlug] = useState<string | null>(manufacturerSlug || dealershipGroupId)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(30)
  const [timeRange, setTimeRange] = useState<TimeRange>({
    start: 0,
    end: 0,
    timeframe: '',
    type: '',
  })

  const fetchUrl = `/${manufacturerSlug ? 'manufacturers' : 'dealership_groups'}/${slug}/websites/overview.json`

  const { data, isLoading, error } = useQuery({
    queryKey: ['overviewData', slug, timeRange],
    queryFn: () => fetchOverviewData(fetchUrl + `?${new URLSearchParams(timeRange as any)}`),
    enabled: !!slug, // Only fetch if slug exists
  })

  const filteredData =
    data?.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase())) || []

  const handlePageChange = (event: { first: number; rows: number }) => {
    setCurrentPage(event.first / event.rows)
    setRowsPerPage(event.rows)
  }

  const handleTimeRangeChange = (dateRange: TimeRange): void => {
    setTimeRange(dateRange)
  }

  return (
    <div className="p-4">
      <Tooltip anchorSelect={`.ga-not-configured`} place="bottom" style={{ zIndex: 10000 }}>
        GA Profile not configured.
      </Tooltip>
      <div className="row">
        <h4 className="col-6 p-0 pl-3">Websites Overview</h4>
      </div>
      <div className="row mb-3">
        <div className="col-6 p-0 pl-3">
          <InputText
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search by name"
          />
        </div>
        <div className="float-right col-6 d-flex justify-content-end">
          <div className="d-flex flex-column align-items-end">
            <TimeRangePicker handleUpdateTimeRange={handleTimeRangeChange} />
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Loading />
        </div>
      ) : error ? (
        <div className="text-danger text-center">Error fetching data</div>
      ) : (
        <DataTable
          value={filteredData.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage)}
          rows={rowsPerPage}
          paginator
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          onPage={handlePageChange}
          totalRecords={filteredData.length}
          className="table table-striped table-bordered"
        >
          <Column
            field="name"
            header="Name"
            sortable
            body={(rowData: AnalyticsDetail) => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {rowData.name}
                {!rowData.gaProfileExists && (
                  <i
                    className="fa fa-exclamation-triangle ga-not-configured"
                    style={{ marginLeft: '8px', color: 'orange', cursor: 'pointer' }}
                  />
                )}
              </div>
            )}
          />
          <Column field="leads" sortable header="Leads" />
          <Column
            field="conversionRate"
            sortable
            header="Conversion Rate"
            body={(rowData) => <span>{`${rowData.conversionRate}%`}</span>}
          />
          <Column
            field="pageViews"
            sortable
            header="Page Views"
            body={({ pageViews }) => pageViews.toLocaleString()}
          />
          <Column
            field="uniqueVisits"
            sortable
            header="Unique Visits"
            body={({ uniqueVisits }) => uniqueVisits.toLocaleString()}
          />
          <Column
            header="View Dashboard"
            body={(rowData: any) => (
              <Link to={Routes.dealership_website_path(rowData.dealershipSlug, rowData.slug)}>
                View
              </Link>
            )}
          />
        </DataTable>
      )}
      {filteredData.length > rowsPerPage && (
        <Paginator
          first={currentPage * rowsPerPage}
          rows={rowsPerPage}
          totalRecords={filteredData.length}
          onPageChange={handlePageChange}
          className="mt-2"
        />
      )}
    </div>
  )
}

export default Overview
