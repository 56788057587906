import { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import * as Routes from '../../routes'
import { FormContext } from '../contexts'
import { Errors } from '../entries/FormElements'
import { SectionHeading, standardHeaders } from '../entries/utils'
import { CopyableText } from '../shared/CopyableText'
import SlideIn from '../shared/SlideIn'
import HookFormField from '../shared/hookFormField'

let attributes = {
  name: {
    type: 'text',
    label: 'Manufacturer Colour Name',
    required: true,
    placeholder: 'eg. Ice White',
  },
  generic_colour: { type: 'select', label: 'Generic Colour', required: true, options: 'colours' },
  colour_type: { type: 'select', label: 'Colour Type', required: true, options: 'colour_types' },
  colour_alias: { type: 'creatable_select', label: 'Alias', required: true },
}

const Form = ({ colourOption, refetch, setVisible }) => {
  let { manufacturerSlug } = useParams()
  let [errors, setErrors] = useState({})
  let [loading, setLoading] = useState(false)
  let [formData, setFormData] = useState({})

  useEffect(() => {
    fetch(Routes.new_manufacturer_colour_option_path(manufacturerSlug), {
      headers: standardHeaders,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.json()
      })
      .then((res) => {
        setFormData(res)
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }, [])

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      name: colourOption?.name || '',
      generic_colour: colourOption?.generic_colour || '',
      colour_alias: colourOption?.colour_alias || [],
      colour_type: colourOption?.colour_type || [],
    },
  })

  const onSubmit = (data) => {
    setLoading(true)

    let url = Routes.manufacturer_colour_options_path(manufacturerSlug)
    let method = 'POST'

    if (colourOption) {
      url = Routes.manufacturer_colour_option_path(manufacturerSlug, colourOption)
      method = 'PUT'
    }

    fetch(url, {
      method: method,
      headers: standardHeaders,
      body: JSON.stringify({ colour_option: data }),
    })
      .then((response) => {
        if (!response.ok) {
          // If the response status is 422, handle it separately
          if (response.status === 422) {
            return response.json().then((res) => {
              setErrors(res) // Assuming the API returns errors in `res.errors`
              setLoading(false)
            })
          }
          throw new Error('Network response was not ok')
        }
        return response.json()
      })
      .then((res) => {
        refetch()
        reset()
        setVisible(false)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error:', error)
        setLoading(false)
      })
  }

  let submitText = 'Create Colour Option'
  if (colourOption) {
    submitText = 'Update Colour Option'
  }

  return (
    <FormContext.Provider value={{ control, errors, attributes, formData }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <HookFormField attribute="generic_colour" />
        <HookFormField attribute="name" />
        <HookFormField attribute="colour_type" />
        <HookFormField attribute="colour_alias" />
        <Errors errors={errors} />
        <button className="btn btn-block btn-outline-success" type="submit">
          {loading ? 'Loading...' : submitText}
        </button>
      </form>
    </FormContext.Provider>
  )
}

const NewForm = ({ refetchData }) => {
  let [visible, setVisible] = useState(false)

  return (
    <>
      <div className="btn btn-outline-secondary" onClick={() => setVisible(true)}>
        <i className="fa fa-plus mr-1"></i>
        Colour Option
      </div>
      <Dialog
        header="Add Colour"
        visible={visible}
        style={{ width: '50vw' }}
        onHide={() => setVisible(false)}
        dismissableMask
      >
        <Form refetch={refetchData} setVisible={setVisible} />
      </Dialog>
    </>
  )
}

const Edit = ({ colourOption, refetch }) => {
  let [visible, setVisible] = useState(false)

  return (
    <>
      <div className="btn btn-outline-secondary btn-sm mr-2" onClick={() => setVisible(true)}>
        Edit
      </div>
      <SlideIn isOpen={visible} setIsOpen={setVisible} header="Editing Colour Option">
        <Form colourOption={colourOption} refetch={refetch} setVisible={setVisible} />
      </SlideIn>
    </>
  )
}

const Actions = ({ rowData, refetch }) => {
  let { manufacturerSlug } = useParams()
  let [loading, setLoading] = useState(false)

  const deleteRecord = async () => {
    setLoading(true)
    const response = await fetch(
      Routes.manufacturer_colour_option_path(manufacturerSlug, rowData.id),
      {
        method: 'DELETE',
        headers: standardHeaders,
      }
    )
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    refetch()
    setLoading(false)
    return response.json()
  }

  return (
    <div>
      <Edit colourOption={rowData} refetch={refetch} />
      <div className="btn btn-outline-danger btn-sm" onClick={deleteRecord}>
        {loading ? 'Loading...' : 'Delete'}
      </div>
    </div>
  )
}

const ColourOptionsIndex = () => {
  let { manufacturerSlug } = useParams()

  const fetchColourOptions = async () => {
    const response = await fetch(Routes.manufacturer_colour_options_path(manufacturerSlug), {
      headers: standardHeaders,
    })
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    return response.json()
  }

  // Use TanStack Query to fetch and cache the data.
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ['colourOptions', manufacturerSlug],
    queryFn: fetchColourOptions,
    staleTime: 5 * 60 * 1000, // Cache data for 5 minutes
  })

  // Handle loading and error states.
  if (isLoading) return <div>Loading...</div>
  if (error) return <div>Error fetching colour options</div>

  return (
    <div>
      <SectionHeading title="Colour Options">
        <NewForm refetchData={refetch} />
      </SectionHeading>
      <p>
        When we receive colours from third parties, these are often not in a standard format (eg.
        "Ice White"), while we prefer to have these in a generic format (eg. "White"). This resource
        is used to transform both internal and external colour to a generic/standard colour (eg.
        Black, White, Red, etc).
      </p>
      <div className="box p-3 my-3">
        <div>
          <b>Current unmatched exterior colours:</b>{' '}
          {data.unmatched_colours.map((c) => (
            <div className="badge badge-secondary mr-1">
              <CopyableText text={c} />
            </div>
          ))}
        </div>
        <div>
          <b>Current unmatched interior colours:</b>{' '}
          {data.unmatched_interior_colours.map((c) => (
            <div className="badge badge-secondary mr-1">
              <CopyableText text={c} />
            </div>
          ))}
        </div>
      </div>
      <div className="box">
        <DataTable value={data.colour_options} paginator rows={50}>
          <Column
            field="created_at"
            header="Created"
            body={({ created_at }) => moment(created_at).fromNow()}
          />
          <Column field="generic_colour" header="Generic Colour" sortable />
          <Column field="name" header="OEM Name" sortable />
          <Column field="colour_type" header="Type" sortable />
          <Column
            field="colour_alias"
            header="Alias"
            body={(rowData) => <>{rowData.colour_alias.join(', ')}</>}
          />
          <Column
            header="Actions"
            body={(rowData) => <Actions rowData={rowData} refetch={refetch} />}
          />
        </DataTable>
      </div>
    </div>
  )
}

const Wrapper = () => {
  return (
    <div className="container py-3">
      <ColourOptionsIndex />
    </div>
  )
}

export default Wrapper
