import React from 'react'

import TimeRangePicker from '../../entries/TimeRange'
import { useAnalyticsDashboard, useConfig, useItems, useTimeRangeLocation } from '../contexts/hooks'
import LayoutSizeComponent from '../features/DashboardSettings/components/LayoutSizeComponent'
import SelectLocations from '../features/DashboardSettings/components/SelectLocations'
import Settings from '../features/DashboardSettings/components/Settings'
import EditMode from '../features/EditMode/components/EditMode'
import HeaderButtons from '../features/EditMode/components/HeaderButtons'
import ModulePicker from '../features/EditMode/components/ModulePicker'
import { useNameBasedOnLevel } from '../hooks'
import { ModuleItem, TimeRange } from '../types'

// Manufacturer-specific header component
const ManufacturerHeader: React.FC<{
  editModeEnabled: boolean
  items: ModuleItem[]
  handleUpdateTimeRange: (newTimeRange: TimeRange) => void
}> = ({ editModeEnabled, items, handleUpdateTimeRange }) => (
  <div className="row">
    <h3 className="col-12 col-sm-6 pb-2 pb-sm-0">Analytics Dashboard</h3>
    <div className="col-12 col-sm-6 d-flex justify-content-end">
      <TimeRangePicker handleUpdateTimeRange={handleUpdateTimeRange} />
    </div>
    {editModeEnabled && <div className="ml-auto"></div>}
    <div className="col-12 d-flex justify-content-end align-items-center py-2">
      {editModeEnabled ? (
        <div className="d-flex flex-wrap gap-1">
          <LayoutSizeComponent />
          <ModulePicker items={items} />
        </div>
      ) : null}
      <HeaderButtons />
      <EditMode />
    </div>
  </div>
)

const Header: React.FC = () => {
  const { editModeEnabled, currentUser, dashboardLevel } = useAnalyticsDashboard()
  const { items } = useItems()
  const { config } = useConfig()
  const { handleUpdateTimeRange, handleUpdateSelectedLocation } = useTimeRangeLocation()

  const name = useNameBasedOnLevel()

  // Conditional rendering variables
  const showSettings: boolean =
    (currentUser?.admin || !config?.fixedMode) &&
    (dashboardLevel === 'Dealership' || dashboardLevel === 'Website')

  if (dashboardLevel === 'Manufacturer') {
    return (
      <ManufacturerHeader
        editModeEnabled={editModeEnabled}
        items={items}
        handleUpdateTimeRange={handleUpdateTimeRange}
      />
    )
  }

  return (
    <div className="AnalyticsDashboardHeader">
      <div className="row">
        <div className="d-flex col-12 col-sm-6 col-md-5 justify-content-between pb-2 pb-sm-0">
          {/* Show the dashboard name */}
          {name !== undefined ? <h4>{name}</h4> : null}
          {showSettings ? (
            <div className="d-block d-sm-none">
              <Settings />
            </div>
          ) : null}
        </div>
        <div className="col-12 col-sm-6 col-md-7 d-flex justify-content-end">
          <div className="d-flex justify-content-end flex-wrap gap-2 w-100">
            {/* Settings button */}
            {showSettings ? (
              <div className="d-none d-sm-block DashboardSettings">
                <Settings />
              </div>
            ) : null}

            {/* Location picker */}
            {currentUser ? (
              <SelectLocations
                handleUpdateSelectedLocation={handleUpdateSelectedLocation}
                currentUser={currentUser}
              />
            ) : null}

            {/* Time range picker */}
            <TimeRangePicker handleUpdateTimeRange={handleUpdateTimeRange} />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center py-2">
        {/* Dashboard header for non-manufacturers */}
        <h3 className="col-8 p-0">Analytics Dashboard</h3>

        <div className={'col-4 p-0 d-flex justify-content-end'}>
          {/* Module picker for non-manufacturers*/}
          {editModeEnabled ? <ModulePicker items={items} /> : null}
        </div>
      </div>

      <div className="col-12 d-flex flex-wrap justify-content-end align-items-center px-0 pt-2 gap-1">
        {/* Layout size component */}
        {editModeEnabled ? <LayoutSizeComponent /> : null}
        {/* Header buttons */}
        <HeaderButtons />
        {/* Edit mode */}
        <EditMode />
      </div>
    </div>
  )
}

export default Header
