import { useEffect, useRef, useState } from 'react'

import { Toast } from 'primereact/toast'
import Select from 'react-select'

import { humanize } from '../entries/utils'
import showToast from '../shared/ShowToast'

const SelectStatus = ({
  hit,
  attribute = 'lead_status_option_id',
  placeholder = 'Status',
  onStatusChange,
  selectedValue,
  disabled,
  leadStatusOptions = false,
  leadCluster,
  setLeadCluster,
}) => {
  if (!leadStatusOptions) {
    return <></>
  }

  const notification = useRef(null)

  let combined_options = leadStatusOptions.map((option) => {
    return {
      ...option,
      label: humanize(option.name),
      value: option.id,
      colour: option.colour_name,
    }
  })

  let [loading, setLoading] = useState(false)
  let [value, setValue] = useState(combined_options.filter((u) => u.value === hit[attribute])[0])

  useEffect(() => {
    setValue(combined_options.filter((u) => u.value === (selectedValue || hit[attribute]))[0])
  }, [selectedValue, hit[attribute]])

  const onChange = async ({ value, colour, label }) => {
    try {
      setLoading(true)
      setValue({ value, colour, label })

      await onStatusChange(hit.id, value, leadCluster, setLeadCluster)

      showToast(notification, 'success', 'Lead cluster status updated successfully')
    } catch (error) {
      console.error('Error updating status:', error)
      showToast(notification, 'error', 'Failed to update lead cluster status')
    } finally {
      setLoading(false) // Ensures loading is always reset
    }
  }

  const customStyles = {
    singleValue: (provided, status) => {
      let custom = {
        background: `var(--${value?.colour})`,
        color: 'white',
        padding: '0.25rem 0.5rem',
        borderRadius: '0.5rem',
      }

      return { ...provided, ...custom }
    },
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensure dropdown is above the modal
    }),
  }

  return (
    <>
      <Toast ref={notification} />
      <Select
        options={combined_options}
        name={attribute}
        isLoading={loading}
        value={value}
        onChange={onChange}
        styles={customStyles}
        placeholder={placeholder}
        isDisabled={disabled}
        className="status-select"
        menuPortalTarget={document.body}
      />
    </>
  )
}

export default SelectStatus
