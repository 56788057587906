import { useContext, useRef } from 'react'

import moment from 'moment'
import { Panel } from 'primereact/panel'
import { Toast } from 'primereact/toast'
import { useNavigate } from 'react-router-dom'

import { LeadClusterContext } from '../contexts'
import CarsAlsoViewed from '../leads/CarsAlsoViewed'
import { canManageLeads } from '../leads/utils'
import showToast from '../shared/ShowToast'
import Emails from './Emails'

const LeadContainer = ({ lead, position }) => {
  let { leadCluster } = useContext(LeadClusterContext)
  const notification = useRef(null)
  const navigate = useNavigate()
  const url = `/dealerships/${leadCluster.dealership_id}/lead_clusters/move_lead_to_new_lead_cluster`

  const moveToNewLeadCluster = async () => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        },
        body: JSON.stringify({ lead_id: lead.id }),
      })

      if (response.ok) {
        const jsonResponse = await response.json()
        const newLeadClusterId = jsonResponse.lead_cluster_id

        showToast(notification, 'success', 'Lead successfully moved to new Lead Cluster.', '')
        setTimeout(() => {
          navigate(Routes.dealership_lead_cluster_path(leadCluster.dealership_id, newLeadClusterId))
        }, 1000)
      } else {
        showToast(notification, 'error', 'Failed to move lead lead.', '')
      }
    } catch (error) {
      console.error(error)
      showToast(notification, 'error', 'An error occurred.', '')
    }
  }

  const Header = () => (
    <div>
      Lead {position + 1}: {lead.category}
      <small className="text-secondary">
        <span> - {moment(lead.created_at).fromNow()}</span>
        {lead.source && <span> from {lead.source}</span>}
      </small>
    </div>
  )

  const Footer = () => (
    <div className="d-flex">
      <div>
        {canManageLeads() && (
          <>
            <a
              href={`/dealerships/${leadCluster.dealership_id}/leads/${lead.id}/edit`}
              className="btn btn-outline-secondary btn-sm"
            >
              <i className="fa fa-edit mr-2"></i>
              Edit
            </a>
            {leadCluster.leads.length > 1 && (
              <button className="btn btn-secondary btn-sm ml-2" onClick={moveToNewLeadCluster}>
                <i className="fa fa-plus-circle mr-2"></i>
                Move to new Lead Cluster
              </button>
            )}
          </>
        )}
        <Emails
          leadId={lead.id}
          count={lead.emails_and_apicalls_count}
          hasUnitsNotification={lead.has_units_notification}
        />
      </div>
      <div className="ml-auto text-secondary">{moment(lead.created_at).format('h:mm a')}</div>
    </div>
  )

  return (
    <>
      <Toast ref={notification} />
      <Panel header={Header} footer={Footer} toggleable collapsed={position > 2} className="mb-3">
        {lead.car && <p>Lead submitted on a {lead.car.name}</p>}
        <div
          style={{ overflowX: 'scroll' }}
          dangerouslySetInnerHTML={{ __html: lead.leadDetails }}
        />
        {lead.carsAlsoViewed?.length > 0 && <CarsAlsoViewed lead={lead} />}
      </Panel>
    </>
  )
}

export default LeadContainer
